<template>
  <v-dialog
    :value="showDialog"
    @input="closeDialog()"
    content-class="showDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="950px"
    style="z-index: 9999"
  >
    <v-card class="custom-tooltips">
      <v-card-title>
        <span
          v-html="matchTitle"
          class="headline"
          style="font-size: 1.3rem !important"
        ></span>
        <v-btn @click="closeDialog()" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs
        v-model="tab"
        class="custom-user-tabs"
        centered
        background-color="transparent"
        color="basil"
        grow
        show-arrows
        stacked
      >
        <v-tab v-for="(item, idx) in arr" :key="idx" exact>
          {{ item.id }}
        </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item v-for="(item, idx) in arr" :key="idx">
          <v-card class="padding-bottom-tooltips" scrollable>
            <v-card-text
              v-if="item.tooltip && !item.tooltip_new"
              class="padding-bottom-0"
            >
              <v-expansion-panels v-if="explanatoryText" class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="explanatoryText"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <span
                v-html="item.title"
                class="headline"
                style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
              ></span>
              <div v-html="item.tooltip"></div>
            </v-card-text>
            <v-card-text v-if="item.tooltip_new" class="padding-bottom-0">
              <v-expansion-panels v-if="explanatoryText" class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="explanatoryText"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <span
                v-html="item.title"
                class="headline"
                style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
              ></span>
              <div v-html="item.tooltip_new"></div>
            </v-card-text>
            <v-card-text v-if="item.description" class="padding-bottom-0">
              <v-expansion-panels v-if="explanatoryText" class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="explanatoryText"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <span
                v-html="item.title"
                class="headline"
                style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
              ></span>
              <div v-html="item.description"></div>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
      <div class="reg-go">
        <div v-html="RegGoTextBeforeBtn"></div>
        <v-btn
          @click="clickTooltipBtnBG(extTips)"
          v-html="getMessage(btnTooltipName)"
          class="tooltip-btn-bg mob-tooltip-btn-bg"
        ></v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  name: "MultiTooltipDiaolog",
  mixins: [commonInitAppMethods],
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    arr: {
      type: Array,
      default: null
    },
    explanatoryText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tab: this.arr.findIndex((i) => i.id === this.arr[0].id),
      show: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      Composite: (state) => state.account.Composite,
      Transit: (state) => state.account.Transit,
      Infliction: (state) => state.account.Infliction,
      Return: (state) => state.account.Return
    }),
    firstname: {
      get() {
        return this.$store.state.account.User.name;
      },
      set(value) {
        this.$store.commit("account/update_first_name", value);
      }
    },
    matchTitle() {
      return "";
      /* return this.obj.icon
        ? `<span style="color:${
            this.$store.state.account.User.gexagrammColor[this.obj.gate]
          }
          ">${this.obj.icon}</span> ${this.obj.title}`
        : this.obj.title; */
    },
    RegGoTextBeforeBtn() {
      return this.$route.path === "/bodygraph" ||
        this.$route.path === "/transit" ||
        this.$route.path === "/returns"
        ? this.$store.state.account.User.RegGoTextBeforeBtn
        : this.$route.path === "/composite"
        ? this.$store.state.account.User.RegGoTextBeforeBtnComposite
        : "";
    },
    btnTooltipName() {
      return this.$route.path === "/bodygraph" ||
        this.$route.path === "/transit" ||
        this.$route.path === "/returns"
        ? this.$store.state.account.User.btnTooltipName
        : this.$route.path === "/composite"
        ? this.$store.state.account.User.btnTooltipNameComposite
        : "";
    },
    extTips() {
      return this.$store.state.conf.ConfApp.isBuyedProTooltips;
    }
  },
  beforeMount() {
    window.openLoginDialog = this.openLoginDialog;
    window.enableFullTooltips = this.enableFullTooltips;
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      request_full_tooltips_on: "conf/request_full_tooltips_on"
    }),
    clickTooltipBtnBG(extTips, isInfoBlockExtTips) {
      if (
        this.$route.path === "/bodygraph" ||
        this.$route.path === "/transit" ||
        this.$route.path === "/returns"
      ) {
        const SHOP_URL =
          extTips === "ext_tips"
            ? this.$store.state.conf.ConfApp.tooltips_buy_full_url
            : extTips
            ? this.$store.state.conf.ConfApp.tooltips_shop_url
            : this.$store.state.conf.ConfApp.tooltips_sale_shop_url;
        switch (this.$store.state.conf.ConfApp.tooltip_button_type) {
          case 1:
            if (isInfoBlockExtTips) {
              const URL = SHOP_URL + "&tag=r" + this.User.reportid;
              window.open(URL, "_blank");
            } else {
              this.closeDialog();
              this.update_login_dialog(true);
            }
            break;
          case 2:
            this.$router.push("/profile");
            break;
          case 3: {
            // this.$router.push("/products");
            const URL3 = this.$store.state.conf.ConfApp.pdfs_shop_url;
            window.open(URL3, "_blank");
            break;
          }
          case 4: {
            const URL4 = SHOP_URL + "&tag=r" + this.User.reportid;
            window.open(URL4, "_blank");
            break;
          }
          case 6: {
            // Здесь сейчас показываются всегда FULL-подсказки
            const SHOP_URL6 = this.$store.state.conf.ConfApp
              .tooltips_buy_full_url;
            const URL6 = SHOP_URL6 + "&tag=r" + this.User.reportid;
            window.open(URL6, "_blank");
            break;
          }
          case 7:
            // FULL-подсказки выключены, предложение включить
            this.$router.push("/profile");
            break;
          case 8: {
            // Если приходит 8, то в бодиграфе предлагаются демо-расшифровки
            const url8 = this.$store.state.conf.ConfApp.demo_report_shop_url;
            window.open(url8, "_blank");
            break;
          }
          default:
            if (this.User.isLogged) {
              // реклама партнерки по дифолту (отключена)
              /* this.$store.commit(
                "account/set_partner_info_text",
                this.getMessage("partners")
              );
              this.$store.commit("account/call_partner_dialog"); */
              // по дифолту для авторизованных юзеров сейчас переход на покупку PDF
              const URL = this.$store.state.conf.ConfApp.pdfs_shop_url;
              window.open(URL, "_blank");
            } else {
              this.closeDialog();
              this.update_login_dialog(true);
            }
            break;
        }
      } else if (this.$route.path === "/composite") {
        switch (this.$store.state.conf.ConfApp.cp_tooltip_button_type) {
          case 1:
            this.update_login_dialog(true);
            break;
          case 2:
            this.$router.push("/profile");
            break;
          case 3:
            this.$router.push("/products");
            break;
          case 4: {
            const SHOP_URL4 = extTips
              ? this.$store.state.conf.ConfApp.tooltips_shop_url
              : this.$store.state.conf.ConfApp.tooltips_sale_shop_url;
            const url4 = SHOP_URL4 + "&tag=r" + this.User.reportid;
            window.open(url4, "_blank");
            break;
          }
          case 5: {
            const url5 =
              this.$store.state.conf.ConfApp.composite_shop_url +
              "&tag=r" +
              this.User.reportid;
            window.open(url5, "_blank");
            break;
          }
          case 6: {
            /* const SHOP_URL6 =
                extTips ||
                extTips === 'ext_tips' ||
                this.User.full_tooltips_count
                  ? this.$store.state.ConfApp.tooltips_buy_full_url
                  : this.$store.state.ConfApp.tooltips_shop_url */
            // Если приходит 6, то в композите предлагаются только расширенные
            const SHOP_URL6 = this.$store.state.conf.ConfApp
              .tooltips_buy_full_url;
            const url6 = SHOP_URL6 + "&tag=r" + this.User.reportid;
            window.open(url6, "_blank");
            break;
          }
          case 7:
            // FULL-подсказки выключены, предложение включить
            this.$router.push("/profile");
            break;
          case 8: {
            // Если приходит 8, то в бодиграфе предлагаются демо-расшифровки
            const url8 = this.$store.state.conf.ConfApp.demo_report_shop_url;
            window.open(url8, "_blank");
            break;
          }
          default:
            if (this.User.isLogged) {
              // реклама партнерки по дифолту (отключена)
              /* this.$store.commit(
                "account/set_partner_info_text",
                this.getMessage("partners")
              );
              this.$store.commit("account/call_partner_dialog"); */
              // по дифолту для авторизованных юзеров сейчас переход на покупку PDF
              const URL = this.$store.state.conf.ConfApp.pdfs_shop_url;
              window.open(URL, "_blank");
            } else {
              this.closeDialog();
              this.update_login_dialog(true);
            }
            break;
        }
      }
    },
    openLoginDialog() {
      this.update_login_dialog(true);
    },
    closeDialog() {
      this.$emit("closeMultiTooltipDialog");
    },
    async enableFullTooltips() {
      const endPoint = this.User.user_products_id
        ? "account/getReportFromUserProductById"
        : this.Composite.report_id && this.$route.path === "/composite"
        ? "account/getCompositeReportById"
        : this.Infliction.report_id && this.$route.path === "/transit"
        ? "account/getInflictionReportById"
        : this.Transit.report_id && this.$route.path === "/transit"
        ? "account/getTransitReportById"
        : this.Return.report_id && this.$route.path === "/returns"
        ? "account/getReturnReportById"
        : "account/getRaveReportById";
      const reportId = this.User.user_products_id
        ? this.User.user_products_id
        : this.Composite.report_id && this.$route.path === "/composite"
        ? this.Composite.report_id
        : this.Infliction.report_id && this.$route.path === "/transit"
        ? this.Infliction.report_id
        : this.Transit.report_id && this.$route.path === "/transit"
        ? this.Transit.report_id
        : this.Return.report_id && this.$route.path === "/returns"
        ? this.Return.report_id
        : this.User.reportid;
      const successResult = await this.$store.dispatch(endPoint, {
        id: reportId,
        isFullTooltips: true
      });
      if (successResult) {
        // console.log("successResult: ", successResult);
        this.closeDialog();
        this.request_full_tooltips_on();
      }
    }
  }
};
</script>
<style scoped>
.custom-user-tabs.v-tabs {
  flex: initial;
}
.v-window.v-item-group {
  overflow-y: auto;
}
</style>
