<template>
  <v-container>
    <v-row>
      <v-col
        class="text-center nopadding"
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="newReport(1)"
              class="mx-2 min-width-120 margin-btm-5 hd-gradient"
              large
              color="primary"
            >
              <v-icon dark>mdi-plus</v-icon>&nbsp;Нового расчета
            </v-btn>
          </template>
          <span>Создать новый расчет</span>
        </v-tooltip>
        <div
          v-if="
            $store.state.User.reportid ||
              userProdReports.length ||
              userProReports.length ||
              userReports.length
          "
        >
          или
        </div>
      </v-col>
      <br />
      <v-col
        v-if="
          $store.state.User.reportid ||
            userProdReports.length ||
            userProReports.length ||
            userReports.length
        "
        cols="12"
        class="text-center modal-remember nopadding"
      >
        выберите нужную карту и нажмите кнопку «Выбрать»
      </v-col>
      <br />
      <v-col
        v-if="$store.state.User.reportid"
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div class="display-0-5 text--primary demo-report-header">
          <p class="text-align-left text--primary">
            Текущий расчет:
          </p>
        </div>
        <div class="text--primary d-flex modal-view-reports-wrapper">
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div class="display-0-24">Исходные данные</div>
            <ul class="ul-no-li-style">
              <li>
                <v-icon>mdi-account</v-icon>
                <span>{{ currentReportName }}</span>
              </li>
              <li>
                <v-icon>mdi-calendar-today</v-icon>
                <span>{{ currentReportBirthtime }}</span>
              </li>
              <li>
                <v-icon>mdi-city-variant</v-icon>
                <span>
                  {{ currentReportCity }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div class="display-0-24">Human Design</div>
            <ul class="ul-no-li-style">
              <li>
                <v-icon>mdi-account-box-outline</v-icon>
                <span>
                  {{ currentReportType }}
                </span>
              </li>
              <li>
                <v-icon>mdi-account-circle</v-icon>
                <span>
                  {{ currentReportProfile }}
                  {{ currentReportProfileId }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div class="display-0-24">Действия</div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="selectUserReport($store.state.User.reportid)"
                  class="mx-2 min-width-120 margin-btm-5"
                  small
                  color="primary"
                >
                  <v-icon dark>mdi-check</v-icon>&nbsp;Выбрать
                </v-btn>
              </template>
              <span>Добавить рейв-карту</span>
            </v-tooltip>
          </v-col>
        </div>
      </v-col>
      <br />
      <v-col
        v-if="userProdReports.length && $store.state.User.isLogged"
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div
          class="display-0-5 text--primary demo-report-header"
          style="margin-top: 25px"
        >
          <p class="text-align-left text--primary">
            Расшифровки:
          </p>
        </div>
        <div
          v-for="(usereport, i) in userProdReports"
          :key="i"
          class="text--primary d-flex modal-view-reports-wrapper"
        >
          <v-col
            v-if="
              userProdReports.length === 1 &&
                usereport.id === $store.state.User.reportid
            "
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <div class="text-center display-0-24">
              Данный отчет текущий
            </div>
          </v-col>
          <v-col
            v-if="userProdReports.length === 1 && !usereport.is_used"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <div class="text-center display-0-24">
              Данный отчет еще неактивирован
            </div>
          </v-col>
          <v-col
            v-if="
              usereport.id !== $store.state.User.reportid && usereport.is_used
            "
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Исходные данные</div>
            <ul class="ul-no-li-style">
              <li v-if="usereport.first_name">
                <v-icon>mdi-account</v-icon>
                <span>{{ usereport.first_name }}</span>
              </li>
              <li v-if="usereport.email">
                <v-icon>mdi-at</v-icon>
                <span>{{ usereport.email }}</span>
              </li>
              <li v-if="usereport.birthday">
                <v-icon>mdi-calendar-today</v-icon>
                <span>{{ usereport.birthday }}</span>
              </li>
              <li v-if="usereport.city">
                <v-icon>mdi-city-variant</v-icon>
                <span>{{ usereport.city.title }}</span>
              </li>
            </ul>
          </v-col>
          <v-col
            v-if="
              usereport.id !== $store.state.User.reportid && usereport.is_used
            "
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Human Design</div>
            <ul class="ul-no-li-style">
              <li v-if="usereport.chart_type">
                <v-icon>mdi-account-box-outline</v-icon>
                <span>{{ usereport.chart_type }}</span>
              </li>
              <li v-if="usereport.chart_profile">
                <v-icon>mdi-account-circle</v-icon>
                <span>
                  {{ usereport.chart_profile_title }}
                  {{ usereport.chart_profile }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            v-if="
              usereport.id !== $store.state.User.reportid && usereport.is_used
            "
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Действия</div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="selectUserReport(usereport.id, 'user')"
                  class="mx-2 min-width-120 margin-btm-5"
                  small
                  color="primary"
                >
                  <v-icon dark>mdi-check</v-icon>&nbsp;Выбрать
                </v-btn>
              </template>
              <span>Добавить рейв-карту</span>
            </v-tooltip>
          </v-col>
        </div>
      </v-col>
      <br />
      <v-col
        v-if="userProReports.length && $store.state.User.isLogged"
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div
          class="display-0-5 text--primary demo-report-header"
          style="margin-top: 25px"
        >
          <p class="text-align-left text--primary">
            Pro-подсказки:
          </p>
        </div>
        <div
          v-for="(proreport, i) in userProReports"
          :key="i"
          class="text--primary d-flex modal-view-reports-wrapper"
        >
          <v-col
            v-if="
              userProReports.length === 1 &&
                proreport.id === $store.state.User.reportid
            "
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <div class="text-center display-0-24">
              Данный отчет текущий
            </div>
          </v-col>
          <v-col
            v-if="proreport.id !== $store.state.User.reportid"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Исходные данные</div>
            <ul class="ul-no-li-style">
              <li v-if="proreport.first_name">
                <v-icon>mdi-account</v-icon>
                <span>{{ proreport.first_name }}</span>
              </li>
              <li v-if="proreport.email">
                <v-icon>mdi-at</v-icon>
                <span>{{ proreport.email }}</span>
              </li>
              <li v-if="proreport.birthday">
                <v-icon>mdi-calendar-today</v-icon>
                <span>{{ proreport.birthday }}</span>
              </li>
              <li v-if="proreport.city">
                <v-icon>mdi-city-variant</v-icon>
                <span>{{ proreport.city.title }}</span>
              </li>
            </ul>
          </v-col>
          <v-col
            v-if="proreport.id !== $store.state.User.reportid"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Human Design</div>
            <ul class="ul-no-li-style">
              <li v-if="proreport.chart_type">
                <v-icon>mdi-account-box-outline</v-icon>
                <span>{{ proreport.chart_type }}</span>
              </li>
              <li v-if="proreport.chart_profile">
                <v-icon>mdi-account-circle</v-icon>
                <span>
                  {{ proreport.chart_profile_title }}
                  {{ proreport.chart_profile }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            v-if="proreport.id !== $store.state.User.reportid"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Действия</div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="selectUserReport(proreport.id)"
                  class="mx-2 min-width-120 margin-btm-5"
                  small
                  color="primary"
                >
                  <v-icon dark>mdi-check</v-icon>&nbsp;Выбрать
                </v-btn>
              </template>
              <span>Добавить рейв-карту</span>
            </v-tooltip>
          </v-col>
        </div>
      </v-col>
      <br />
      <v-col
        v-if="userReports.length && $store.state.User.isLogged"
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div
          class="display-0-5 text--primary demo-report-header"
          style="margin-top: 25px"
        >
          <p class="text-align-left text--primary">
            Демо-расчеты:
          </p>
        </div>
        <div
          v-for="(report, i) in userReports"
          :key="i"
          class="text--primary d-flex modal-view-reports-wrapper"
        >
          <v-col
            v-if="
              userReports.length === 1 &&
                report.id === $store.state.User.reportid
            "
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <div class="text-center display-0-24">
              Данный отчет текущий
            </div>
          </v-col>
          <v-col
            v-if="report.id !== $store.state.User.reportid"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Исходные данные</div>
            <ul class="ul-no-li-style">
              <li v-if="report.first_name">
                <v-icon>mdi-account</v-icon>
                <span>{{ report.first_name }}</span>
              </li>
              <li v-if="report.email">
                <v-icon>mdi-at</v-icon>
                <span>{{ report.email }}</span>
              </li>
              <li v-if="report.birthday">
                <v-icon>mdi-calendar-today</v-icon>
                <span>{{ report.birthday }}</span>
              </li>
              <li v-if="report.city">
                <v-icon>mdi-city-variant</v-icon>
                <span>{{ report.city.title }}</span>
              </li>
            </ul>
          </v-col>
          <v-col
            v-if="report.id !== $store.state.User.reportid"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Human Design</div>
            <ul class="ul-no-li-style">
              <li v-if="report.chart_type">
                <v-icon>mdi-account-box-outline</v-icon>
                <span>{{ report.chart_type }}</span>
              </li>
              <li v-if="report.chart_profile">
                <v-icon>mdi-account-circle</v-icon>
                <span>
                  {{ report.chart_profile_title }}
                  {{ report.chart_profile }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            v-if="report.id !== $store.state.User.reportid"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <div class="display-0-24">Действия</div>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="selectUserReport(report.id)"
                  class="mx-2 min-width-120 margin-btm-5"
                  small
                  color="primary"
                >
                  <v-icon dark>mdi-check</v-icon>&nbsp;Выбрать
                </v-btn>
              </template>
              <span>Добавить рейв-карту</span>
            </v-tooltip>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="createravedialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Добавление новой Рейв-карты
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="createravedialog = false" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-col>
              <form>
                <v-card-text class="mob-nopadding">
                  <v-row>
                    <v-col
                      class="mob-padding-top-0-bottom-10"
                      cols="4"
                      sm="2"
                      md="2"
                      lg="2"
                      xl="2"
                    >
                      <v-select
                        v-on:change="onDayChange"
                        v-model="day"
                        :hint="`день`"
                        :items="days"
                        item-text="day"
                        label="Выберите день"
                        autofocus
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col
                      class="mob-padding-top-0-bottom-10"
                      cols="4"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                    >
                      <v-select
                        ref="monthSelect"
                        v-on:change="onMonthChange"
                        v-model="month"
                        :hint="`месяц`"
                        :items="months"
                        item-text="month"
                        label="Выберите месяц"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col
                      class="mob-padding-top-0-bottom-10"
                      cols="4"
                      sm="2"
                      md="2"
                      lg="2"
                      xl="2"
                    >
                      <v-select
                        ref="yearSelect"
                        v-on:change="onYearChange"
                        v-model="year"
                        :hint="`год`"
                        :items="years"
                        item-text="year"
                        label="Выберите год"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col
                      class="mob-padding-top-0-bottom-10"
                      cols="6"
                      sm="2"
                      md="2"
                      lg="2"
                      xl="2"
                    >
                      <v-select
                        ref="hourSelect"
                        v-on:change="onHourChange"
                        v-model="hour"
                        :hint="`часы`"
                        :items="hours"
                        item-text="hour"
                        label="Выберите час"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col
                      class="mob-padding-top-0-bottom-10"
                      cols="6"
                      sm="2"
                      md="2"
                      lg="2"
                      xl="2"
                    >
                      <v-select
                        ref="minuteSelect"
                        v-on:change="onMinuteChange"
                        v-model="minute"
                        :hint="`минуты`"
                        :items="minutes"
                        item-text="minute"
                        label="Выберите минуту"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col
                      class="mob-padding-top-0-bottom-10"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                    >
                      <v-autocomplete
                        ref="cityInput"
                        v-on:change="onCityChange"
                        v-model="city"
                        :items="cities"
                        :loading="isLoading"
                        :search-input.sync="search"
                        :error-messages="cityErrors"
                        :no-filter="true"
                        @input="$v.city.$touch()"
                        @blur="$v.city.$touch()"
                        chips
                        clearable
                        hide-details
                        hide-selected
                        item-text="title"
                        item-value="id"
                        label="Город"
                        required
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title v-if="!search || isLoading">
                              Ближайший крупный <strong>город</strong>...
                            </v-list-item-title>
                            <v-list-item-title
                              v-else
                              class="v-list-item-title-custom"
                            >
                              <span
                                v-if="!searchResult"
                                v-html="getMessage('tooltip_find_city')"
                              ></span>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            v-on="on"
                            v-bind="attr"
                            :input-value="selected"
                            color="white"
                            class="black--text"
                          >
                            <span v-text="item.title"></span>&nbsp;
                            <img
                              :src="
                                'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                  item.country.iso +
                                  '.svg'
                              "
                            />
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.country.title"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <img
                              :src="
                                'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                  item.country.iso +
                                  '.svg'
                              "
                            />
                          </v-list-item-action>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2" class="mob-dispalay-none"></v-col>
                    <v-col
                      class="mob-padding-top-0-bottom-10"
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                    >
                      <v-text-field
                        ref="firstnameInput"
                        v-model="firstname"
                        :error-messages="nameErrors"
                        :counter="35"
                        @input="$v.firstname.$touch()"
                        @blur="$v.firstname.$touch()"
                        label="Имя"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col
                      class="text-center display-0-24-no-line-h"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >
                      У вас осталось
                      {{ $store.state.User.full_tooltips_count }} бесплатных
                      расчетов.
                    </v-col> -->
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row class="d-flex justify-center">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="text-center"
                    >
                      <v-btn
                        @click="validateNewReport(chartNum)"
                        rounded
                        large
                        color="primary"
                        class="v-btn-custom v-btn-custom--bg-f"
                      >
                        Построить карту&nbsp;<img
                          src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                          width="20"
                        />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </form>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin, commonInitAppMethods],
  validations: {
    firstname: { required, maxLength: maxLength(35) },
    day: { required },
    month: { required },
    year: { required },
    hour: { required },
    minute: { required },
    city: { required }
  },
  data() {
    return {
      tabAddInfo: 0,
      firstname: "",
      isChartToggle: true,
      isChart2Toggle: true,
      chartcompositedialog: false,
      chart2compositedialog: false,
      createravedialog: false,
      progressdialog: false,
      RegGoTextBeforeBtn: "",
      btnTooltipName: "",
      isVisibleTooltipBtn: true,
      chartBodygraphChartName: "",
      chartNum: 0,
      chartBodygraphChart: null,
      chartBodygraphChart2: null,
      chartBodygraphConnection: [],
      chartBodygraphChannels: {},
      chartBodygraphLove: {},
      chartBodygraphTypeComptb: {},
      chartBodygraphCenters: {},
      connectionGatesDefined: [],
      connectionCentersUndefined: [],
      loading: true,
      isLoading: false,
      isShowTDButton: false,
      userReports: [],
      userProReports: [],
      userProdReports: [],
      compReports: {},
      city: 18009,
      search: null,
      searchResult: true,
      toolTips: {},
      gatesUndefinedCentres: [],
      day: { day: "15" },
      month: { month: "Июнь (6)", id: "06" },
      year: { year: "1990" },
      hour: { hour: "12" },
      minute: { minute: "30" }
    };
  },
  computed: {
    ...mapState(["dateTime"]),
    gexagrammColor() {
      return this.$store.state.User.gexagrammColor;
    },
    days() {
      return this.dateTime.days;
    },
    months() {
      return this.dateTime.months;
    },
    years() {
      return this.cutAllYearsMoreCurrent(this.dateTime.years);
    },
    hours() {
      return this.dateTime.hours;
    },
    minutes() {
      return this.dateTime.minutes;
    },
    cities: {
      get() {
        return this.$store.state.User.lastCities;
      },
      set(value) {
        this.$store.commit("account/update_last_cities", value);
      }
    },
    currentReportName: {
      get() {
        return this.$store.state.User.firstname;
      },
      set(value) {
        this.$store.commit("account/update_first_name", value);
      }
    },
    currentReportBirthtime: {
      get() {
        const year = this.$store.state.User.year.year;
        const month = this.$store.state.User.month.id;
        const day = this.$store.state.User.day.day;
        const hour = this.$store.state.User.hour.hour;
        const minute = this.$store.state.User.minute.minute;
        const birthtime =
          year + "-" + month + "-" + day + " " + hour + ":" + minute;
        return birthtime;
        // return this.$store.state.User.chartBodygraphChart.birthtime
      },
      set(value) {
        this.$store.commit("UPDATE_CHART_BODYGRAPH_BIRTHTIME", value);
      }
    },
    currentReportCity() {
      this.$getItemFromLocalStorage("city", "UPDATE_CITY");
      return this.$store.state.User.city;
    },
    currentReportType() {
      let type = "";
      if (this.$store.state.User.chartBodygraph)
        type = this.$store.state.User.chartBodygraph.type.title;
      return type;
    },
    currentReportProfile() {
      let profile = "";
      if (this.$store.state.User.chartBodygraph)
        profile = this.$store.state.User.chartBodygraph.profile.title;
      return profile;
    },
    currentReportProfileId() {
      let profileId = "";
      if (this.$store.state.User.chartBodygraph)
        profileId = this.$store.state.User.chartBodygraph.profile.id;
      return profileId;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.maxLength &&
        errors.push("Имя должно быть не более 35 символов.");
      !this.$v.firstname.required && errors.push("Введите имя.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Выберите город.");
      return errors;
    },
    extTips() {
      return this.$store.state.ConfApp.isBuyedProTooltips;
    }
  },
  watch: {
    search(val) {
      if (!val) return;
      this.fetchCitiesDebounced(val);
    }
  },
  beforeMount() {
    window.clickTooltipBtnCP = this.clickTooltipBtnCP;
    window.clearTooltips = this.$clearTooltips;
    window.clearTooltipsCP = this.$clearTooltips;
    window.offerTooltipsBuyCP = this.$offerTooltipsBuy;
    window.offerTooltipsReverseCP = this.$offerTooltipsReverse;
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "UPDATE_CONF_APP_COMM_TAB":
          this.tab = state.ConfApp.community_tab;
          this.chartBodygraph = state.User.chartBodygraph;
          this.toolTips = state.User.toolTips;
          this.$clearTooltips();
          this.initActiveGatesInUndefinedCentres();
          break;
        case "UPDATE_CLEAR_TOOLTIPS":
          this.$clearTooltips();
          break;
        case "GET_COMPOSITE_BY_ID":
          this.getBodygraphByComposite(
            this.$store.state.Composite.method,
            this.$store.state.Composite.report_id
          );
          break;
      }
    });
    this.selectUserReport();
  },
  methods: {
    onDayChange() {
      this.$refs.monthSelect.activateMenu();
      this.$refs.monthSelect.focus();
    },
    onMonthChange() {
      this.$refs.yearSelect.activateMenu();
      this.$refs.yearSelect.focus();
    },
    onYearChange() {
      this.$refs.hourSelect.activateMenu();
      this.$refs.hourSelect.focus();
    },
    onHourChange() {
      this.$refs.minuteSelect.activateMenu();
      this.$refs.minuteSelect.focus();
    },
    onMinuteChange() {
      this.$refs.cityInput.focus();
    },
    onCityChange() {
      this.$refs.cityInput.blur();
      this.$refs.firstnameInput.focus();
    },
    validateNewReport(chartNum) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.getBodygraph(chartNum);
      }
    },
    chartCompositeReport() {
      this.selectUserReport();
    },
    async selectUserReport(id, type) {
      if (!this.$store.state.User.isLogged) {
        this.userReports = [];
        this.userProReports = [];
        this.userProdReports = [];
        this.compReports = {};
        if (id) {
          this.compReports.id1 = id;
          if (type) this.compReports.type1 = type;
          this.chartcompositedialog = false;
          this.chart2compositedialog = true;
        } else {
          this.chartcompositedialog = true;
        }
        this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
      } else {
        try {
          const result = await this.$getProfile.get();
          // console.log(result)
          if (result.success) {
            this.userReports = [];
            this.userProReports = [];
            this.userProdReports = [];
            this.compReports = {};
            this.userReports = result.profile.reports || [];
            this.userProReports = result.profile.pro_reports || [];
            this.userProdReports = result.profile.user_products || [];
            if (id) {
              this.compReports.id1 = id;
              if (type) this.compReports.type1 = type;
              this.chartcompositedialog = false;
              this.chart2compositedialog = true;
            } else {
              this.chartcompositedialog = true;
            }
            this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    async getBodygraphByComposite(method, id, id2, type2) {
      try {
        this.progressdialog = true;
        this.chartcompositedialog = false;
        this.chart2compositedialog = false;
        this.createravedialog = false;
        this.chartNum = 1;
        const compositereport = {};
        if (method === "get" || method === "edit") {
          compositereport.report_id = id;
        } else if (method === "create") {
          if (this.compReports.type1 === "user") {
            compositereport.user_product_id = id;
          } else {
            compositereport.report_id = id;
          }
          if (type2 === "user") {
            compositereport.user_product_id2 = id2;
          } else {
            compositereport.report_id2 = id2;
          }
        }
        let result;
        method === "create"
          ? (result = await this.$createCompositeReport.post(compositereport))
          : (result = await this.$getCompositeReport.get(compositereport));
        if (result.success) {
          this.chartBodygraphChartName = result.report.first_name;
          this.chartBodygraphChartName2 = result.report.first_name2;
          this.$store.commit("UPDATE_CHART_BODYGRAPH_COMPOSITE", result.report);
          this.$store.commit(
            "UPDATE_COMPOSITE_HASH",
            result.report.report_hash
          );
          this.$store.commit(
            "UPDATE_CP_TOOLTIPS_BUTTON_TYPE",
            result.report.tooltip_button_type
          );
          this.chartBodygraphChart = this.$store.state.Composite.chartBodygraphChart;
          this.chartBodygraphChart2 = this.$store.state.Composite.chartBodygraphChart2;
          this.progressdialog = false;
          this.connectionGatesDefined = [];
          this.connectionCentersUndefined = [];
          this.connectionGatesDefined = this.$store.state.Composite.chartBodygraphConnection.gates3;
          this.connectionCentersUndefined = this.$store.state.Composite.chartBodygraphConnection.centersUndefined;
          this.chartBodygraphConnection = this.$store.state.Composite.chartBodygraphConnection;
          this.chartBodygraphChannels = this.$store.state.Composite.chartBodygraphConnection.channels;
          this.chartBodygraphLove = this.$store.state.Composite.chartBodygraphConnection.love;
          this.chartBodygraphCenters = this.$store.state.Composite.chartBodygraphConnection.centers;
          this.chartBodygraphTypeComptb = this.$store.state.Composite.chartBodygraphConnection.type_compatibility;
          this.loading = false;
          if (method === "edit") {
            this.chartCompositeReport();
          }
          this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
          // setTimeout(() => this.$callPanZoom('#svg1582_composite'), 0)
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getBodygraph(chartNum) {
      // Prefetch Cities, UserName, DD/MM/YYYY HH:MM to localStorage
      localStorage.setItem("name", this.firstname);
      localStorage.setItem("lastCities", JSON.stringify(this.cities));
      this.$store.commit("account/update_last_cities", this.cities);
      localStorage.setItem("cityId", JSON.stringify(this.city));
      localStorage.setItem("day", JSON.stringify(this.day));
      localStorage.setItem("month", JSON.stringify(this.month));
      localStorage.setItem("year", JSON.stringify(this.year));
      localStorage.setItem("hour", JSON.stringify(this.hour));
      localStorage.setItem("minute", JSON.stringify(this.minute));
      try {
        const birthday =
          this.year.year +
          "-" +
          this.month.id +
          "-" +
          this.day.day +
          " " +
          this.hour.hour +
          ":" +
          this.minute.minute;
        const reportParams = {
          birthday,
          city_id: this.city,
          first_name: this.firstname,
          email: this.$store.state.User.email
        };
        const result = await this.$createRaveReport.post(reportParams);
        if (result.success) {
          if (chartNum === 1) {
            // Refresh appStorage chartBodygraph, Tooltips, Report Id & Stored
            this.$store.commit("UPDATE_CITY", result.report.city.title);
            localStorage.setItem(
              "city",
              JSON.stringify(result.report.city.title)
            );
            this.$store.commit(
              "UPDATE_CHART_BODYGRAPH",
              result.report.body_graph
            );
            this.$store.commit("UPDATE_TOOLTIPS", result.report.tooltips);
            this.$store.commit("UPDATE_REPORT_ID", result.report.id);
            this.$store.commit("UPDATE_IS_STORED", result.report.is_stored);
            // Save to localStorage chartBodygraph, Tooltips, Report Id & Stored
            localStorage.setItem(
              "chartBodygraph",
              JSON.stringify(result.report.body_graph)
            );
            localStorage.setItem(
              "toolTips",
              JSON.stringify(result.report.tooltips)
            );
            this.compReports.id1 = result.report.id;
            localStorage.setItem("reportId", result.report.id);
            localStorage.setItem("is_stored", result.report.is_stored);
            this.$store.commit("UPDATE_CITY_ID", result.report.city.id);
            this.$store.commit(
              "account/update_first_name",
              result.report.first_name
            );
            this.$store.commit("UPDATE_DAY", this.day);
            this.$store.commit("UPDATE_MONTH", this.month);
            this.$store.commit("UPDATE_YEAR", this.year);
            this.$store.commit("UPDATE_HOUR", this.hour);
            this.$store.commit("UPDATE_MINUTE", this.minute);
            // firstbgdialog
            if (
              !localStorage.getItem("firstBgDialog") &&
              !this.$store.state.account.User.isLogged
            ) {
              localStorage.setItem("firstBgDialog", true);
              this.$store.commit("UPDATE_FIRST_BG_DIALOG", true);
            }
            this.$store.commit(
              "UPDATE_CP_TOOLTIPS_BUTTON_TYPE",
              result.report.tooltip_button_type
            );
            localStorage.setItem("reportHash", result.report.report_hash);
            this.$store.commit("UPDATE_REPORT_HASH", result.report.report_hash);
            localStorage.setItem(
              "confApp",
              JSON.stringify(this.$store.state.ConfApp)
            );
            this.createravedialog = false;
            this.chart2compositedialog = true;
          } else {
            this.getBodygraphByComposite(
              "create",
              this.compReports.id1,
              result.report.id
            );
          }
          this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getCompositeByHash(hash) {
      try {
        this.chartcompositedialog = false;
        this.chart2compositedialog = false;
        this.progressdialog = true;
        const compositeReportUrl = { report_hash: hash };
        const result = await this.$getSharedCompositeReport.get(
          compositeReportUrl
        );
        if (result.success) {
          this.chartBodygraphChartName = result.report.first_name;
          this.chartBodygraphChartName2 = result.report.first_name2;
          this.$store.commit("UPDATE_CHART_BODYGRAPH_COMPOSITE", result.report);
          this.$store.commit(
            "UPDATE_COMPOSITE_HASH",
            result.report.report_hash
          );
          this.$store.commit(
            "UPDATE_CP_TOOLTIPS_BUTTON_TYPE",
            result.report.tooltip_button_type
          );
          this.chartBodygraphChart = this.$store.state.Composite.chartBodygraphChart;
          this.chartBodygraphChart2 = this.$store.state.Composite.chartBodygraphChart2;
          this.connectionGatesDefined = [];
          this.connectionCentersUndefined = [];
          this.progressdialog = false;
          this.connectionGatesDefined = this.$store.state.Composite.chartBodygraphConnection.gates3;
          this.connectionCentersUndefined = this.$store.state.Composite.chartBodygraphConnection.centersUndefined;
          this.chartBodygraphConnection = this.$store.state.Composite.chartBodygraphConnection;
          this.chartBodygraphChannels = this.$store.state.Composite.chartBodygraphConnection.channels;
          this.chartBodygraphLove = this.$store.state.Composite.chartBodygraphConnection.love;
          this.chartBodygraphCenters = this.$store.state.Composite.chartBodygraphConnection.centers;
          this.chartBodygraphTypeComptb = this.$store.state.Composite.chartBodygraphConnection.type_compatibility;
          this.loading = false;
          this.createravedialog = false;
          this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
          // setTimeout(() => this.$callPanZoom('#svg1582_composite'), 0)
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    // Events for init bodygraph
    initActiveGatesInUndefinedCentres() {
      this.gatesUndefinedCentres = [];
      this.connectionCentersUnDefined = [];
      if (this.chartBodygraphChart) {
        // Search activated gates in undefined centers
        this.connectionCentersUnDefined.forEach((center) => {
          this.$store.state.centerToGates[center].forEach((gate) => {
            Object.values(this.chartBodygraphChart.gates).forEach((acgates) => {
              if (+acgates === +gate) this.gatesUndefinedCentres.push(gate);
            });
          });
        });
        // console.log(this.gatesUndefinedCentres)
        // For mobile devices scroll down
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
          this.$scrollToTop();
        }, 500); /* 500ms throttle */
      }
    },
    /* scrollToSvg() {
      document.getElementsByClassName('svg_bg')[0].scrollTo({
        left: document.getElementById('svg1582_composite').scrollWidth / 5,
        behavior: 'smooth'
      })
    }, */
    clickTooltipBtnCP(extTips) {
      switch (this.$store.state.ConfApp.cp_tooltip_button_type) {
        case 1:
          this.$dialogconfirm();
          break;
        case 3:
          this.$router.push("/products");
          break;
        case 4: {
          const SHOP_URL4 = extTips
            ? this.$store.state.ConfApp.tooltips_shop_url
            : this.$store.state.ConfApp.tooltips_sale_shop_url;
          const url4 = SHOP_URL4 + "&tag=r" + this.$store.state.User.reportid;
          window.open(url4, "_blank");
          break;
        }
        case 5: {
          const url5 =
            this.$store.state.ConfApp.composite_shop_url +
            "&tag=r" +
            this.$store.state.User.reportid;
          window.open(url5, "_blank");
          break;
        }
        case 6: {
          const SHOP_URL6 =
            extTips || extTips === "ext_tips"
              ? this.$store.state.ConfApp.tooltips_buy_full_url
              : this.$store.state.ConfApp.tooltips_shop_url;
          const url6 = SHOP_URL6 + "&tag=r" + this.$store.state.User.reportid;
          window.open(url6, "_blank");
          break;
        }
        default:
          this.$store.commit(
            "account/set_partner_info_text",
            this.getMessage("partners")
          );
          this.$store.commit("account/call_partner_dialog");
          break;
      }
    },
    getTooltipsMsg(obj) {
      if (obj) {
        switch (this.$store.state.ConfApp.cp_tooltip_button_type) {
          case 1: {
            const gexColor = obj.gate ? this.gexagrammColor[obj.gate] : "";
            const msg =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
              gexColor +
              '">' +
              obj.icon +
              "</span> " +
              obj.title +
              "</h3>" +
              obj.tooltip +
              '<div class="tooltip-inner-divider"></div>';
            this.RegGoTextBeforeBtn = this.$getMessage(
              "tooltip_composite_normal"
            );
            this.btnTooltipName = "btn_tooltip_normal";
            return msg;
          }
          case 2: {
            const gexColor2 = obj.gate ? this.gexagrammColor[obj.gate] : "";
            const msg2 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
              gexColor2 +
              '">' +
              obj.icon +
              "</span> " +
              obj.title +
              "</h3>" +
              obj.tooltip +
              '<div class="tooltip-inner-last-child"></div>';
            this.RegGoTextBeforeBtn = this.$getMessage(
              "tooltip_composite_notconfirmed"
            );
            this.btnTooltipName = "btn_tooltip_composite_notconfirmed";
            return msg2;
          }
          case 3: {
            const gexColor3 = obj.gate ? this.gexagrammColor[obj.gate] : "";
            const msg3 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
              gexColor3 +
              '">' +
              obj.icon +
              "</span> " +
              obj.title +
              "</h3>" +
              obj.tooltip +
              '<div class="tooltip-inner-divider"></div>';
            this.RegGoTextBeforeBtn = this.$getMessage(
              "tooltip_composite_full"
            );
            this.btnTooltipName = "btn_tooltip_full";
            return msg3;
          }
          case 4: {
            const gexColor4 = obj.gate ? this.gexagrammColor[obj.gate] : "";
            const msg4 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
              gexColor4 +
              '">' +
              obj.icon +
              "</span> " +
              obj.title +
              "</h3>" +
              obj.tooltip +
              '<div class="tooltip-inner-divider"></div>';
            this.RegGoTextBeforeBtn = this.$getMessage("tooltip_composite_pro");
            this.btnTooltipName = "btn_tooltip_pro";
            return msg4;
          }
          case 5: {
            const gexColor5 = obj.gate ? this.gexagrammColor[obj.gate] : "";
            const msg5 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
              gexColor5 +
              '">' +
              obj.icon +
              "</span> " +
              obj.title +
              "</h3>" +
              obj.tooltip +
              '<div class="tooltip-inner-divider"></div>';
            this.RegGoTextBeforeBtn = this.$getMessage("tooltip_composite_buy");
            this.btnTooltipName = "btn_tooltip_composite_buy";
            return msg5;
          }
          case 6: {
            const gexColor6 = this.gexagrammColor[obj.gate]
              ? this.gexagrammColor[obj.gate]
              : "";
            /* const TOOLTIPS_FULL_OFFER_BUY = this.$store.state.User
              .isTooltipsOfferbuy
              ? this.$getMessage('tooltip_buy_full') +
                '<div style="display:flex;justify-content:center"><button class="tooltip-inner-btn-offer-no" onclick="offerTooltipsBuyCP(false)">Ок, позже</button>&nbsp;' +
                '<button class="tooltip-inner-btn-offer" onclick="clickTooltipBtnCP(`ext_tips`)">Подробнее</button></div>'
              : ''
            const TOOLTIPS_EXP_PANEL_HEADER_CHEVRON = this.$store.state.User
              .isTooltipsOfferbuy
              ? '<i class="v-icon notranslate mdi mdi-chevron-up"></i>'
              : '<i class="v-icon notranslate mdi mdi-chevron-down"></i>' */
            const msg6 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
              gexColor6 +
              '">' +
              obj.icon +
              "</span> " +
              obj.title +
              "</h3>" +
              /* '<div class="tooltip-inner-divider"><div class="tooltip-inner-offer-buy">' +
              '<div class="tooltip-inner-expansion-panel-header" onclick="offerTooltipsReverseCP()">Открыть доступ' +
              TOOLTIPS_EXP_PANEL_HEADER_CHEVRON +
              '</div>' +
              TOOLTIPS_FULL_OFFER_BUY +
              '</div></div>' + */
              obj.tooltip +
              '<div class="tooltip-inner-divider"></div>';
            this.RegGoTextBeforeBtn = this.$getMessage("tooltip_buy_full");
            this.btnTooltipName = "btn_tooltip_buy_full";
            return msg6;
          }
          default: {
            const gexColorDf = this.gexagrammColor[obj.gate]
              ? this.gexagrammColor[obj.gate]
              : "";
            const msgDf =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
              gexColorDf +
              '">' +
              obj.icon +
              "</span> " +
              obj.title +
              "</h3>" +
              obj.tooltip +
              '<div class="tooltip-inner-divider"></div>';
            this.RegGoTextBeforeBtn = this.$getMessage("tooltip_partner");
            this.btnTooltipName = "btn_tooltip_partner";
            return msgDf;
          }
        }
      } else {
        // Ошибка в названии ключа подсказки! Проверь Одминко!
        return "Что-то пошло не так...";
      }
    },
    fetchCitiesDebounced(val) {
      this.search = this.$searchRequestProcessing(val);
      this.isLoading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchCities(this.search);
        this.isLoading = false;
      }, 500); /* 500ms throttle */
    },
    async fetchCities(searchrequest) {
      if (searchrequest) {
        try {
          this.searchResult = true;
          const searchRequest = { q: searchrequest };
          const result = await this.$searchCity.get(searchRequest);
          if (result.success) {
            this.cities = result.cities;
            this.$citiesMemoization(result.cities);
            if (!this.cities.length) {
              this.searchResult = false;
            }
          }
          this.isLoading = false;
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    // Defined Context Gates Points
    isDefinedTogglePointGate1(num) {
      if (
        this.$isDefinedObj(
          this.chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
          num
        )
      ) {
        return false;
      } else if (
        this.$isDefinedObj(
          this.chartBodygraphChart2.gatesActivatedAtDesignOnly,
          num
        ) &&
        !this.isChartToggle
      ) {
        return true;
      } else if (
        this.$isDefinedObj(
          this.chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
          num
        ) &&
        !this.isChartToggle
      ) {
        return true;
      }
    },
    isDefinedTogglePointGate2(num) {
      if (
        this.$isDefinedObj(
          this.chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
          num
        )
      ) {
        return false;
      } else if (
        this.$isDefinedObj(
          this.chartBodygraphChart.gatesActivatedAtDesignOnly,
          num
        ) &&
        !this.isChart2Toggle
      ) {
        return true;
      } else if (
        this.$isDefinedObj(
          this.chartBodygraphChart.gatesActivatedAtPersonalityOnly,
          num
        ) &&
        !this.isChart2Toggle
      ) {
        return true;
      }
    },
    // Search activated gates in hovered center
    isGatesActiveForCenter(center) {
      if (this.chartBodygraphChart) {
        this.$store.state.centerToGates[center].forEach((gate) => {
          Object.values(this.chartBodygraphChart.gates).forEach((acgates) => {
            if (+acgates === +gate) {
              // this.$store.commit('PUSH_TO_GATE_LINES_CENTER_HOVER', gate)
              this.searchAcPlanets(gate); // Call to activated gates in planet context
            }
          });
          Object.values(this.chartBodygraphChart2.gates).forEach((acgates) => {
            if (+acgates === +gate) {
              // this.$store.commit('PUSH_TO_GATE_LINES_CENTER_HOVER', gate)
              this.searchAcPlanets(gate); // Call to activated gates in planet context
            }
          });
        });
        // console.log(this.gateLinesCenterHover)
      }
    },
    // Search activated planets for gate
    // Key is gate number
    // versum = 1 is Person; versum = 2 is Design; versum = 3 is Both;
    searchAcPlanets(key) {
      if (key in this.chartBodygraphChart.gatesExtended) {
        if (this.chartBodygraphChart.gatesExtended[key].versum === 1) {
          this.chartBodygraphChart.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("SET_IS_PLANET_PER", { planet, num: 1 });
            }
          );
        } else if (this.chartBodygraphChart.gatesExtended[key].versum === 2) {
          this.chartBodygraphChart.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("SET_IS_PLANET_DES", { planet, num: 1 });
            }
          );
        } else {
          this.chartBodygraphChart.gatesExtended[key].planets.forEach(
            (planet) => {
              if (
                +key === +this.chartBodygraphChart.mandalas[0][planet].gate &&
                +key === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("SET_IS_PLANET_PER", { planet, num: 1 });
                this.$store.commit("SET_IS_PLANET_DES", { planet, num: 1 });
              } else if (
                +key === +this.chartBodygraphChart.mandalas[0][planet].gate
              ) {
                this.$store.commit("SET_IS_PLANET_PER", { planet, num: 1 });
              } else if (
                +key === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("SET_IS_PLANET_DES", { planet, num: 1 });
              } else {
                // console.log('Data Error! Check chartBodygraphChart')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key in this.chartBodygraphChart2.gatesExtended) {
        if (this.chartBodygraphChart2.gatesExtended[key].versum === 1) {
          this.chartBodygraphChart2.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("SET_IS_PLANET_PER2", { planet, num: 1 });
            }
          );
        } else if (this.chartBodygraphChart2.gatesExtended[key].versum === 2) {
          this.chartBodygraphChart2.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("SET_IS_PLANET_DES2", { planet, num: 1 });
            }
          );
        } else {
          this.chartBodygraphChart2.gatesExtended[key].planets.forEach(
            (planet) => {
              if (
                +key === +this.chartBodygraphChart2.mandalas[0][planet].gate &&
                +key === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("SET_IS_PLANET_PER2", { planet, num: 1 });
                this.$store.commit("SET_IS_PLANET_DES2", { planet, num: 1 });
              } else if (
                +key === +this.chartBodygraphChart2.mandalas[0][planet].gate
              ) {
                this.$store.commit("SET_IS_PLANET_PER2", { planet, num: 1 });
              } else if (
                +key === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("SET_IS_PLANET_DES2", { planet, num: 1 });
              } else {
                // console.log('Data Error! Check chartBodygraphChart2')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
    },
    // Search The All Same Gates
    searchAllAcPlanets(num) {
      this.searchAcPlanets(num);
      this.searchGateAcInCenter(num);
    },
    // Search & Return Activated Gate (planet hover) in Center
    searchGateAcInCenter(num) {
      if (this.chartBodygraphChart) {
        for (const center in this.$store.state.centerToGates) {
          this.$store.state.centerToGates[center].forEach((gate) => {
            if (+num === +gate) {
              this.$store.commit("PUSH_TO_GATE_IN_CENTER_HOVER", gate);
              // console.log(gate, center, this.$store.state.gateInCenterHover)
            }
          });
        }
      }
      if (this.chartBodygraphChart2) {
        for (const center in this.$store.state.centerToGates) {
          this.$store.state.centerToGates[center].forEach((gate) => {
            if (+num === +gate) {
              this.$store.commit("PUSH_TO_GATE_IN_CENTER_HOVER", gate);
            }
          });
        }
      }
    },
    newReport(num) {
      if (num) this.chartNum = num;
      this.day = { day: "15" };
      this.month = { month: "Июнь (6)", id: "06" };
      this.year = { year: "1990" };
      this.hour = { hour: "12" };
      this.minute = { minute: "30" };
      this.firstname = "";
      this.city = "";
      this.chartcompositedialog = false;
      this.chart2compositedialog = false;
      this.createravedialog = true;
      this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
    },
    showChart() {
      this.$clearTooltips();
      this.isChartToggle = !this.isChartToggle;
    },
    showChart2() {
      this.$clearTooltips();
      this.isChart2Toggle = !this.isChart2Toggle;
    }
  }
};
</script>
