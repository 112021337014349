<template>
  <v-dialog
    v-model="showDialog"
    @click:outside="closeDialog"
    scrollable
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >&nbsp;</span
        >
        <v-btn @click="closeDialog" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="no-top-bottom-padding">
        <v-container class="no-top-bottom-padding mob-flex-center-height-100">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-bottom-padding display-0-24-no-line-h"
            >
              <img
                v-if="$store.state.conf.errorDialog.image"
                :src="$store.state.conf.errorDialog.image"
                width="120"
                class="padding-bottom-40"
              />
              <span
                v-html="$store.state.conf.errorDialog.message"
                style="display:block;font-size:16px;margin-bottom:33px"
              >
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex justify-center">
          <v-col
            v-if="
              $store.state.conf.errorDialog.button1 &&
                $store.state.conf.errorDialog.button2
            "
            cols="12"
            class="text-center flex-space-evenly padding-top-0 padding-bottom-52 margin-top-25 margin-bottom-15"
          >
            <v-btn
              @click="closeDialog"
              :href="$store.state.conf.errorDialog.button1"
              target="_blank"
              class="my-prof-controls-flex"
              color="#2565d2"
              fab
              small
              icon
              dark
            >
              <v-icon style="font-size:26px">mdi-email-fast-outline</v-icon>
              <span class="d-flex" style="font-size:11px;margin-top:5px">
                E-mail
              </span>
            </v-btn>
            <v-btn
              @click="closeDialog"
              :href="$store.state.conf.errorDialog.button2"
              target="_blank"
              class="my-prof-controls-flex"
              color="#8660dc"
              fab
              small
              icon
              dark
            >
              <v-icon style="font-size:26px">mdi-near-me</v-icon>
              <span class="d-flex" style="font-size:11px;margin-top:5px">
                Telegram
              </span>
            </v-btn>
          </v-col>
          <v-col
            v-else
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center padding-bottom-52"
          >
            <v-btn
              @click="closeDialog"
              :href="supportUrl"
              target="_blank"
              color="primary accent-4"
              class="small-med mx-2 hd-gradient"
              >Написать</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  name: "LogoutDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [commonInitAppMethods],
  data() {
    return {
      supportUrl: "https://human-design.space/support/"
    };
  },
  computed: {
    //
  },
  methods: {
    closeDialog() {
      this.$emit("closeErrorDialog");
    }
  }
};
</script>
