<template>
  <v-dialog
    :value="showDialog"
    @input="closeDialog()"
    content-class="showDialog"
    scrollable
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >&nbsp;</span
        >
        <v-btn @click="closeDialog()" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="no-top-bottom-padding">
        <v-container class="mob-flex-center-height-100">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-top-bottom-padding display-0-24-mob-p-d"
            >
              <span
                v-html="$store.state.account.Profile.partner_info_text"
                style="display:block;margin-bottom:33px;font-size:16px;line-height:20px"
              >
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center padding-bottom-52"
          >
            <v-btn
              @click="closeDialog()"
              color="primary accent-4"
              class="small-med mx-2 hd-gradient"
              >Понятно</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PartnerInfoDiaolog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closePartnerInfoDialog");
    }
  }
};
</script>
