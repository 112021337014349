<template>
  <v-card flat d-flex pa-2>
    <v-card-text>
      <v-row id="reports_row">
        <v-col
          v-for="(report, i) in reports"
          :key="i"
          class="mx-auto padding-2-0"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          outlined
        >
          <v-card
            class="mx-auto"
            max-width="950"
            style="padding: 15px !important"
          >
            <div class="d-flex justify-space-around align-center">
              <div class="text-align-left no-mobile">
                <v-icon>mdi-account</v-icon>
                <span>{{ report.first_name }}</span>
                <span class="no-mobile">
                  <br /><v-icon>mdi-account-box-outline</v-icon>
                  <span>{{ report.chart_type }}</span>
                  <br />
                  <v-icon>mdi-account-circle</v-icon>
                  <span>
                    {{ report.chart_profile_title }}
                    {{ report.chart_profile }}
                  </span>
                </span>
              </div>
              <div class="no-desktop">
                <v-icon>mdi-account</v-icon><br />
                <span>{{ report.first_name }}</span>
              </div>
              <div class="no-mobile">
                <v-icon>mdi-calendar-today</v-icon>
                <span>
                  {{ dateTimeFormatting(report.birthday) }}
                </span>
              </div>
              <div class="no-desktop">
                <v-icon>mdi-calendar-today</v-icon>
                <br />
                <span>
                  {{ dateFormatting(report.birthday) }}
                </span>
              </div>
              <div class="no-mobile">
                <v-btn
                  @click="getBodygraphById(report.id)"
                  class="min-width-120"
                  small
                  color="primary"
                  style="margin-top:5px"
                >
                  <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                </v-btn>
              </div>
              <div class="no-desktop">
                <v-btn
                  @click="getBodygraphById(report.id)"
                  class="my-prof-controls-flex span-padding-left-0"
                  color="primary"
                  fab
                  small
                  icon
                  dark
                >
                  <v-icon dark>mdi-eye</v-icon>
                  <span
                    class="d-flex"
                    style="font-size:11px;text-transform:initial"
                  >
                    Открыть
                  </span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
        <div v-intersect="onIntersect" class="pa-4 teal--text">
          <span v-if="page < pageCount">Подождите идет загрузка ...</span>
          <span v-else>Загрузка завершена.</span>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  name: "ReportsHistory",
  data() {
    return {
      selected: null,
      perPage: 20,
      page: 1
    };
  },
  mixins: [commonInitAppMethods, commonErrorsMethods],
  computed: {
    ...mapState(["dateTime"]),
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    reports: {
      get() {
        return this.User.reportsHistory.reports;
      },
      set(arr) {
        this.set_reports_history(arr);
      }
    },
    pageCount() {
      return this.User.reportsHistory.pageCount;
    },
    days() {
      return this.dateTime.days;
    },
    months() {
      return this.dateTime.months;
    },
    years() {
      return this.cutAllYearsMoreCurrent(this.dateTime.years);
    },
    hours() {
      return this.dateTime.hours;
    },
    minutes() {
      return this.dateTime.minutes;
    }
  },
  created() {
    this.getReports();
  },
  methods: {
    ...mapMutations({ set_reports_history: "account/set_reports_history" }),
    async getBodygraphById(reportId) {
      const successResult = await this.$store.dispatch(
        "account/getRaveReportById",
        { id: reportId }
      );
      if (successResult) {
        this.$router.push("/bodygraph");
      }
    },
    closeDialog() {
      this.$emit("closeReportsDialog", true);
    },
    dateFormatting(date) {
      const year = date.slice(0, 4);
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);
      const newDate = `${day}-${month}-${year}`;
      return newDate;
    },
    dateTimeFormatting(date) {
      const year = date.slice(0, 4);
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);
      const time = date.slice(11);
      const newDate = `${day}-${month}-${year} ${time}`;
      return newDate;
    },
    // Infinity Scroll first implementation
    async getReports() {
      // console.log('page', this.page)
      try {
        const result = await Api.getRaveReports(this.page, this.HttpOptions);
        if (result.reports.length) {
          this.reports = [...this.reports, ...result.reports];
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    onIntersect() {
      if (this.page < this.pageCount) {
        // console.log('load more...')
        this.page += 1;
        this.getReports();
      } else {
        // console.log('load done!!!')
      }
    }
  }
};
</script>
<style scoped></style>
