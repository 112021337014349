<template>
  <v-dialog
    :value="showDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="closeDialog"
    scrollable
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >Смена имени</span
        >
        <v-btn @click="closeDialog()" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="text-center">
                  <div class="log-sucsess">{{ nameSuccess }}</div>
                  <div class="log-errors">{{ nameErrors }}</div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-text-field
                    v-model="newname"
                    :rules="[rules.required]"
                    type="text"
                    validate-on-blur
                    label="Новое имя"
                    hint="Введите новое имя"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row class="d-flex justify-center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="text-center"
                >
                  <v-btn
                    @click="closeDialog()"
                    color="primary darken-1"
                    class="margin-right-3"
                    >Закрыть</v-btn
                  >
                  <v-btn
                    @click="changeName()"
                    :disabled="!newname"
                    color="primary"
                  >
                    Сменить имя
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from "@/api/api";
import { mapState } from "vuex";

export default {
  name: "ChangeNameDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newname: "",
      nameSuccess: "",
      nameErrors: "",
      rules: {
        required: (value) => !!value || "Обязательный параметр."
      }
    };
  },
  computed: {
    ...mapState({
      HttpOptions: (state) => state.conf.http_options
    })
  },
  methods: {
    async changeName() {
      try {
        this.nameErrors = "";
        const changeNameParams = {
          first_name: this.newname
        };
        const result = await Api.changeName(changeNameParams, this.HttpOptions);
        if (result.success) {
          this.nameData = result;
          if (this.nameData.success) {
            this.nameSuccess = "Имя успешно изменено!";
            localStorage.setItem("name", this.nameData.profile.first_name);
            this.$store.commit(
              "account/update_name",
              this.nameData.profile.first_name
            );
            setTimeout(() => this.closeDialog(), 500);
          } else {
            this.nameErrors = this.nameData.errors.message;
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
        this.nameErrors = e.response.data.message;
      }
    },
    closeDialog() {
      this.newname = "";
      this.nameSuccess = "";
      this.nameErrors = "";
      this.$emit("closeChangeNameDialog");
    }
  }
};
</script>
<style scoped>
.margin-right-3 {
  margin-right: 3px;
}
</style>
