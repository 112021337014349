<template>
  <v-dialog
    :value="showDialog"
    @input="closeDialog()"
    content-class="showDialog"
    max-width="500px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >&nbsp;</span
        >
        <v-btn @click="closeDialog()" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="no-top-bottom-padding">
        <v-container class="no-top-bottom-padding">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-top-bottom-padding display-0-24-no-line-h"
            >
              <span
                v-html="getMessage('partners_shr')"
                class="partner-info-text"
              ></span>
              <textarea
                ref="targetUrl"
                :value="shareLink"
                style="height:1px;opacity:0"
              ></textarea>
              <div style="display:block;margin:7px 0">
                <v-btn
                  @click="copyToClipboard()"
                  class="mx-1_5 btn-bg-share"
                  small
                  text
                >
                  <v-icon dark>mdi-content-copy</v-icon>
                  <span>
                    &nbsp;&nbsp;Скопировать ссылку
                  </span>
                </v-btn>
                <br />
                <v-snackbar v-model="copied" class="snack-custom" top>
                  Ссылка скопирована
                </v-snackbar>
              </div>
              <div style="margin:15px 0 43px">
                <yandex-share
                  v-if="shareLink"
                  :services="[
                    'vkontakte',
                    'facebook',
                    'instagram',
                    'skype',
                    'telegram',
                    'whatsapp',
                    'odnoklassniki',
                    'moimir'
                  ]"
                  :url="shareLink"
                  image="https://static.human-design.space/share-default.png"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import YandexShare from "@cookieseater/vue-yandex-share";

export default {
  name: "ShareReportDialog",
  components: { YandexShare },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    shareLink: {
      type: String,
      default: ""
    }
  },
  mixins: [commonReportingMethods],
  data() {
    return {
      copied: false
    };
  },
  methods: {
    copyToClipboard() {
      this.copied = true;
      setTimeout(() => (this.copied = false), 1500);
      this.$refs.targetUrl.select();
      document.execCommand("copy");
    },
    closeDialog() {
      this.$emit("closeShareReportDialog");
    }
  }
};
</script>
<style scoped>
.partner-info-text {
  display: block;
  max-height: 120px;
}
</style>
