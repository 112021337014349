var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"z-index":"9999"},attrs:{"value":_vm.showDialog,"content-class":"showDialog","max-width":"500px"},on:{"input":function($event){return _vm.closeDialog()}}},[_c('v-card',{staticClass:"modal-pay-report"},[_c('v-card-title',[_c('span',{staticClass:"headline",staticStyle:{"font-size":"1.3rem !important"}},[_vm._v(" ")]),_c('v-btn',{attrs:{"icon":"","absolute":"","right":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"no-top-bottom-padding"},[_c('v-container',{staticClass:"no-top-bottom-padding"},[_c('v-row',[_c('v-col',{staticClass:"text-center no-top-bottom-padding display-0-24-no-line-h",attrs:{"cols":"12"}},[_c('span',{staticClass:"partner-info-text",domProps:{"innerHTML":_vm._s(_vm.getMessage('partners_shr'))}}),_c('textarea',{ref:"targetUrl",staticStyle:{"height":"1px","opacity":"0"},domProps:{"value":_vm.shareLink}}),_c('div',{staticStyle:{"display":"block","margin":"7px 0"}},[_c('v-btn',{staticClass:"mx-1_5 btn-bg-share",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.copyToClipboard()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-copy")]),_c('span',[_vm._v("   Скопировать ссылку ")])],1),_c('br'),_c('v-snackbar',{staticClass:"snack-custom",attrs:{"top":""},model:{value:(_vm.copied),callback:function ($$v) {_vm.copied=$$v},expression:"copied"}},[_vm._v(" Ссылка скопирована ")])],1),_c('div',{staticStyle:{"margin":"15px 0 43px"}},[(_vm.shareLink)?_c('yandex-share',{attrs:{"services":[
                  'vkontakte',
                  'facebook',
                  'instagram',
                  'skype',
                  'telegram',
                  'whatsapp',
                  'odnoklassniki',
                  'moimir'
                ],"url":_vm.shareLink,"image":"https://static.human-design.space/share-default.png"}}):_vm._e()],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }