import Store from "@/store/index";
import Api from "@/api/api";
import vuetify from "@/plugins/vuetify";
import Router from "@/router";

export const commonInitAppMethods = {
  methods: {
    initApp: () => {
      // Don't context menu & selection call
      document.oncontextmenu = () => {
        return false;
      };
      document.onselectstart = () => false;
      if (!localStorage.getItem("isSecondTime")) {
        Store.commit("IS_FIRST_TIME", true);
      } else {
        Store.commit("IS_FIRST_TIME", false);
      }
      if (localStorage.getItem("token")) {
        Store.commit("account/login_success", true);
      }
    },
    initConfApp: () => {
      // Don't context menu & selection call
      document.oncontextmenu = () => {
        return false;
      };
      document.onselectstart = () => false;
      // Restoring confApp from local storage
      const confApp = JSON.parse(localStorage.getItem("confApp"));
      if (confApp) {
        if (confApp.firstOpenUpdates === false) {
          Store.commit("conf/update_conf_app_first_open_updates", false);
        } else {
          Store.commit("conf/update_conf_app_first_open_updates", true);
        }
        if (confApp.firstOpenCompUpdates === false) {
          Store.commit("conf/update_conf_app_first_open_comp_updates", false);
        } else {
          Store.commit("conf/update_conf_app_first_open_comp_updates", true);
        }
        if (confApp.firstOpenChildUpdates === false) {
          Store.commit("conf/update_conf_app_first_open_child_updates", false);
        } else {
          Store.commit("conf/update_conf_app_first_open_child_updates", true);
        }
        // Restoring a saved theme
        if (confApp.theme === "dark") {
          vuetify.framework.theme.isDark = true;
          Store.commit("conf/update_dark_theme", true);
          Store.commit("conf/update_conf_app_theme", "dark");
        }
      }
      // Restoring regTab from localStorage
      const regTab = localStorage.getItem("regTab");
      if (regTab) {
        Store.commit("conf/update_reg_tab", +regTab);
      }
      // Restoring rave & composite saved localstorage's reports
      const rave = localStorage.getItem("chartBodygraph")
        ? JSON.parse(localStorage.getItem("chartBodygraph"))
        : "";
      if (rave) {
        Store.commit("account/update_chart_bodygraph", rave);
        Store.commit(
          "account/update_chart_bodygraph_name",
          localStorage.getItem("bodygraph_name")
        );
        Store.commit(
          "account/update_chart_bodygraph_age",
          localStorage.getItem("bodygraph_age")
        );
        Store.commit(
          "account/update_chart_bodygraph_city",
          localStorage.getItem("bodygraph_city")
        );
        localStorage.getItem("tooltip_button_type")
          ? Store.commit(
              "conf/update_tooltips_button_type",
              JSON.parse(localStorage.getItem("tooltip_button_type"))
            )
          : Store.commit("conf/update_tooltips_button_type", 0);
        localStorage.getItem("demo_report_shop_url")
          ? Store.commit(
              "conf/update_demo_report_shop_url",
              localStorage.getItem("demo_report_shop_url")
            )
          : Store.commit("conf/update_demo_report_shop_url", "");
      }
      // Restoring Social Steppers
      const socialSteppers = localStorage.getItem("social_steppers")
        ? JSON.parse(localStorage.getItem("social_steppers"))
        : "";
      if (socialSteppers === false) {
        Store.commit("account/update_social_steppers", socialSteppers);
      }
      //Restoring Social Steppers
      const beforeSelector = localStorage.getItem("before_people_searching")
        ? JSON.parse(localStorage.getItem("before_people_searching"))
        : "";
      if (beforeSelector === false) {
        Store.commit("account/update_before_people_searching", beforeSelector);
      }
      // Restoring rave report or user Id saved localstorage
      const reportId = localStorage.getItem("reportId")
        ? JSON.parse(localStorage.getItem("reportId"))
        : "";
      if (reportId) {
        Store.commit("account/update_report_id", reportId);
      }
      // Restoring rave report Name Rod saved localstorage
      const reportNameRod = localStorage.getItem("reportNameRod");
      if (reportNameRod) {
        Store.commit("account/update_report_name_rod", reportNameRod);
      }
      // Restoring rave report hash saved localstorage
      const reportHash = localStorage.getItem("reportHash");
      if (reportHash) {
        Store.commit("account/update_report_hash", reportHash);
      }
      // Restoring rave report stored localstorage
      const isStored = localStorage.getItem("is_stored")
        ? JSON.parse(localStorage.getItem("is_stored"))
        : "";
      if (isStored) {
        Store.commit("account/update_is_stored", isStored);
      }
      // Restoring rave & composite reports
      const reportsHistory = localStorage.getItem("raveReports")
        ? JSON.parse(localStorage.getItem("raveReports"))
        : "";
      // Restoring firstOpenSocial
      if (localStorage.getItem("firstOpenSocial")) {
        Store.commit("conf/update_first_open_social", true);
      }
      if (reportsHistory) {
        Store.commit("account/set_reports_history", reportsHistory.reports);
        Store.commit(
          "account/set_reports_history_count",
          reportsHistory.totalCount
        );
        Store.commit(
          "account/set_reports_history_page_count",
          reportsHistory.pageCount
        );
      }
      const compositesHistory = JSON.parse(
        localStorage.getItem("compositeReports")
      );
      if (compositesHistory) {
        Store.commit(
          "account/set_composites_history",
          compositesHistory.reports
        );
        Store.commit(
          "account/set_composites_history_count",
          compositesHistory.totalCount
        );
        Store.commit(
          "account/set_composites_history_page_count",
          compositesHistory.pageCount
        );
      }
    },
    // scroll SVG for mobile version
    /* scrollToTop: () => {
      window.scrollTo(0, 0);
    }, */
    scrollToSvg: (id) => {
      document.getElementsByClassName("svg_bg")[0].scrollTo({
        left: document.getElementById(id).scrollWidth / 5,
        behavior: "smooth"
      });
    },
    getMessage: (key) => {
      return Store.state.conf.Messages[key];
    },
    transformText: (str) => {
      if (str) {
        // str.replace(/\n/g, '<br />')
        return str.replace(/\r\n|\r|\n/g, "<br />");
      } else {
        return str;
      }
    },
    // Chats
    isUserConnected: (id) => {
      return Store.state.account.User.community.chats.onlineUserIDs.includes(
        id
      );
    },
    getItemFromLocalStorage: (item, method) => {
      if (
        localStorage.getItem(item) &&
        localStorage.getItem(item) !== "undefined"
      ) {
        Store.commit(method, JSON.parse(localStorage.getItem(item)));
      }
    },
    setItemToLocalStorage: (name, item) => {
      localStorage.setItem(name, item);
    },
    cutAllYearsMoreCurrent(years) {
      const currentYear = new Date().getFullYear();
      return years.filter((year) => +year.year <= +currentYear);
    },
    // Search cities result processing
    searchRequestProcessing(val) {
      let search = val.trim();
      search = search.replace(/(\s*)(-)(\s*)/, '$2'); // eslint-disable-line
      search = search.replace(/\s+/, ' '); // eslint-disable-line
      return search;
    },
    // Search cities result memoization
    citiesMemoization(arr) {
      if (arr.length) {
        Store.commit("account/update_last_cities", arr);
        localStorage.setItem("lastCities", JSON.stringify(arr));
      }
    },
    // Sign Out / Log Out / Exit
    logout: (name, tabNum) => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("namereg");
      // localStorage.removeItem("email");
      localStorage.removeItem("reportId");
      localStorage.removeItem("reportHash");
      localStorage.removeItem("userProductId");
      localStorage.removeItem("userProductHash");
      localStorage.removeItem("cityId");
      localStorage.removeItem("lastCities");
      localStorage.removeItem("chartBodygraph");
      localStorage.removeItem("toolTips");
      localStorage.removeItem("is_stored");
      localStorage.removeItem("is_subscribed_to_justclick");
      localStorage.removeItem("year");
      localStorage.removeItem("month");
      localStorage.removeItem("day");
      localStorage.removeItem("hour");
      localStorage.removeItem("minute");
      // localStorage.removeItem("confApp");
      localStorage.removeItem("sessionID");
      Store.commit("account/login_success", false);
      Store.commit("account/update_token", "human-design-token");
      Store.commit("conf/set_token", "human-design-token");
      Store.commit("account/update_user_id", null);
      // Store.commit("account/update_first_name", "");
      // Если передаем имя, то не удаляем его
      if (!name) {
        localStorage.removeItem("name");
        Store.commit("account/update_name", "");
      }
      Store.commit("account/update_justclick", false);
      localStorage.setItem("regTab", 1);
      // Если передаем таб, то отображем его иначе всегда — вход
      if (tabNum !== undefined) {
        Store.commit("conf/update_reg_tab", tabNum);
      } else {
        Store.commit("conf/update_reg_tab", 1);
      }
      Store.commit("account/update_last_cities", []);
      Store.commit("account/set_reports_history_count", 0);
      Store.commit("account/update_chart_bodygraph", null);
      Store.commit("account/update_report_id", "");
      /* Store.commit("UPDATE_REPORT_ID", "");
      Store.commit("UPDATE_REPORT_ID_PRO", "");
      Store.commit("UPDATE_USER_PRODUCT_ID", "");
      Store.commit("IS_USER_PRODUCT_HASH", false);
      Store.commit("UPDATE_CITY_ID", "");
      Store.commit("UPDATE_CHART_BODYGRAPH", null);
      Store.commit("UPDATE_CHART_BODYGRAPH_CHART", null);
      Store.commit("UPDATE_TOOLTIPS", {});
      Store.commit("UPDATE_IS_STORED", 0);
      Store.commit("account/update_justclick", false);
      Store.commit("UPDATE_DAY", { day: "15" });
      Store.commit("UPDATE_MONTH", {
        month: "Июнь (6)",
        id: "06"
      });
      Store.commit("UPDATE_YEAR", { year: "1990" });
      Store.commit("UPDATE_HOUR", { hour: "12" });
      Store.commit("UPDATE_MINUTE", { minute: "30" }); */
      // If active tab = 5 routing to Profile
      // store.commit("UPDATE_REG_TAB", 1)
      localStorage.setItem("regTab", JSON.stringify(1));
      /* Store.commit("HAVE_IS_USED_USER_PRODUCTS", false);
      Store.commit("HAVE_IS_USED_PRO_REPORTS", false);
      Store.commit("conf/update_login_dialog", true); */
      Store.commit("account/update_registered", false);
      localStorage.removeItem("tooltip_button_type");
      Store.commit("conf/update_tooltips_button_type", 0);
      Store.commit("conf/update_demo_report_shop_url", "");
      /* Store.commit("UPDATE_PURCHASES", null);
      Store.commit("DETECT_ADMIN", false);
      Store.commit("UPDATE_IS_FULL_TOOLTIPS_USAGE_ENABLE", false);
      Store.commit("RESET_PASSWORD");
      Store.commit("UPDATE_CONF_APP_COMM_TAB", 0);
      Store.commit("SET_REPORTS_HISTORY_COUNT", 0);
      Store.commit("SET_COMPOSITES_HISTORY_COUNT", 0); */
      Store.commit("account/normalize_profile");
      localStorage.removeItem("raveReports");
      localStorage.removeItem("compositeReports");
      Store.commit("forms/setLogSuccess", "");
      Store.commit("forms/setLogErrors", "");
    },
    async verify() {
      // authToken init before calling verify user status
      //console.log("call to verify");
      if (localStorage.getItem("authToken")) {
        Store.commit("account/update_token", localStorage.getItem("authToken"));
        Store.commit("conf/set_token", localStorage.getItem("authToken"));
      }
      try {
        const result = await Api.verify({}, Store.state.conf.http_options);
        // console.log(result);
        if (result.success && result.access_token) {
          // Запись профиля в стор
          Store.commit("account/set_profile", result.profile);
          // АВТОЛОГИН
          Store.commit("account/login_success", true);
          Store.commit("account/update_token", result.access_token);
          Store.commit("conf/set_token", result.access_token);
          localStorage.setItem("authToken", result.access_token);
          localStorage.setItem(
            "is_subscribed_to_justclick",
            result.profile.is_subscribed_to_justclick
          );
          Store.commit(
            "account/update_justclick",
            result.profile.is_subscribed_to_justclick
          );
          if (result.profile.is_subscribed_to_justclick) {
            Store.commit("account/update_registered", true);
          } else {
            Store.commit("account/update_registered", false);
          }
          localStorage.setItem("user_id", result.profile.id);
          Store.commit(
            "account/update_user_id",
            localStorage.getItem("user_id")
          );
          localStorage.setItem("name", result.profile.first_name);
          Store.commit("account/update_name", localStorage.getItem("name"));
          localStorage.setItem("email", result.profile.email);
          // Restoring email from localStorage
          const email = localStorage.getItem("email");
          if (email && email !== undefined && email !== "undefined") {
            Store.commit("account/update_email", email);
          }
          if (!Store.state.account.User.new_messages_count) {
            Store.dispatch("account/updateProfile");
          } else {
            Store.dispatch("account/updateProfileFromStore");
          }
          if (result.profile.pro_reports !== null) {
            Store.commit("conf/update_is_buyed_pro_tooltips", true);
          } else {
            Store.commit("conf/update_is_buyed_pro_tooltips", false);
          }
          // Get Social Profile
          if (Router.history.current.path === "/feed")
            Store.dispatch("account/getSocialProfile");
          // Restoring Before Posting (Social)
          if (localStorage.getItem("before_posting")) {
            Store.commit("account/update_before_posting", false);
          }
          // Social Profile
          if (localStorage.getItem("before_editing_user_images")) {
            Store.commit("account/update_before_editing_user_images", false);
          }
          if (localStorage.getItem("before_editing_profile")) {
            Store.commit("account/update_before_editing_profile", false);
          }
          // Restoring User Product ID/Hash
          if (localStorage.getItem("userProductHash")) {
            Store.commit("account/is_user_product_hash", true);
          }
          if (localStorage.getItem("reportHash")) {
            Store.commit(
              "account/update_report_hash",
              localStorage.getItem("reportHash")
            );
          }
          if (localStorage.getItem("userProductId")) {
            Store.commit(
              "account/update_user_product_id",
              localStorage.getItem("userProductId")
            );
          }
          // Restoring update user no interest in social profile
          if (localStorage.getItem("no_interesting_in_social_profile")) {
            Store.commit(
              "account/update_user_no_interest_in_soc_profile",
              false
            );
          } else {
            Store.commit(
              "account/update_user_no_interest_in_soc_profile",
              true
            );
          }
        }
      } catch (e) {
        Store.dispatch("errorHandler", e);
      }
    }
  }
};
