<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="editPhotos"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon center>mdi-camera-plus-outline</v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Фото
          </span>
        </v-btn>
      </template>
      <span>Фото</span>
    </v-tooltip>
    <v-dialog
      v-model="editUserPhotos"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Редактирование фотографий
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-title
                  v-if="profileImages.length"
                  class="headline"
                  style="font-size: 1rem !important"
                >
                  Загруженные фотографии (осталось:
                  {{ howManyImgsCanUpload }}):
                </v-card-title>
                <v-container v-if="profileImages.length" fluid>
                  <v-divider style="margin-top:0 !important"></v-divider>
                  <draggable
                    v-model="profileImages"
                    @start="drag = true"
                    @end="drag = false"
                    @change="imageSortUploaded"
                    group="images"
                    class="row"
                    handle=".handle"
                    style="margin:0;overflow:hidden"
                  >
                    <v-col
                      v-for="(item, i) in profileImages"
                      :key="i"
                      cols="4"
                      style="padding:2px;position:relative"
                    >
                      <v-btn
                        slot="header"
                        @click.native="imageDelUploaded($event, i, item.id)"
                        icon
                        small
                        absolute
                        right
                        class="custom-phts-btn-close"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                      <v-card style="cursor:move" class="handle">
                        <v-img
                          :src="item.src"
                          class="image"
                          height="100%"
                          width="100%"
                        ></v-img>
                      </v-card>
                    </v-col>
                  </draggable>
                  <v-divider></v-divider>
                </v-container>
                <v-form ref="form" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <v-row
                        v-if="images.length"
                        style="max-width:100%;margin:0"
                      >
                        <v-col
                          v-for="(image, i) in images"
                          :key="i"
                          cols="4"
                          style="padding:2px"
                        >
                          <v-card>
                            <v-card-title style="padding:2px">
                              <span
                                class="headline"
                                style="font-size: 1.3rem !important;display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width: 90%;"
                                >{{ image.name }}</span
                              >
                              <v-btn
                                @click="imageDel($event, i)"
                                icon
                                small
                                absolute
                                right
                                class="custom-phts-btn-close"
                              >
                                <v-icon>mdi-close-circle</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-img
                              :src="sortArr(imagesSources)[i]"
                              :title="image.name"
                              height="150"
                              class="grey darken-4"
                            ></v-img>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-col cols="12">
                        Добавлять только личные фото и сертификаты HD. Сторонние
                        картинки запрещены.
                        <v-file-input
                          ref="avatarSelect"
                          v-model="images"
                          @change="onAvatarChange($event)"
                          @click.stop
                          :error-messages="imageErrors"
                          :disabled="profileImages.length >= imagesLimit"
                          accept="image/png, image/jpeg, image/bmp"
                          prepend-icon="mdi-camera-plus-outline"
                          label="Фотографии"
                          multiple
                          class="custom-photos-input"
                        ></v-file-input>
                        Выберите тут фотографии для добавления
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="validatesaveProfileImages"
                :disabled="imagesSources.length === 0"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Добавить фото</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="successsaveProfileImages" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;Ваши фотографии успешно сохранены.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successsaveProfileImages = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";

export default {
  components: { draggable },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      editUserPhotos: false,
      successsaveProfileImages: false,
      isLoading: false,
      isLoading2: false,
      images: [],
      imagesSources: [],
      // profileImages: [],
      imagesLimit: 9,
      isImageValidation: true,
      imagesIds: ""
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp
    }),
    imageErrors() {
      const errors = [];
      const tempErrs = [];
      this.images.forEach((item) => {
        if (item.size > 15728640) tempErrs.push(item.name);
      });
      if (tempErrs.length && this.images.length > this.howManyImgsCanUpload) {
        errors.push(
          "Файлы: " +
            tempErrs.join(", ") +
            ` — больше 15Mb, вы можете загрузить не более ${this.howManyImgsCanUpload} файлов.`
        );
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (tempErrs.length) {
        errors.push("Файлы: " + tempErrs.join(", ") + " — больше 15Mb.");
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (this.images.length > this.howManyImgsCanUpload) {
        errors.push(
          `Вы можете загрузить не более ${this.howManyImgsCanUpload} файлов.`
        );
        this.$store.commit("account/update_comm_images_validation", false);
      } else {
        this.$store.commit("account/update_comm_images_validation", true);
      }
      return errors;
    },
    profileImages: {
      get() {
        return this.User.community.my_profile.images;
      },
      set(arr) {
        this.$store.commit("account/update_comm_profile_images", arr);
      }
    },
    isImagesValidation: {
      get() {
        return this.User.community.isImagesValidation;
      },
      set(value) {
        this.$store.commit("account/update_comm_images_validation", value);
      }
    },
    howManyImgsCanUpload() {
      if (
        this.profileImages.length &&
        this.profileImages.length <= this.imagesLimit
      ) {
        return this.imagesLimit - this.profileImages.length;
      } else {
        return this.imagesLimit;
      }
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_dialog_edit_user_images":
          this.editUserPhotos = true;
          break;
      }
    });
    setTimeout(() => {
      this.getProfile();
    }, 0);
  },
  methods: {
    ...mapMutations({
      call_update_profile: "account/call_update_profile",
      update_login_dialog: "conf/update_login_dialog",
      update_before_editing_user_images_dialog:
        "account/update_before_editing_user_images_dialog"
    }),
    editPhotos() {
      if (this.User.isLogged && this.User.community.beforeEditingUserImages) {
        this.update_before_editing_user_images_dialog(true);
      } else if (this.User.isLogged) {
        this.editUserPhotos = true;
      } else {
        this.update_login_dialog(true);
      }
    },
    async getProfile() {
      if (this.User.isLogged) {
        this.$store.dispatch("account/getSocialProfile");
      }
    },
    onAvatarChange() {
      // console.log(this.images)
      this.imagesSources = [];
      this.images.forEach((file, i) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagesSources.push({
            id: i,
            src: e.target.result
          });
        };
        reader.readAsDataURL(file);
      });
    },
    validatesaveProfileImages() {
      if (this.$refs.form.validate() && this.isImagesValidation) {
        this.saveProfileImages();
      }
    },
    sortArr(arr) {
      return arr.slice().sort((a, b) => {
        return a.id - b.id;
      });
    },
    imageDel(e, i) {
      const el =
        e.target.parentElement.parentElement.parentElement.parentElement;
      el.style.cssText = "transform: scale(0); transition: transform 0.2s";
      clearTimeout(this.imagesIds);
      this.imagesIds = setTimeout(() => {
        this.images.splice(i, 1);
        this.imagesSources.forEach((item, j) => {
          // if (item.id === i) this.imagesSources.splice(j, 1)
          if (j === i) this.imagesSources.splice(j, 1);
        });
        el.style = "";
      }, 200);
    },
    async imageDelUploaded(e, i, id) {
      try {
        const data = { image_id: id };
        const result = await Api.removeAvatarSocial(data, this.HttpOptions);
        if (result.success) {
          const el =
            e.target.parentElement.parentElement.parentElement.parentElement;
          el.style.cssText = "transform: scale(0); transition: transform 0.2s";
          setTimeout(() => {
            this.profileImages.splice(i, 1);
            if (this.profileImages.length) {
              this.$store.commit(
                "account/update_comm_avatar",
                this.profileImages[0].src
              );
            } else {
              this.$store.commit("account/update_comm_avatar", "");
              // this.$store.commit('HAS_AVATAR', false)
              this.$store.commit("account/call_social_profile_check_modal");
              this.$store.commit(
                "account/can_add_content_to_social_network",
                false
              );
            }
            el.style = "";
          }, 200);
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async imageSortUploaded() {
      try {
        const sortedObject = {};
        this.profileImages.forEach((item, i) => {
          sortedObject[item.id] = i;
        });
        const data = { data: sortedObject };
        const result = await Api.sortAvatarSocial(data, this.HttpOptions);
        if (result.success) {
          this.$store.commit(
            "account/update_comm_avatar",
            this.profileImages[0].src
          );
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async saveProfileImages() {
      this.editUserPhotos = false;
      if (this.User.isLogged) {
        try {
          // console.log(this.avatar)
          const formData = new FormData();
          this.images.forEach((file) => {
            formData.append("files[]", file);
          });
          formData.append("access-token", this.User.access_token);
          formData.append("partner_id", this.ConfApp.partner_id);
          const result = await Api.uploadSocialProfileImages(
            formData,
            this.HttpOptions
          );
          if (result.social_profile.images) {
            this.images = [];
            this.profileImages = [];
            this.profileImages = result.social_profile.images;
            this.$store.commit(
              "account/update_comm_avatar",
              result.social_profile.images[0].src
            );
            // this.$store.commit('HAS_AVATAR', true)
          }
          if (result.success) {
            this.successsaveProfileImages = true;
            this.call_update_profile();
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    closeDialog() {
      this.editUserPhotos = false;
      this.call_update_profile();
    }
  }
};
</script>
