<template>
  <v-dialog
    :value="showDialog"
    @input="closeDialog()"
    content-class="showDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="950px"
    style="z-index: 9999"
  >
    <v-card v-if="chartBodygraphChart" class="custom-tooltips">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important">
          Каналы
        </span>
        <v-btn @click="closeDialog()" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs
        v-model="activetab"
        class="custom-user-tabs"
        centered
        background-color="transparent"
        color="basil"
        grow
        show-arrows
        stacked
      >
        <v-tab v-if="chartBodygraphChannels.channelsBoth.length">
          Компаньонские
        </v-tab>
        <v-tab v-if="chartBodygraphChannels.channelsElectro.length">
          Электромагнитные
        </v-tab>
        <v-tab v-if="chartBodygraphChannels.channelsCompromise1.length">
          Компромиссные ({{ chartBodygraphChartName }})
        </v-tab>
        <v-tab v-if="chartBodygraphChannels.channelsCompromise2.length">
          Компромиссные ({{ chartBodygraphChartName2 }})
        </v-tab>
        <v-tab v-if="chartBodygraphChannels.channelsEducational1.length">
          Обучающие каналы ({{ chartBodygraphChartName }})
        </v-tab>
        <v-tab v-if="chartBodygraphChannels.channelsEducational2.length">
          Обучающие каналы ({{ chartBodygraphChartName2 }})
        </v-tab>
      </v-tabs>
      <v-window v-model="activetab">
        <v-window-item v-if="chartBodygraphChannels.channelsBoth.length">
          <v-card class="padding-bottom-tooltips">
            <v-card-text>
              <v-expansion-panels class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="getMessage('help_analitycs_channels_both')"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-for="(item, i) in chartBodygraphChannels.channelsBoth"
                :key="'j' + i"
                outlined
              >
                <span
                  v-html="item.title"
                  class="headline"
                  style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
                ></span>
                <div v-if="item.tooltip_new" v-html="item.tooltip_new"></div>
                <div v-else v-html="item.tooltip"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item v-if="chartBodygraphChannels.channelsElectro.length">
          <v-card class="padding-bottom-tooltips">
            <v-card-text>
              <v-expansion-panels class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="getMessage('help_analitycs_channels_electro')"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-for="(item, i) in chartBodygraphChannels.channelsElectro"
                :key="'j' + i"
                outlined
              >
                <span
                  v-html="item.title"
                  class="headline"
                  style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
                ></span>
                <div v-if="item.tooltip_new" v-html="item.tooltip_new"></div>
                <div v-else v-html="item.tooltip"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item v-if="chartBodygraphChannels.channelsCompromise1.length">
          <v-card class="padding-bottom-tooltips">
            <v-card-text>
              <v-expansion-panels class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="getMessage('help_analitycs_channels_сompromise')"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-for="(item, i) in chartBodygraphChannels.channelsCompromise1"
                :key="'j' + i"
                outlined
              >
                <span
                  v-html="item.title"
                  class="headline"
                  style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
                ></span>
                <div v-if="item.tooltip_new" v-html="item.tooltip_new"></div>
                <div v-else v-html="item.tooltip"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item v-if="chartBodygraphChannels.channelsCompromise2.length">
          <v-card class="padding-bottom-tooltips">
            <v-card-text>
              <v-expansion-panels class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="getMessage('help_analitycs_channels_сompromise')"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-for="(item, i) in chartBodygraphChannels.channelsCompromise2"
                :key="'j' + i"
                outlined
              >
                <span
                  v-html="item.title"
                  class="headline"
                  style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
                ></span>
                <div v-if="item.tooltip_new" v-html="item.tooltip_new"></div>
                <div v-else v-html="item.tooltip"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item
          v-if="chartBodygraphChannels.channelsEducational1.length"
        >
          <v-card class="padding-bottom-tooltips">
            <v-card-text>
              <v-expansion-panels class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="getMessage('help_analitycs_channels_educational')"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-for="(item, i) in chartBodygraphChannels.channelsEducational1"
                :key="'j' + i"
                outlined
              >
                <span
                  v-html="item.title"
                  class="headline"
                  style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
                ></span>
                <div v-if="item.tooltip_new" v-html="item.tooltip_new"></div>
                <div v-else v-html="item.tooltip"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item
          v-if="chartBodygraphChannels.channelsEducational2.length"
        >
          <v-card class="padding-bottom-tooltips">
            <v-card-text>
              <v-expansion-panels class="add-info-block">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3>
                      <span>Что это?</span>
                      Подробнее...
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-html="getMessage('help_analitycs_channels_educational')"
                      class="add-info-block-head-desc"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-for="(item, i) in chartBodygraphChannels.channelsEducational2"
                :key="'j' + i"
                outlined
              >
                <span
                  v-html="item.title"
                  class="headline"
                  style="font-size: 1.1rem !important;padding: 15px 0 10px 5px;color: rgba(0, 0, 0, .6);"
                ></span>
                <div v-if="item.tooltip_new" v-html="item.tooltip_new"></div>
                <div v-else v-html="item.tooltip"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
      <div class="reg-go">
        <div v-html="RegGoTextBeforeBtn"></div>
        <v-btn
          @click="clickTooltipBtnBG(extTips)"
          v-html="getMessage(btnTooltipName)"
          class="tooltip-btn-bg mob-tooltip-btn-bg"
        ></v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  name: "CompositeChannelsTooltipDialog",
  mixins: [commonInitAppMethods],
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activetab: 0
    };
  },
  computed: {
    chartBodygraphChart: {
      get() {
        return this.$store.state.account.Composite.chartBodygraphChart;
      },
      set(value) {
        this.$store.commit("account/set_chart_bodygraph_composite", value);
      }
    },
    chartBodygraphChannels() {
      return this.$store.state.account.Composite.chartBodygraphConnection !==
        null
        ? this.$store.state.account.Composite.chartBodygraphConnection.channels
        : null;
    },
    chartBodygraphChartName() {
      return this.$store.state.account.Composite.chartBodygraphChartName;
    },
    chartBodygraphChartName2() {
      return this.$store.state.account.Composite.chartBodygraphChartName2;
    },
    RegGoTextBeforeBtn() {
      return this.$route.path === "/bodygraph" ||
        this.$route.path === "/transit" ||
        this.$route.path === "/returns"
        ? this.$store.state.account.User.RegGoTextBeforeBtn
        : this.$route.path === "/composite"
        ? this.$store.state.account.User.RegGoTextBeforeBtnComposite
        : "";
    },
    btnTooltipName() {
      return this.$route.path === "/bodygraph" ||
        this.$route.path === "/transit" ||
        this.$route.path === "/returns"
        ? this.$store.state.account.User.btnTooltipName
        : this.$route.path === "/composite"
        ? this.$store.state.account.User.btnTooltipNameComposite
        : "";
    },
    extTips() {
      return this.$store.state.conf.ConfApp.isBuyedProTooltips;
    }
  },
  beforeMount() {
    window.openLoginDialog = this.openLoginDialog;
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    clickTooltipBtnBG(extTips, isInfoBlockExtTips) {
      if (
        this.$route.path === "/bodygraph" ||
        this.$route.path === "/transit" ||
        this.$route.path === "/returns"
      ) {
        const SHOP_URL =
          extTips === "ext_tips"
            ? this.$store.state.conf.ConfApp.tooltips_buy_full_url
            : extTips
            ? this.$store.state.conf.ConfApp.tooltips_shop_url
            : this.$store.state.conf.ConfApp.tooltips_sale_shop_url;
        switch (this.$store.state.conf.ConfApp.tooltip_button_type) {
          case 1:
            if (isInfoBlockExtTips) {
              const URL = SHOP_URL + "&tag=r" + this.User.reportid;
              window.open(URL, "_blank");
            } else {
              this.closeDialog();
              this.update_login_dialog(true);
            }
            break;
          case 2:
            this.$router.push("/profile");
            break;
          case 3: {
            // this.$router.push("/products");
            const URL3 = this.$store.state.conf.ConfApp.pdfs_shop_url;
            window.open(URL3, "_blank");
            break;
          }
          case 4: {
            const URL4 = SHOP_URL + "&tag=r" + this.User.reportid;
            window.open(URL4, "_blank");
            break;
          }
          case 6: {
            // Здесь сейчас показываются всегда FULL-подсказки
            const SHOP_URL6 = this.$store.state.conf.ConfApp
              .tooltips_buy_full_url;
            const URL6 = SHOP_URL6 + "&tag=r" + this.User.reportid;
            window.open(URL6, "_blank");
            break;
          }
          case 7:
            // FULL-подсказки выключены, предложение включить
            this.$router.push("/profile");
            break;
          default:
            this.$store.commit(
              "account/set_partner_info_text",
              this.getMessage("partners")
            );
            this.$store.commit("account/call_partner_dialog");
            break;
        }
      } else if (this.$route.path === "/composite") {
        switch (this.$store.state.conf.ConfApp.cp_tooltip_button_type) {
          case 1:
            this.update_login_dialog(true);
            break;
          case 2:
            this.$router.push("/profile");
            break;
          case 3:
            this.$router.push("/products");
            break;
          case 4: {
            const SHOP_URL4 = extTips
              ? this.$store.state.conf.ConfApp.tooltips_shop_url
              : this.$store.state.conf.ConfApp.tooltips_sale_shop_url;
            const url4 = SHOP_URL4 + "&tag=r" + this.User.reportid;
            window.open(url4, "_blank");
            break;
          }
          case 5: {
            const url5 =
              this.$store.state.conf.ConfApp.composite_shop_url +
              "&tag=r" +
              this.User.reportid;
            window.open(url5, "_blank");
            break;
          }
          case 6: {
            /* const SHOP_URL6 =
                extTips ||
                extTips === 'ext_tips' ||
                this.User.full_tooltips_count
                  ? this.$store.state.ConfApp.tooltips_buy_full_url
                  : this.$store.state.ConfApp.tooltips_shop_url */
            // Если приходит 6, то в композите предлагаются только расширенные
            const SHOP_URL6 = this.$store.state.conf.ConfApp
              .tooltips_buy_full_url;
            const url6 = SHOP_URL6 + "&tag=r" + this.User.reportid;
            window.open(url6, "_blank");
            break;
          }
          case 7:
            // FULL-подсказки выключены, предложение включить
            this.$router.push("/profile");
            break;
          default:
            this.$store.commit(
              "account/set_partner_info_text",
              this.getMessage("partners")
            );
            this.$store.commit("account/call_partner_dialog");
            break;
        }
      }
    },
    openLoginDialog() {
      this.update_login_dialog(true);
    },
    closeDialog() {
      this.$emit("closeCompositeChannelsDialog");
    }
  }
};
</script>
<style scoped>
.custom-user-tabs.v-tabs {
  flex: initial;
}
.v-window.v-item-group {
  overflow-y: auto;
}
</style>
