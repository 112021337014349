<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="postAdd(), wasOpenAddPost()"
          v-on="on"
          :class="{ 'pulse-item3': !User.was_open_add_post }"
          class="mx-2"
          fab
          text
          dark
          color="teal accent-4"
        >
          <v-icon dark class="sticky-menu-button-icon">
            mdi-file-document-edit-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Добавить запись</span>
    </v-tooltip>
    <div class="sticky-menu-button-desc">Создать</div>
    <v-dialog
      v-model="dialogAddPost"
      fullscreen
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title v-if="postMethod === 'update'">
                    Редактирование публикации
                  </v-toolbar-title>
                  <v-toolbar-title v-else>
                    Добавление публикации
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn
                    @click="(dialogAddPost = false), clearFormPost()"
                    icon
                    absolute
                    right
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-form ref="formPost" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <div
                        class="flex-center"
                        style="margin-top:15px;width:100%"
                      >
                        <v-chip-group
                          v-model="filterTag"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            v-for="tag in tags"
                            :key="tag.title"
                            :disabled="tag.disabled"
                            @click="filtering(tag.value)"
                            filter
                            outlined
                          >
                            {{ tag.title }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="no-bottom-padding"
                      >
                        <v-text-field
                          ref="postnameInput"
                          v-model="postName"
                          :counter="64"
                          :rules="postNameRules"
                          :label="
                            postType === 'post'
                              ? 'Заголовок публикации'
                              : 'Заголовок опроса'
                          "
                          filled
                          required
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-select
                          ref="postcategoriesInput"
                          v-model="selectedPostCat"
                          :items="filters"
                          :item-text="'title'"
                          :item-value="'id'"
                          :rules="postCatRules"
                          :label="
                            postType === 'post'
                              ? 'Категории публикации'
                              : 'Категории опроса'
                          "
                          filled
                          chips
                          multiple
                          required
                          menu-props="bottom, offset-y"
                        ></v-select>
                      </v-col> -->
                      <v-col
                        v-if="postType === 'quiz'"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="no-bottom-padding"
                      >
                        <v-select
                          ref="postQuizTypes"
                          v-model="selectedQuizType"
                          :items="quizTypes"
                          :rules="postQuizTypesRules"
                          label="Тип опроса"
                          filled
                          chips
                          required
                          menu-props="bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center no-top-bottom-padding display-0-24-mob-p-d mob-padding-bottom-0"
                        style="overflow:hidden;overflow-y:auto"
                      >
                        <v-card-title
                          v-if="postImages.length"
                          class="headline"
                          style="font-size: 1rem !important"
                        >
                          Загруженные фотографии (осталось:
                          {{ howManyImgsCanUpload }}):
                        </v-card-title>
                        <v-container v-if="postImages.length" fluid>
                          <v-divider
                            style="margin-top:0 !important"
                          ></v-divider>
                          <draggable
                            v-model="postImages"
                            @start="drag = true"
                            @end="drag = false"
                            @change="imageSortUploaded"
                            group="images"
                            class="row"
                            handle=".handle"
                            style="margin:0;overflow:hidden"
                          >
                            <v-col
                              v-for="(item, i) in postImages"
                              :key="i"
                              cols="4"
                              style="padding:2px;position:relative"
                            >
                              <v-btn
                                slot="header"
                                @click.native="
                                  imageDelUploaded($event, i, item.id)
                                "
                                icon
                                small
                                absolute
                                right
                                class="custom-phts-btn-close"
                              >
                                <v-icon>mdi-close-circle</v-icon>
                              </v-btn>
                              <v-card style="cursor:move" class="handle">
                                <v-img
                                  :src="item.src"
                                  class="image"
                                  height="100%"
                                  width="100%"
                                ></v-img>
                              </v-card>
                            </v-col>
                          </draggable>
                          <v-divider></v-divider>
                        </v-container>
                      </v-col>
                      <v-card-text v-if="images.length" class="mob-nopadding">
                        <v-row>
                          <v-row style="max-width:100%;margin:0">
                            <v-col
                              v-for="(image, i) in images"
                              :key="i"
                              cols="4"
                              style="padding:2px"
                            >
                              <v-card>
                                <v-card-title style="padding:2px">
                                  <span
                                    class="headline"
                                    style="font-size: 1.3rem !important;display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width: 90%;"
                                    >{{ image.name }}</span
                                  >
                                  <v-btn
                                    @click="imageDel($event, i)"
                                    icon
                                    small
                                    absolute
                                    right
                                    class="custom-phts-btn-close"
                                  >
                                    <v-icon>mdi-close-circle</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-img
                                  :src="sortArr(imagesSources)[i]"
                                  :title="image.name"
                                  height="150"
                                  class="grey darken-4"
                                ></v-img>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-card-text>
                      <v-col cols="12" class="nopadding">
                        <v-file-input
                          ref="imagesSelect"
                          v-model="images"
                          @change="onpostImagesChange($event)"
                          @click.stop
                          :error-messages="imageErrors"
                          :disabled="postImages.length >= imagesLimit"
                          accept="image/png, image/jpeg, image/bmp"
                          prepend-icon="mdi-camera-plus-outline"
                          label="Фотографии"
                          multiple
                          class="custom-photos-input nopadding"
                        ></v-file-input>
                        Добавить фото
                      </v-col>
                      <v-col
                        ref="postText"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="no-bottom-padding"
                      >
                        <v-textarea
                          v-model="text"
                          :counter="1000"
                          :rules="postTextRules"
                          :value="text"
                          :label="
                            postType === 'post'
                              ? 'Текст вашей публикации'
                              : 'Опишите суть опроса'
                          "
                          name="text"
                          filled
                          auto-grow
                          rows="2"
                          row-height="30"
                          class="post-textarea"
                        ></v-textarea>
                      </v-col>
                      <v-col
                        v-if="postType === 'quiz'"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="no-top-bottom-padding"
                      >
                        <v-card-title class="flex-center nopadding"
                          >Варианты ответа</v-card-title
                        >
                        <v-text-field
                          ref="quizanswerInput"
                          v-for="(quiz, index) in quizAnswers"
                          v-model="quiz.content"
                          :key="index"
                          :counter="100"
                          :rules="quizAnswerRules"
                          :value="quiz.content"
                          :label="`Вариант ответа ${index + 1}`"
                          filled
                          required
                        ></v-text-field>
                        <div>
                          добавить / удалить вариант
                        </div>
                        <div
                          class="position-sticky flex-center margin-bottom-25"
                        >
                          <v-btn
                            @click="removeQuizAnsverVariant"
                            :disabled="quizAnswers.length < 3"
                            color="red"
                          >
                            <v-icon>mdi-minus</v-icon></v-btn
                          >
                          <v-btn
                            @click="addQuizAnsverVariant(postId)"
                            :disabled="quizAnswers.length > 8"
                            color="green"
                          >
                            <v-icon>mdi-plus</v-icon></v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="over-swipe-button3">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="validateСreatePost()"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
              >
                <span v-if="postMethod === 'update'">
                  Сохранить
                </span>
                <span v-else>
                  Публиковать
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <div class="swipe-button3">
          <v-icon class="box bounce-3" x-large
            >mdi-gesture-swipe-vertical</v-icon
          >
        </div>
      </v-card>
      <v-snackbar v-model="underConstructions" top color="warning">
        <v-icon color="white">mdi-alert-outline</v-icon>
        &nbsp;Данная функция в разработке.
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            @click="underConstructions = false"
            color="red"
            x-small
            text
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
    <v-snackbar v-model="successSavePost" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;Публикация добавлена.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successSavePost = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
// import { validationMixin } from "vuelidate";
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  // mixins: [validationMixin],
  data() {
    return {
      postMethod: "",
      postId: null,
      postName: "",
      postType: "post",
      filterTag: null,
      images: [],
      imagesSources: [],
      imagesIds: "",
      postImages: [],
      imagesLimit: 9,
      // selectedPostCat: [],
      isImageValidation: true,
      text: null,
      selectedQuizType: null,
      quizTypes: [
        { text: "Один вариант ответа", value: 1 },
        { text: "Несколько вариантов ответа", value: 2 }
      ],
      quizAnswers: [
        {
          content: "",
          votes_count: 0
        },
        {
          content: "",
          votes_count: 0
        }
      ],
      dialogAddPost: false,
      successSavePost: false,
      postNameRules: [
        (v) => !!v || "Введите название.",
        (v) =>
          (v && v.length <= 64) ||
          "Название публикации не должен быть более 64 символов."
      ],
      postCatRules: [(v) => v.length > 0 || "Выберите категорию."],
      postQuizTypesRules: [(v) => v !== null || "Выберите тип."],
      quizAnswerRules: [
        (v) => v.length > 0 || "Введите ответ на вопрс.",
        (v) =>
          (v && v.length <= 100) ||
          "ответ на вопрос не должен быть более 100 символов."
      ],
      postTextRules: [
        (v) => !!v || "Введите текст.",
        (v) =>
          (v && v.length <= 1000) ||
          "Текст публикации не должено быть более 256 символов."
      ],
      tags: [
        { title: "Пост", disabled: false, value: "post" },
        { title: "Опрос", disabled: false, value: "quiz" }
      ],
      underConstructions: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    imageErrors() {
      const errors = [];
      const tempErrs = [];
      this.images.forEach((item) => {
        if (item.size > 15728640) tempErrs.push(item.name);
      });
      if (tempErrs.length && this.images.length > this.howManyImgsCanUpload) {
        errors.push(
          "Файлы: " +
            tempErrs.join(", ") +
            ` — больше 15Mb, вы можете загрузить не более ${this.howManyImgsCanUpload} файлов.`
        );
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (tempErrs.length) {
        errors.push("Файлы: " + tempErrs.join(", ") + " — больше 15Mb.");
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (this.images.length > this.howManyImgsCanUpload) {
        errors.push(
          `Вы можете загрузить не более ${this.howManyImgsCanUpload} файлов.`
        );
        this.$store.commit("account/update_comm_images_validation", false);
      } else {
        this.$store.commit("account/update_comm_images_validation", true);
      }
      return errors;
    },
    isImagesValidation: {
      get() {
        return this.User.community.isImagesValidation;
      },
      set(value) {
        this.$store.commit("account/update_comm_images_validation", value);
      }
    },
    howManyImgsCanUpload() {
      if (
        this.postImages.length &&
        this.postImages.length <= this.imagesLimit
      ) {
        return this.imagesLimit - this.postImages.length;
      } else {
        return this.imagesLimit;
      }
    },
    filters() {
      return this.$store.getters["account/posts_filters"];
    },
    posts() {
      return this.$store.getters["account/get_posts"];
    },
    posts_method() {
      return this.$store.state.account.User.community.posts_method;
    },
    canAddContentToSocialNetwork() {
      return this.$store.getters["account/can_add_content_to_social_network"];
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "account/call_edit_post":
          this.postEdit(state.account.User.community.edit_post_idx);
          break;
        case "account/call_dialog_add_post":
          this.postAdd();
          break;
      }
    });
  },
  methods: {
    ...mapMutations({
      set_user_posts_categories: "account/set_user_posts_categories",
      set_user_city_posts_only: "account/set_user_city_posts_only",
      update_login_dialog: "conf/update_login_dialog",
      update_before_posting_dialog: "account/update_before_posting_dialog"
    }),
    postAdd() {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        // if (!this.hasAvatar || !this.hasBodygraph) {
        if (!this.canAddContentToSocialNetwork) {
          this.socialProfileCheckDialog();
        } else if (this.User.community.beforePosting) {
          this.update_before_posting_dialog(true);
        } else {
          this.dialogAddPost = true;
        }
      } else {
        this.update_login_dialog(true);
      }
    },
    postEdit(idx) {
      this.postName = this.posts[idx].title;
      this.text = this.posts[idx].description;
      // this.selectedPostCat = this.posts[idx].categories
      if (this.posts[idx].images) this.postImages = this.posts[idx].images;
      this.postMethod = "update";
      this.postId = this.posts[idx].id;
      if (this.posts[idx].post_type === 2) {
        this.selectedQuizType = this.posts[idx].poll_type;
        this.postType = "quiz";
        this.filterTag = 1;
        this.tags[0].disabled = true;
        this.quizAnswers = this.posts[idx].pollItems;
      } else {
        this.filterTag = 0;
        this.tags[1].disabled = true;
      }
      this.dialogAddPost = true;
    },
    onpostImagesChange() {
      this.imagesSources = [];
      this.images.forEach((file, i) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagesSources.push({
            id: i,
            src: e.target.result
          });
        };
        reader.readAsDataURL(file);
      });
    },
    sortArr(arr) {
      return arr.slice().sort((a, b) => {
        return a.id - b.id;
      });
    },
    imageDel(e, i) {
      const el =
        e.target.parentElement.parentElement.parentElement.parentElement;
      el.style.cssText = "transform: scale(0); transition: transform 0.2s";
      clearTimeout(this.imagesIds);
      this.imagesIds = setTimeout(() => {
        this.images.splice(i, 1);
        this.imagesSources.forEach((item, j) => {
          // if (item.id === i) this.imagesSources.splice(j, 1)
          if (j === i) this.imagesSources.splice(j, 1);
        });
        el.style = "";
      }, 200);
    },
    async imageDelUploaded(e, i, id) {
      try {
        // const token = this.User.access_token
        const result = await this.$removeImage.post({
          // 'access-token': token,
          image_id: id
        });
        if (result.success) {
          const el =
            e.target.parentElement.parentElement.parentElement.parentElement;
          el.style.cssText = "transform: scale(0); transition: transform 0.2s";
          setTimeout(() => {
            this.postImages.splice(i, 1);
            el.style = "";
          }, 200);
          this.getSocialPosts(this.User.id, this.User.community.posts_options);
          // this.GET_POSTS_FROM_API({ page: this.page, id: null });
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async imageSortUploaded() {
      try {
        const sortedObject = {};
        this.postImages.forEach((item, i) => {
          sortedObject[item.id] = i;
        });
        const data = { data: sortedObject };
        const result = await Api.sortImages(data, this.HttpOptions);
        if (result.success) {
          this.getSocialPosts(this.User.id, this.User.community.posts_options);
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    validateСreatePost() {
      if (this.$refs.formPost.validate() && this.isImagesValidation) {
        this.sendPost();
      } else {
        this.$refs.postText.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
      }
    },
    async sendPost() {
      try {
        let result;
        const type = this.postType === "quiz" ? 2 : 1;
        const formData = new FormData();
        this.images.forEach((file, i) => {
          formData.append(`files[${i}]`, file);
        });
        formData.append("post_type", type);
        formData.append("poll_type", 1);
        if (type === 2) {
          formData.append("poll_type", this.selectedQuizType);
          formData.append("poll_items", JSON.stringify(this.quizAnswers));
        }
        formData.append("access-token", this.User.access_token);
        formData.append("partner_id", this.ConfApp.partner_id);
        formData.append("title", this.postName);
        // formData.append('category_ids', this.selectedPostCat)
        formData.append("description", this.text.trim());
        this.dialogAddPost = false;
        // console.log('quizAnswers: ', JSON.stringify(this.quizAnswers))
        if (type === 2 && !this.quizAnswers.length) {
          this.$store.commit("conf/update_service_modal", {
            message:
              "По каким-то причинам варианты ответа не сохранились...<br /> Попробуйте еще раз.<br />"
          });
          return;
        }
        if (this.postMethod === "update") {
          formData.append("post_id", this.postId);
          result = await Api.updatePost(formData);
        } else {
          result = await Api.createPost(formData);
        }
        if (result.success) {
          this.successSavePost = true;
          if (this.posts_method === "myposts") {
            // this.GET_POSTS_FROM_API({ user_id: this.User.id });
            this.getSocialPosts(
              this.User.id,
              this.User.community.posts_options
            );
          } else {
            this.getSocialPosts(
              this.User.id,
              this.User.community.posts_options
            );
          }
        }
        this.clearFormPost();
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    clearFormPost() {
      this.postType = "post";
      this.filterTag = null;
      this.images = [];
      this.postImages = [];
      this.$refs.formPost.reset();
      this.quizAnswers = [
        {
          content: "",
          votes_count: 0
        },
        {
          content: "",
          votes_count: 0
        }
      ];
      this.postMethod = "";
      this.postId = null;
      this.tags[0].disabled = false;
      this.tags[1].disabled = false;
      if (this.posts_method === "myposts") {
        // this.GET_POSTS_FROM_API({ user_id: this.User.id });
        this.getSocialPosts(this.User.id, this.User.community.posts_options);
      } else {
        // this.GET_POSTS_FROM_API({ page: 1, id: null });
        this.getSocialPosts(this.User.id, this.User.community.posts_options);
      }
    },
    filtering(category) {
      /* if (category === 'quiz') {
        this.underConstructions = true
        return
      } */
      switch (category) {
        case "post":
          this.postType = category;
          return this.postType;
        case "quiz":
          this.postType = category;
          return this.postType;
      }
    },
    addQuizAnsverVariant() {
      this.quizAnswers.push({
        content: "",
        votes_count: 0
      });
    },
    removeQuizAnsverVariant() {
      this.quizAnswers.pop();
    },
    socialProfileCheckDialog() {
      this.$store.commit("account/call_social_profile_check_modal");
    },
    getSocialPosts(id, options) {
      const data = { id, options };
      this.$store.dispatch("account/getSocialPosts", data);
    },
    wasOpenAddPost() {
      localStorage.setItem("was_open_add_post", JSON.stringify(true));
      this.$store.commit("account/update_was_open_add_post", true);
    }
  }
};
</script>
<style scoped>
.sticky-menu-button-desc {
  line-height: 0.5rem;
  text-align: center;
  opacity: 0.6;
  font-size: 12px;
}
.sticky-menu-button-icon {
  font-size: 32px !important;
}
.over-swipe-button3 {
  position: relative;
  z-index: 102;
}
.swipe-button3 {
  display: flex;
  position: absolute;
  z-index: 101;
  height: 100px;
  width: 100%;
  bottom: 0;
  justify-content: flex-end;
  padding-right: 10px;
  opacity: 0.7;
}
.custom-photos-input {
  flex-direction: column !important;
  align-items: center !important;
}
.custom-photos-input .v-input__prepend-outer {
  margin: 0 !important;
}
.custom-photos-input .v-input__prepend-outer i {
  font-size: 66px !important;
}
.custom-photos-input .v-input__icon--prepend {
  width: 66px;
  height: 66px;
}
.custom-photos-input .v-input__slot {
  display: none !important;
}
@media screen and (min-width: 1024px) {
  .swipe-button3 i {
    font-size: 80px !important;
  }
}
</style>
