<template>
  <v-card flat d-flex pa-2 class="card-md bodygraph">
    <v-card-text class="mob-padding-10-4">
      <v-row
        v-if="chartBodygraph"
        :class="{ 'mob-column': isLinesGates || isCircuitGates }"
      >
        <v-col
          :class="{
            'margin-0-auto':
              isHarmoniousGates ||
              isLoveGates ||
              isFearsGates ||
              isMelancholyGates ||
              isDreamGates ||
              isLinesGates ||
              isCircuitGates
          }"
          cols="12"
          sm="12"
          md="9"
          class="flex-basis__auto svg_bg nopadding"
          style="overflow:hidden;outline:none"
        >
          <svg
            id="svg1582"
            ref="bodygraph"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            viewBox="0 0 880 789.99997"
            version="1.1"
            text-rendering="optimizeLegibility"
            sodipodi:docname="bodygraph.svg"
            width="100%"
            height="100%"
            inkscape:version="0.92.4 (unknown)"
          >
            <metadata id="metadata1588">
              <rdf:RDF>
                <cc:Work rdf:about="">
                  <dc:format>image/svg+xml</dc:format>
                  <dc:type
                    rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                  />
                  <dc:title>human-design</dc:title>
                </cc:Work>
              </rdf:RDF>
            </metadata>
            <defs id="defs1586">
              <filter
                id="filter1874"
                inkscape:collect="always"
                style="color-interpolation-filters:sRGB"
                x="-0.024505657"
                width="1.0490113"
                y="-0.0142239"
                height="1.0284478"
              >
                <feGaussianBlur
                  id="feGaussianBlur1876"
                  inkscape:collect="always"
                  stdDeviation="4.2793031"
                />
              </filter>
            </defs>
            <sodipodi:namedview
              id="namedview1584"
              pagecolor="#f8f8f8"
              bordercolor="#666666"
              borderopacity="1"
              objecttolerance="10"
              gridtolerance="10"
              guidetolerance="10"
              inkscape:pageopacity="1"
              inkscape:pageshadow="2"
              inkscape:window-width="2560"
              inkscape:window-height="1386"
              showgrid="false"
              fit-margin-top="20"
              fit-margin-left="20"
              fit-margin-right="20"
              fit-margin-bottom="20"
              inkscape:zoom="1"
              inkscape:cx="145.87012"
              inkscape:cy="564.47925"
              inkscape:window-x="0"
              inkscape:window-y="25"
              inkscape:window-maximized="1"
              inkscape:current-layer="svg1582"
              inkscape:pagecheckerboard="false"
              borderlayer="true"
              showguides="true"
              inkscape:guide-bbox="true"
            />
            <title id="title2"></title>
            <g
              id="layer2"
              inkscape:groupmode="layer"
              inkscape:label="Background"
              style="display:inline"
            >
              <rect
                id="rect1853"
                @click="clearTooltips"
                style="opacity:1;fill-opacity:1;stroke:none;stroke-width:1.25;stroke-miterlimit:4;stroke-dasharray:9.99999993, 4.99999996;stroke-dashoffset:0;stroke-opacity:1"
                width="880"
                height="790"
                x="0"
                y="0"
              />
              <g
                id="Слой_1"
                data-name="Слой 1"
                transform="translate(30.727409,17.409983)"
              >
                <g id="Слой_4" data-name="Слой 4">
                  <g id="g8250">
                    <path
                      id="path4"
                      @click="clearTooltips"
                      style="fill:#e1e1e1"
                      inkscape:connector-curvature="0"
                      d="m 680.10834,596.59 -206,-510.7 c -11.9,-29.4 -30.6,-25.5 -62.3,-26.2 -0.8,0 -12.8,0.2 -13.7,0.2 -47.7,-1.8 -52,22.7 -54.3,28.4 l -205.3,508.3 a 25.57,25.57 0 0 0 2.3,23.7 c 51.1,78.7 152.1,132.3 268.5,132.3 116.4,0 217.4,-53.6 268.5,-132.3 a 26.16,26.16 0 0 0 2.3,-23.7 z"
                    />
                    <path
                      id="path6"
                      @click="clearTooltips"
                      style="fill:#b2bec0"
                      inkscape:connector-curvature="0"
                      d="m 443.76,239.59 c 1.9,14.2 7.7,20.3 7.7,20.3 6.9,9.8 32.3,17.3 64.7,22 34.5,5 37.1,28.7 54.4,71.3 l 88.5,222.5 a 32.72,32.72 0 0 1 -3.1,29.9 c -44,66 -123.9,112.7 -217.5,120.8 a 1.7,1.7 0 0 0 -0.7,0.1 c -4.5,0.4 -9.1,0.7 -13.7,0.9 h -0.8 c -1.8,0.1 -3.7,0.1 -5.5,0.2 h -1.9 c -2.2,0 -4.4,0.1 -6.6,0.1 -2.2,0 -4.4,0 -6.6,-0.1 h -1.9 c -1.8,0 -3.7,-0.1 -5.5,-0.2 h -0.8 c -4.6,-0.2 -9.2,-0.5 -13.7,-0.9 a 1.7,1.7 0 0 1 -0.7,-0.1 c -93.5,-8.1 -173.5,-54.8 -217.4,-120.8 a 32.11,32.11 0 0 1 -3.1,-29.9 l 88.5,-222.5 c 17.2,-42.7 19.9,-66.3 54.4,-71.3 20.5,-3 40.7,-8.9 53.9,-15.4 7.9,-3.9 15.4,-8.4 18.7,-16.6 l 2,-10.3 c 1.1,-11 -3.1,-24.4 -10.4,-33.4 -8.7,-10.9 -10.8,-24.2 -11.4,-37.8 a 7.86,7.86 0 0 0 -3.4,-6.7 25,25 0 0 1 -10.3,-16.9 c -0.7,-4.9 -1.9,-9.7 -2,-14.6 -0.2,-6.8 -0.6,-10.7 9.2,-11.3 0,-5.9 0.1,-11.7 0,-17.6 a 74,74 0 0 1 5.1,-28.7 c 9.1,-23 26.9,-34.1 50.6,-36.7 l 9.3,0.7 c 23.7,2.6 41.5,13.7 50.6,36.7 a 74,74 0 0 1 5.1,28.7 c -0.1,5.9 0,11.7 0,17.6 9.7,0.6 9.3,4.5 9.2,11.3 -0.1,4.9 -1.3,9.7 -2,14.6 -1,7.1 -4.7,12.6 -10.3,16.9 a 7.86,7.86 0 0 0 -3.4,6.7 c -0.6,13.5 -2.7,26.9 -11.4,37.8 -7.2,9.1 -9.1,19 -8,29.9 z"
                    />
                  </g>
                  <text
                    id="text20"
                    font-size="14.57"
                    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:14.56933308px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:center;writing-mode:lr-tb;text-anchor:middle;isolation:isolate;fill:#3e4448"
                    x="409.01001"
                    y="744.23999"
                  >
                    Human-Design.Space
                  </text>
                </g>
              </g>
              <g
                id="g11508"
                v-if="
                  !isHarmoniousGates &&
                    !isLoveGates &&
                    !isFearsGates &&
                    !isMelancholyGates &&
                    !isDreamGates &&
                    !isLinesGates &&
                    !isCircuitGates
                "
                transform="translate(7.080552,8.962463)"
              >
                <g
                  id="environment_group"
                  :class="{ opacity4: isDesignToggle }"
                  transform="translate(22.209448,18.44752)"
                >
                  <path
                    id="color"
                    d="m 240.5,150.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone"
                    d="m 286.5,148.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,162 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="environment_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="227.85001"
                    y="141.17"
                  >
                    {{ chartBodygraph.mandalas[1][2].tone }}
                  </text>
                  <text
                    id="environment_number_color"
                    transform="translate(191.64,141.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraph.mandalas[1][2].color }}
                  </text>
                  <path
                    id="color-over"
                    @click="openColorTooltipDialog('environment', 'color')"
                    d="m 240.5,150.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:transparent;stroke-width:1;stroke-miterlimit:10;cursor:pointer"
                  />
                  <path
                    id="tone-over"
                    @click="openColorTooltipDialog('environment', 'tone')"
                    d="m 286.5,148.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,162 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:transparent;stroke-width:1;stroke-linejoin:round;cursor:pointer"
                  />
                  <rect
                    @click="openColorTooltipDialog('environment', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[3] === 2
                    }"
                    x="255"
                    y="120"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="environment_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[3] === 2
                    }"
                    d="M 302.18,172.32 H 306 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 308.78,148 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <rect
                    @click="openColorTooltipDialog('environment', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[3] === 1
                    }"
                    x="150"
                    y="120"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="environment_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[3] === 1
                    }"
                    d="m 221.1,154.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="environment_tone">
                    <text
                      id="text36"
                      font-size="11.68"
                      style="font-size:11.68000031px;isolation:isolate;fill:#58b730"
                      x="222.66"
                      y="113.09"
                    >
                      ТОН
                    </text>
                  </g>
                  <text
                    id="text43"
                    font-size="11.68"
                    style="font-size:11.68000031px;isolation:isolate;fill:#49c4be"
                    x="180.28"
                    y="113.46"
                  >
                    ЦВЕТ
                  </text>
                </g>
                <g
                  id="food_group"
                  :class="{ opacity4: isDesignToggle }"
                  transform="translate(22.209448,18.44752)"
                >
                  <path
                    id="color-2"
                    data-name="color"
                    d="m 240.5,98.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-2"
                    data-name="tone"
                    d="m 286.5,96.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,110 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="food_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="227.85001"
                    y="89.169998"
                  >
                    {{ chartBodygraph.mandalas[1][0].tone }}
                  </text>
                  <text
                    id="food_number_color"
                    transform="translate(191,89.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraph.mandalas[1][0].color }}
                  </text>
                  <path
                    id="color-2-over"
                    @click="openColorTooltipDialog('food', 'color')"
                    data-name="color"
                    d="m 240.5,98.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:transparent;stroke-width:1;stroke-miterlimit:10;cursor:pointer"
                  />
                  <path
                    id="tone-2-over"
                    @click="openColorTooltipDialog('food', 'tone')"
                    data-name="tone"
                    d="m 286.5,96.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,110 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:transparent;stroke-width:1;stroke-linejoin:round;cursor:pointer"
                  />
                  <rect
                    @click="openColorTooltipDialog('food', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[2] === 2
                    }"
                    x="255"
                    y="70"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="food_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[2] === 2
                    }"
                    d="M 302.18,120.32 H 306 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 308.78,96 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0;cursor:pointer"
                  />
                  <rect
                    @click="openColorTooltipDialog('food', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[2] === 1
                    }"
                    x="150"
                    y="70"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="food_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[2] === 1
                    }"
                    d="m 221.1,102.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-des"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="food_tone" />
                </g>
                <g
                  id="vision_group"
                  :class="{ opacity4: isPersonToggle }"
                  transform="translate(22.209448,16.44752)"
                >
                  <path
                    id="color-3"
                    data-name="color"
                    d="m 668.52,152.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-3"
                    data-name="tone"
                    d="m 641.52,150.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="vision_number_color"
                    transform="translate(619.66,143.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraph.mandalas[0][2].color }}
                  </text>
                  <text
                    id="vision_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:15.22000027px;font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;isolation:isolate;fill:#ffffff"
                    x="582.87"
                    y="143.17"
                  >
                    <tspan
                      id="tspan8437"
                      sodipodi:role="line"
                      x="582.87"
                      y="143.17"
                    >
                      {{ chartBodygraph.mandalas[0][2].tone }}
                    </tspan>
                  </text>
                  <path
                    id="color-3-over"
                    @click="openColorTooltipDialog('vision', 'color')"
                    data-name="color"
                    d="m 668.52,152.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:transparent;stroke-width:1;stroke-miterlimit:10;cursor:pointer"
                  />
                  <path
                    id="tone-3-over"
                    @click="openColorTooltipDialog('vision', 'tone')"
                    data-name="tone"
                    d="m 641.52,150.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:transparent;stroke-width:1;stroke-linejoin:round;cursor:pointer"
                  />
                  <rect
                    @click="openColorTooltipDialog('vision', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[1] === 2
                    }"
                    x="645"
                    y="120"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="vision_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[1] === 2
                    }"
                    d="M 694.19,174.32 H 698 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 700.79,150 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <rect
                    @click="openColorTooltipDialog('vision', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[1] === 1
                    }"
                    x="540"
                    y="120"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="vision_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[1] === 1
                    }"
                    d="m 613.12,156.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="vision_tone">
                    <text
                      id="text80"
                      font-size="11.68"
                      style="font-size:11.68000031px;isolation:isolate;fill:#58b730"
                      x="575.67999"
                      y="115.09"
                    >
                      ТОН
                    </text>
                  </g>
                  <text
                    id="text87"
                    font-size="11.68"
                    style="font-size:11.68000031px;isolation:isolate;fill:#49c4be"
                    x="608.29999"
                    y="115.46"
                  >
                    ЦВЕТ
                  </text>
                </g>
                <g
                  id="motivation_group"
                  :class="{ opacity4: isPersonToggle }"
                  transform="translate(22.209448,16.44752)"
                >
                  <path
                    id="color-4"
                    data-name="color"
                    d="m 668.52,100.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-4"
                    data-name="tone"
                    d="m 641.52,98.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="motivation_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="582.87"
                    y="91.169998"
                  >
                    {{ chartBodygraph.mandalas[0][0].tone }}
                  </text>
                  <text
                    id="motivation_number_color"
                    transform="translate(619,91.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraph.mandalas[0][0].color }}
                  </text>
                  <path
                    id="color-4-over"
                    @click="openColorTooltipDialog('motivation', 'color')"
                    data-name="color"
                    d="m 668.52,100.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:transparent;stroke-width:1;stroke-miterlimit:10;cursor:pointer"
                  />
                  <path
                    id="tone-4-over"
                    @click="openColorTooltipDialog('motivation', 'tone')"
                    data-name="tone"
                    d="m 641.52,98.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:transparent;stroke-width:1;stroke-linejoin:round;cursor:pointer"
                  />
                  <rect
                    @click="openColorTooltipDialog('motivation', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[0] === 2
                    }"
                    x="645"
                    y="70"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="motivation_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[0] === 2
                    }"
                    d="M 694.19,122.32 H 698 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 700.79,98 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <rect
                    @click="openColorTooltipDialog('motivation', 'arrow')"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[0] === 1
                    }"
                    x="540"
                    y="70"
                    style="width:26px;height:26px;display:none;fill:transparent;stroke:transparent;opacity:0;cursor:pointer"
                  ></rect>
                  <path
                    id="motivation_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraph.variablesArray[0] === 1
                    }"
                    d="m 613.12,104.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="motivation_tone" />
                </g>
              </g>
              <g
                id="g8332"
                v-if="
                  !isHarmoniousGates &&
                    !isLoveGates &&
                    !isFearsGates &&
                    !isMelancholyGates &&
                    !isDreamGates &&
                    !isLinesGates &&
                    !isCircuitGates
                "
                transform="translate(0,2.9624634)"
              >
                <g id="g7327" :class="{ opacity4: isDesignToggle }">
                  <g id="g6958" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_pluto"
                      v-if="chartBodygraph.planets.pluto"
                      @click="openTooltipDialog(chartBodygraph.planets.pluto)"
                      class="planet-des"
                    >
                      <path
                        d="m 26.96,711.26 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 0 0 0,-15.2 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect pluto-des"
                        x="5"
                        y="683"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[12]
                      }"
                      class="planet-number-des pluto-num-des"
                      x="38"
                      y="683"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_pluto"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.pluto)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="703.03998"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate;"
                    >
                      {{ chartBodygraph.gatesDesignExt.pluto.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.pluto.isExalted"
                      y="695"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.pluto.isDetriment"
                      y="708.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6954" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_neptune"
                      v-if="chartBodygraph.planets.neptune"
                      @click="openTooltipDialog(chartBodygraph.planets.neptune)"
                      class="planet-des"
                      transform="translate(-0.05279548,-0.34454346)"
                    >
                      <path
                        id="path114"
                        d="m 67.3,676.65 a 13.51,13.51 0 0 1 -13.5,-13.5 1.2,1.2 0 0 1 2.4,0 11.2,11.2 0 0 0 22.4,0 1.2,1.2 0 0 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.5 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path116"
                        d="m 67.3,689.75 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 0 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path118"
                        d="M 75.1,683.55 H 59.6 a 1.2,1.2 0 1 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <polygon
                        id="polygon120"
                        points="8.06,630.23 4.66,636.13 11.56,636.13 "
                      />
                      <polygon
                        id="polygon122"
                        points="20.36,630.23 16.86,636.13 23.76,636.13 "
                      />
                      <polygon
                        id="polygon124"
                        points="32.66,630.23 29.26,636.13 36.16,636.13 "
                      />
                      <rect
                        class="planet-substrate-rect neptune-des"
                        x="5"
                        y="630"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[11]
                      }"
                      class="planet-number-des neptune-num-des"
                      x="38"
                      y="630"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_neptune"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.neptune)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="652.12445"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.neptune.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.neptune.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.neptune.isExalted"
                      y="644"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.neptune.isDetriment"
                      y="657.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6944" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_uranus"
                      v-if="chartBodygraph.planets.uranus"
                      @click="openTooltipDialog(chartBodygraph.planets.uranus)"
                      class="planet-des"
                      transform="translate(-0.00279548,-0.99504089)"
                    >
                      <path
                        id="path127"
                        d="m 56.3,634.08 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path129"
                        d="m 78.3,634.58 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path131"
                        d="m 67.3,628.58 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path133"
                        d="m 67.3,637.88 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.48 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path135"
                        d="M 73.8,623.08 H 60.7 a 1,1 0 1 1 0,-2 h 13.1 a 1,1 0 1 1 0,2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect uranus-des"
                        x="5"
                        y="580"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[10]
                      }"
                      class="planet-number-des uranus-num-des"
                      x="38"
                      y="580"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_uranus"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.uranus)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="601.20892"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.uranus.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.uranus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.uranus.isExalted"
                      y="593"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.uranus.isDetriment"
                      y="606.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6935" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_saturn"
                      v-if="chartBodygraph.planets.saturn"
                      @click="openTooltipDialog(chartBodygraph.planets.saturn)"
                      class="planet-des"
                      transform="translate(0.04756189,0.1998291)"
                    >
                      <path
                        id="path138"
                        d="m 71.2,586.87 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 0 0 -11.4,0 1.2,1.2 0 0 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path140"
                        d="m 61.8,587.47 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 0 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path142"
                        d="M 64.5,558.67 H 59 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 0 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect saturn-des"
                        x="5"
                        y="529"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[9]
                      }"
                      class="planet-number-des saturn-num-des"
                      x="38"
                      y="529"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_saturn"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.saturn)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="550.1795"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.saturn.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.saturn.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.saturn.isExalted"
                      y="542"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.saturn.isDetriment"
                      y="555.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6928" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_jupiter"
                      v-if="chartBodygraph.planets.jupiter"
                      @click="openTooltipDialog(chartBodygraph.planets.jupiter)"
                      class="planet-des"
                      transform="translate(0.19720089,-0.14602661)"
                    >
                      <path
                        id="path145"
                        d="m 60.6,530.05 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 0 1 -2.4,0 A 8.1000386,8.1000386 0 1 1 71,516 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 l -17.7,0.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path147"
                        d="m 74.3,536.05 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 0 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect jupiter-des"
                        x="5"
                        y="478"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[8]
                      }"
                      class="planet-number-des jupiter-num-des"
                      x="38"
                      y="478"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_jupiter"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.jupiter)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="499.36761"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.jupiter.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.jupiter.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.jupiter.isExalted"
                      y="490"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.jupiter.isDetriment"
                      y="503.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6922" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_mars"
                      v-if="chartBodygraph.planets.mars"
                      @click="openTooltipDialog(chartBodygraph.planets.mars)"
                      class="planet-des"
                      transform="translate(-0.06931317,0.0256958)"
                    >
                      <path
                        id="path150"
                        d="m 66,484.14 a 12.2,12.2 0 1 1 12.2,-12.2 12.25,12.25 0 0 1 -12.2,12.2 z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.9 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path152"
                        d="m 73.2,465.14 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 0 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path154"
                        d="m 78.9,464 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 0 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 L 80,463 a 1.16,1.16 0 0 1 -1.1,1 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect mars-des"
                        x="5"
                        y="426"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[7]
                      }"
                      class="planet-number-des mars-num-des"
                      x="38"
                      y="426"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mars"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.mars)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="448.45212"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.mars.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.mars.isExalted"
                      y="440"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.mars.isDetriment"
                      y="453.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6915" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_venus"
                      v-if="chartBodygraph.planets.venus"
                      @click="openTooltipDialog(chartBodygraph.planets.venus)"
                      class="planet-des"
                      transform="translate(-0.00279789,0.29763794)"
                    >
                      <path
                        id="path157"
                        d="M 67.3,425 A 10.4,10.4 0 1 1 77.7,414.6 10.39,10.39 0 0 1 67.3,425 Z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.05 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path159"
                        d="m 67.3,433.85 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 0 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path161"
                        d="M 72.6,429.75 H 62.1 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect venus-des"
                        x="5"
                        y="376"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[6]
                      }"
                      class="planet-number-des venus-num-des"
                      x="38"
                      y="376"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_venus"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.venus)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="397.53662"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.venus.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.venus.isExalted"
                      y="389"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.venus.isDetriment"
                      y="402.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6908" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_mercury"
                      v-if="chartBodygraph.planets.mercury"
                      @click="openTooltipDialog(chartBodygraph.planets.mercury)"
                      class="planet-des"
                      transform="translate(-0.0420642,0.36169434)"
                    >
                      <path
                        id="path164"
                        d="m 67.4,374.89 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path166"
                        d="m 67.4,383.19 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 V 382 a 1.16,1.16 0 0 1 -1.2,1.19 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path168"
                        d="m 72.4,379.39 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path170"
                        d="m 60.9,359.79 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path172"
                        d="m 73.5,358.89 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect mercury-des"
                        x="5"
                        y="325"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[5]
                      }"
                      class="planet-number-des mercury-num-des"
                      x="38"
                      y="325"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mercury"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.mercury)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="346.6315"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.mercury.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.mercury.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.mercury.isExalted"
                      y="338"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.mercury.isDetriment"
                      y="351.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6899" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_south_node"
                      v-if="chartBodygraph.planets.south_node"
                      @click="
                        openTooltipDialog(chartBodygraph.planets.south_node)
                      "
                      class="planet-des"
                    >
                      <path
                        d="m 20.457205,302.22933 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2.0000002,-1.2 7.55,7.55 0 0 1 6.5000002,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.5 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect south-node-des"
                        x="5"
                        y="274"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[3]
                      }"
                      class="planet-number-des south-node-num-des"
                      x="38"
                      y="274"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesDesignExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.south_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="295.70563"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraph.gatesDesignExt.south_node.gate || 0
                      }}.{{
                        chartBodygraph.gatesDesignExt.south_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.south_node.isExalted"
                      y="288"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesDesignExt.south_node.isDetriment
                      "
                      y="301.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6895" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_north_node"
                      v-if="chartBodygraph.planets.north_node"
                      @click="
                        openTooltipDialog(chartBodygraph.planets.north_node)
                      "
                      class="planet-des"
                    >
                      <path
                        data-name="design_sign_north_node"
                        d="m 26.057205,251.3175 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5000007,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8.0000007,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect north-node-des"
                        x="5"
                        y="223"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[2]
                      }"
                      class="planet-number-des north-node-num-des"
                      x="38"
                      y="223"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesDesignExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.north_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="244.80052"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraph.gatesDesignExt.north_node.gate || 0
                      }}.{{
                        chartBodygraph.gatesDesignExt.north_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.north_node.isExalted"
                      y="237"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesDesignExt.north_node.isDetriment
                      "
                      y="250.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6891" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_moon"
                      v-if="chartBodygraph.planets.moon"
                      @click="openTooltipDialog(chartBodygraph.planets.moon)"
                      class="planet-des"
                    >
                      <path
                        d="m 22.387774,202.05097 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect moon-des"
                        x="5"
                        y="171"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[4]
                      }"
                      class="planet-number-des moon-num-des"
                      x="38"
                      y="172"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_moon"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.moon)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="193.88498"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.moon.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.moon.isExalted"
                      y="187"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.moon.isDetriment"
                      y="200.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6887" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_earth"
                      v-if="chartBodygraph.planets.earth"
                      @click="openTooltipDialog(chartBodygraph.planets.earth)"
                      class="planet-des"
                      transform="translate(-0.00279548,0.74554443)"
                    >
                      <path
                        id="path178"
                        d="M 67.3,176.7 A 12.7,12.7 0 1 1 80,164 12.72,12.72 0 0 1 67.3,176.7 Z m 0,-23.1 A 10.4,10.4 0 1 0 77.7,164 10.39,10.39 0 0 0 67.3,153.6 Z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path180"
                        d="m 67.3,176.5 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path182"
                        d="M 78.7,165 H 55.9 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        class="planet-substrate-rect earth-des"
                        x="5"
                        y="121"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[1]
                      }"
                      class="planet-number-des earth-num-des"
                      x="38"
                      y="121"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_earth"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.earth)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="142.96954"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraph.gatesDesignExt.earth.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.earth.isExalted"
                      y="134"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.earth.isDetriment"
                      y="147.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6880" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_sun"
                      v-if="chartBodygraph.planets.sun"
                      @click="openTooltipDialog(chartBodygraph.planets.sun)"
                      class="planet-des"
                      transform="translate(0.2471061)"
                    >
                      <path
                        id="path185"
                        d="m 67,126.48 a 12.65,12.65 0 1 1 12.7,-12.6 12.72,12.72 0 0 1 -12.7,12.6 z m 0,-23 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <circle
                        id="circle187"
                        cx="20.059999"
                        cy="85.169998"
                        r="5.5999999"
                      />
                      <rect
                        class="planet-substrate-rect sun-des"
                        x="5"
                        y="70"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[0]
                      }"
                      class="planet-number-des sun-num-des"
                      x="38"
                      y="70"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_sun"
                      @click="
                        openTooltipDialog(chartBodygraph.gatesDesignExt.sun)
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesDesignExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="92.054047"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                      inkscape:label=""
                    >
                      {{ chartBodygraph.gatesDesignExt.sun.gate || 0 }}.{{
                        chartBodygraph.gatesDesignExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.sun.isExalted"
                      y="84"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesDesignExt.sun.isDetriment"
                      y="97"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                </g>
                <g id="g7409">
                  <text
                    id="text226"
                    class="col-bg-des"
                    style="font-size:20.71999931px;isolation:isolate"
                    font-size="20.72"
                    x="30.018841"
                    y="35"
                  >
                    ДИЗАЙН
                  </text>
                  <path
                    :class="{ not_active_des_toggle: isDesignToggle }"
                    class="des_toggle"
                    fill="none"
                    stroke-miterlimit="10"
                    d="M61.141,15.818c0,8.461-6.858,15.319-15.319,15.319H15.819c-8.46,0-15.319-6.858-15.319-15.319l0,0C0.5,7.358,7.358,0.5,15.819,0.5h30.003C54.282,0.5,61.141,7.358,61.141,15.818L61.141,15.818z"
                    transform="translate(30,45)"
                  />
                  <circle
                    :class="{ not_active_des: isDesignToggle }"
                    class="des_toggle_cir"
                    cx="47"
                    cy="61"
                    r="12.836"
                  />
                  <rect
                    @click="hideDesign()"
                    class="design-rect"
                    x="28"
                    y="45"
                  />
                  <circle class="des_toggle_quest" cx="128.5" cy="25" r="8" />
                  <text
                    class="des_quest_quest"
                    style="font-size:14px;isolation:isolate"
                    font-size="14"
                    x="125"
                    y="30"
                  >
                    ?
                  </text>
                  <rect
                    @click="
                      openTooltipDialog(
                        convertToTooltipDialogObj(
                          Messages['title_help_bodygraph_des'],
                          Messages['help_bodygraph_des']
                        )
                      )
                    "
                    class="design-rect_quest"
                    x="120"
                    y="15"
                  />
                </g>
              </g>
              <g
                id="g8416"
                v-if="
                  !isHarmoniousGates &&
                    !isLoveGates &&
                    !isFearsGates &&
                    !isMelancholyGates &&
                    !isDreamGates &&
                    !isLinesGates &&
                    !isCircuitGates
                "
                transform="translate(10,2.9624634)"
              >
                <g id="g7404" :class="{ opacity4: isPersonToggle }">
                  <g id="g7331">
                    <g
                      id="person_sign_pluto"
                      v-if="chartBodygraph.planets.pluto"
                      @click="openTooltipDialog(chartBodygraph.planets.pluto)"
                      class="planet-per"
                    >
                      <path
                        inkscape:connector-curvature="0"
                        d="m 834.76945,729.70752 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 1 0 0,-15.2 z"
                        data-name="design_sign_pluto"
                      />
                      <rect
                        class="planet-substrate-rect pluto-per"
                        x="815"
                        y="700"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[12]
                      }"
                      class="planet-number-per pluto-num-per"
                      x="762"
                      y="701"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="721.48749"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.pluto.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.pluto.isExalted"
                      y="713"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.pluto.isDetriment
                      "
                      y="726.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7167">
                    <g
                      id="person_sign_neptune"
                      v-if="chartBodygraph.planets.neptune"
                      @click="openTooltipDialog(chartBodygraph.planets.neptune)"
                      class="planet-per"
                      transform="translate(23.756655,22.292976)"
                    >
                      <path
                        id="path233"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851.3,672.46 A 13.51,13.51 0 0 1 837.8,659 a 1.2,1.2 0 1 1 2.4,0 11.2,11.2 0 1 0 22.4,0 1.2,1.2 0 1 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.46 z"
                      />
                      <path
                        id="path235"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,685.56 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 1 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path237"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 859.1,679.36 h -15.5 a 1.2,1.2 0 0 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                      />
                      <polygon
                        id="polygon239"
                        points="795.56,631.94 792.06,626.04 788.66,631.94 "
                      />
                      <polygon
                        id="polygon241"
                        points="807.76,631.94 804.36,626.04 800.86,631.94 "
                      />
                      <polygon
                        id="polygon243"
                        points="820.16,631.94 816.66,626.04 813.26,631.94 "
                      />
                      <rect
                        class="planet-substrate-rect neptune-per"
                        x="790"
                        y="625"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[11]
                      }"
                      class="planet-number-per neptune-num-per"
                      x="762"
                      y="650"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="670.57196"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.neptune.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.neptune.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.neptune.isExalted
                      "
                      y="663"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.neptune.isDetriment
                      "
                      y="676.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7157">
                    <g
                      id="person_sign_uranus"
                      v-if="chartBodygraph.planets.uranus"
                      @click="openTooltipDialog(chartBodygraph.planets.uranus)"
                      class="planet-per"
                      transform="translate(23.806655,21.642479)"
                    >
                      <path
                        id="path246"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 840.3,629.89 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path248"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.3,630.39 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path250"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,624.39 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                      />
                      <path
                        id="path252"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,633.69 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.5 z"
                      />
                      <path
                        id="path254"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.8,618.89 h -13.1 a 1,1 0 0 1 0,-2 h 13.1 a 1,1 0 0 1 0,2 z"
                      />
                      <rect
                        class="planet-substrate-rect uranus-per"
                        x="790"
                        y="576"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[10]
                      }"
                      class="planet-number-per uranus-num-per"
                      x="762"
                      y="600"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="619.65643"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.uranus.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.uranus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.uranus.isExalted"
                      y="614"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.uranus.isDetriment
                      "
                      y="627.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7148">
                    <g
                      id="person_sign_saturn"
                      v-if="chartBodygraph.planets.saturn"
                      @click="openTooltipDialog(chartBodygraph.planets.saturn)"
                      class="planet-per"
                      transform="translate(23.857012,22.837349)"
                    >
                      <path
                        id="path257"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 855.2,582.68 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 1 0 -11.4,0 1.2,1.2 0 1 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                      />
                      <path
                        id="path259"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 845.8,583.28 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 1 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path261"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 848.5,554.48 H 843 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 1 1 0,2.4 z"
                      />
                      <rect
                        class="planet-substrate-rect saturn-per"
                        x="790"
                        y="523"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[9]
                      }"
                      class="planet-number-per saturn-num-per"
                      x="762"
                      y="549"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="568.62701"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.saturn.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.saturn.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.saturn.isExalted"
                      y="562"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.saturn.isDetriment
                      "
                      y="575.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7141">
                    <g
                      id="person_sign_jupiter"
                      v-if="chartBodygraph.planets.jupiter"
                      @click="openTooltipDialog(chartBodygraph.planets.jupiter)"
                      class="planet-per"
                      transform="translate(24.006651,22.491493)"
                    >
                      <path
                        id="path264"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.6,525.86 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 1 1 -2.4,0 8.1,8.1 0 1 1 16.2,0 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 z"
                      />
                      <path
                        id="path266"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 858.3,531.86 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 1 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        class="planet-substrate-rect jupiter-per"
                        x="790"
                        y="472"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[8]
                      }"
                      class="planet-number-per jupiter-num-per"
                      x="762"
                      y="496"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="517.81512"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.jupiter.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.jupiter.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.jupiter.isExalted
                      "
                      y="511"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.jupiter.isDetriment
                      "
                      y="524.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7135">
                    <g
                      id="person_sign_mars"
                      v-if="chartBodygraph.planets.mars"
                      @click="openTooltipDialog(chartBodygraph.planets.mars)"
                      class="planet-per"
                      transform="translate(23.740137,22.618215)"
                    >
                      <path
                        id="path269"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 850,480 A 12.2,12.2 0 1 1 862.2,467.8 12.25,12.25 0 0 1 850,480 Z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.95 z"
                      />
                      <path
                        id="path271"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.2,461 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 1 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                      />
                      <path
                        id="path273"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.9,459.85 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 1 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 l -0.9,5.4 a 1.16,1.16 0 0 1 -1.1,1 z"
                      />
                      <rect
                        class="planet-substrate-rect mars-per"
                        x="790"
                        y="423"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[7]
                      }"
                      class="planet-number-per mars-num-per"
                      x="762"
                      y="446"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="466.89963"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{ chartBodygraph.gatesPersonalityExt.mars.gate || 0 }}.{{
                        chartBodygraph.gatesPersonalityExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.mars.isExalted"
                      y="459"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.mars.isDetriment"
                      y="472.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7128">
                    <g
                      id="person_sign_venus"
                      v-if="chartBodygraph.planets.venus"
                      @click="openTooltipDialog(chartBodygraph.planets.venus)"
                      class="planet-per"
                      transform="translate(23.806652,22.910158)"
                    >
                      <path
                        id="path276"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,420.86 a 10.4,10.4 0 1 1 10.4,-10.4 10.39,10.39 0 0 1 -10.4,10.4 z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.1 z"
                      />
                      <path
                        id="path278"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,429.66 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 1 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path280"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.6,425.56 h -10.5 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <rect
                        class="planet-substrate-rect venus-per"
                        x="790"
                        y="370"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[6]
                      }"
                      class="planet-number-per venus-num-per"
                      x="762"
                      y="396"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="415.98413"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.venus.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.venus.isExalted"
                      y="409"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.venus.isDetriment
                      "
                      y="422.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7121">
                    <g
                      id="person_sign_mercury"
                      v-if="chartBodygraph.planets.mercury"
                      @click="openTooltipDialog(chartBodygraph.planets.mercury)"
                      class="planet-per"
                      transform="translate(23.767386,22.999209)"
                    >
                      <path
                        id="path283"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,370.7 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                      />
                      <path
                        id="path285"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,379 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 v 7.7 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path287"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.4,375.2 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <path
                        id="path289"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.9,355.6 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                      />
                      <path
                        id="path291"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.5,354.7 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                      />
                      <rect
                        class="planet-substrate-rect mercury-per"
                        x="790"
                        y="319"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[5]
                      }"
                      class="planet-number-per mercury-num-per"
                      x="762"
                      y="343"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="365.07901"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.mercury.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.mercury.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.mercury.isExalted
                      "
                      y="357"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.mercury.isDetriment
                      "
                      y="370.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7112">
                    <g
                      id="person_sign_south_node"
                      v-if="chartBodygraph.planets.south_node"
                      @click="
                        openTooltipDialog(chartBodygraph.planets.south_node)
                      "
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 828.26665,320.67685 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2,-1.2 7.55,7.55 0 0 1 6.5,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.45 z"
                      />
                      <rect
                        class="planet-substrate-rect south-node-per"
                        x="815"
                        y="293"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[3]
                      }"
                      class="planet-number-per south-node-num-per"
                      x="762"
                      y="293"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.south_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="314.15314"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.south_node.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.south_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.south_node.isExalted
                      "
                      y="307"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.south_node
                          .isDetriment
                      "
                      y="320.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7108">
                    <g
                      id="person_sign_north_node"
                      v-if="chartBodygraph.planets.north_node"
                      @click="
                        openTooltipDialog(chartBodygraph.planets.north_node)
                      "
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 833.86665,269.76502 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                      />
                      <rect
                        class="planet-substrate-rect north-node-per"
                        x="815"
                        y="242"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[2]
                      }"
                      class="planet-number-per north-node-num-per"
                      x="762"
                      y="242"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.north_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="263.24805"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.north_node.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.north_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.north_node.isExalted
                      "
                      y="257"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.north_node
                          .isDetriment
                      "
                      y="270.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7104">
                    <g
                      id="person_sign_moon"
                      v-if="chartBodygraph.planets.moon"
                      @click="openTooltipDialog(chartBodygraph.planets.moon)"
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 830.19722,220.49849 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                      />
                      <rect
                        class="planet-substrate-rect moon-per"
                        x="815"
                        y="192"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[4]
                      }"
                      class="planet-number-per moon-num-per"
                      x="762"
                      y="192"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="212.3325"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{ chartBodygraph.gatesPersonalityExt.moon.gate || 0 }}.{{
                        chartBodygraph.gatesPersonalityExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.moon.isExalted"
                      y="207"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.moon.isDetriment"
                      y="220.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7100">
                    <g
                      id="person_sign_earth"
                      v-if="chartBodygraph.planets.earth"
                      @click="openTooltipDialog(chartBodygraph.planets.earth)"
                      transform="translate(23.806655,23.383064)"
                      class="planet-per"
                    >
                      <path
                        id="path297"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.51 a 12.7,12.7 0 1 1 12.7,-12.7 12.72,12.72 0 0 1 -12.7,12.7 z m 0,-23.1 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                      />
                      <path
                        id="path299"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.31 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path301"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.7,160.81 h -22.8 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        class="planet-substrate-rect earth-per"
                        x="790"
                        y="116"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[1]
                      }"
                      class="planet-number-per earth-num-per"
                      x="762"
                      y="141"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="161.41707"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraph.gatesPersonalityExt.earth.gate || 0
                      }}.{{
                        chartBodygraph.gatesPersonalityExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.earth.isExalted"
                      y="155"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraph.gatesPersonalityExt.earth.isDetriment
                      "
                      y="168.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7093">
                    <g
                      id="person_sign_sun"
                      v-if="chartBodygraph.planets.sun"
                      @click="openTooltipDialog(chartBodygraph.planets.sun)"
                      transform="translate(24.056651,23.28752)"
                      class="planet-per"
                    >
                      <path
                        id="path304"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851,121.64 A 12.65,12.65 0 1 1 863.7,109 12.72,12.72 0 0 1 851,121.64 Z m 0,-23 A 10.4,10.4 0 1 0 861.4,109 10.39,10.39 0 0 0 851,98.64 Z"
                      />
                      <circle
                        id="circle306"
                        r="5.5999999"
                        cy="80.330002"
                        cx="804.06"
                      />
                      <rect
                        class="planet-substrate-rect sun-per"
                        x="790"
                        y="66"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[0]
                      }"
                      class="planet-number-per sun-num-per"
                      x="762"
                      y="90"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraph.gatesPersonalityExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraph.gatesPersonalityExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="110.50156"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{ chartBodygraph.gatesPersonalityExt.sun.gate || 0 }}.{{
                        chartBodygraph.gatesPersonalityExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.sun.isExalted"
                      y="104"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraph.gatesPersonalityExt.sun.isDetriment"
                      y="117"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                </g>
                <g id="g7420">
                  <text
                    id="text351"
                    class="col-bg-person"
                    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;isolation:isolate;"
                    font-size="21.34"
                    x="739.53381"
                    y="35"
                  >
                    Л
                    <tspan
                      id="tspan343"
                      style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;letter-spacing:-0.01em;writing-mode:lr-tb;text-anchor:start"
                      letter-spacing="-0.01em"
                      y="35"
                      x="752.21381"
                    >
                      И
                    </tspan>
                    <tspan
                      id="tspan345"
                      y="35"
                      x="766.12384"
                      style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start"
                    >
                      ЧНО
                    </tspan>
                    <tspan
                      id="tspan347"
                      style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;letter-spacing:0.04em;writing-mode:lr-tb;text-anchor:start"
                      letter-spacing="0.04em"
                      y="35"
                      x="807.47382"
                    >
                      С
                    </tspan>
                    <tspan
                      id="tspan349"
                      y="35"
                      x="820.60382"
                      style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start"
                    >
                      ТЬ
                    </tspan>
                  </text>
                  <path
                    :class="{ not_active_per_toggle: isPersonToggle }"
                    class="per_toggle"
                    fill="none"
                    stroke-miterlimit="10"
                    d="M61.141,15.818c0,8.461-6.858,15.319-15.319,15.319H15.819c-8.46,0-15.319-6.858-15.319-15.319l0,0C0.5,7.358,7.358,0.5,15.819,0.5h30.003C54.282,0.5,61.141,7.358,61.141,15.818L61.141,15.818z"
                    transform="translate(780,45)"
                  />
                  <circle
                    :class="{ not_active_per: isPersonToggle }"
                    class="per_toggle_cir"
                    cx="824.5"
                    cy="61"
                    r="12.836"
                  />
                  <rect
                    @click="hidePerson()"
                    class="design-rect"
                    x="775"
                    y="44"
                  />
                  <circle class="per_toggle_quest" cx="728.5" cy="25" r="8" />
                  <text
                    class="per_quest_quest"
                    style="font-size:14px;isolation:isolate"
                    font-size="14"
                    x="725"
                    y="30"
                  >
                    ?
                  </text>
                  <rect
                    @click="
                      openTooltipDialog(
                        convertToTooltipDialogObj(
                          Messages['title_help_bodygraph_per'],
                          Messages['help_bodygraph_per']
                        )
                      )
                    "
                    class="design-rect_quest"
                    x="720"
                    y="15"
                  />
                </g>
              </g>
              <g
                id="Слой_12"
                data-name="Слой 12"
                transform="translate(31.134997,17.409983)"
              >
                <g id="Слой_9" data-name="Слой 9">
                  <g id="g394">
                    <path
                      id="path380"
                      d="m 455.8,740.4 c -51.8,0 -101.4,-14.7 -139.7,-41.3 -37.4,-26 -58.8,-60.5 -58.8,-94.7 0,-78.6 20.9,-159.8 57.3,-222.6 18.4,-31.7 39.5,-56.8 62.8,-74.5 25.1,-19.1 51.4,-28.8 78.4,-28.8 53.5,0 103.2,33.6 140,94.7 36.3,60.2 57.1,142.3 58.5,231.3 v 0 c 0.4,25.6 -11.2,51.2 -33.6,74 -18.1,18.4 -42.4,33.9 -70.3,44.7 a 267.18,267.18 0 0 1 -94.6,17.2 z m 0,-452 c -48.6,0 -95.7,34.9 -132.5,98.4 -35.6,61.4 -56,140.7 -56,217.6 0,30.8 19.9,62.3 54.5,86.5 36.6,25.5 84.2,39.5 134,39.5 61.5,0 121.9,-22.7 157.8,-59.1 20.5,-20.8 31.1,-43.9 30.7,-66.8 v 0 C 642.9,517.3 622.6,437 587.2,378.2 552.3,320.3 505.7,288.4 455.8,288.4 Z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path382"
                      d="m 455.8,719.4 c -47,0 -92,-13.3 -126.7,-37.5 -34,-23.7 -53.4,-55 -53.4,-86 0,-71.2 18.9,-144.8 52,-201.7 16.7,-28.7 35.8,-51.5 56.9,-67.6 22.8,-17.4 46.7,-26.2 71.2,-26.2 48.6,0 93.7,30.5 127.1,85.9 32.9,54.5 51.7,128.9 53,209.5 v 0 c 0.4,23.3 -10.2,46.6 -30.5,67.3 -16.4,16.7 -38.5,30.7 -63.8,40.6 a 241.4,241.4 0 0 1 -85.8,15.7 z m 0,-409 c -43.8,0 -86.3,31.5 -119.5,88.8 -32.2,55.5 -50.6,127.1 -50.6,196.7 0,27.7 17.9,56.1 49.1,77.8 33,23 76,35.7 120.9,35.7 55.5,0 110.1,-20.4 142.4,-53.4 18.4,-18.7 28,-39.5 27.6,-60.1 v 0 c -1.3,-78.8 -19.6,-151.4 -51.6,-204.5 -31.3,-52.2 -73.4,-81 -118.3,-81 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path384"
                      d="m 455.8,697.4 c -41.6,0 -81.4,-11.8 -112.1,-33.2 -30.1,-21 -47.4,-48.8 -47.4,-76.4 h 10 c 0,24.3 15.7,49.1 43.1,68.2 29.1,20.2 66.9,31.4 106.4,31.4 48.8,0 96.8,-18 125.3,-46.9 16.1,-16.4 24.5,-34.6 24.2,-52.6 l 10,-0.2 c 0.3,20.7 -9,41.4 -27.1,59.8 -30.7,31.2 -80.2,49.9 -132.4,49.9 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path386"
                      d="M 457.5,627.4 C 369.7,627.4 301,596 301,555.9 c 0,-40.1 68.7,-71.5 156.5,-71.5 87.8,0 156.5,31.4 156.5,71.5 0,40.1 -68.8,71.5 -156.5,71.5 z m 0,-133 c -79.4,0 -146.5,28.2 -146.5,61.5 0,33.3 67.1,61.5 146.5,61.5 79.4,0 146.5,-28.2 146.5,-61.5 0,-33.3 -67.1,-61.5 -146.5,-61.5 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path388"
                      d="m 395.56,564.19 c -41.2,0 -80,-8.4 -109.3,-23.6 -30.4,-15.8 -47.2,-37.3 -47.2,-60.5 0,-23.2 16.8,-44.7 47.2,-60.5 29.3,-15.2 68.1,-23.6 109.3,-23.6 v 10.5 c -39.7,0 -77,8 -104.9,22.6 -26.8,13.9 -41.6,32 -41.6,51 0,19 14.8,37 41.6,51 27.9,14.5 65.2,22.6 104.9,22.6 z"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path390"
                      d="m 559,506.4 h -10 c 0,-40.8 -37.2,-74 -83,-74 v -10 c 51.3,0 93,37.7 93,84 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path392"
                      d="m 565,478.4 h -10 c 0,-81.6 -44.2,-148 -98.5,-148 v -10 c 29.4,0 56.8,16.7 77.3,47 20.1,29.7 31.2,69.1 31.2,111 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Слой_8"
                data-name="Слой 8"
                transform="translate(31.140015,17.409983)"
              >
                <g id="g865">
                  <rect
                    id="rect859"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="633"
                    style="fill:#ffffff"
                  />
                  <rect
                    id="rect861"
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="633"
                    style="fill:#ffffff"
                  />
                  <rect
                    id="rect863"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="633"
                    style="fill:#ffffff"
                  />
                </g>
              </g>
              <g
                id="Слой_9-2"
                data-name="Слой 9"
                transform="translate(31.140015,17.409983)"
              >
                <g id="Слой_13-2" data-name="Слой 13">
                  <g id="g874">
                    <rect
                      id="rect868"
                      x="383.35999"
                      y="73.889999"
                      width="10"
                      height="633"
                      style="fill:#ffffff"
                    />
                    <rect
                      id="rect870"
                      x="403.85999"
                      y="73.889999"
                      width="10"
                      height="633"
                      style="fill:#ffffff"
                    />
                    <rect
                      id="rect872"
                      x="424.35999"
                      y="73.889999"
                      width="10"
                      height="633"
                      style="fill:#ffffff"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g
              id="layer8"
              v-if="
                !isHarmoniousGates &&
                  !isLoveGates &&
                  !isFearsGates &&
                  !isMelancholyGates &&
                  !isDreamGates &&
                  !isLinesGates &&
                  !isCircuitGates
              "
              inkscape:groupmode="layer"
              inkscape:label="Gates Person"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_person"
                  v-if="chartBodygraph.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      41
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      41
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_30_person"
                  v-if="chartBodygraph.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      30
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      30
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_55_person"
                  v-if="chartBodygraph.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      55
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      55
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_49_person"
                  v-if="chartBodygraph.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      49
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      49
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_39_person"
                  v-if="chartBodygraph.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      39
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      39
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_19_person"
                  v-if="chartBodygraph.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      19
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      19
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_58_person"
                  v-if="chartBodygraph.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      58
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      58
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_38_person"
                  v-if="chartBodygraph.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      38
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      38
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_54_person"
                  v-if="chartBodygraph.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      54
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      54
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_36_person"
                  v-if="chartBodygraph.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      36
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      36
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_48_person"
                  v-if="chartBodygraph.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      48
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      48
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_45_person"
                  v-if="chartBodygraph.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      45
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      45
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_21_person"
                  v-if="chartBodygraph.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      21
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      21
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_22_person"
                  v-if="chartBodygraph.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      22
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      22
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_57_person"
                  v-if="chartBodygraph.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      57
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      57
                    ),
                    opacity4: isPersonToggle
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_person"
                  v-if="chartBodygraph.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      20
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      20
                    ),
                    opacity4: isPersonToggle
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_person"
                  v-if="chartBodygraph.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      18
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      18
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_28_person"
                  v-if="chartBodygraph.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      28
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      28
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_32_person"
                  v-if="chartBodygraph.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      32
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      32
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_35_person"
                  v-if="chartBodygraph.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      35
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      35
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_12_person"
                  v-if="chartBodygraph.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      12
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      12
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_16_person"
                  v-if="chartBodygraph.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      16
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      16
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_10_person"
                  v-if="chartBodygraph.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      10
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      10
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_person"
                  v-if="chartBodygraph.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      34
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      34
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_person"
                  v-if="chartBodygraph.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      27
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      27
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_59_person"
                  v-if="chartBodygraph.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      59
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      59
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_50_person"
                  v-if="chartBodygraph.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      50
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      50
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_06_person"
                  v-if="chartBodygraph.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      6
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      6
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_37_person"
                  v-if="chartBodygraph.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      37
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      37
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_26_person"
                  v-if="chartBodygraph.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      26
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      26
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_40_person"
                  v-if="chartBodygraph.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      40
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      40
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_25_person"
                  v-if="chartBodygraph.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      25
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      25
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_51_person"
                  v-if="chartBodygraph.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      51
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      51
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_44_person"
                  v-if="chartBodygraph.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      44
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      44
                    ),
                    opacity4: isPersonToggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_person"
                  v-if="chartBodygraph.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      42
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      42
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_05_person"
                  v-if="chartBodygraph.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      5
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      5
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_15_person"
                  v-if="chartBodygraph.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      15
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      15
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_07_person"
                  v-if="chartBodygraph.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      7
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      7
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_31_person"
                  v-if="chartBodygraph.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      31
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      31
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_62_person"
                  v-if="chartBodygraph.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      62
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      62
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_17_person"
                  v-if="chartBodygraph.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      17
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      17
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_64_person"
                  v-if="chartBodygraph.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      64
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      64
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_60_person"
                  v-if="chartBodygraph.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      60
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      60
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_03_person"
                  v-if="chartBodygraph.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      3
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      3
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_14_person"
                  v-if="chartBodygraph.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      14
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      14
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_02_person"
                  v-if="chartBodygraph.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      2
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      2
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_01_person"
                  v-if="chartBodygraph.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      1
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      1
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_08_person"
                  v-if="chartBodygraph.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      8
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      8
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_23_person"
                  v-if="chartBodygraph.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      23
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      23
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_43_person"
                  v-if="chartBodygraph.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      43
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      43
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_61_person"
                  v-if="chartBodygraph.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      61
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      61
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_52_person"
                  v-if="chartBodygraph.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      52
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      52
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_09_person"
                  v-if="chartBodygraph.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      9
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      9
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_29_person"
                  v-if="chartBodygraph.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      29
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      29
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_46_person"
                  v-if="chartBodygraph.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      46
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      46
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_13_person"
                  v-if="chartBodygraph.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      13
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      13
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_33_person"
                  v-if="chartBodygraph.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      33
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      33
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_56_person"
                  v-if="chartBodygraph.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      56
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      56
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_11_person"
                  v-if="chartBodygraph.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      11
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      11
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_04_person"
                  v-if="chartBodygraph.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      4
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      4
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_63_person"
                  v-if="chartBodygraph.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      63
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      63
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_53_person"
                  v-if="chartBodygraph.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      53
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      53
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_47_person"
                  v-if="chartBodygraph.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      47
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      47
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_24_person"
                  v-if="chartBodygraph.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      24
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityOnly,
                      24
                    ),
                    opacity4: isPersonToggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer8-1"
              v-if="isHarmoniousGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Person Harmonious Gates"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14-1"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[41]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[41])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      41
                    )
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_30_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[30]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[30])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      30
                    )
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_55_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[55]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[55])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      55
                    )
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_49_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[49]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[49])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      49
                    )
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_39_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[39]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[39])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      39
                    )
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_19_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[19]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[19])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      19
                    )
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_58_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[58]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[58])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      58
                    )
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_38_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[38]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[38])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      38
                    )
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_54_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[54]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[54])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      54
                    )
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_36_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[36]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[36])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      36
                    )
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_48_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[48]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[48])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      48
                    )
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_45_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[45]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[45])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      45
                    )
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_21_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[21]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[21])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      21
                    )
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_22_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[22]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[22])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      22
                    )
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_57_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[57]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[57])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      57
                    )
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[20]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[20])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      20
                    )
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[18]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[18])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      18
                    )
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_28_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[28]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[28])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      28
                    )
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_32_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[32]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[32])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      32
                    )
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_35_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[35]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[35])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      35
                    )
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_12_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[12]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[12])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      12
                    )
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_16_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[16]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[16])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      16
                    )
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_10_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[10]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[10])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      10
                    )
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[34]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[34])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      34
                    )
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[27]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[27])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      27
                    )
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_59_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[59]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[59])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      59
                    )
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_50_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[50]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[50])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      50
                    )
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_06_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[6]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[6])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      6
                    )
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_37_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[37]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[37])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      37
                    )
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_26_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[26]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[26])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      26
                    )
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_40_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[40]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[40])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      40
                    )
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_25_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[25]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[25])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      25
                    )
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_51_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[51]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[51])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      51
                    )
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_44_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[44]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[44])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      44
                    )
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[42]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[42])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      42
                    )
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_05_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[5]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[5])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      5
                    )
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_15_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[15]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[15])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      15
                    )
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_07_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[7]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[7])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      7
                    )
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_31_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[31]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[31])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      31
                    )
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_62_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[62]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[62])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      62
                    )
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_17_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[17]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[17])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      17
                    )
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_64_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[64]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[64])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      64
                    )
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_60_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[60]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[60])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      60
                    )
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_03_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[3]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[3])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      3
                    )
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_14_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[14]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[14])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      14
                    )
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_02_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[2]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[2])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      2
                    )
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_01_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[1]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[1])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      1
                    )
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_08_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[8]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[8])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      8
                    )
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_23_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[23]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[23])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      23
                    )
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_43_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[43]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[43])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      43
                    )
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_61_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[61]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[61])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      61
                    )
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_52_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[52]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[52])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      52
                    )
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_09_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[9]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[9])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      9
                    )
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_29_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[29]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[29])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      29
                    )
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_46_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[46]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[46])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      46
                    )
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_13_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[13]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[13])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      13
                    )
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_33_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[33]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[33])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      33
                    )
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_56_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[56]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[56])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      56
                    )
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_11_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[11]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[11])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      11
                    )
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_04_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[4]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[4])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      4
                    )
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_63_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[63]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[63])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      63
                    )
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_53_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[53]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[53])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      53
                    )
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_47_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[47]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[47])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      47
                    )
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_24_harmonious_person"
                  v-if="chartBodygraph.harmoniousGates[24]"
                  @click="openTooltipDialog(chartBodygraph.harmoniousGates[24])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.harmoniousGateIds,
                      24
                    )
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer8-2"
              v-if="isLoveGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Person Love Gates"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14-2"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_love_person"
                  v-if="chartBodygraph.loveGates[41]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[41])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 41)
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_30_love_person"
                  v-if="chartBodygraph.loveGates[30]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[30])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 30)
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_55_love_person"
                  v-if="chartBodygraph.loveGates[55]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[55])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 55)
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_49_love_person"
                  v-if="chartBodygraph.loveGates[49]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[49])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 49)
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_39_love_person"
                  v-if="chartBodygraph.loveGates[39]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[39])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 39)
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_19_love_person"
                  v-if="chartBodygraph.loveGates[19]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[19])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 19)
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_58_love_person"
                  v-if="chartBodygraph.loveGates[58]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[58])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 58)
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_38_love_person"
                  v-if="chartBodygraph.loveGates[38]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[38])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 38)
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_54_love_person"
                  v-if="chartBodygraph.loveGates[54]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[54])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 54)
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_36_love_person"
                  v-if="chartBodygraph.loveGates[36]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[36])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 36)
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_48_love_person"
                  v-if="chartBodygraph.loveGates[48]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[48])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 48)
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_45_love_person"
                  v-if="chartBodygraph.loveGates[45]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[45])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 45)
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_21_love_person"
                  v-if="chartBodygraph.loveGates[21]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[21])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 21)
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_22_love_person"
                  v-if="chartBodygraph.loveGates[22]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[22])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 22)
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_57_love_person"
                  v-if="chartBodygraph.loveGates[57]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[57])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 57)
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_love_person"
                  v-if="chartBodygraph.loveGates[20]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[20])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 20)
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_love_person"
                  v-if="chartBodygraph.loveGates[18]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[18])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 18)
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_28_love_person"
                  v-if="chartBodygraph.loveGates[28]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[28])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 28)
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_32_love_person"
                  v-if="chartBodygraph.loveGates[32]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[32])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 32)
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_35_love_person"
                  v-if="chartBodygraph.loveGates[35]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[35])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 35)
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_12_love_person"
                  v-if="chartBodygraph.loveGates[12]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[12])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 12)
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_16_love_person"
                  v-if="chartBodygraph.loveGates[16]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[16])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 16)
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_10_love_person"
                  v-if="chartBodygraph.loveGates[10]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[10])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 10)
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_love_person"
                  v-if="chartBodygraph.loveGates[34]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[34])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 34)
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_love_person"
                  v-if="chartBodygraph.loveGates[27]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[27])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 27)
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_59_love_person"
                  v-if="chartBodygraph.loveGates[59]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[59])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 59)
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_50_love_person"
                  v-if="chartBodygraph.loveGates[50]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[50])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 50)
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_06_love_person"
                  v-if="chartBodygraph.loveGates[6]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[6])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 6)
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_37_love_person"
                  v-if="chartBodygraph.loveGates[37]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[37])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 37)
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_26_love_person"
                  v-if="chartBodygraph.loveGates[26]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[26])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 26)
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_40_love_person"
                  v-if="chartBodygraph.loveGates[40]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[40])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 40)
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_25_love_person"
                  v-if="chartBodygraph.loveGates[25]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[25])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 25)
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_51_love_person"
                  v-if="chartBodygraph.loveGates[51]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[51])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 51)
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_44_love_person"
                  v-if="chartBodygraph.loveGates[44]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[44])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 44)
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_love_person"
                  @click="openTooltipDialog(chartBodygraph.loveGates[42])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 42)
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_05_love_person"
                  v-if="chartBodygraph.loveGates[5]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[5])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 5)
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_15_love_person"
                  v-if="chartBodygraph.loveGates[15]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[15])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 15)
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_07_love_person"
                  v-if="chartBodygraph.loveGates[7]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[7])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 7)
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_31_love_person"
                  v-if="chartBodygraph.loveGates[31]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[31])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 31)
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_62_love_person"
                  v-if="chartBodygraph.loveGates[62]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[62])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 62)
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_17_love_person"
                  v-if="chartBodygraph.loveGates[17]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[17])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 17)
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_64_love_person"
                  v-if="chartBodygraph.loveGates[64]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[64])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 64)
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_60_love_person"
                  v-if="chartBodygraph.loveGates[60]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[60])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 60)
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_03_love_person"
                  v-if="chartBodygraph.loveGates[3]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[3])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 3)
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_14_love_person"
                  v-if="chartBodygraph.loveGates[14]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[14])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 14)
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_02_love_person"
                  v-if="chartBodygraph.loveGates[2]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[2])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 2)
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_01_love_person"
                  v-if="chartBodygraph.loveGates[1]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[1])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 1)
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_08_love_person"
                  v-if="chartBodygraph.loveGates[8]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[8])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 8)
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_23_love_person"
                  v-if="chartBodygraph.loveGates[23]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 23)
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_43_love_person"
                  v-if="chartBodygraph.loveGates[43]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[43])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 43)
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_61_love_person"
                  v-if="chartBodygraph.loveGates[61]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[61])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 61)
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_52_love_person"
                  v-if="chartBodygraph.loveGates[52]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[52])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 52)
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_09_love_person"
                  v-if="chartBodygraph.loveGates[9]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[9])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 9)
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_29_love_person"
                  v-if="chartBodygraph.loveGates[29]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[29])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 29)
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_46_love_person"
                  v-if="chartBodygraph.loveGates[46]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[46])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 46)
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_13_love_person"
                  v-if="chartBodygraph.loveGates[13]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[13])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 13)
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_33_love_person"
                  v-if="chartBodygraph.loveGates[33]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[33])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 33)
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_56_love_person"
                  v-if="chartBodygraph.loveGates[56]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[56])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 56)
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_11_love_person"
                  v-if="chartBodygraph.loveGates[11]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[11])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 11)
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_04_love_person"
                  v-if="chartBodygraph.loveGates[4]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[4])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 4)
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_63_love_person"
                  v-if="chartBodygraph.loveGates[63]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[63])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 63)
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_53_love_person"
                  v-if="chartBodygraph.loveGates[53]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[53])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 53)
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_47_love_person"
                  v-if="chartBodygraph.loveGates[47]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[47])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 47)
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_24_love_person"
                  v-if="chartBodygraph.loveGates[24]"
                  @click="openTooltipDialog(chartBodygraph.loveGates[24])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 24)
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer8-3"
              v-if="isFearsGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Person Fears Gates"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14-3"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_fears_person"
                  v-if="chartBodygraph.fearGates[41]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[41])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 41)
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_30_fears_person"
                  v-if="chartBodygraph.fearGates[30]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[30])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 30)
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_55_fears_person"
                  v-if="chartBodygraph.fearGates[55]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[55])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 55)
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_49_fears_person"
                  v-if="chartBodygraph.fearGates[49]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[49])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 49)
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_39_fears_person"
                  v-if="chartBodygraph.fearGates[39]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[39])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 39)
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_19_fears_person"
                  v-if="chartBodygraph.fearGates[19]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[19])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 19)
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_58_fears_person"
                  v-if="chartBodygraph.fearGates[58]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[58])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 58)
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_38_fears_person"
                  v-if="chartBodygraph.fearGates[38]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[38])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 38)
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_54_fears_person"
                  v-if="chartBodygraph.fearGates[54]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[54])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 54)
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_36_fears_person"
                  v-if="chartBodygraph.fearGates[36]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[36])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 36)
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_48_fears_person"
                  v-if="chartBodygraph.fearGates[48]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[48])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 48)
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_45_fears_person"
                  v-if="chartBodygraph.fearGates[45]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[45])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 45)
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_21_fears_person"
                  v-if="chartBodygraph.fearGates[21]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[21])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 21)
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_22_fears_person"
                  v-if="chartBodygraph.fearGates[22]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[22])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 22)
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_57_fears_person"
                  v-if="chartBodygraph.fearGates[57]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[57])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 57)
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_fears_person"
                  v-if="chartBodygraph.fearGates[20]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[20])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 20)
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_fears_person"
                  v-if="chartBodygraph.fearGates[18]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[18])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 18)
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_28_fears_person"
                  v-if="chartBodygraph.fearGates[28]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[28])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 28)
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_32_fears_person"
                  v-if="chartBodygraph.fearGates[32]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[32])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 32)
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_35_fears_person"
                  v-if="chartBodygraph.fearGates[35]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[35])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 35)
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_12_fears_person"
                  v-if="chartBodygraph.fearGates[12]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[12])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 12)
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_16_fears_person"
                  v-if="chartBodygraph.fearGates[16]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[16])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 16)
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_10_fears_person"
                  v-if="chartBodygraph.fearGates[10]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[10])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 10)
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_fears_person"
                  v-if="chartBodygraph.fearGates[34]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[34])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 34)
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_fears_person"
                  v-if="chartBodygraph.fearGates[27]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[27])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 27)
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_59_fears_person"
                  v-if="chartBodygraph.fearGates[59]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[59])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 59)
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_50_fears_person"
                  v-if="chartBodygraph.fearGates[50]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[50])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 50)
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_06_fears_person"
                  v-if="chartBodygraph.fearGates[6]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[6])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 6)
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_37_fears_person"
                  v-if="chartBodygraph.fearGates[37]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[37])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 37)
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_26_fears_person"
                  v-if="chartBodygraph.fearGates[26]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[26])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 26)
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_40_fears_person"
                  v-if="chartBodygraph.fearGates[40]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[40])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 40)
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_25_fears_person"
                  v-if="chartBodygraph.fearGates[25]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[25])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 25)
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_51_fears_person"
                  v-if="chartBodygraph.fearGates[51]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[51])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 51)
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_44_fears_person"
                  v-if="chartBodygraph.fearGates[44]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[44])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 44)
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_fears_person"
                  v-if="chartBodygraph.fearGates[42]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[42])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 42)
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_05_fears_person"
                  v-if="chartBodygraph.fearGates[5]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[5])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 5)
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_15_fears_person"
                  v-if="chartBodygraph.fearGates[15]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[15])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 15)
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_07_fears_person"
                  v-if="chartBodygraph.fearGates[7]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[7])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 7)
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_31_fears_person"
                  v-if="chartBodygraph.fearGates[31]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[31])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 31)
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_62_fears_person"
                  v-if="chartBodygraph.fearGates[62]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[62])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 62)
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_17_fears_person"
                  v-if="chartBodygraph.fearGates[17]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[17])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 17)
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_64_fears_person"
                  v-if="chartBodygraph.fearGates[64]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[64])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 64)
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_60_fears_person"
                  v-if="chartBodygraph.fearGates[60]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[60])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 60)
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_03_fears_person"
                  v-if="chartBodygraph.fearGates[3]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[3])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 3)
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_14_fears_person"
                  v-if="chartBodygraph.fearGates[14]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[14])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 14)
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_02_fears_person"
                  v-if="chartBodygraph.fearGates[2]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[2])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 2)
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_01_fears_person"
                  v-if="chartBodygraph.fearGates[1]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[1])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 1)
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_08_fears_person"
                  v-if="chartBodygraph.fearGates[8]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[8])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 8)
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_23_fears_person"
                  v-if="chartBodygraph.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[23])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 23)
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_43_fears_person"
                  v-if="chartBodygraph.fearGates[43]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[43])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 43)
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_61_fears_person"
                  v-if="chartBodygraph.fearGates[61]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[61])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 61)
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_52_fears_person"
                  v-if="chartBodygraph.fearGates[52]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[52])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 52)
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_09_fears_person"
                  v-if="chartBodygraph.fearGates[9]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[9])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 9)
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_29_fears_person"
                  v-if="chartBodygraph.fearGates[29]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[29])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 29)
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_46_fears_person"
                  v-if="chartBodygraph.fearGates[46]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[46])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 46)
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_13_fears_person"
                  v-if="chartBodygraph.fearGates[13]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[13])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 13)
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_33_fears_person"
                  v-if="chartBodygraph.fearGates[33]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[33])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 33)
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_56_fears_person"
                  v-if="chartBodygraph.fearGates[56]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[56])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 56)
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_11_fears_person"
                  v-if="chartBodygraph.fearGates[11]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[11])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 11)
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_04_fears_person"
                  v-if="chartBodygraph.fearGates[4]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[4])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 4)
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_63_fears_person"
                  v-if="chartBodygraph.fearGates[63]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[63])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 63)
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_53_fears_person"
                  v-if="chartBodygraph.fearGates[53]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[53])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 53)
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_47_fears_person"
                  v-if="chartBodygraph.fearGates[47]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[47])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 47)
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_24_fears_person"
                  v-if="chartBodygraph.fearGates[24]"
                  @click="openTooltipDialog(chartBodygraph.fearGates[24])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 24)
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer8-4"
              v-if="isMelancholyGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Person Melancholy Gates"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14-4"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[41]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[41])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      41
                    )
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_30_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[30]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[30])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      30
                    )
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_55_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[55]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[55])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      55
                    )
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_49_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[49]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[49])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      49
                    )
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_39_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[39]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[39])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      39
                    )
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_19_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[19]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[19])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      19
                    )
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_58_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[58]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[58])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      58
                    )
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_38_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[38]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[38])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      38
                    )
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_54_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[54]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[54])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      54
                    )
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_36_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[36]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[36])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      36
                    )
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_48_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[48]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[48])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      48
                    )
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_45_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[45]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[45])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      45
                    )
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_21_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[21]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[21])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      21
                    )
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_22_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[22]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[22])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      22
                    )
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_57_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[57]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[57])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      57
                    )
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[20]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[20])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      20
                    )
                  }"
                  style="fill:#3e4448;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[18]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[18])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      18
                    )
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_28_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[28]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[28])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      28
                    )
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_32_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[32]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[32])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      32
                    )
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_35_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[35]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[35])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      35
                    )
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_12_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[12]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[12])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      12
                    )
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_16_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[16]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[16])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      16
                    )
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_10_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[10]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[10])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      10
                    )
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[34]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[34])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      34
                    )
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[27]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[27])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      27
                    )
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_59_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[59]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[59])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      59
                    )
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_50_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[50]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[50])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      50
                    )
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_06_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[6]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[6])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      6
                    )
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_37_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[37]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[37])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      37
                    )
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_26_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[26]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[26])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      26
                    )
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_40_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[40]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[40])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      40
                    )
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_25_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[25]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[25])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      25
                    )
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_51_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[51]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[51])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      51
                    )
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <path
                  id="gate_44_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[44]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[44])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      44
                    )
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[42]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[42])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      42
                    )
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_05_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[5]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[5])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      5
                    )
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_15_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[15]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[15])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      15
                    )
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_07_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[7]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[7])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      7
                    )
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_31_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[31]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[31])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      31
                    )
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_62_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[62]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[62])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      62
                    )
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_17_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[17]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[17])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      17
                    )
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_64_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[64]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[64])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      64
                    )
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_60_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[60]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[60])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      60
                    )
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_03_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[3]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[3])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      3
                    )
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_14_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[14]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[14])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      14
                    )
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_02_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[2]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[2])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      2
                    )
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_01_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[1]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[1])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      1
                    )
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_08_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[8]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[8])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      8
                    )
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_23_melancholy_person"
                  v-if="chartBodygraph.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[23])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      23
                    )
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_43_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[43]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[43])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      43
                    )
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_61_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[61]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[61])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      61
                    )
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_52_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[52]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[52])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      52
                    )
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_09_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[9]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[9])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      9
                    )
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_29_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[29]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[29])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      29
                    )
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_46_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[46]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[46])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      46
                    )
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_13_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[13]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[13])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      13
                    )
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_33_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[33]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[33])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      33
                    )
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_56_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[56]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[56])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      56
                    )
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_11_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[11]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[11])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      11
                    )
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_04_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[4]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[4])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      4
                    )
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_63_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[63]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[63])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      63
                    )
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_53_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[53]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[53])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      53
                    )
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_47_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[47]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[47])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      47
                    )
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
                <rect
                  id="gate_24_melancholy_person"
                  v-if="chartBodygraph.melancholyGates[24]"
                  @click="openTooltipDialog(chartBodygraph.melancholyGates[24])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.melancholyGateIds,
                      24
                    )
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#3e4448;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer8-5"
              v-if="isDreamGates"
              inkscape:groupmode="layer"
              inkscape:label="Dream's Gates"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14-5"
                style="display:inline"
                data-name="Слой 14-5"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_dream_gates"
                  v-if="chartBodygraph.dreamGates[41]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[41])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 41)
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_30_dream_gates"
                  v-if="chartBodygraph.dreamGates[30]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[30])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 30)
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_55_dream_gates"
                  v-if="chartBodygraph.dreamGates[55]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[55])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 55)
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_49_dream_gates"
                  v-if="chartBodygraph.dreamGates[49]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[49])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 49)
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_39_dream_gates"
                  v-if="chartBodygraph.dreamGates[39]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[39])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 39)
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_19_dream_gates"
                  v-if="chartBodygraph.dreamGates[19]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[19])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 19)
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_58_dream_gates"
                  v-if="chartBodygraph.dreamGates[58]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[58])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 58)
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_38_dream_gates"
                  v-if="chartBodygraph.dreamGates[38]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[38])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 38)
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_54_dream_gates"
                  v-if="chartBodygraph.dreamGates[54]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[54])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 54)
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_36_dream_gates"
                  v-if="chartBodygraph.dreamGates[36]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[36])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 36)
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_48_dream_gates"
                  v-if="chartBodygraph.dreamGates[48]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[48])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 48)
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_45_dream_gates"
                  v-if="chartBodygraph.dreamGates[45]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[45])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 45)
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_21_dream_gates"
                  v-if="chartBodygraph.dreamGates[21]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[21])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 21)
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_22_dream_gates"
                  v-if="chartBodygraph.dreamGates[22]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[22])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 22)
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_57_dream_gates"
                  v-if="chartBodygraph.dreamGates[57]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[57])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 57)
                  }"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_dream_gates"
                  v-if="chartBodygraph.dreamGates[20]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[20])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 20)
                  }"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <!-- <g
                  id="gate_20_57_dream_channel"
                  v-if="chartBodygraph.dreamChannels['20-57']"
                  @click="
                    openTooltipDialog(chartBodygraph.dreamChannels['20-57'])
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamChannelIds,
                      '20-57'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_10_57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g> -->
                <path
                  id="gate_18_dream_gates"
                  v-if="chartBodygraph.dreamGates[18]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[18])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 18)
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_28_dream_gates"
                  v-if="chartBodygraph.dreamGates[28]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[28])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 28)
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <!-- <g
                  id="gate_28_38_dream_channel"
                  v-if="chartBodygraph.dreamChannels['28-38']"
                  @click="
                    openTooltipDialog(chartBodygraph.dreamChannels['28-38'])
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamChannelIds,
                      '28-38'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_28_38_dream_channel"
                >
                  <path
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g> -->
                <path
                  id="gate_32_dream_gates"
                  v-if="chartBodygraph.dreamGates[32]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[32])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 32)
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_35_dream_gates"
                  v-if="chartBodygraph.dreamGates[35]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[35])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 35)
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_12_dream_gates"
                  v-if="chartBodygraph.dreamGates[12]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[12])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 12)
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_16_dream_gates"
                  v-if="chartBodygraph.dreamGates[16]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[16])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 16)
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_10_dream_gates"
                  v-if="chartBodygraph.dreamGates[10]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[10])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 10)
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_dream_gates"
                  v-if="chartBodygraph.dreamGates[34]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[34])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 34)
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_dream_gates"
                  v-if="chartBodygraph.dreamGates[27]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[27])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 27)
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_59_dream_gates"
                  v-if="chartBodygraph.dreamGates[59]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[59])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 59)
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_50_dream_gates"
                  v-if="chartBodygraph.dreamGates[50]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[50])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 50)
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <!-- <g
                  id="gate_27_50_dream_channel"
                  v-if="chartBodygraph.dreamChannels['27-50']"
                  @click="
                    openTooltipDialog(chartBodygraph.dreamChannels['27-50'])
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamChannelIds,
                      '27-50'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_27_50_dream_channel"
                >
                  <path
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g> -->
                <path
                  id="gate_06_dream_gates"
                  v-if="chartBodygraph.dreamGates[6]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[6])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 6)
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_37_dream_gates"
                  v-if="chartBodygraph.dreamGates[37]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[37])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 37)
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_26_dream_gates"
                  v-if="chartBodygraph.dreamGates[26]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[26])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 26)
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_40_dream_gates"
                  v-if="chartBodygraph.dreamGates[40]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[40])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 40)
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_25_dream_gates"
                  v-if="chartBodygraph.dreamGates[25]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[25])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 25)
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_51_dream_gates"
                  v-if="chartBodygraph.dreamGates[51]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[51])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 51)
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_44_dream_gates"
                  v-if="chartBodygraph.dreamGates[44]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[44])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 44)
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_dream_gates"
                  v-if="chartBodygraph.dreamGates[42]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[42])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 42)
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_05_dream_gates"
                  v-if="chartBodygraph.dreamGates[5]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[5])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 5)
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_15_dream_gates"
                  v-if="chartBodygraph.dreamGates[15]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[15])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 15)
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <!-- <g
                  id="gate_05_15_dream_channel"
                  v-if="chartBodygraph.dreamChannels['5-15']"
                  @click="
                    openTooltipDialog(chartBodygraph.dreamChannels['5-15'])
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamChannelIds,
                      '5-15'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_05_15_dream_channel"
                >
                  <rect
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g> -->
                <rect
                  id="gate_07_dream_gates"
                  v-if="chartBodygraph.dreamGates[7]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[7])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 7)
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_31_dream_gates"
                  v-if="chartBodygraph.dreamGates[31]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[31])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 31)
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_62_dream_gates"
                  v-if="chartBodygraph.dreamGates[62]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[62])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 62)
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_17_dream_gates"
                  v-if="chartBodygraph.dreamGates[17]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[17])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 17)
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_64_dream_gates"
                  v-if="chartBodygraph.dreamGates[64]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[64])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 64)
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_60_dream_gates"
                  v-if="chartBodygraph.dreamGates[60]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[60])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 60)
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_03_dream_gates"
                  v-if="chartBodygraph.dreamGates[3]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[3])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 3)
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_14_dream_gates"
                  v-if="chartBodygraph.dreamGates[14]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[14])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 14)
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_02_dream_gates"
                  v-if="chartBodygraph.dreamGates[2]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[2])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 2)
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_01_dream_gates"
                  v-if="chartBodygraph.dreamGates[1]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[1])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 1)
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_08_dream_gates"
                  v-if="chartBodygraph.dreamGates[8]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[8])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 8)
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <!-- <g
                  id="gate_01_08_dream_channel"
                  v-if="chartBodygraph.dreamChannels['1-8']"
                  @click="
                    openTooltipDialog(chartBodygraph.dreamChannels['1-8'])
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamChannelIds,
                      '1-8'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_01_08_dream_channel"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g> -->
                <rect
                  id="gate_23_dream_gates"
                  v-if="chartBodygraph.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[23])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 23)
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_43_dream_gates"
                  v-if="chartBodygraph.dreamGates[43]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[43])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 43)
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_61_dream_gates"
                  v-if="chartBodygraph.dreamGates[61]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[61])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 61)
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_52_dream_gates"
                  v-if="chartBodygraph.dreamGates[52]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[52])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 52)
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_09_dream_gates"
                  v-if="chartBodygraph.dreamGates[9]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[9])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 9)
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_29_dream_gates"
                  v-if="chartBodygraph.dreamGates[29]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[29])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 29)
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_46_dream_gates"
                  v-if="chartBodygraph.dreamGates[46]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[46])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 46)
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_13_dream_gates"
                  v-if="chartBodygraph.dreamGates[13]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[13])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 13)
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_33_dream_gates"
                  v-if="chartBodygraph.dreamGates[33]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[33])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 33)
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_56_dream_gates"
                  v-if="chartBodygraph.dreamGates[56]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[56])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 56)
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_11_dream_gates"
                  v-if="chartBodygraph.dreamGates[11]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[11])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 11)
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_04_dream_gates"
                  v-if="chartBodygraph.dreamGates[4]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[4])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 4)
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_63_dream_gates"
                  v-if="chartBodygraph.dreamGates[63]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[63])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 63)
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_53_dream_gates"
                  v-if="chartBodygraph.dreamGates[53]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[53])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 53)
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <!-- <g
                  id="gate_42_53_dream_channel"
                  v-if="chartBodygraph.dreamChannels['42-53']"
                  @click="
                    openTooltipDialog(chartBodygraph.dreamChannels['42-53'])
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamChannelIds,
                      '42-53'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_42_53_dream_channel"
                >
                  <rect
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g> -->
                <rect
                  id="gate_47_dream_gates"
                  v-if="chartBodygraph.dreamGates[47]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[47])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 47)
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_24_dream_gates"
                  v-if="chartBodygraph.dreamGates[24]"
                  @click="openTooltipDialog(chartBodygraph.dreamGates[24])"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 24)
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer8-6"
              v-if="isLinesGates"
              inkscape:groupmode="layer"
              inkscape:label="Lines's Gates"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14-5"
                style="display:inline"
                data-name="Слой 14-5"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 41)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      41
                    )
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_30_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 30)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      30
                    )
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_55_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 55)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      55
                    )
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_49_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 49)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      49
                    )
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_39_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 39)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      39
                    )
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_19_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 19)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      19
                    )
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_58_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 58)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      58
                    )
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_38_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 38)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      38
                    )
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_54_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 54)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      54
                    )
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_36_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 36)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      36
                    )
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_48_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 48)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      48
                    )
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_45_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 45)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      45
                    )
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_21_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 21)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      21
                    )
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_22_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 22)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      22
                    )
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_57_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 57)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      57
                    )
                  }"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 20)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      20
                    )
                  }"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 18)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      18
                    )
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_28_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 28)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      28
                    )
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_32_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 32)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      32
                    )
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_35_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 35)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      35
                    )
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_12_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 12)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      12
                    )
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_16_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 16)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      1
                    )
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_10_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 10)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      10
                    )
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 34)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      34
                    )
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 27)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[27])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      27
                    )
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_59_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 59)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      59
                    )
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_50_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 50)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      50
                    )
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_06_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 6)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      6
                    )
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_37_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 37)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      37
                    )
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_26_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 26)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      26
                    )
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_40_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 40)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      40
                    )
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_25_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 25)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      25
                    )
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_51_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 51)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      51
                    )
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_44_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 44)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      44
                    )
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 42)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      42
                    )
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_05_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 5)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      5
                    )
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_15_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 15)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      15
                    )
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_07_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 7)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      7
                    )
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_31_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 31)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      31
                    )
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_62_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 62)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      62
                    )
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_17_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 17)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      17
                    )
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_64_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 64)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      64
                    )
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_60_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 60)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      60
                    )
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_03_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 3)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      3
                    )
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_14_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 14)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      14
                    )
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_02_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 2)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      2
                    )
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_01_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 1)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      1
                    )
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_08_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 8)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      8
                    )
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_23_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 23)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      23
                    )
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_43_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 43)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      43
                    )
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_61_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 61)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      61
                    )
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_52_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 52)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      52
                    )
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_09_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 9)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      9
                    )
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_29_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 29)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      29
                    )
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_46_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 46)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      46
                    )
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_13_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 13)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      13
                    )
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_33_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 33)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      33
                    )
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_56_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 56)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      56
                    )
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_11_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 11)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      11
                    )
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_04_lines_gates"
                  v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 4)"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      4
                    )
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_63_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 63)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      63
                    )
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_53_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 53)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      53
                    )
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_47_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 47)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[47])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      47
                    )
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_24_lines_gates"
                  v-if="
                    isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 24)
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.lines[tabAddInfo].gates,
                      24
                    )
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer8-7"
              v-if="isCircuitGates"
              inkscape:groupmode="layer"
              inkscape:label="Circuit's Gates"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14-5"
                style="display:inline"
                data-name="Слой 14-5"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      41
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      41
                    )
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_30_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      30
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      30
                    )
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_55_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      55
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      55
                    )
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_49_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      49
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      49
                    )
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_39_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      39
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      39
                    )
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_19_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      19
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      19
                    )
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_58_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      58
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      58
                    )
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_38_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      38
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      38
                    )
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_54_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      54
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      54
                    )
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_36_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      36
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      36
                    )
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_48_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      48
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      48
                    )
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_45_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      45
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      45
                    )
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_21_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      21
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      21
                    )
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_22_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      22
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      22
                    )
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_57_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      57
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      57
                    )
                  }"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      20
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      20
                    )
                  }"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      18
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      18
                    )
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_28_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      28
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      28
                    )
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_32_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      32
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      32
                    )
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_35_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      35
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      35
                    )
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_12_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      12
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      12
                    )
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_16_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      16
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      1
                    )
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_10_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      10
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      10
                    )
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      34
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      34
                    )
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      27
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[27])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      27
                    )
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_59_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      59
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      59
                    )
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_50_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      50
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      50
                    )
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_06_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      6
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      6
                    )
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_37_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      37
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      37
                    )
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_26_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      26
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      26
                    )
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_40_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      40
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      40
                    )
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_25_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      25
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      25
                    )
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_51_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      51
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      51
                    )
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <path
                  id="gate_44_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      44
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      44
                    )
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      42
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      42
                    )
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_05_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      5
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      5
                    )
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_15_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      15
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      15
                    )
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_07_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      7
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      7
                    )
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_31_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      31
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      31
                    )
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_62_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      62
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      62
                    )
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_17_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      17
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      17
                    )
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_64_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      64
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      64
                    )
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_60_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      60
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      60
                    )
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_03_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      3
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      3
                    )
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_14_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      14
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      14
                    )
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_02_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      2
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      2
                    )
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_01_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      1
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      1
                    )
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_08_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      8
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      8
                    )
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_23_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      23
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      23
                    )
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_43_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      43
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      43
                    )
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_61_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      61
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      61
                    )
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_52_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      52
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      52
                    )
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_09_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      9
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      9
                    )
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_29_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      29
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      29
                    )
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_46_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      46
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      46
                    )
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_13_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      13
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      13
                    )
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_33_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      33
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      33
                    )
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_56_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      56
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      56
                    )
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_11_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      11
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      11
                    )
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_04_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      4
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      4
                    )
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_63_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      63
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      63
                    )
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_53_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      53
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      53
                    )
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_47_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      47
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[47])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      47
                    )
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
                <rect
                  id="gate_24_circuit_gates"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      24
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].gates,
                      24
                    )
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#5d3aaf;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer7"
              v-if="
                !isHarmoniousGates &&
                  !isLoveGates &&
                  !isFearsGates &&
                  !isMelancholyGates &&
                  !isDreamGates &&
                  !isLinesGates &&
                  !isCircuitGates
              "
              inkscape:groupmode="layer"
              inkscape:label="Gates Design"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_15"
                style="display:inline"
                data-name="Слой 15"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_design"
                  v-if="chartBodygraph.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      41
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      41
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_30_design"
                  v-if="chartBodygraph.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      30
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      30
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_55_design"
                  v-if="chartBodygraph.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      55
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      55
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_49_design"
                  v-if="chartBodygraph.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      49
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      49
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_39_design"
                  v-if="chartBodygraph.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      39
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      39
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_19_design"
                  v-if="chartBodygraph.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      19
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      19
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_58_design"
                  v-if="chartBodygraph.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      58
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      58
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_38_design"
                  v-if="chartBodygraph.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      38
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      38
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_54_design"
                  v-if="chartBodygraph.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      54
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      54
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_36_design"
                  v-if="chartBodygraph.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      36
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      36
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_48_design"
                  v-if="chartBodygraph.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      48
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      48
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_45_design"
                  v-if="chartBodygraph.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      45
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      45
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_21_design"
                  v-if="chartBodygraph.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      21
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      21
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_22_design"
                  v-if="chartBodygraph.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      22
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      22
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_57_design"
                  v-if="chartBodygraph.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      57
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      57
                    ),
                    opacity4: isDesignToggle
                  }"
                  inkscape:connector-curvature="0"
                  d="m 239.65602,473.13104 c -6.22339,27.38635 -9.99238,55.55515 -10.69601,83.65896 l 10,1 c 0.68476,-28.46 4.57991,-56.99261 11.02413,-84.65896 z"
                  style="fill:#ff001d;opacity:0;display:none"
                  sodipodi:nodetypes="ccccc"
                />
                <path
                  id="gate_18_design"
                  v-if="chartBodygraph.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      18
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      18
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_28_design"
                  v-if="chartBodygraph.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      28
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      28
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_32_design"
                  v-if="chartBodygraph.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      32
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      32
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_35_design"
                  v-if="chartBodygraph.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      35
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      35
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_12_design"
                  v-if="chartBodygraph.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      12
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      12
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_16_design"
                  v-if="chartBodygraph.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      16
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      16
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_20_design"
                  v-if="chartBodygraph.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      20
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      20
                    ),
                    opacity4: isDesignToggle
                  }"
                  inkscape:connector-curvature="0"
                  d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29025,68.76196 -41.10282,107.54221 h 10.32812 c 8.61343,-36.97921 21.91455,-72.28182 39.47587,-102.54221 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                  style="fill:#ff001d;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccccc"
                />
                <path
                  id="gate_10_design"
                  v-if="chartBodygraph.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      10
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      10
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.63621,34.1043 -46.60398,53.64104 h 10.32812 C 252.60997,457.7943 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_design"
                  v-if="chartBodygraph.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      34
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      34
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.24428,-3.88325 0.92414,-6.65896 H 239.65602 C 239.25827,475.0917 239.06,478.89 239.06,479.79 c 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_design"
                  v-if="chartBodygraph.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      27
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      27
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_59_design"
                  v-if="chartBodygraph.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      59
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      59
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_50_design"
                  v-if="chartBodygraph.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      50
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      50
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_06_design"
                  v-if="chartBodygraph.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      6
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      6
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_37_design"
                  v-if="chartBodygraph.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      37
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      37
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_26_design"
                  v-if="chartBodygraph.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      26
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      26
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_40_design"
                  v-if="chartBodygraph.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      40
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      40
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_25_design"
                  v-if="chartBodygraph.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      25
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      25
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_51_design"
                  v-if="chartBodygraph.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      51
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      51
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                />
                <path
                  id="gate_44_design"
                  v-if="chartBodygraph.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      44
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      44
                    ),
                    opacity4: isDesignToggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#ff001d;opacity:0;display:none"
                  sodipodi:nodetypes="sccsccs"
                />
              </g>
              <g
                id="Слой_11-2"
                style="display:inline"
                data-name="Слой 11"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_design"
                  v-if="chartBodygraph.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      42
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      42
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_05_design"
                  v-if="chartBodygraph.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      5
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      5
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_15_design"
                  v-if="chartBodygraph.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      15
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      15
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_07_design"
                  v-if="chartBodygraph.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      7
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      7
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_31_design"
                  v-if="chartBodygraph.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      31
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      31
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_62_design"
                  v-if="chartBodygraph.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      62
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      62
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_17_design"
                  v-if="chartBodygraph.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      17
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      17
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_64_design"
                  v-if="chartBodygraph.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      64
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      64
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_60_design"
                  v-if="chartBodygraph.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      60
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      60
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_03_design"
                  v-if="chartBodygraph.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      3
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      3
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_14_design"
                  v-if="chartBodygraph.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      14
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      14
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_02_design"
                  v-if="chartBodygraph.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      2
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      2
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_01_design"
                  v-if="chartBodygraph.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      1
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      1
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_08_design"
                  v-if="chartBodygraph.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      8
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      8
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_23_design"
                  v-if="chartBodygraph.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      23
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      23
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_43_design"
                  v-if="chartBodygraph.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      43
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      43
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_61_design"
                  v-if="chartBodygraph.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      61
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      61
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_52_design"
                  v-if="chartBodygraph.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      52
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      52
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_09_design"
                  v-if="chartBodygraph.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      9
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      9
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_29_design"
                  v-if="chartBodygraph.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      29
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      29
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_46_design"
                  v-if="chartBodygraph.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      46
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      46
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_13_design"
                  v-if="chartBodygraph.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      13
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      13
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_33_design"
                  v-if="chartBodygraph.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      33
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      33
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_56_design"
                  v-if="chartBodygraph.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      56
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      56
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_11_design"
                  v-if="chartBodygraph.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      11
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      11
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_04_design"
                  v-if="chartBodygraph.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      4
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      4
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_63_design"
                  v-if="chartBodygraph.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      63
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      63
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_53_design"
                  v-if="chartBodygraph.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      53
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      53
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_47_design"
                  v-if="chartBodygraph.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      47
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      47
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
                <rect
                  id="gate_24_design"
                  v-if="chartBodygraph.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity5: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      24
                    ),
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtDesignOnly,
                      24
                    ),
                    opacity4: isDesignToggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fc3030;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer6"
              v-if="
                !isHarmoniousGates &&
                  !isLoveGates &&
                  !isFearsGates &&
                  !isMelancholyGates &&
                  !isDreamGates &&
                  !isLinesGates &&
                  !isCircuitGates
              "
              inkscape:groupmode="layer"
              inkscape:label="Gates Both"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_16"
                style="display:inline"
                data-name="Слой 16"
                transform="translate(31.134997,17.409983)"
              >
                <g
                  id="gate_44_both"
                  v-if="chartBodygraph.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      44
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path484"
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                    sodipodi:nodetypes="sccsccs"
                  />
                  <path
                    id="path486"
                    d="m 312.2,563.6 -9.7,2.3 a 46.94,46.94 0 0 1 -1.3,-13.6 l 10,0.4 a 38.86,38.86 0 0 0 1,10.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path488"
                    d="m 313.6,544.4 -8.9,-4.6 c 2.01937,-3.91099 4.77116,-7.62682 7.475,-11.1 l 7.5,6.53125 c -2.31213,3.06971 -4.2599,5.78135 -6.075,9.16875 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path490"
                    d="m 328.6,527 -6.1,-7.9 c 3.3,-2.5 6.8,-5 10.6,-7.4 l 5.3,8.5 c -3.5,2.2 -6.8,4.5 -9.8,6.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path492"
                    d="m 348.9,514.9 -4.3,-9 c 3.7,-1.8 7.7,-3.5 11.7,-5.1 l 3.6,9.3 c -3.8,1.5 -7.5,3.1 -11,4.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path494"
                    d="m 371.8,506.4 -3.1,-9.5 c 3.9,-1.3 8,-2.4 12.2,-3.5 l 2.5,9.7 c -4,1 -7.9,2.1 -11.6,3.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path496"
                    d="m 394.91563,500.49687 -2.07813,-9.90937 c 4.1,-0.8 8.8625,-1.7875 13.0625,-2.3875 l 3,9.8 c -4,0.6 -10.08437,1.69687 -13.98437,2.49687 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="gate_44_all_both"
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    sodipodi:nodetypes="sccsccs"
                  />
                </g>
                <g
                  id="gate_41_both"
                  v-if="chartBodygraph.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      41
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path499"
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path501"
                    d="m 509.2,735.3 -2,-9.8 c 3.9,-0.8 7.7,-1.6 11.5,-2.6 l 2.4,9.7 c -3.9,1 -7.9,1.9 -11.9,2.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path503"
                    d="m 532.9,729.3 -2.9,-9.6 c 3.7,-1.1 7.5,-2.4 11.2,-3.7 l 3.4,9.4 q -5.85,2.1 -11.7,3.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path505"
                    d="m 555.4,721.2 -3.8,-9.2 c 3.6,-1.5 7.2,-3.1 10.7,-4.8 l 4.3,9 c -3.7,1.7 -7.5,3.4 -11.2,5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path507"
                    d="m 577.7,710.2 -4.8,-8.8 c 3.4,-1.9 4.4,-2.4 7.6,-4.4 l 5.6,8.1 c -3.4,2.2 -4.7,3.1 -8.4,5.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_41_all_both"
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_58_both"
                  v-if="chartBodygraph.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      58
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path510"
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path512"
                    d="m 399.9,734.5 c -4,-0.9 -8,-1.8 -11.9,-2.9 l 2.6,-9.7 c 3.8,1 7.6,1.9 11.4,2.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path514"
                    d="m 376.3,728.2 c -3.9,-1.2 -7.8,-2.6 -11.6,-4 l 3.5,-9.4 c 3.6,1.4 7.4,2.7 11.1,3.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path516"
                    d="m 353.3,719.5 c -3.8,-1.6 -7.5,-3.4 -11.1,-5.2 l 4.5,-8.9 c 3.5,1.7 7,3.4 10.6,5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path518"
                    d="m 331.4,708.5 c -3.6,-2.1 -7.1,-4.2 -10.5,-6.4 l 5.5,-8.4 c 3.2,2.1 6.6,4.2 10,6.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_58_all_both"
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_30_both"
                  v-if="chartBodygraph.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      30
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path521"
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path523"
                    d="m 598.6,697.1 -5.8,-8.1 c 3.2,-2.3 6.3,-4.7 9.2,-7.1 l 6.4,7.7 c -3.2,2.6 -6.5,5.1 -9.8,7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path525"
                    d="m 617.7,681.5 -6.9,-7.2 c 2.8,-2.7 5.5,-5.5 8,-8.3 l 7.5,6.6 c -2.7,3 -5.6,6 -8.6,8.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path527"
                    d="m 633.9,663 -8.1,-5.9 a 113,113 0 0 0 6.3,-9.6 l 8.6,5.1 a 108.84,108.84 0 0 1 -6.8,10.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path529"
                    d="m 646.9,641.2 -9.2,-4 a 90.7,90.7 0 0 0 3.9,-10.6 l 9.6,2.8 a 93.37,93.37 0 0 1 -4.3,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_30_all_both"
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_30_41_channel"
                  v-if="chartBodygraph.channels2['30-41']"
                  @click="openTooltipDialog(chartBodygraph.channels2['30-41'])"
                  @mouseover="searchAcPlanets(30, 41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_30_41_channel"
                >
                  <path
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_18_both"
                  v-if="chartBodygraph.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      18
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path532"
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path534"
                    d="m 310.7,695.3 q -4.95,-3.75 -9.6,-7.8 l 6.6,-7.5 c 2.9,2.5 5.9,5 9.1,7.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path536"
                    d="m 291.9,678.9 c -2.9,-3 -5.8,-6.1 -8.4,-9.2 l 7.7,-6.4 c 2.4,2.9 5,5.8 7.8,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path538"
                    d="m 276,659.8 c -2.4,-3.5 -4.6,-7.1 -6.6,-10.7 l 8.8,-4.8 a 112,112 0 0 0 6,9.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path540"
                    d="m 263.9,637.7 a 93.1,93.1 0 0 1 -3.9,-12 l 9.7,-2.5 a 90.41,90.41 0 0 0 3.5,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_18_all_both"
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_18_58_channel"
                  v-if="chartBodygraph.channels2['18-58']"
                  @click="openTooltipDialog(chartBodygraph.channels2['18-58'])"
                  @mouseover="searchAcPlanets(18, 58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_18_58_channel"
                >
                  <path
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_48_both"
                  v-if="chartBodygraph.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      48
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path543"
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path545"
                    d="m 271.6,542.1 -9.9,-1.4 1.8,-12 9.9,1.6 c -0.6,3.9 -1.2,7.9 -1.8,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path547"
                    d="m 275.7,518.5 -9.8,-1.9 c 0.8,-4 1.6,-8 2.4,-11.9 l 9.8,2.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path549"
                    d="m 280.1,496.3 -9.7,-2.4 c 1,-3.9 2,-7.9 3,-11.7 l 9.6,2.6 c -0.9,3.8 -1.9,7.6 -2.9,11.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path551"
                    d="m 286.4,473.4 -9.6,-2.9 c 1.2,-3.9 2.4,-7.8 3.7,-11.6 l 9.5,3.1 q -1.95,5.7 -3.6,11.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path553"
                    d="m 293.8,450.8 -9.4,-3.4 c 1.4,-3.8 2.8,-7.6 4.3,-11.4 l 9.3,3.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path555"
                    d="m 302.5,428.8 -9.2,-3.9 c 1.6,-3.7 3.3,-7.5 4.9,-11.1 l 9.1,4.2 q -2.4,5.25 -4.8,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path557"
                    d="m 312.5,407.3 -8.9,-4.5 c 1.8,-3.6 3.7,-7.3 5.6,-10.8 l 8.8,4.8 c -1.9,3.4 -3.7,6.9 -5.5,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path559"
                    d="m 323.8,386.5 -8.6,-5.1 c 2.1,-3.5 4.2,-7 6.4,-10.4 l 8.4,5.4 c -2.1,3.3 -4.2,6.6 -6.2,10.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_48_all_both"
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_36_both"
                  v-if="chartBodygraph.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      36
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path562"
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path564"
                    d="m 639.1,535.1 c -0.5,-3.9 -1.1,-7.9 -1.8,-11.8 l 9.9,-1.6 1.8,12 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path566"
                    d="m 635.4,511.8 c -0.7,-3.9 -1.5,-7.8 -2.3,-11.7 l 9.8,-2 c 0.8,3.9 1.6,7.9 2.3,11.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path568"
                    d="m 630.4,488.6 c -0.9,-3.9 -1.9,-7.7 -2.9,-11.5 l 9.7,-2.5 c 1,3.9 2,7.8 2.9,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path570"
                    d="m 624.5,465.9 c -1.1,-3.8 -2.3,-7.6 -3.5,-11.4 l 9.5,-3 c 1.2,3.8 2.4,7.7 3.5,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path572"
                    d="m 617.3,443.2 c -1.3,-3.7 -2.7,-7.5 -4.1,-11.1 l 9.3,-3.6 c 1.4,3.7 2.8,7.6 4.2,11.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path574"
                    d="m 608.8,421 q -2.25,-5.55 -4.8,-10.8 l 9.1,-4.2 c 1.7,3.6 3.3,7.4 4.9,11.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path576"
                    d="m 599.2,399.8 c -1.8,-3.5 -3.6,-7.1 -5.5,-10.5 l 8.8,-4.8 q 2.85,5.25 5.7,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path578"
                    d="m 588.9,381 c -2,-3.4 -4.1,-6.7 -6.3,-10 l 9,-4.4 c 2.2,3.4 3.8,5.7 5.9,9.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_36_all_both"
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_16_both"
                  v-if="chartBodygraph.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      16
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path581"
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path583"
                    d="m 336.9,365.2 -8.2,-5.7 c 2.3,-3.4 4.8,-6.7 7.2,-9.9 l 8,6.1 c -2.4,3 -4.7,6.2 -7,9.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path585"
                    d="m 351.2,346.5 -7.7,-6.4 c 2.6,-3.2 5.4,-6.3 8.1,-9.2 l 7.3,6.8 c -2.5,2.8 -5.1,5.7 -7.7,8.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path587"
                    d="m 367.2,329.3 -6.9,-7.2 c 3,-2.9 6,-5.7 9.1,-8.3 l 6.5,7.6 c -3,2.5 -5.9,5.1 -8.7,7.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path589"
                    d="m 384.9,314.1 -6,-8 c 3.3,-2.5 6.8,-4.8 10.2,-7 l 5.4,8.4 q -4.8,3.15 -9.6,6.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path591"
                    d="m 404.5,301.8 -4.6,-8.9 c 3.7,-1.9 7.5,-3.7 11.3,-5.3 l 3.8,9.2 c -3.5,1.5 -7,3.2 -10.5,5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_16_all_both"
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_16_48_channel"
                  v-if="chartBodygraph.channels2['16-48']"
                  @click="openTooltipDialog(chartBodygraph.channels2['16-48'])"
                  @mouseover="searchAcPlanets(16, 48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_16_48_channel"
                >
                  <path
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_35_both"
                  v-if="chartBodygraph.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      35
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path594"
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path596"
                    d="m 574.8,359.2 c -2.3,-3.2 -4.7,-6.3 -7.1,-9.3 l 7.8,-6.3 c 2.5,3.1 5,6.4 7.4,9.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path598"
                    d="m 559.9,340.9 c -2.6,-2.9 -5.3,-5.8 -8,-8.5 l 7.1,-7.1 c 2.8,2.8 5.7,5.8 8.4,8.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path600"
                    d="m 543.5,324.3 c -2.9,-2.6 -6,-5.1 -9,-7.4 l 6.1,-7.9 c 3.2,2.5 6.4,5.1 9.5,7.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path602"
                    d="m 525.1,310.2 c -3.3,-2.1 -6.6,-4.2 -9.9,-6 l 4.8,-8.8 c 3.6,2 7.1,4.1 10.6,6.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path604"
                    d="M 504.7,299.1 A 107.79,107.79 0 0 0 494,294.9 l 3.2,-9.5 c 3.9,1.3 7.8,2.8 11.6,4.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_35_all_both"
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_35_36_channel"
                  v-if="chartBodygraph.channels2['35-36']"
                  @click="openTooltipDialog(chartBodygraph.channels2['35-36'])"
                  @mouseover="searchAcPlanets(35, 36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_35_35_channel"
                >
                  <path
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_39_both"
                  v-if="chartBodygraph.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      39
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path607"
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path609"
                    d="m 508.7,713.7 -2.2,-9.8 c 3.8,-0.8 7.7,-1.8 11.4,-2.8 l 2.7,9.6 c -3.9,1.1 -7.9,2.1 -11.9,3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path611"
                    d="m 532.5,706.8 -3.2,-9.5 c 3.7,-1.2 7.4,-2.6 11,-4 l 3.7,9.3 c -3.8,1.5 -7.7,2.9 -11.5,4.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path613"
                    d="m 555,698 -4.2,-9.1 c 3.6,-1.7 7.1,-3.4 10.4,-5.3 l 4.8,8.8 c -3.6,2 -7.2,3.8 -11,5.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_39_all_both"
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_38_both"
                  v-if="chartBodygraph.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      38
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path616"
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path618"
                    d="m 399.5,712.8 c -4,-1 -8,-2 -11.8,-3.2 l 2.9,-9.6 c 3.7,1.1 7.5,2.1 11.3,3.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path620"
                    d="m 376,705.7 q -5.85,-2.1 -11.4,-4.5 l 3.9,-9.2 c 3.5,1.5 7.2,3 10.9,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path622"
                    d="m 353.4,696.1 c -3.7,-1.8 -7.3,-3.8 -10.8,-5.8 l 5,-8.7 c 3.3,1.9 6.8,3.8 10.3,5.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_38_all_both"
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_55_both"
                  v-if="chartBodygraph.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      55
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path625"
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path627"
                    d="m 576.8,685.8 -5.3,-8.5 c 3.3,-2.1 6.6,-4.3 9.6,-6.6 l 5.9,8.1 c -3.3,2.5 -6.7,4.8 -10.2,7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path629"
                    d="m 596.4,671.4 -6.5,-7.6 c 3,-2.6 5.8,-5.2 8.5,-7.9 l 7.1,7 a 123.38,123.38 0 0 1 -9.1,8.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path631"
                    d="m 613.9,653.6 -7.8,-6.3 a 100.41,100.41 0 0 0 6.8,-9.3 l 8.4,5.4 c -2.3,3.5 -4.8,6.9 -7.4,10.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path633"
                    d="m 627.2,632.9 -9,-4.4 a 71.85,71.85 0 0 0 4.2,-10.5 l 9.5,3.1 a 74.39,74.39 0 0 1 -4.7,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_55_all_both"
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_39_55_channel"
                  v-if="chartBodygraph.channels2['39-55']"
                  @click="openTooltipDialog(chartBodygraph.channels2['39-55'])"
                  @mouseover="searchAcPlanets(39, 55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_39_55_channel"
                >
                  <path
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_28_both"
                  v-if="chartBodygraph.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      28
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path636"
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path638"
                    d="m 330.6,682.9 a 108.07,108.07 0 0 1 -8.9,-6.4 l 6.2,-7.9 c 3,2.3 6.2,4.6 8.3,6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path640"
                    d="m 312.5,668.7 a 121.75,121.75 0 0 1 -8.8,-8.8 l 7.4,-6.8 c 2.5,2.8 5.3,5.5 8.2,8.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path642"
                    d="m 295.4,650 a 111.39,111.39 0 0 1 -7,-10.4 l 8.6,-5.1 a 93,93 0 0 0 6.4,9.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path644"
                    d="m 282.8,628.4 a 95.23,95.23 0 0 1 -4.2,-12 l 9.7,-2.6 a 89.08,89.08 0 0 0 3.7,10.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_28_all_both"
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_28_38_channel"
                  v-if="chartBodygraph.channels2['28-38']"
                  @click="openTooltipDialog(chartBodygraph.channels2['28-38'])"
                  @mouseover="searchAcPlanets(28, 38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_28_38_channel"
                >
                  <path
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_22_both"
                  v-if="chartBodygraph.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      22
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path647"
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path649"
                    d="m 624,557 c -0.4,-3.9 -0.8,-7.9 -1.2,-11.8 l 9.9,-1.1 c 0.5,4 0.9,8 1.2,12.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path651"
                    d="m 621.3,533.3 c -0.5,-3.9 -1.1,-7.9 -1.8,-11.7 l 9.9,-1.6 c 0.6,3.9 1.3,8 1.8,12 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path653"
                    d="m 617.3,510.1 c -0.7,-3.9 -1.5,-7.8 -2.4,-11.6 l 9.8,-2.1 c 0.9,3.9 1.7,7.9 2.4,11.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path655"
                    d="m 612.3,486.8 c -0.9,-3.8 -2,-7.7 -3,-11.5 l 9.6,-2.7 c 1.1,3.9 2.1,7.8 3.1,11.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path657"
                    d="m 605.8,463.8 c -1.2,-3.8 -2.4,-7.6 -3.7,-11.3 l 9.5,-3.2 c 1.3,3.8 2.6,7.7 3.8,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path659"
                    d="m 598.3,441.5 c -1.4,-3.7 -2.9,-7.4 -4.4,-11 l 9.2,-3.9 c 1.5,3.7 3.1,7.5 4.5,11.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path661"
                    d="m 589.5,420.2 c -1.7,-3.6 -3.4,-7.2 -5.2,-10.6 l 8.9,-4.5 c 1.8,3.6 3.6,7.3 5.3,11 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path663"
                    d="m 578.7,399.1 c -1.9,-3.5 -4,-6.9 -6,-10.1 l 8.5,-5.3 c 2.1,3.4 4.2,6.9 6.2,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_22_all_both"
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_57_both"
                  v-if="chartBodygraph.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      57
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path8089"
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path668"
                    d="m 241.06,526.89 -10,-1 c 0.4,-4 0.9,-8.1 1.4,-12.1 l 9.9,1.3 c -0.5,3.9 -0.9,7.8 -1.3,11.8 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path670"
                    d="m 244.06,503.29 -9.9,-1.5 q 0.9,-6 2.1,-12 l 9.8,1.8 c -0.7,3.8 -1.4,7.8 -2,11.7 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_57_all_both"
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_12_both"
                  v-if="chartBodygraph.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      12
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path685"
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path687"
                    d="m 564.4,376.5 c -2.2,-3.3 -3.1,-4.4 -5.4,-7.4 l 7.9,-6.1 c 2.4,3.2 4.9,6.5 7.2,9.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path689"
                    d="m 552.1,360.4 c -2.6,-3 -5.2,-5.9 -7.9,-8.6 l 7.2,-7 c 2.8,2.9 5.6,5.9 8.3,9.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path691"
                    d="m 535.8,343.6 c -2.9,-2.6 -5.9,-5.1 -8.9,-7.4 l 6.1,-7.9 c 3.2,2.5 6.4,5.1 9.5,7.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path693"
                    d="m 517.2,329.5 a 112.5,112.5 0 0 0 -10,-5.8 l 4.7,-8.9 c 3.6,1.9 7.2,4 10.7,6.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_12_all_both"
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_12_22_channel"
                  v-if="chartBodygraph.channels2['12-22']"
                  @click="openTooltipDialog(chartBodygraph.channels2['12-22'])"
                  @mouseover="searchAcPlanets(12, 22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_12_22_channel"
                >
                  <path
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_both"
                  v-if="chartBodygraph.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      20
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path8091"
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path672"
                    transform="translate(-31.134997,-17.409983)"
                    d="m 272.51562,483.40625 c -0.10912,0.44615 -0.22035,0.89121 -0.32812,1.33789 -0.44354,1.89227 -0.86225,3.78956 -1.30273,5.69531 h 10.23437 c 0.0517,-0.2218 0.12378,-0.43458 0.17578,-0.65625 0.30752,-1.33162 0.58872,-2.66648 0.90039,-3.98242 z"
                    style="fill:#ff001d"
                    inkscape:connector-curvature="0"
                  />
                  <path
                    id="path674"
                    d="m 254.06,456.89 -9.6,-2.7 c 1.1,-3.9 2.2,-7.8 3.4,-11.7 l 9.6,2.9 c -1.2,3.8 -2.4,7.7 -3.4,11.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path676"
                    d="m 260.96,434.19 -9.5,-3.2 c 1.3,-3.8 2.7,-7.7 4.1,-11.5 l 9.4,3.5 c -1.4,3.7 -2.7,7.5 -4,11.2 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path678"
                    d="m 269.26,411.99 -9.3,-3.8 c 1.5,-3.8 3.1,-7.5 4.8,-11.2 l 9.1,4.1 c -1.5,3.6 -3.1,7.2 -4.6,10.9 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path680"
                    d="m 278.96,390.39 -9,-4.4 c 1.8,-3.7 3.7,-7.3 5.5,-10.8 l 8.8,4.7 c -1.8,3.4 -3.6,6.9 -5.3,10.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path682"
                    d="m 290.06,369.59 -8.6,-5 c 2.1,-3.5 4.2,-7.1 6.4,-10.4 l 8.4,5.4 c -2.1,3.1 -4.2,6.5 -6.2,10 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path698"
                    d="m 303.46,348.79 -8.4,-5.1 c 2.4,-3.4 4.8,-6.7 7.3,-9.8 l 7.9,6.1 c -2.5,3 -4.5,5.6 -6.8,8.8 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path700"
                    d="m 316.96,331.49 -7.6,-6.5 c 2.7,-3.1 5.5,-6.2 8.3,-9.1 l 7.2,7 c -2.6,2.7 -5.3,5.6 -7.9,8.6 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path702"
                    d="m 333.56,314.49 -6.7,-7.4 c 3.1,-2.8 6.3,-5.5 9.4,-8 l 6.2,7.9 c -3,2.3 -6,4.9 -8.9,7.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path704"
                    d="m 351.96,300.29 -5.5,-8.3 c 3.5,-2.3 7.1,-4.5 10.7,-6.4 l 4.8,8.8 a 109.46,109.46 0 0 0 -10,5.9 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_20_all_both"
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_57_channel"
                  v-if="chartBodygraph.channels2['20-57']"
                  @click="openTooltipDialog(chartBodygraph.channels2['20-57'])"
                  @mouseover="searchAcPlanets(20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10_57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_19_both"
                  v-if="chartBodygraph.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      19
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path707"
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path709"
                    d="m 505.5,691.4 -2.3,-9.7 c 3.8,-0.9 7.6,-1.9 11.3,-3 l 2.9,9.6 c -3.9,1.1 -7.9,2.2 -11.9,3.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path711"
                    d="m 529.1,684.3 -3.5,-9.4 c 3.7,-1.4 7.3,-2.8 10.8,-4.4 l 4.1,9.1 c -3.7,1.7 -7.5,3.3 -11.4,4.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path713"
                    d="m 551.6,674.3 -4.7,-8.8 c 3.5,-1.8 6.9,-3.8 10.1,-5.8 l 5.3,8.5 c -3.4,2.1 -7,4.1 -10.7,6.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_19_all_both"
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_54_both"
                  v-if="chartBodygraph.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      54
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path716"
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path718"
                    d="m 420.3,694.5 c -4,-0.7 -8.1,-1.5 -12.1,-2.4 l 2.3,-9.7 c 3.8,0.9 7.6,1.6 11.5,2.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path720"
                    d="m 396.3,688.9 c -3.9,-1.2 -7.9,-2.5 -11.7,-3.9 l 3.4,-9.4 c 3.6,1.3 7.4,2.6 11.1,3.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path722"
                    d="m 373.2,680.5 c -3.8,-1.7 -7.5,-3.5 -11.1,-5.3 l 4.6,-8.9 q 5.1,2.7 10.5,5.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path724"
                    d="m 351.2,669.1 c -3.5,-2.2 -3.7,-2.2 -7,-4.6 l 5.5,-8.3 c 3.1,2.2 3.4,2.3 6.7,4.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_54_all_both"
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_49_both"
                  v-if="chartBodygraph.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      49
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path727"
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path729"
                    d="m 572.3,661.3 -6,-8 c 3.1,-2.3 6.2,-4.8 9,-7.3 l 6.6,7.5 c -3,2.6 -6.2,5.3 -9.6,7.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path731"
                    d="m 590.9,644.7 -7.3,-6.8 c 2.6,-2.9 5.1,-5.8 7.3,-8.8 l 8.1,5.9 a 122.42,122.42 0 0 1 -8.1,9.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path733"
                    d="m 606,624.5 -8.8,-4.8 a 77.23,77.23 0 0 0 4.7,-10.2 l 9.4,3.4 a 80.06,80.06 0 0 1 -5.3,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_49_all_both"
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_19_49_channel"
                  v-if="chartBodygraph.channels2['19-49']"
                  @click="openTooltipDialog(chartBodygraph.channels2['19-49'])"
                  @mouseover="searchAcPlanets(19, 49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_19_49_channel"
                >
                  <path
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_32_both"
                  v-if="chartBodygraph.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      32
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path736"
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path738"
                    d="m 331.4,654.7 a 123.89,123.89 0 0 1 -9,-8.7 l 7.3,-6.8 c 2.6,2.7 5.4,5.4 8.3,8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path740"
                    d="m 314.1,636.6 a 94.67,94.67 0 0 1 -6.9,-10.5 l 8.7,-5 a 78.08,78.08 0 0 0 6.3,9.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path742"
                    d="m 301.9,614.9 a 82.16,82.16 0 0 1 -3.7,-12.3 l 9.8,-2 a 62.11,62.11 0 0 0 3.2,10.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_32_all_both"
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_32_54_channel"
                  v-if="chartBodygraph.channels2['32-54']"
                  @click="openTooltipDialog(chartBodygraph.channels2['32-54'])"
                  @mouseover="searchAcPlanets(32, 54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_32_54_channel"
                >
                  <path
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_27_both"
                  v-if="chartBodygraph.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      27
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path745"
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path747"
                    d="m 419.4,625.4 c -4.1,-0.5 -8.1,-1 -12.1,-1.6 l 1.5,-9.9 c 3.8,0.6 7.8,1.1 11.7,1.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path749"
                    d="m 395.2,621.6 c -4.1,-0.8 -8.1,-1.7 -11.9,-2.6 l 2.4,-9.7 c 3.7,0.9 7.6,1.8 11.5,2.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_27_all_both"
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_59_both"
                  v-if="chartBodygraph.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      59
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path752"
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path754"
                    d="m 504.3,624.3 -1.4,-9.9 c 3.9,-0.5 7.8,-1.2 11.7,-1.9 l 1.8,9.8 c -4,0.8 -8,1.4 -12.1,2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path756"
                    d="m 523.5,621 -3.3,-9.8 c 3.9,-0.9 6.5,-1.4 7.4,-1.6 l 2.7,9.6 a 50.23,50.23 0 0 1 -6.8,1.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_59_all_both"
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_50_both"
                  v-if="chartBodygraph.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      50
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path759"
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path761"
                    d="m 371.5,615.8 c -4,-1.2 -7.9,-2.5 -11.7,-3.8 l 3.4,-9.4 c 3.6,1.3 7.3,2.5 11.1,3.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path763"
                    d="m 348.3,607.4 c -3.9,-1.7 -7.7,-3.6 -11.1,-5.5 l 4.8,-8.8 a 111.9,111.9 0 0 0 10.4,5.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_50_all_both"
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_27_50_channel"
                  v-if="chartBodygraph.channels2['27-50']"
                  @click="openTooltipDialog(chartBodygraph.channels2['27-50'])"
                  @mouseover="searchAcPlanets(27, 50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_27_50_channel"
                >
                  <path
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_06_both"
                  v-if="chartBodygraph.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      6
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path766"
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path768"
                    d="m 534.6,618.5 -3.3,-9.8 c 3.9,-0.9 6.2,-1.6 7.1,-1.7 l 2.7,9.6 a 59.28,59.28 0 0 1 -6.5,1.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path770"
                    d="m 551.8,613.2 -3.2,-9.5 c 3.8,-1.3 7.5,-2.7 10.9,-4.1 l 3.8,9.2 c -3.6,1.6 -7.5,3 -11.5,4.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path772"
                    d="m 574.6,603.8 -4.5,-8.9 a 104.84,104.84 0 0 0 10,-5.7 l 5.4,8.4 c -3.4,2.1 -7,4.2 -10.9,6.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_06_all_both"
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_06_59_channel"
                  v-if="chartBodygraph.channels2['6-59']"
                  @click="openTooltipDialog(chartBodygraph.channels2['6-59'])"
                  @mouseover="searchAcPlanets(6, 59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_06_59_channel"
                >
                  <path
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_37_both"
                  v-if="chartBodygraph.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      37
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path775"
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path777"
                    d="m 611.3,569.9 -9.3,-3.7 a 26,26 0 0 0 2,-9.8 h 10 a 36.11,36.11 0 0 1 -2.7,13.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path779"
                    d="m 603.8,553.2 a 28.9,28.9 0 0 0 -3,-9.6 l 9.2,-3.1 a 30.24,30.24 0 0 1 3.7,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_37_all_both"
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_40_both"
                  v-if="chartBodygraph.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      40
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path782"
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path784"
                    d="m 595.2,534.8 a 60,60 0 0 0 -8.2,-7.5 l 6.1,-7.9 a 73.3,73.3 0 0 1 9.5,8.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_40_all_both"
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_37_40_channel"
                  v-if="chartBodygraph.channels2['37-40']"
                  @click="openTooltipDialog(chartBodygraph.channels2['37-40'])"
                  @mouseover="searchAcPlanets(37, 40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_37_40_channel"
                >
                  <path
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                  <path
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_26_both"
                  v-if="chartBodygraph.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      26
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path787"
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path789"
                    d="m 511,498.7 c -3.8,-0.6 -7.7,-1.2 -11.7,-1.7 l 1.3,-9.9 c 4.1,0.5 8.1,1.1 12.1,1.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path791"
                    d="m 408.9,498 -3,-9.8 c 4,-0.7 6.9,-0.9 11,-1.4 l 1.2,9.9 c -3.9,0.5 -5.3,0.7 -9.2,1.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path793"
                    d="m 488.4,495.7 c -3.9,-0.3 -7.9,-0.6 -11.8,-0.8 l 0.5,-10 c 4.1,0.2 8.2,0.5 12.2,0.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path795"
                    d="m 428.8,495.6 -0.9,-10 c 4,-0.3 8.1,-0.6 12.2,-0.8 l 0.5,10 c -4,0.2 -8,0.5 -11.8,0.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path797"
                    d="m 464.3,494.6 c -3.9,-0.1 -7.9,-0.1 -11.8,0 l -0.2,-10 c 4,-0.1 8.1,-0.1 12.2,0 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_26_all_both"
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>

                <g
                  id="gate_26_44_channel"
                  v-if="chartBodygraph.channels2['26-44']"
                  @click="openTooltipDialog(chartBodygraph.channels2['26-44'])"
                  @mouseover="searchAcPlanets(26, 44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_26_44_channel"
                >
                  <path
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_34_both"
                  v-if="chartBodygraph.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      34
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                  transform="translate(-1)"
                >
                  <path
                    id="path800"
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                    transform="translate(-46.94,-28.51)"
                    sodipodi:nodetypes="cccccccsc"
                  />
                  <path
                    id="path802"
                    d="m 399.6,589.3 c -4.2,-0.6 -8.4,-1.4 -12.4,-2.2 l 2,-9.8 c 3.9,0.8 7.9,1.5 11.9,2.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path804"
                    d="m 374.8,584.2 c -4.1,-1.1 -8.2,-2.3 -12.2,-3.5 l 3,-9.5 c 3.8,1.2 7.7,2.3 11.6,3.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path806"
                    d="m 350.7,576.5 c -4,-1.6 -8,-3.3 -11.7,-5 l 4.3,-9 c 3.5,1.7 7.2,3.3 11,4.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path808"
                    d="m 327.7,565.5 c -3.71727,-2.14722 -7.322,-4.48362 -10.8,-7 l 6.11875,-8.69375 c 3.1,2.2 6.525,4.275 10.025,6.375 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path810"
                    d="m 260.17875,521.49625 c -3.17562,-3.0407 -6.11731,-6.31667 -8.8,-9.8 l 8.09375,-6.1875 c 2.34365,3.05879 5.01157,5.87134 7.79375,8.5375 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path812"
                    d="m 244.47875,500.0025 a 53,53 0 0 1 -3.9,-13.1 l 9.9,-1.5 a 40.64,40.64 0 0 0 3.1,10.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_34_all_both"
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                    sodipodi:nodetypes="cccccccsc"
                  />
                </g>
                <g
                  id="gate_20_34_channel"
                  v-if="chartBodygraph.channels2['20-34']"
                  @click="openTooltipDialog(chartBodygraph.channels2['20-34'])"
                  @mouseover="searchAcPlanets(20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_20_34_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_34_57_channel"
                  v-if="chartBodygraph.channels2['34-57']"
                  @click="openTooltipDialog(chartBodygraph.channels2['34-57'])"
                  @mouseover="searchAcPlanets(34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_34_57_channel"
                >
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                  <path
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_both"
                  v-if="chartBodygraph.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      10
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                  transform="translate(-0.75)"
                >
                  <path
                    id="path815"
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                    sodipodi:nodetypes="cccsccc"
                  />
                  <path
                    id="path817"
                    d="m 297.67414,501.64104 h -10.32812 c 0.63581,-4.03751 1.90449,-8.79462 3.85398,-12.94104 l 9.1,4.1 c -1.50098,3.30975 -2.16352,6.15589 -2.62586,8.84104 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path819"
                    d="m 306,483.1 -8,-6 a 80.13,80.13 0 0 1 8.8,-9.9 l 6.9,7.2 a 76.26,76.26 0 0 0 -7.7,8.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path821"
                    d="m 322.7,467 -5.9,-8.1 c 3.3,-2.4 7,-4.8 10.8,-7.1 l 5.1,8.6 a 108.22,108.22 0 0 0 -10,6.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path823"
                    d="m 343.2,454.9 -4.3,-9 c 3.7,-1.8 7.7,-3.5 11.7,-5.1 l 3.6,9.3 c -3.8,1.5 -7.5,3.1 -11,4.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path825"
                    d="m 365.6,446.1 -3.1,-9.5 c 3.9,-1.3 8,-2.4 12.2,-3.5 l 2.5,9.7 c -3.9,0.9 -7.8,2.1 -11.6,3.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path827"
                    d="m 389,440 -2,-9.8 c 4.1,-0.8 8.2,-1.6 12.4,-2.2 l 1.5,9.9 c -4,0.6 -8,1.3 -11.9,2.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_10_all_both"
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    sodipodi:nodetypes="cccsccc"
                  />
                </g>
                <g
                  id="gate_10_34_channel"
                  v-if="chartBodygraph.channels2['10-34']"
                  @click="openTooltipDialog(chartBodygraph.channels2['10-34'])"
                  @mouseover="searchAcPlanets(10, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10_34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_20_channel"
                  v-if="chartBodygraph.channels2['10-20']"
                  @click="openTooltipDialog(chartBodygraph.channels2['10-20'])"
                  @mouseover="searchAcPlanets(10, 20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10_20_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_57_channel"
                  v-if="chartBodygraph.channels2['10-57']"
                  @click="openTooltipDialog(chartBodygraph.channels2['10-57'])"
                  @mouseover="searchAcPlanets(10, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10_57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-37'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_20-34_34-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_20-57_34-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_34-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['20-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-57'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34'] &&
                      !chartBodygraph.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20'] &&
                      !chartBodygraph.channels2['10-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      !chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57'] &&
                      !chartBodygraph.channels2['10-20']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    chartBodygraph.channels2['10-20'] &&
                      chartBodygraph.channels2['10-34'] &&
                      chartBodygraph.channels2['10-57'] &&
                      chartBodygraph.channels2['20-34'] &&
                      chartBodygraph.channels2['20-57'] &&
                      chartBodygraph.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraph.channels2['10-20'],
                      chartBodygraph.channels2['10-34'],
                      chartBodygraph.channels2['10-57'],
                      chartBodygraph.channels2['20-34'],
                      chartBodygraph.channels2['20-57'],
                      chartBodygraph.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_51_both"
                  v-if="chartBodygraph.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      51
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path830"
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path832"
                    d="m 535.3,465.7 a 73.85,73.85 0 0 0 -7.4,-8.5 l 7,-7.1 a 96.21,96.21 0 0 1 8.4,9.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_51_all_both"
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_25_both"
                  v-if="chartBodygraph.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      25
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path835"
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path837"
                    d="m 519.4,449.8 a 85.29,85.29 0 0 0 -9.5,-6.1 l 4.8,-8.8 a 92.83,92.83 0 0 1 10.7,6.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_25_all_both"
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_25_51_channel"
                  v-if="chartBodygraph.channels2['25-51']"
                  @click="openTooltipDialog(chartBodygraph.channels2['25-51'])"
                  @mouseover="searchAcPlanets(37, 40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_25_51_channel"
                >
                  <path
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_21_both"
                  v-if="chartBodygraph.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      21
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path840"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path842"
                    d="m 554,456 c -0.4,-3.9 -0.9,-7.8 -1.5,-11.6 l 9.9,-1.6 c 0.6,4 1.2,8.1 1.6,12.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path844"
                    d="m 550.3,432.7 c -0.8,-3.8 -1.8,-7.6 -2.8,-11.4 l 9.6,-2.7 c 1.1,3.9 2.1,7.9 3,11.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path846"
                    d="m 543.9,410 c -1.3,-3.7 -2.7,-7.4 -4.2,-10.9 l 9.2,-3.9 c 1.6,3.7 3.1,7.6 4.4,11.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_21_all_both"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_45_both"
                  v-if="chartBodygraph.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      45
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path849"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#3e4448"
                  />
                  <path
                    id="path851"
                    d="m 534.8,388.6 c -1.8,-3.5 -3.7,-6.9 -5.7,-10.1 l 8.5,-5.2 c 2.1,3.5 4.2,7.1 6.1,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path853"
                    d="m 522.8,368.8 c -2.3,-3.1 -4.7,-6.2 -7.2,-9 l 7.5,-6.7 c 2.7,3 5.3,6.3 7.8,9.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="path855"
                    d="m 507.5,351.6 a 95.39,95.39 0 0 0 -8.9,-7.1 l 5.8,-8.2 a 116.91,116.91 0 0 1 9.8,7.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#ff001d"
                  />
                  <path
                    id="gate_45_all_both"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_21_45_channel"
                  v-if="chartBodygraph.channels2['21-45']"
                  @click="openTooltipDialog(chartBodygraph.channels2['21-45'])"
                  @mouseover="searchAcPlanets(21, 45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_21_45_channel"
                >
                  <path
                    id="gate_21_channel"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    id="gate_45_channel"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
              </g>
              <g
                id="Слой_3"
                style="display:inline"
                data-name="Слой 3"
                transform="translate(31.240005,17.409983)"
              >
                <g
                  id="gate_42_both"
                  v-if="chartBodygraph.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      42
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect940"
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect942"
                    x="383.35999"
                    y="623.19"
                    width="10"
                    height="3.0999999"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_42_all_both"
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_05_both"
                  v-if="chartBodygraph.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      5
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect945"
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect947"
                    x="383.35999"
                    y="506.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect949"
                    x="383.35999"
                    y="485.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_05_all_both"
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_15_both"
                  v-if="chartBodygraph.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      15
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect952"
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect954"
                    x="383.35999"
                    y="461.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect956"
                    x="383.35999"
                    y="437.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_15_all_both"
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_05_15_channel"
                  v-if="chartBodygraph.channels2['5-15']"
                  @click="openTooltipDialog(chartBodygraph.channels2['5-15'])"
                  @mouseover="searchAcPlanets(5, 15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_05_15_channel"
                >
                  <rect
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_07_both"
                  v-if="chartBodygraph.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      7
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect959"
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect961"
                    x="383.35999"
                    y="345.48999"
                    width="10"
                    height="6.4000001"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_07_all_both"
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_31_both"
                  v-if="chartBodygraph.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      31
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect964"
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect966"
                    x="383.35999"
                    y="328.59"
                    width="10"
                    height="6.4000001"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_31_all_both"
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_07_31_channel"
                  v-if="chartBodygraph.channels2['7-31']"
                  @click="openTooltipDialog(chartBodygraph.channels2['7-31'])"
                  @mouseover="searchAcPlanets(7, 31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_07_31_channel"
                >
                  <rect
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_62_both"
                  v-if="chartBodygraph.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      62
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect969"
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect971"
                    x="383.35999"
                    y="220.59"
                    width="10"
                    height="6"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_62_all_both"
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_17_both"
                  v-if="chartBodygraph.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      17
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect974"
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect976"
                    x="383.35999"
                    y="190.89"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect978"
                    x="383.35999"
                    y="166.89"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_17_all_both"
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_17_62_channel"
                  v-if="chartBodygraph.channels2['17-62']"
                  @click="openTooltipDialog(chartBodygraph.channels2['17-62'])"
                  @mouseover="searchAcPlanets(17, 62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_17_62_channel"
                >
                  <rect
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_64_both"
                  v-if="chartBodygraph.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      64
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect981"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect983"
                    x="383.35999"
                    y="92.489998"
                    width="10"
                    height="8.8000002"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect985"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="8"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_64_all_both"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_47_64_channel"
                  v-if="chartBodygraph.channels2['47-64']"
                  @click="openTooltipDialog(chartBodygraph.channels2['47-64'])"
                  @mouseover="searchAcPlanets(47, 64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_47_64_channel"
                >
                  <rect
                    id="gate_64_47_rect"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_47_64_rect"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_60_both"
                  v-if="chartBodygraph.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      60
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect988"
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect990"
                    x="403.85999"
                    y="630.09003"
                    width="10"
                    height="3.0999999"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_60_all_both"
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_03_both"
                  v-if="chartBodygraph.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      3
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect993"
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect995"
                    x="403.85999"
                    y="622.78998"
                    width="10"
                    height="3.0999999"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_03_all_both"
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_03_60_channel"
                  v-if="chartBodygraph.channels2['3-60']"
                  @click="openTooltipDialog(chartBodygraph.channels2['3-60'])"
                  @mouseover="searchAcPlanets(3, 60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_3_60_channel"
                >
                  <rect
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_14_both"
                  v-if="chartBodygraph.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      14
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect998"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1000"
                    x="403.85999"
                    y="506.79001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect1002"
                    x="403.85999"
                    y="485.48999"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_14_all_both"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_02_both"
                  v-if="chartBodygraph.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      2
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1005"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1007"
                    x="403.76001"
                    y="461.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_02_all_both"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_02_14_channel"
                  v-if="chartBodygraph.channels2['2-14']"
                  @click="openTooltipDialog(chartBodygraph.channels2['2-14'])"
                  @mouseover="searchAcPlanets(2, 14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_02_14_channel"
                >
                  <rect
                    id="gate_02_14"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_14_02"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_01_both"
                  v-if="chartBodygraph.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      1
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1012"
                    x="403.76001"
                    y="342.29001"
                    width="10"
                    height="4.6999998"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_01_all_both"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_08_both"
                  v-if="chartBodygraph.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      8
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1017"
                    x="403.85999"
                    y="331.79001"
                    width="10"
                    height="4.3000002"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_08_all_both"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_01_08_channel"
                  v-if="chartBodygraph.channels2['1-8']"
                  @click="openTooltipDialog(chartBodygraph.channels2['1-8'])"
                  @mouseover="searchAcPlanets(1, 8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_01_08_channel"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_23_both"
                  v-if="chartBodygraph.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      23
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1020"
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1022"
                    x="403.76001"
                    y="220.28999"
                    width="10"
                    height="5.5"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_23_all_both"
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_43_both"
                  v-if="chartBodygraph.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      43
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1025"
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1027"
                    x="403.76001"
                    y="197.89"
                    width="10"
                    height="14"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_43_all_both"
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_23_43_channel"
                  v-if="chartBodygraph.channels2['23-43']"
                  @click="openTooltipDialog(chartBodygraph.channels2['23-43'])"
                  @mouseover="searchAcPlanets(23, 43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_27_50_channel"
                >
                  <rect
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_61_both"
                  v-if="chartBodygraph.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      61
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1030"
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1032"
                    x="403.76001"
                    y="94.889999"
                    width="10"
                    height="6.5"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_61_all_both"
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_52_both"
                  v-if="chartBodygraph.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      52
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1035"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1037"
                    x="424.35999"
                    y="630.39001"
                    width="10"
                    height="3"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_52_all_both"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_09_both"
                  v-if="chartBodygraph.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      9
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1040"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1042"
                    x="424.35999"
                    y="622.89001"
                    width="10"
                    height="3"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_09_all_both"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_09_52_channel"
                  v-if="chartBodygraph.channels2['9-52']"
                  @click="openTooltipDialog(chartBodygraph.channels2['9-52'])"
                  @mouseover="searchAcPlanets(9, 52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_09_52_channel"
                >
                  <rect
                    id="gate_09_52"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_52_09"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_29_both"
                  v-if="chartBodygraph.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      29
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1045"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1047"
                    x="424.35999"
                    y="506.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect1049"
                    x="424.35999"
                    y="485.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_29_all_both"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_46_both"
                  v-if="chartBodygraph.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      46
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1052"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1054"
                    x="424.35999"
                    y="461.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect1056"
                    x="424.35999"
                    y="437.89001"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_46_all_both"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_29_46_channel"
                  v-if="chartBodygraph.channels2['29-46']"
                  @click="openTooltipDialog(chartBodygraph.channels2['29-46'])"
                  @mouseover="searchAcPlanets(29, 46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_29_46_channel"
                >
                  <rect
                    id="gate_46_29"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_29_46"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_13_both"
                  v-if="chartBodygraph.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      13
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1059"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1061"
                    x="424.35999"
                    y="344.59"
                    width="10"
                    height="7.5999999"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_13_all_both"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_33_both"
                  v-if="chartBodygraph.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      33
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1064"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1066"
                    x="424.35999"
                    y="328.79001"
                    width="10"
                    height="6.0999999"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_33_all_both"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_13_33_channel"
                  v-if="chartBodygraph.channels2['13-33']"
                  @click="openTooltipDialog(chartBodygraph.channels2['13-33'])"
                  @mouseover="searchAcPlanets(13, 33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_13_33_channel"
                >
                  <rect
                    id="gate_13_33"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_33_13"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_56_both"
                  v-if="chartBodygraph.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      56
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1069"
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1071"
                    x="424.35999"
                    y="220.28999"
                    width="10"
                    height="5.4000001"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_56_all_both"
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_11_both"
                  v-if="chartBodygraph.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      11
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                  transform="translate(-0.75)"
                >
                  <rect
                    id="rect1074"
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1076"
                    x="424.35999"
                    y="193.89"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="rect1078"
                    x="424.35999"
                    y="169.89"
                    width="10"
                    height="12"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_11_all_both"
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_11_56_channel"
                  v-if="chartBodygraph.channels2['11-56']"
                  @click="openTooltipDialog(chartBodygraph.channels2['11-56'])"
                  @mouseover="searchAcPlanets(11, 56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_11_56_channel"
                >
                  <rect
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_04_both"
                  v-if="chartBodygraph.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      4
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1081"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1083"
                    x="424.35999"
                    y="107.89"
                    width="10"
                    height="3.3"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_04_all_both"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_63_both"
                  v-if="chartBodygraph.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      63
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1086"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1088"
                    x="424.26001"
                    y="94.190002"
                    width="10"
                    height="6.4000001"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_63_all_both"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_04_63_channel"
                  v-if="chartBodygraph.channels2['4-63']"
                  @click="openTooltipDialog(chartBodygraph.channels2['4-63'])"
                  @mouseover="searchAcPlanets(4, 63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_04_63_channel"
                >
                  <rect
                    id="gate_63_04_channel"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_04_64_channel"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_53_both"
                  v-if="chartBodygraph.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      53
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1091"
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1093"
                    x="383.45999"
                    y="630.59003"
                    width="10"
                    height="3.0999999"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_53_all_both"
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_42_53_channel"
                  v-if="chartBodygraph.channels2['42-53']"
                  @click="openTooltipDialog(chartBodygraph.channels2['42-53'])"
                  @mouseover="searchAcPlanets(42, 53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_42_53_channel"
                >
                  <rect
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_47_both"
                  v-if="chartBodygraph.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      47
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1096"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1098"
                    x="383.16"
                    y="108.29"
                    width="10"
                    height="3.8"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_47_all_both"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_24_both"
                  v-if="chartBodygraph.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.gatesActivatedAtPersonalityAndDesign,
                      24
                    ),
                    opacity4: isDesignToggle || isPersonToggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1101"
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:#3e4448"
                  />
                  <rect
                    id="rect1103"
                    x="403.95999"
                    y="108.09"
                    width="10"
                    height="3.8"
                    style="fill:#ff001d"
                  />
                  <rect
                    id="gate_24_all_both"
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_24_61_channel"
                  v-if="chartBodygraph.channels2['24-61']"
                  @click="openTooltipDialog(chartBodygraph.channels2['24-61'])"
                  @mouseover="searchAcPlanets(24, 61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                  style="display:inline"
                  data-name="gate_24_61_channel"
                >
                  <rect
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
              </g>
            </g>
            <g
              id="layer6-2"
              v-if="isCircuitGates"
              inkscape:groupmode="layer"
              inkscape:label="Circuit Group's Channels"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_16"
                style="display:inline"
                data-name="Слой 16"
                transform="translate(31.134997,17.409983)"
              >
                <g
                  id="gate_02_14_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '2-14'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['2-14'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '2-14'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_02_14_circuit_channel"
                >
                  <rect
                    id="gate_02_14"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_14_02"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_03_60_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '3-60'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['3-60'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '3-60'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_3_60_circuit_channel"
                >
                  <rect
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_06_59_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '6-59'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['6-59'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '6-59'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_06_59_circuit_channel"
                >
                  <path
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_20_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '10-20'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['10-20'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '10-20'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_10_20_circuit_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_57_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '10-57'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['10-57'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '10-57'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_10_57_circuit_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_11_56_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '11-56'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['11-56'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '11-56'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_11_56_circuit_channel"
                >
                  <rect
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_12_22_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '12-22'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['12-22'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '12-22'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_12_22_circuit_channel"
                >
                  <path
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_16_48_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '16-48'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['16-48'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '16-48'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_16_48_circuit_channel"
                >
                  <path
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_18_58_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '18-58'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['18-58'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '18-58'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_18_58_circuit_channel"
                >
                  <path
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_19_49_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '19-49'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['19-49'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '19-49'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_19_49_circuit_channel"
                >
                  <path
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_34_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '20-34'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['20-34'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '20-34'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_20_34_circuit_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20_57_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '20-57'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['20-57'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '20-57'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_20_57_circuit_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_21_45_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '21-45'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['21-45'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '21-45'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_21_45_circuit_channel"
                >
                  <path
                    id="gate_21_channel"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    id="gate_45_channel"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_25_51_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '25-51'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['25-51'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '25-51'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_25_51_circuit_channel"
                >
                  <path
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_26_44_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '26-44'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['26-44'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '26-44'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_26_44_circuit_channel"
                >
                  <path
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_27_50_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '27-50'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['27-50'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '27-50'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_27_50_circuit_channel"
                >
                  <path
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_28_38_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '28-38'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['28-38'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '28-38'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_28_38_circuit_channel"
                >
                  <path
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_30_41_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '30-41'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['30-41'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '30-41'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_30_41_circuit_channel"
                >
                  <path
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_32_54_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '32-54'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['32-54'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '32-54'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_32_54_circuit_channel"
                >
                  <path
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_34_57_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '34-57'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['34-57'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '34-57'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_34_57_circuit_channel"
                >
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                  <path
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_35_36_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '35-36'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['35-36'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '35-36'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_35_36_circuit_channel"
                >
                  <path
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_37_40_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '37-40'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['37-40'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '37-40'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_37_40_circuit_channel"
                >
                  <path
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                  <path
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_39_55_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '39-55'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['39-55'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '39-55'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_39_55_circuit_channel"
                >
                  <path
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
              </g>
              <g
                id="Слой_3"
                style="display:inline"
                data-name="Слой 3"
                transform="translate(31.240005,17.409983)"
              >
                <g
                  id="gate_01_08_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '1-8'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['1-8'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '1-8'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_01_08_circuit_channel"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_04_63_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '4-63'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['4-63'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '4-63'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_04_63_circuit_channel"
                >
                  <rect
                    id="gate_63_04_channel"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_04_64_channel"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_05_15_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '5-15'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['5-15'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '5-15'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_05_15_circuit_channel"
                >
                  <rect
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_07_31_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '7-31'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['7-31'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '7-31'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_07_31_circuit_channel"
                >
                  <rect
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_09_52_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '9-52'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['9-52'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '9-52'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_09_52_circuit_channel"
                >
                  <rect
                    id="gate_09_52"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_52_09"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_13_33_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '13-33'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['13-33'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '13-33'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_13_33_circuit_channel"
                >
                  <rect
                    id="gate_13_33"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_33_13"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_17_62_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '17-62'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['17-62'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '17-62'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_17_62_circuit_channel"
                >
                  <rect
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_23_43_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '23-43'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['23-43'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '23-43'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_23_43_circuit_channel"
                >
                  <rect
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_24_61_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '24-61'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['24-61'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '24-61'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_24_61_circuit_channel"
                >
                  <rect
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_29_46_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '29-46'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['29-46'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '29-46'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_29_46_circuit_channel"
                >
                  <rect
                    id="gate_46_29"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_29_46"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_42_53_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '42-53'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['42-53'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '42-53'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_42_53_circuit_channel"
                >
                  <rect
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_47_64_circuit_channel"
                  v-if="
                    isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '47-64'
                    )
                  "
                  @click="openTooltipDialog(chartBodygraph.channels2['47-64'])"
                  v-bind:class="{
                    opacity1: isDefinedObj(
                      chartBodygraph.circuitGroups[tabAddInfo].channels,
                      '47-64'
                    )
                  }"
                  style="display:inline"
                  data-name="gate_47_64_circuit_channel"
                >
                  <rect
                    id="gate_64_47_rect"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_47_64_rect"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
              </g>
            </g>
            <g
              id="layer3"
              inkscape:groupmode="layer"
              inkscape:label="Centers"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="g1824"
                transform="translate(30.889992,17.409983)"
                data-name="Слой 11"
                style="display:inline;fill:#0c0b00;fill-opacity:0.8125;filter:url(#filter1874)"
              >
                <path
                  id="path1806"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1808"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1810"
                  d="m 523.16,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.3 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-41 a 6.27,6.27 0 0 1 9.2,2.2 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1812"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                  inkscape:connector-curvature="0"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                />
                <path
                  id="path1814"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                  inkscape:connector-curvature="0"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                />
                <path
                  id="path1816"
                  d="m 433.36,518.19033 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.19,8.19 0 0 0 -7.2,-4.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1818"
                  d="m 462.86,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 8,8 0 0 1 0,11.3 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <circle
                  id="circle1820"
                  cx="409.06"
                  cy="279.39001"
                  r="51.5"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1822"
                  d="M 445.89333,722.34736 H 372.22666 A 8.6666664,8.2626054 0 0 1 363.56,714.08475 v -70.23214 a 8.6666664,8.2626054 0 0 1 8.66666,-8.26261 h 73.66667 a 8.6666664,8.2626054 0 0 1 8.66666,8.26261 v 70.23214 a 8.6666664,8.2626054 0 0 1 -8.66666,8.26261 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125;stroke-width:0.99999994"
                />
              </g>
              <g
                id="Слой_11"
                style="display:inline"
                data-name="Слой 11"
                transform="translate(30.89,17.409983)"
              >
                <path
                  id="path357"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_9"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_9
                    )
                  "
                  @mouseover="isGatesActiveForCenter(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                />
                <path
                  id="path359"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_8"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_8
                    )
                  "
                  @mouseover="isGatesActiveForCenter(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                />
                <path
                  id="path361"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_5"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_5
                    )
                  "
                  @mouseover="isGatesActiveForCenter(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 523.16,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.3 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-41 a 6.27,6.27 0 0 1 9.2,2.2 z"
                />
                <path
                  id="path363"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_4"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_4
                    )
                  "
                  @mouseover="isGatesActiveForCenter(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path365"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_2"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_2
                    )
                  "
                  @mouseover="isGatesActiveForCenter(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path369"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_3"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_3
                    )
                  "
                  @mouseover="isGatesActiveForCenter(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 433.36,518.19033 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.19,8.19 0 0 0 -7.2,-4.1 z"
                />
                <path
                  id="path371"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_6"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_6
                    )
                  "
                  @mouseover="isGatesActiveForCenter(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 462.86,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 8,8 0 0 1 0,11.3 z"
                />
                <circle
                  id="circle373"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_7"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_7
                    )
                  "
                  @mouseover="isGatesActiveForCenter(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  r="51.5"
                  cy="279.39001"
                  cx="409.06"
                />
                <path
                  id="path375"
                  v-if="chartBodygraph.centersUndefinedExt.center_undefined_1"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersUndefinedExt.center_undefined_1
                    )
                  "
                  @mouseover="isGatesActiveForCenter(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="M 445.89333,722.34736 H 372.22666 A 8.6666664,8.2626054 0 0 1 363.56,714.08475 v -70.23214 a 8.6666664,8.2626054 0 0 1 8.66666,-8.26261 h 73.66667 a 8.6666664,8.2626054 0 0 1 8.66666,8.26261 v 70.23214 a 8.6666664,8.2626054 0 0 1 -8.66666,8.26261 z"
                />
              </g>
              <g
                id="Слой_4-2"
                v-if="
                  !isHarmoniousGates &&
                    !isLoveGates &&
                    !isFearsGates &&
                    !isMelancholyGates &&
                    !isDreamGates &&
                    !isLinesGates &&
                    !isCircuitGates
                "
                style="display:inline"
                data-name="Слой 4"
                transform="translate(30.89,17.409983)"
              >
                <path
                  id="center_head"
                  ref="center_9"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_9"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_9
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      9
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_ajna"
                  ref="center_8"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_8"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_8
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      8
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#61ad42;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_ego"
                  ref="center_5"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_5"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_5
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      5
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 523.26,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.4 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-40.9 a 6.18,6.18 0 0 1 9.2,2.2 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_splenic"
                  ref="center_4"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_4"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_4
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      4
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#c45808;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                />
                <path
                  id="center_emo"
                  ref="center_2"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_2"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_2
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      2
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#c45808;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                />
                <path
                  id="center_sacral"
                  ref="center_3"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_3"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_3
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      3
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 433.36,518.19 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.65,8.65 0 0 0 -7.2,-4.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_self"
                  ref="center_6"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_6"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_6
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      6
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 462.76,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 7.85,7.85 0 0 1 0,11.3 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;cursor:pointer;display:none"
                />
                <circle
                  id="center_throat"
                  ref="center_7"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_7"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_7
                    )
                  "
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      7
                    )
                  }"
                  @mouseover="isGatesActiveForCenter(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  cx="409.06"
                  cy="279.39001"
                  r="51.5"
                  style="fill:#49c4be;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_root"
                  ref="center_1"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.centersDefined,
                      1
                    )
                  }"
                  v-if="chartBodygraph.centersDefinedExt.center_defined_1"
                  @click="
                    openTooltipDialog(
                      chartBodygraph.centersDefinedExt.center_defined_1
                    )
                  "
                  @mouseover="isGatesActiveForCenter(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 446.56,722.34736 h -75 a 8,7.6270206 0 0 1 -8,-7.62702 v -71.50332 a 8,7.6270206 0 0 1 8,-7.62702 h 75 a 8,7.6270206 0 0 1 8,7.62702 v 71.50332 a 8,7.6270206 0 0 1 -8,7.62702 z"
                  inkscape:connector-curvature="0"
                  style="fill:#c45808;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                />
              </g>
              <g
                id="Слой_11_dream"
                v-if="
                  isHarmoniousGates ||
                    isLoveGates ||
                    isFearsGates ||
                    isMelancholyGates ||
                    isDreamGates ||
                    isLinesGates ||
                    isCircuitGates
                "
                style="display:inline"
                data-name="Слой 11 dream"
                transform="translate(30.89,17.409983)"
              >
                <path
                  id="path357_dream"
                  style="fill:#ffffff"
                  inkscape:connector-curvature="0"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                />
                <path
                  id="path359_dream"
                  style="fill:#ffffff"
                  inkscape:connector-curvature="0"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                />
                <path
                  id="path361_dream"
                  style="fill:#ffffff"
                  inkscape:connector-curvature="0"
                  d="m 523.16,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.3 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-41 a 6.27,6.27 0 0 1 9.2,2.2 z"
                />
                <path
                  id="path363_dream"
                  style="fill:#ffffff"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path365_dream"
                  style="fill:#ffffff"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path369_dream"
                  style="fill:#ffffff"
                  inkscape:connector-curvature="0"
                  d="m 433.36,518.19033 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.19,8.19 0 0 0 -7.2,-4.1 z"
                />
                <path
                  id="path371_dream"
                  style="fill:#ffffff"
                  inkscape:connector-curvature="0"
                  d="m 462.86,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 8,8 0 0 1 0,11.3 z"
                />
                <circle
                  id="circle373_dream"
                  style="fill:#ffffff"
                  r="51.5"
                  cy="279.39001"
                  cx="409.06"
                />
                <path
                  id="path375_dream"
                  style="fill:#ffffff;stroke-width:0.99999994"
                  inkscape:connector-curvature="0"
                  d="M 445.89333,722.34736 H 372.22666 A 8.6666664,8.2626054 0 0 1 363.56,714.08475 v -70.23214 a 8.6666664,8.2626054 0 0 1 8.66666,-8.26261 h 73.66667 a 8.6666664,8.2626054 0 0 1 8.66666,8.26261 v 70.23214 a 8.6666664,8.2626054 0 0 1 -8.66666,8.26261 z"
                />
              </g>
              <g
                id="Слой_4-2_dream"
                v-if="
                  !isHarmoniousGates &&
                    !isLoveGates &&
                    !isFearsGates &&
                    !isMelancholyGates &&
                    !isLinesGates &&
                    !isCircuitGates &&
                    isDreamGates
                "
                style="display:inline"
                data-name="Слой 4"
                transform="translate(30.89,17.409983)"
              >
                <path
                  id="center_head_dream"
                  ref="center_9"
                  v-if="chartBodygraph.dreamCenters[9]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      9
                    )
                  }"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;display:none"
                />
                <path
                  id="center_ajna_dream"
                  ref="center_8"
                  v-if="chartBodygraph.dreamCenters[8]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      8
                    )
                  }"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#61ad42;opacity:0;display:none"
                />
                <path
                  id="center_ego_dream"
                  ref="center_5"
                  v-if="chartBodygraph.dreamCenters[5]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      5
                    )
                  }"
                  d="m 523.26,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.4 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-40.9 a 6.18,6.18 0 0 1 9.2,2.2 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;display:none"
                />
                <path
                  id="center_splenic_dream"
                  ref="center_4"
                  v-if="chartBodygraph.dreamCenters[4]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      4
                    )
                  }"
                  style="fill:#c45808;opacity:0;display:none"
                  inkscape:connector-curvature="0"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                />
                <path
                  id="center_emo_dream"
                  ref="center_2"
                  v-if="chartBodygraph.dreamCenters[2]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      2
                    )
                  }"
                  style="fill:#c45808;opacity:0;display:none"
                  inkscape:connector-curvature="0"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                />
                <path
                  id="center_sacral_dream"
                  ref="center_3"
                  v-if="chartBodygraph.dreamCenters[3]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      3
                    )
                  }"
                  d="m 433.36,518.19 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.65,8.65 0 0 0 -7.2,-4.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;display:none"
                />
                <path
                  id="center_self_dream"
                  ref="center_6"
                  v-if="chartBodygraph.dreamCenters[6]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      6
                    )
                  }"
                  d="m 462.76,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 7.85,7.85 0 0 1 0,11.3 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;display:none"
                />
                <circle
                  id="center_throat_dream"
                  ref="center_7"
                  v-if="chartBodygraph.dreamCenters[7]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      7
                    )
                  }"
                  cx="409.06"
                  cy="279.39001"
                  r="51.5"
                  style="fill:#49c4be;opacity:0;display:none"
                />
                <path
                  id="center_root_dream"
                  ref="center_1"
                  v-if="chartBodygraph.dreamCenters[1]"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraph.dreamCenterIds,
                      1
                    )
                  }"
                  d="m 446.56,722.34736 h -75 a 8,7.6270206 0 0 1 -8,-7.62702 v -71.50332 a 8,7.6270206 0 0 1 8,-7.62702 h 75 a 8,7.6270206 0 0 1 8,7.62702 v 71.50332 a 8,7.6270206 0 0 1 -8,7.62702 z"
                  inkscape:connector-curvature="0"
                  style="fill:#c45808;stroke-width:0.99999994;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer4"
              inkscape:groupmode="layer"
              inkscape:label="Gate Numbers"
              style="display:inline"
            >
              <g id="g2173">
                <text
                  id="text1131"
                  y="110"
                  x="411"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  64
                </text>
                <text
                  id="text1133"
                  y="110"
                  x="454"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  63
                </text>
                <text
                  id="text1135"
                  y="266.33548"
                  x="412.12885"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  62
                </text>
                <text
                  id="text1137"
                  y="110"
                  x="432"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  61
                </text>
                <text
                  id="text1139"
                  y="668.43549"
                  x="434.2088"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  60
                </text>
                <text
                  id="text1141"
                  y="610.1355"
                  x="468.95541"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  59
                </text>
                <text
                  id="text1143"
                  y="728.73547"
                  x="398.26874"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  58
                </text>
                <text
                  id="text1145"
                  y="553.86212"
                  x="258.02878"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  57
                </text>
                <text
                  id="text1147"
                  y="265.3288"
                  x="452.94208"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  56
                </text>
                <text
                  id="text1149"
                  y="605.9621"
                  x="606.26886"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  55
                </text>
                <text
                  id="text1151"
                  y="685.36212"
                  x="398.56873"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  54
                </text>
                <text
                  id="text1153"
                  y="668.83551"
                  x="412.35553"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  53
                </text>
                <text
                  id="text1155"
                  y="669.03546"
                  x="455.27548"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  52
                </text>
                <text
                  id="text1157"
                  y="471.66211"
                  x="518.90259"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  51
                </text>
                <text
                  id="text1159"
                  y="580.33551"
                  x="302.95541"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  50
                </text>
                <text
                  id="text1161"
                  y="594.84125"
                  x="586.93549"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  49
                </text>
                <text
                  id="text1163"
                  y="543.33551"
                  x="239.14885"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  48
                </text>
                <text
                  id="text1165"
                  y="145.53548"
                  x="411.70886"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  47
                </text>
                <text
                  id="text1167"
                  y="447.9288"
                  x="453.22217"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  46
                </text>
                <text
                  id="text1169"
                  y="324.66211"
                  x="466.64893"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  45
                </text>
                <text
                  id="text1171"
                  y="566.93549"
                  x="280.34879"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  44
                </text>
                <text
                  id="text1173"
                  y="212.53548"
                  x="432.7356"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  43
                </text>
                <text
                  id="text1175"
                  y="631.20886"
                  x="411.45557"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  42
                </text>
                <text
                  id="text1177"
                  y="729.33551"
                  x="468.4827"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  41
                </text>
                <text
                  id="text1179"
                  y="506.53549"
                  x="566.03552"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  40
                </text>
                <text
                  id="text1181"
                  y="706.6355"
                  x="467.55542"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  39
                </text>
                <text
                  id="text1183"
                  y="705.93549"
                  x="398.56876"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  38
                </text>
                <text
                  id="text1185"
                  y="568.83551"
                  x="582.8288"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  37
                </text>
                <text
                  id="text1187"
                  y="544.83551"
                  x="624.54205"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  36
                </text>
                <text
                  id="text1189"
                  y="285.73547"
                  x="471.46884"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  35
                </text>
                <text
                  id="text1191"
                  y="577.83551"
                  x="393.86871"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  34
                </text>
                <text
                  id="text1193"
                  y="337.73547"
                  x="452.15552"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  33
                </text>
                <text
                  id="text1195"
                  y="593.93549"
                  x="280.37546"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  32
                </text>
                <text
                  id="text1197"
                  y="337.53549"
                  x="413.90259"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  31
                </text>
                <text
                  id="text1199"
                  y="617.83551"
                  x="627.25543"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  30
                </text>
                <text
                  id="text1201"
                  y="550.64124"
                  x="453.93539"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  29
                </text>
                <text
                  id="text1203"
                  y="605.6355"
                  x="258.54874"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  28
                </text>
                <text
                  id="text1205"
                  y="610.70886"
                  x="399.00879"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  27
                </text>
                <text
                  id="text1207"
                  y="489.23547"
                  x="494.32205"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  26
                </text>
                <text
                  id="text1209"
                  y="423.73547"
                  x="476.54883"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  25
                </text>
                <text
                  id="text1211"
                  y="145.40884"
                  x="433.34869"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  24
                </text>
                <text
                  id="text1213"
                  y="260.43549"
                  x="432.7355"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  23
                </text>
                <text
                  id="text1215"
                  y="556.10883"
                  x="604.35547"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  22
                </text>
                <text
                  id="text1217"
                  y="456.20886"
                  x="539.9826"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  21
                </text>
                <text
                  id="text1219"
                  y="308.23547"
                  x="392.43539"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  20
                </text>
                <text
                  id="text1221"
                  y="686.14124"
                  x="467.24188"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  19
                </text>
                <text
                  id="text1223"
                  y="616.93549"
                  x="238.85522"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  18
                </text>
                <text
                  id="text1225"
                  y="181.43549"
                  x="412.61523"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  17
                </text>
                <text
                  id="text1227"
                  y="284.8288"
                  x="394.52853"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  16
                </text>
                <text
                  id="text1229"
                  y="448.16211"
                  x="412.0553"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  15
                </text>
                <text
                  id="text1231"
                  y="550.33551"
                  x="432.15518"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  14
                </text>
                <text
                  id="text1233"
                  y="397.03549"
                  x="451.54196"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  13
                </text>
                <text
                  id="text1235"
                  y="307.10886"
                  x="473.56192"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  12
                </text>
                <text
                  id="text1237"
                  y="180.33548"
                  x="454.08905"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  11
                </text>
                <text
                  id="text1239"
                  y="423.43549"
                  x="388.64188"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  10
                </text>
                <text
                  id="text1241"
                  y="631.64124"
                  x="456.59497"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  9
                </text>
                <text
                  id="text1243"
                  y="342.93549"
                  x="436.52829"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  8
                </text>
                <text
                  id="text1245"
                  y="397.93549"
                  x="416.95493"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  7
                </text>
                <text
                  id="text1247"
                  y="580.32886"
                  x="565.72162"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  6
                </text>
                <text
                  id="text1249"
                  y="550.56213"
                  x="415.59503"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  5
                </text>
                <text
                  id="text1251"
                  y="145.53548"
                  x="457.47498"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  4
                </text>
                <text
                  id="text1253"
                  y="631.6355"
                  x="435.78171"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  3
                </text>
                <text
                  id="text1255"
                  y="467.60886"
                  x="436.48166"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  2
                </text>
                <text
                  id="text1257"
                  y="378.53549"
                  x="437.01526"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5"
              v-if="
                !isHarmoniousGates &&
                  !isLoveGates &&
                  !isFearsGates &&
                  !isMelancholyGates &&
                  !isDreamGates &&
                  !isLinesGates &&
                  !isCircuitGates
              "
              inkscape:groupmode="layer"
              inkscape:label="Gates Active"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_on"
                v-if="chartBodygraph.gatesExt[64]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 64),
                  opacity4: isDefinedTogglePointGate(64)
                }"
                @mouseover="searchAcPlanets(64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 64)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      64
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_64_47_channel_on"
                v-if="chartBodygraph.channels2['47-64']"
                @click="openTooltipDialog(chartBodygraph.channels2['47-64'])"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="87.889999"
                  cx="387.45999"
                />
              </g>
              <g
                id="gate_63_on"
                v-if="chartBodygraph.gatesExt[63]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 63),
                  opacity4: isDefinedTogglePointGate(63)
                }"
                @mouseover="searchAcPlanets(63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 63)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      63
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_63_04_channel_on"
                v-if="chartBodygraph.channels2['4-63']"
                @click="openTooltipDialog(chartBodygraph.channels2['4-63'])"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="88.389999"
                  cx="430.16"
                />
              </g>
              <g
                id="gate_62_on"
                v-if="chartBodygraph.gatesExt[62]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 62),
                  opacity4: isDefinedTogglePointGate(62)
                }"
                @mouseover="searchAcPlanets(62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 62)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      62
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_62_17_channel_on"
                v-if="chartBodygraph.channels2['17-62']"
                @click="openTooltipDialog(chartBodygraph.channels2['17-62'])"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
              </g>
              <g
                id="gate_61_on"
                v-if="chartBodygraph.gatesExt[61]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 61),
                  opacity4: isDefinedTogglePointGate(61)
                }"
                @mouseover="searchAcPlanets(61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 61)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      61
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_61_24_channel_on"
                v-if="chartBodygraph.channels2['24-61']"
                @click="openTooltipDialog(chartBodygraph.channels2['24-61'])"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="88.290001"
                  cx="408.85999"
                />
              </g>
              <g
                id="gate_60_on"
                v-if="chartBodygraph.gatesExt[60]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 60),
                  opacity4: isDefinedTogglePointGate(60)
                }"
                @mouseover="searchAcPlanets(60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 60)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      60
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_60_03_channel_on"
                v-if="chartBodygraph.channels2['3-60']"
                @click="openTooltipDialog(chartBodygraph.channels2['3-60'])"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
              </g>
              <g
                id="gate_59_on"
                v-if="chartBodygraph.gatesExt[59]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 59),
                  opacity4: isDefinedTogglePointGate(59)
                }"
                @mouseover="searchAcPlanets(59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 59)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      59
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_59_06_channel_on"
                v-if="chartBodygraph.channels2['6-59']"
                @click="openTooltipDialog(chartBodygraph.channels2['6-59'])"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
              </g>
              <g
                id="gate_58_on"
                v-if="chartBodygraph.gatesExt[58]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 58),
                  opacity4: isDefinedTogglePointGate(58)
                }"
                @mouseover="searchAcPlanets(58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 58)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      58
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_58_18_channel_on"
                v-if="chartBodygraph.channels2['18-58']"
                @click="openTooltipDialog(chartBodygraph.channels2['18-58'])"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
              </g>
              <g
                id="gate_57_on"
                v-if="chartBodygraph.gatesExt[57]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 57),
                  opacity4: isDefinedTogglePointGate(57)
                }"
                @mouseover="searchAcPlanets(57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 57)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      57
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_57_34_channel_on"
                v-if="chartBodygraph.channels2['34-57']"
                @click="openTooltipDialog(chartBodygraph.channels2['34-57'])"
                @mouseover="searchAcPlanets(34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_channel_on"
                v-if="chartBodygraph.channels2['10-57']"
                @click="openTooltipDialog(chartBodygraph.channels2['10-57'])"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_20_channel_on"
                v-if="chartBodygraph.channels2['20-57']"
                @click="openTooltipDialog(chartBodygraph.channels2['20-57'])"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_channel_on"
                v-if="
                  chartBodygraph.channels2['10-57'] &&
                    chartBodygraph.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-57'],
                    chartBodygraph.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_34_channel_on"
                v-if="
                  chartBodygraph.channels2['10-57'] &&
                    chartBodygraph.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-57'],
                    chartBodygraph.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_20_34_channel_on"
                v-if="
                  chartBodygraph.channels2['20-57'] &&
                    chartBodygraph.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['20-57'],
                    chartBodygraph.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_34_channel_on"
                v-if="
                  chartBodygraph.channels2['10-57'] &&
                    chartBodygraph.channels2['20-57'] &&
                    chartBodygraph.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-57'],
                    chartBodygraph.channels2['20-57'],
                    chartBodygraph.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_56_on"
                v-if="chartBodygraph.gatesExt[56]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 56),
                  opacity4: isDefinedTogglePointGate(56)
                }"
                @mouseover="searchAcPlanets(56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 56)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      56
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_56_11_channel_on"
                v-if="chartBodygraph.channels2['11-56']"
                @click="openTooltipDialog(chartBodygraph.channels2['11-56'])"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
              </g>
              <g
                id="gate_55_on"
                v-if="chartBodygraph.gatesExt[55]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 55),
                  opacity4: isDefinedTogglePointGate(55)
                }"
                @mouseover="searchAcPlanets(55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 55)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      55
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_55_39_channel_on"
                v-if="chartBodygraph.channels2['39-55']"
                @click="openTooltipDialog(chartBodygraph.channels2['39-55'])"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
              </g>
              <g
                id="gate_54_on"
                v-if="chartBodygraph.gatesExt[54]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 54),
                  opacity4: isDefinedTogglePointGate(54)
                }"
                @mouseover="searchAcPlanets(54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 54)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      54
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_54_32_channel_on"
                v-if="chartBodygraph.channels2['32-54']"
                @click="openTooltipDialog(chartBodygraph.channels2['32-54'])"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
              </g>
              <g
                id="gate_53_on"
                v-if="chartBodygraph.gatesExt[53]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 53),
                  opacity4: isDefinedTogglePointGate(53)
                }"
                @mouseover="searchAcPlanets(53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 53)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      53
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_53_42_channel_on"
                v-if="chartBodygraph.channels2['42-53']"
                @click="openTooltipDialog(chartBodygraph.channels2['42-53'])"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
              </g>
              <g
                id="gate_52_on"
                v-if="chartBodygraph.gatesExt[52]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 52),
                  opacity4: isDefinedTogglePointGate(52)
                }"
                @mouseover="searchAcPlanets(52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 52)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      52
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_52_09_channel_on"
                v-if="chartBodygraph.channels2['9-52']"
                @click="openTooltipDialog(chartBodygraph.channels2['9-52'])"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
              </g>
              <g
                id="gate_51_on"
                v-if="chartBodygraph.gatesExt[51]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 51),
                  opacity4: isDefinedTogglePointGate(51)
                }"
                @mouseover="searchAcPlanets(51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 51)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      51
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_51_25_channel_on"
                v-if="chartBodygraph.channels2['25-51']"
                @click="openTooltipDialog(chartBodygraph.channels2['25-51'])"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
              </g>
              <g
                id="gate_50_on"
                v-if="chartBodygraph.gatesExt[50]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 50),
                  opacity4: isDefinedTogglePointGate(50)
                }"
                @mouseover="searchAcPlanets(50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 50)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      50
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_50_27_channel_on"
                v-if="chartBodygraph.channels2['27-50']"
                @click="openTooltipDialog(chartBodygraph.channels2['27-50'])"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
              </g>
              <g
                id="gate_49_on"
                v-if="chartBodygraph.gatesExt[49]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 49),
                  opacity4: isDefinedTogglePointGate(49)
                }"
                @mouseover="searchAcPlanets(49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 49)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      49
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_49_19_channel_on"
                v-if="chartBodygraph.channels2['19-49']"
                @click="openTooltipDialog(chartBodygraph.channels2['19-49'])"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
              </g>
              <g
                id="gate_48_on"
                v-if="chartBodygraph.gatesExt[48]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 48),
                  opacity4: isDefinedTogglePointGate(48)
                }"
                @mouseover="searchAcPlanets(48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 48)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      48
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_48_16_channel_on"
                v-if="chartBodygraph.channels2['16-48']"
                @click="openTooltipDialog(chartBodygraph.channels2['16-48'])"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
              </g>
              <g
                id="gate_47_on"
                v-if="chartBodygraph.gatesExt[47]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 47),
                  opacity4: isDefinedTogglePointGate(47)
                }"
                @mouseover="searchAcPlanets(47)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 47)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      47
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_47_64_channel_on"
                v-if="chartBodygraph.channels2['47-64']"
                @click="openTooltipDialog(chartBodygraph.channels2['47-64'])"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
              </g>
              <g
                id="gate_46_on"
                v-if="chartBodygraph.gatesExt[46]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 46),
                  opacity4: isDefinedTogglePointGate(46)
                }"
                @mouseover="searchAcPlanets(46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 46)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      46
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_46_29_channel_on"
                v-if="chartBodygraph.channels2['29-46']"
                @click="openTooltipDialog(chartBodygraph.channels2['29-46'])"
                @mouseover="searchAcPlanets(29, 46)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
              </g>
              <g
                id="gate_45_on"
                v-if="chartBodygraph.gatesExt[45]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 45),
                  opacity4: isDefinedTogglePointGate(45)
                }"
                @mouseover="searchAcPlanets(45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 45)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      45
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_45_21_channel_on"
                v-if="chartBodygraph.channels2['21-45']"
                @click="openTooltipDialog(chartBodygraph.channels2['21-45'])"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
              </g>
              <g
                id="gate_44_on"
                v-if="chartBodygraph.gatesExt[44]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 44),
                  opacity4: isDefinedTogglePointGate(44)
                }"
                @mouseover="searchAcPlanets(44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 44)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      44
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_44_26_channel_on"
                v-if="chartBodygraph.channels2['26-44']"
                @click="openTooltipDialog(chartBodygraph.channels2['26-44'])"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
              </g>
              <g
                id="gate_43_on"
                v-if="chartBodygraph.gatesExt[43]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 43),
                  opacity4: isDefinedTogglePointGate(43)
                }"
                @mouseover="searchAcPlanets(43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 43)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      43
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_43_23_channel_on"
                v-if="chartBodygraph.channels2['23-43']"
                @click="openTooltipDialog(chartBodygraph.channels2['23-43'])"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
              </g>
              <g
                id="gate_42_on"
                v-if="chartBodygraph.gatesExt[42]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 42),
                  opacity4: isDefinedTogglePointGate(42)
                }"
                @mouseover="searchAcPlanets(42)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 42)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      42
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_42_53_channel_on"
                v-if="chartBodygraph.channels2['42-53']"
                @click="openTooltipDialog(chartBodygraph.channels2['42-53'])"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
              </g>
              <g
                id="gate_41_on"
                v-if="chartBodygraph.gatesExt[41]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 41),
                  opacity4: isDefinedTogglePointGate(41)
                }"
                @mouseover="searchAcPlanets(41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 41)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      41
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_41_30_channel_on"
                v-if="chartBodygraph.channels2['30-41']"
                @click="openTooltipDialog(chartBodygraph.channels2['30-41'])"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
              </g>
              <g
                id="gate_40_on"
                v-if="chartBodygraph.gatesExt[40]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 40),
                  opacity4: isDefinedTogglePointGate(40)
                }"
                @mouseover="searchAcPlanets(40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 40)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      40
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_40_37_channel_on"
                v-if="chartBodygraph.channels2['37-40']"
                @click="openTooltipDialog(chartBodygraph.channels2['37-40'])"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
              </g>
              <g
                id="gate_39_on"
                v-if="chartBodygraph.gatesExt[39]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 39),
                  opacity4: isDefinedTogglePointGate(39)
                }"
                @mouseover="searchAcPlanets(39)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 39)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      39
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_39_55_channel_on"
                v-if="chartBodygraph.channels2['39-55']"
                @click="openTooltipDialog(chartBodygraph.channels2['39-55'])"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
              </g>
              <g
                id="gate_38_on"
                v-if="chartBodygraph.gatesExt[38]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 38),
                  opacity4: isDefinedTogglePointGate(38)
                }"
                @mouseover="searchAcPlanets(38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      38
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_38_28_channel_on"
                v-if="chartBodygraph.channels2['28-38']"
                @click="openTooltipDialog(chartBodygraph.channels2['28-38'])"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
              </g>
              <g
                id="gate_37_on"
                v-if="chartBodygraph.gatesExt[37]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 37),
                  opacity4: isDefinedTogglePointGate(37)
                }"
                @mouseover="searchAcPlanets(37)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 37)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      37
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_37_40_channel_on"
                v-if="chartBodygraph.channels2['37-40']"
                @click="openTooltipDialog(chartBodygraph.channels2['37-40'])"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
              </g>
              <g
                id="gate_36_on"
                v-if="chartBodygraph.gatesExt[36]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 36),
                  opacity4: isDefinedTogglePointGate(36)
                }"
                @mouseover="searchAcPlanets(36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 36)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      36
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_36_35_channel_on"
                v-if="chartBodygraph.channels2['35-36']"
                @click="openTooltipDialog(chartBodygraph.channels2['35-36'])"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
              </g>
              <g
                id="gate_35_on"
                v-if="chartBodygraph.gatesExt[35]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 35),
                  opacity4: isDefinedTogglePointGate(35)
                }"
                @mouseover="searchAcPlanets(35)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 35)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      35
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_35_36_channel_on"
                v-if="chartBodygraph.channels2['35-36']"
                @click="openTooltipDialog(chartBodygraph.channels2['35-36'])"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
              </g>
              <g
                id="gate_34_on"
                v-if="chartBodygraph.gatesExt[34]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 34),
                  opacity4: isDefinedTogglePointGate(34)
                }"
                @mouseover="searchAcPlanets(34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 34)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      34
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_34_20_channel_on"
                v-if="chartBodygraph.channels2['20-34']"
                @click="openTooltipDialog(chartBodygraph.channels2['20-34'])"
                @mouseover="searchAcPlanets(20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_channel_on"
                v-if="chartBodygraph.channels2['10-34']"
                @click="openTooltipDialog(chartBodygraph.channels2['10-34'])"
                @mouseover="searchAcPlanets(10, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_57_channel_on"
                v-if="chartBodygraph.channels2['34-57']"
                @click="openTooltipDialog(chartBodygraph.channels2['34-57'])"
                @mouseover="searchAcPlanets(34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_20_channel_on"
                v-if="
                  chartBodygraph.channels2['10-34'] &&
                    chartBodygraph.channels2['20-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-34'],
                    chartBodygraph.channels2['20-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_57_channel_on"
                v-if="
                  chartBodygraph.channels2['10-34'] &&
                    chartBodygraph.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-34'],
                    chartBodygraph.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_20_57_channel_on"
                v-if="
                  chartBodygraph.channels2['20-34'] &&
                    chartBodygraph.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['20-34'],
                    chartBodygraph.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_20_57_channel_on"
                v-if="
                  chartBodygraph.channels2['10-34'] &&
                    chartBodygraph.channels2['20-34'] &&
                    chartBodygraph.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-34'],
                    chartBodygraph.channels2['20-34'],
                    chartBodygraph.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_33_on"
                v-if="chartBodygraph.gatesExt[33]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 33),
                  opacity4: isDefinedTogglePointGate(33)
                }"
                @mouseover="searchAcPlanets(33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 33)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      33
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_33_13_channel_on"
                v-if="chartBodygraph.channels2['13-33']"
                @click="openTooltipDialog(chartBodygraph.channels2['13-33'])"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
              </g>
              <g
                id="gate_32_on"
                v-if="chartBodygraph.gatesExt[32]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 32),
                  opacity4: isDefinedTogglePointGate(32)
                }"
                @mouseover="searchAcPlanets(32)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 32)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      32
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_32_54_channel_on"
                v-if="chartBodygraph.channels2['32-54']"
                @click="openTooltipDialog(chartBodygraph.channels2['32-54'])"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
              </g>
              <g
                id="gate_31_on"
                v-if="chartBodygraph.gatesExt[31]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 31),
                  opacity4: isDefinedTogglePointGate(31)
                }"
                @mouseover="searchAcPlanets(31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 31)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      31
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_31_07_channel_on"
                v-if="chartBodygraph.channels2['7-31']"
                @click="openTooltipDialog(chartBodygraph.channels2['7-31'])"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
              </g>
              <g
                id="gate_30_on"
                v-if="chartBodygraph.gatesExt[30]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 30),
                  opacity4: isDefinedTogglePointGate(30)
                }"
                @mouseover="searchAcPlanets(30)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 30)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      30
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_30_41_channel_on"
                v-if="chartBodygraph.channels2['30-41']"
                @click="openTooltipDialog(chartBodygraph.channels2['30-41'])"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1433"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
              </g>
              <g
                id="gate_29_on"
                v-if="chartBodygraph.gatesExt[29]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 29),
                  opacity4: isDefinedTogglePointGate(29)
                }"
                @mouseover="searchAcPlanets(29)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 29)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      29
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_on"
                v-if="chartBodygraph.gatesExt[28]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 28),
                  opacity4: isDefinedTogglePointGate(28)
                }"
                @mouseover="searchAcPlanets(28)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 28)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      28
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_28_38_channel_on"
                v-if="chartBodygraph.channels2['28-38']"
                @click="openTooltipDialog(chartBodygraph.channels2['28-38'])"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
              </g>
              <g
                id="gate_27_on"
                v-if="chartBodygraph.gatesExt[27]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 27),
                  opacity4: isDefinedTogglePointGate(27)
                }"
                @mouseover="searchAcPlanets(27)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 27)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      27
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_27_50_channel_on"
                v-if="chartBodygraph.channels2['27-50']"
                @click="openTooltipDialog(chartBodygraph.channels2['27-50'])"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
              </g>
              <g
                id="gate_26_on"
                v-if="chartBodygraph.gatesExt[26]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 26),
                  opacity4: isDefinedTogglePointGate(26)
                }"
                @mouseover="searchAcPlanets(26)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 26)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      26
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_26_44_channel_on"
                v-if="chartBodygraph.channels2['26-44']"
                @click="openTooltipDialog(chartBodygraph.channels2['26-44'])"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
              </g>
              <g
                id="gate_25_on"
                v-if="chartBodygraph.gatesExt[25]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 25),
                  opacity4: isDefinedTogglePointGate(25)
                }"
                @mouseover="searchAcPlanets(25)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 25)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      25
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_25_51_channel_on"
                v-if="chartBodygraph.channels2['25-51']"
                @click="openTooltipDialog(chartBodygraph.channels2['25-51'])"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
              </g>
              <g
                id="gate_24_on"
                v-if="chartBodygraph.gatesExt[24]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 24),
                  opacity4: isDefinedTogglePointGate(24)
                }"
                @mouseover="searchAcPlanets(24)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 24)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      24
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_24_61_channel_on"
                v-if="chartBodygraph.channels2['24-61']"
                @click="openTooltipDialog(chartBodygraph.channels2['24-61'])"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
              </g>
              <g
                id="gate_23_on"
                v-if="chartBodygraph.gatesExt[23]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 23),
                  opacity4: isDefinedTogglePointGate(23)
                }"
                @mouseover="searchAcPlanets(23)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 23)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      23
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_23_43_channel_on"
                v-if="chartBodygraph.channels2['23-43']"
                @click="openTooltipDialog(chartBodygraph.channels2['23-43'])"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
              </g>
              <g
                id="gate_22_on"
                v-if="chartBodygraph.gatesExt[22]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 22),
                  opacity4: isDefinedTogglePointGate(22)
                }"
                @mouseover="searchAcPlanets(22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 22)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      22
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_22_12_channel_on"
                v-if="chartBodygraph.channels2['12-22']"
                @click="openTooltipDialog(chartBodygraph.channels2['12-22'])"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
              </g>
              <g
                id="gate_21_on"
                v-if="chartBodygraph.gatesExt[21]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 21),
                  opacity4: isDefinedTogglePointGate(21)
                }"
                @mouseover="searchAcPlanets(21)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 21)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      21
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_21_45_channel_on"
                v-if="chartBodygraph.channels2['21-45']"
                @click="openTooltipDialog(chartBodygraph.channels2['21-45'])"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
              </g>
              <g
                id="gate_20_on"
                v-if="chartBodygraph.gatesExt[20]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 20),
                  opacity4: isDefinedTogglePointGate(20)
                }"
                @mouseover="searchAcPlanets(20)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 20)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      20
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_20_34_channel_on"
                v-if="chartBodygraph.channels2['20-34']"
                @click="openTooltipDialog(chartBodygraph.channels2['20-34'])"
                @mouseover="searchAcPlanets(20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_channel_on"
                v-if="chartBodygraph.channels2['10-20']"
                @click="openTooltipDialog(chartBodygraph.channels2['10-20'])"
                @mouseover="searchAcPlanets(10, 20)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_57_channel_on"
                v-if="chartBodygraph.channels2['20-57']"
                @click="openTooltipDialog(chartBodygraph.channels2['20-57'])"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_34_channel_on"
                v-if="
                  chartBodygraph.channels2['10-20'] &&
                    chartBodygraph.channels2['20-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-20'],
                    chartBodygraph.channels2['20-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_57_channel_on"
                v-if="
                  chartBodygraph.channels2['10-20'] &&
                    chartBodygraph.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-20'],
                    chartBodygraph.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_34_57_channel_on"
                v-if="
                  chartBodygraph.channels2['20-34'] &&
                    chartBodygraph.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['20-34'],
                    chartBodygraph.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_34_57_channel_on"
                v-if="
                  chartBodygraph.channels2['10-20'] &&
                    chartBodygraph.channels2['20-34'] &&
                    chartBodygraph.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-20'],
                    chartBodygraph.channels2['20-34'],
                    chartBodygraph.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_19_on"
                v-if="chartBodygraph.gatesExt[19]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 19),
                  opacity4: isDefinedTogglePointGate(19)
                }"
                @mouseover="searchAcPlanets(19)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 19)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      19
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_19_49_channel_on"
                v-if="chartBodygraph.channels2['19-49']"
                @click="openTooltipDialog(chartBodygraph.channels2['19-49'])"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
              </g>
              <g
                id="gate_18_on"
                v-if="chartBodygraph.gatesExt[18]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 18),
                  opacity4: isDefinedTogglePointGate(18)
                }"
                @mouseover="searchAcPlanets(18)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 18)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      18
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_18_58_channel_on"
                v-if="chartBodygraph.channels2['18-58']"
                @click="openTooltipDialog(chartBodygraph.channels2['18-58'])"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
              </g>
              <g
                id="gate_17_on"
                v-if="chartBodygraph.gatesExt[17]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 17),
                  opacity4: isDefinedTogglePointGate(17)
                }"
                @mouseover="searchAcPlanets(17)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 17)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      17
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_17_62_channel_on"
                v-if="chartBodygraph.channels2['17-62']"
                @click="openTooltipDialog(chartBodygraph.channels2['17-62'])"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
              </g>
              <g
                id="gate_16_on"
                v-if="chartBodygraph.gatesExt[16]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 16),
                  opacity4: isDefinedTogglePointGate(16)
                }"
                @mouseover="searchAcPlanets(16)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 16)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      16
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_16_48_channel_on"
                v-if="chartBodygraph.channels2['16-48']"
                @click="openTooltipDialog(chartBodygraph.channels2['16-48'])"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
              </g>
              <g
                id="gate_15_on"
                v-if="chartBodygraph.gatesExt[15]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 15),
                  opacity4: isDefinedTogglePointGate(15)
                }"
                @mouseover="searchAcPlanets(15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 15)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      15
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_15_05_channel_on"
                v-if="chartBodygraph.channels2['5-15']"
                @click="openTooltipDialog(chartBodygraph.channels2['5-15'])"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
              </g>
              <g
                id="gate_14_on"
                v-if="chartBodygraph.gatesExt[14]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 14),
                  opacity4: isDefinedTogglePointGate(14)
                }"
                @mouseover="searchAcPlanets(14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 14)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      14
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_14_02_channel_on"
                v-if="chartBodygraph.channels2['2-14']"
                @click="openTooltipDialog(chartBodygraph.channels2['2-14'])"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
              </g>
              <g
                id="gate_13_on"
                v-if="chartBodygraph.gatesExt[13]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 13),
                  opacity4: isDefinedTogglePointGate(13)
                }"
                @mouseover="searchAcPlanets(13)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 13)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      13
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_13_33_channel_on"
                v-if="chartBodygraph.channels2['13-33']"
                @click="openTooltipDialog(chartBodygraph.channels2['13-33'])"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
              </g>
              <g
                id="gate_12_on"
                v-if="chartBodygraph.gatesExt[12]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 12),
                  opacity4: isDefinedTogglePointGate(12)
                }"
                @mouseover="searchAcPlanets(12)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 12)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      12
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_12_22_channel_on"
                v-if="chartBodygraph.channels2['12-22']"
                @click="openTooltipDialog(chartBodygraph.channels2['12-22'])"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
              </g>
              <g
                id="gate_11_on"
                v-if="chartBodygraph.gatesExt[11]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 11),
                  opacity4: isDefinedTogglePointGate(11)
                }"
                @mouseover="searchAcPlanets(11)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 11)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      11
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_11_56_channel_on"
                v-if="chartBodygraph.channels2['11-56']"
                @click="openTooltipDialog(chartBodygraph.channels2['11-56'])"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
              </g>
              <g
                id="gate_10_on"
                v-if="chartBodygraph.gatesExt[10]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 10),
                  opacity4: isDefinedTogglePointGate(10)
                }"
                @mouseover="searchAcPlanets(10)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 10)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      10
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_34_channel_on"
                v-if="chartBodygraph.channels2['10-34']"
                @click="openTooltipDialog(chartBodygraph.channels2['10-34'])"
                @mouseover="searchAcPlanets(10, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_channel_on"
                v-if="chartBodygraph.channels2['10-20']"
                @click="openTooltipDialog(chartBodygraph.channels2['10-20'])"
                @mouseover="searchAcPlanets(10, 20)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_57_channel_on"
                v-if="chartBodygraph.channels2['10-57']"
                @click="openTooltipDialog(chartBodygraph.channels2['10-57'])"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_34_channel_on"
                v-if="
                  chartBodygraph.channels2['10-20'] &&
                    chartBodygraph.channels2['10-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-20'],
                    chartBodygraph.channels2['10-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_57_channel_on"
                v-if="
                  chartBodygraph.channels2['10-20'] &&
                    chartBodygraph.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-20'],
                    chartBodygraph.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_34_57_channel_on"
                v-if="
                  chartBodygraph.channels2['10-34'] &&
                    chartBodygraph.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-34'],
                    chartBodygraph.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_34_57_channel_on"
                v-if="
                  chartBodygraph.channels2['10-20'] &&
                    chartBodygraph.channels2['10-34'] &&
                    chartBodygraph.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraph.channels2['10-20'],
                    chartBodygraph.channels2['10-34'],
                    chartBodygraph.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_09_on"
                v-if="chartBodygraph.gatesExt[9]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 9),
                  opacity4: isDefinedTogglePointGate(9)
                }"
                @mouseover="searchAcPlanets(9)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 9)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      9
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_09_52_channel_on"
                v-if="chartBodygraph.channels2['9-52']"
                @click="openTooltipDialog(chartBodygraph.channels2['9-52'])"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
              </g>
              <g
                id="gate_08_on"
                v-if="chartBodygraph.gatesExt[8]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 8),
                  opacity4: isDefinedTogglePointGate(8)
                }"
                @mouseover="searchAcPlanets(8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 8)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      8
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_08_01_channel_on"
                v-if="chartBodygraph.channels2['1-8']"
                @click="openTooltipDialog(chartBodygraph.channels2['1-8'])"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
              </g>
              <g
                id="gate_07_on"
                v-if="chartBodygraph.gatesExt[7]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 7),
                  opacity4: isDefinedTogglePointGate(7)
                }"
                @mouseover="searchAcPlanets(7)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 7)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      7
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_07_31_channel_on"
                v-if="chartBodygraph.channels2['7-31']"
                @click="openTooltipDialog(chartBodygraph.channels2['7-31'])"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
              </g>
              <g
                id="gate_06_on"
                v-if="chartBodygraph.gatesExt[6]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 6),
                  opacity4: isDefinedTogglePointGate(6)
                }"
                @mouseover="searchAcPlanets(6)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 6)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      6
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_06_59_channel_on"
                v-if="chartBodygraph.channels2['6-59']"
                @click="openTooltipDialog(chartBodygraph.channels2['6-59'])"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
              </g>
              <g
                id="gate_05_on"
                v-if="chartBodygraph.gatesExt[5]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 5),
                  opacity4: isDefinedTogglePointGate(5)
                }"
                @mouseover="searchAcPlanets(5)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 5)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      5
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_05_15_channel_on"
                v-if="chartBodygraph.channels2['5-15']"
                @click="openTooltipDialog(chartBodygraph.channels2['5-15'])"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
              </g>
              <g
                id="gate_04_on"
                v-if="chartBodygraph.gatesExt[4]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 4),
                  opacity4: isDefinedTogglePointGate(4)
                }"
                @mouseover="searchAcPlanets(4)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 4)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      4
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_04_63_channel_on"
                v-if="chartBodygraph.channels2['4-63']"
                @click="openTooltipDialog(chartBodygraph.channels2['4-63'])"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
              </g>
              <g
                id="gate_03_on"
                v-if="chartBodygraph.gatesExt[3]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 3),
                  opacity4: isDefinedTogglePointGate(3)
                }"
                @mouseover="searchAcPlanets(3)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 3)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      3
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_03_60_channel_on"
                v-if="chartBodygraph.channels2['3-60']"
                @click="openTooltipDialog(chartBodygraph.channels2['3-60'])"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
              </g>
              <g
                id="gate_02_on"
                v-if="chartBodygraph.gatesExt[2]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 2),
                  opacity4: isDefinedTogglePointGate(2)
                }"
                @mouseover="searchAcPlanets(2)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 2)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      2
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_02_14_channel_on"
                v-if="chartBodygraph.channels2['2-14']"
                @click="openTooltipDialog(chartBodygraph.channels2['2-14'])"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
              </g>
              <g
                id="gate_01_on"
                v-if="chartBodygraph.gatesExt[1]"
                @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.gates, 1),
                  opacity4: isDefinedTogglePointGate(1)
                }"
                @mouseover="searchAcPlanets(1)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 1)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      1
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
              <g
                id="gate_01_08_channel_on"
                v-if="chartBodygraph.channels2['1-8']"
                @click="openTooltipDialog(chartBodygraph.channels2['1-8'])"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isDesignToggle || isPersonToggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
              </g>
            </g>
            <g
              id="layer5-1"
              v-if="isHarmoniousGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active isHarmoniousGates Only"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[64]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    64
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[63]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    63
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[62]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    62
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[61]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    61
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[60]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    60
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[59]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    59
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[58]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    58
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[57]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    57
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[56]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    56
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[55]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    55
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[54]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    54
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[53]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    53
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[52]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    52
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[51]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    51
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[50]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    50
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[49]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    49
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[48]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    48
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[47]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    47
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[46]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    46
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[45]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    45
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[44]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    44
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[43]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    43
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[42]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    42
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[41]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    41
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[40]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    40
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[39]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    39
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[38]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    38
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[37]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    37
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[36]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    36
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[35]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    35
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[34]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    34
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[33]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    33
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[32]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    32
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[31]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    31
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[30]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    30
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[29]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    29
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[28]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    28
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[27]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    27
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[26]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    26
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[25]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    25
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[24]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    24
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[23]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    23
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[22]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    22
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[21]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    21
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[20]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    20
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[19]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    19
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[18]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    18
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[17]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    17
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[16]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    16
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[15]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    15
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[14]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    14
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[13]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    13
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[12]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    12
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[11]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    11
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[10]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    10
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[9]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    9
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[8]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    8
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[7]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    7
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[6]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    6
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[5]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    5
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[4]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    4
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[3]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    3
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[2]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    2
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_harmonious_on"
                v-if="chartBodygraph.harmoniousGates[1]"
                @click="openTooltipDialog(chartBodygraph.harmoniousGates[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.harmoniousGateIds,
                    1
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5-2"
              v-if="isLoveGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active isLoveGates Only"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_love_on"
                v-if="chartBodygraph.loveGates[64]"
                @click="openTooltipDialog(chartBodygraph.loveGates[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 64)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_love_on"
                v-if="chartBodygraph.loveGates[63]"
                @click="openTooltipDialog(chartBodygraph.loveGates[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 63)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_love_on"
                v-if="chartBodygraph.loveGates[62]"
                @click="openTooltipDialog(chartBodygraph.loveGates[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 62)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_love_on"
                v-if="chartBodygraph.loveGates[61]"
                @click="openTooltipDialog(chartBodygraph.loveGates[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 61)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_love_on"
                v-if="chartBodygraph.loveGates[60]"
                @click="openTooltipDialog(chartBodygraph.loveGates[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 60)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_love_on"
                v-if="chartBodygraph.loveGates[59]"
                @click="openTooltipDialog(chartBodygraph.loveGates[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 59)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_love_on"
                v-if="chartBodygraph.loveGates[58]"
                @click="openTooltipDialog(chartBodygraph.loveGates[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 58)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_love_on"
                v-if="chartBodygraph.loveGates[57]"
                @click="openTooltipDialog(chartBodygraph.loveGates[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 57)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_love_on"
                v-if="chartBodygraph.loveGates[56]"
                @click="openTooltipDialog(chartBodygraph.loveGates[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 56)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_love_on"
                v-if="chartBodygraph.loveGates[55]"
                @click="openTooltipDialog(chartBodygraph.loveGates[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 55)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_love_on"
                v-if="chartBodygraph.loveGates[54]"
                @click="openTooltipDialog(chartBodygraph.loveGates[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 54)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_love_on"
                v-if="chartBodygraph.loveGates[53]"
                @click="openTooltipDialog(chartBodygraph.loveGates[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 53)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_love_on"
                v-if="chartBodygraph.loveGates[52]"
                @click="openTooltipDialog(chartBodygraph.loveGates[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 52)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_love_on"
                v-if="chartBodygraph.loveGates[51]"
                @click="openTooltipDialog(chartBodygraph.loveGates[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 51)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_love_on"
                v-if="chartBodygraph.loveGates[50]"
                @click="openTooltipDialog(chartBodygraph.loveGates[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 50)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_love_on"
                v-if="chartBodygraph.loveGates[49]"
                @click="openTooltipDialog(chartBodygraph.loveGates[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 49)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_love_on"
                v-if="chartBodygraph.loveGates[48]"
                @click="openTooltipDialog(chartBodygraph.loveGates[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 48)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_love_on"
                v-if="chartBodygraph.loveGates[47]"
                @click="openTooltipDialog(chartBodygraph.loveGates[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 47)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_love_on"
                v-if="chartBodygraph.loveGates[46]"
                @click="openTooltipDialog(chartBodygraph.loveGates[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 46)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_love_on"
                v-if="chartBodygraph.loveGates[45]"
                @click="openTooltipDialog(chartBodygraph.loveGates[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 45)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_love_on"
                v-if="chartBodygraph.loveGates[44]"
                @click="openTooltipDialog(chartBodygraph.loveGates[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 44)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_love_on"
                v-if="chartBodygraph.loveGates[43]"
                @click="openTooltipDialog(chartBodygraph.loveGates[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 43)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_love_on"
                v-if="chartBodygraph.loveGates[42]"
                @click="openTooltipDialog(chartBodygraph.loveGates[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 42)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_love_on"
                v-if="chartBodygraph.loveGates[41]"
                @click="openTooltipDialog(chartBodygraph.loveGates[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 41)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_love_on"
                v-if="chartBodygraph.loveGates[40]"
                @click="openTooltipDialog(chartBodygraph.loveGates[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 40)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_love_on"
                v-if="chartBodygraph.loveGates[39]"
                @click="openTooltipDialog(chartBodygraph.loveGates[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 39)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_love_on"
                v-if="chartBodygraph.loveGates[38]"
                @click="openTooltipDialog(chartBodygraph.loveGates[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 38)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_love_on"
                v-if="chartBodygraph.loveGates[37]"
                @click="openTooltipDialog(chartBodygraph.loveGates[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 37)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_love_on"
                v-if="chartBodygraph.loveGates[36]"
                @click="openTooltipDialog(chartBodygraph.loveGates[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 36)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_love_on"
                v-if="chartBodygraph.loveGates[35]"
                @click="openTooltipDialog(chartBodygraph.loveGates[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 35)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_love_on"
                v-if="chartBodygraph.loveGates[34]"
                @click="openTooltipDialog(chartBodygraph.loveGates[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 34)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_love_on"
                v-if="chartBodygraph.loveGates[33]"
                @click="openTooltipDialog(chartBodygraph.loveGates[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 33)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_love_on"
                v-if="chartBodygraph.loveGates[32]"
                @click="openTooltipDialog(chartBodygraph.loveGates[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 32)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_love_on"
                v-if="chartBodygraph.loveGates[31]"
                @click="openTooltipDialog(chartBodygraph.loveGates[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 31)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_love_on"
                v-if="chartBodygraph.loveGates[30]"
                @click="openTooltipDialog(chartBodygraph.loveGates[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 30)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_love_on"
                v-if="chartBodygraph.loveGates[29]"
                @click="openTooltipDialog(chartBodygraph.loveGates[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 29)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_love_on"
                v-if="chartBodygraph.loveGates[28]"
                @click="openTooltipDialog(chartBodygraph.loveGates[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 28)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_love_on"
                v-if="chartBodygraph.loveGates[27]"
                @click="openTooltipDialog(chartBodygraph.loveGates[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 27)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_love_on"
                v-if="chartBodygraph.loveGates[26]"
                @click="openTooltipDialog(chartBodygraph.loveGates[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 26)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_love_on"
                v-if="chartBodygraph.loveGates[25]"
                @click="openTooltipDialog(chartBodygraph.loveGates[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 25)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_love_on"
                v-if="chartBodygraph.loveGates[24]"
                @click="openTooltipDialog(chartBodygraph.loveGates[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 24)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_love_on"
                v-if="chartBodygraph.loveGates[23]"
                @click="openTooltipDialog(chartBodygraph.loveGates[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 23)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_love_on"
                v-if="chartBodygraph.loveGates[22]"
                @click="openTooltipDialog(chartBodygraph.loveGates[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 22)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_love_on"
                v-if="chartBodygraph.loveGates[21]"
                @click="openTooltipDialog(chartBodygraph.loveGates[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 21)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_love_on"
                v-if="chartBodygraph.loveGates[20]"
                @click="openTooltipDialog(chartBodygraph.loveGates[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 20)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_love_on"
                v-if="chartBodygraph.loveGates[19]"
                @click="openTooltipDialog(chartBodygraph.loveGates[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 19)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_love_on"
                v-if="chartBodygraph.loveGates[18]"
                @click="openTooltipDialog(chartBodygraph.loveGates[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 18)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_love_on"
                v-if="chartBodygraph.loveGates[17]"
                @click="openTooltipDialog(chartBodygraph.loveGates[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 17)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_love_on"
                v-if="chartBodygraph.loveGates[16]"
                @click="openTooltipDialog(chartBodygraph.loveGates[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 16)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_love_on"
                v-if="chartBodygraph.loveGates[15]"
                @click="openTooltipDialog(chartBodygraph.loveGates[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 15)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_love_on"
                v-if="chartBodygraph.loveGates[14]"
                @click="openTooltipDialog(chartBodygraph.loveGates[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 14)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_love_on"
                v-if="chartBodygraph.loveGates[13]"
                @click="openTooltipDialog(chartBodygraph.loveGates[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 13)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_love_on"
                v-if="chartBodygraph.loveGates[12]"
                @click="openTooltipDialog(chartBodygraph.loveGates[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 12)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_love_on"
                v-if="chartBodygraph.loveGates[11]"
                @click="openTooltipDialog(chartBodygraph.loveGates[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 11)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_love_on"
                v-if="chartBodygraph.loveGates[10]"
                @click="openTooltipDialog(chartBodygraph.loveGates[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 10)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_love_on"
                v-if="chartBodygraph.loveGates[9]"
                @click="openTooltipDialog(chartBodygraph.loveGates[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 9)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_love_on"
                v-if="chartBodygraph.loveGates[8]"
                @click="openTooltipDialog(chartBodygraph.loveGates[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 8)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_love_on"
                v-if="chartBodygraph.loveGates[7]"
                @click="openTooltipDialog(chartBodygraph.loveGates[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 7)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_love_on"
                v-if="chartBodygraph.loveGates[6]"
                @click="openTooltipDialog(chartBodygraph.loveGates[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 6)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_love_on"
                v-if="chartBodygraph.loveGates[5]"
                @click="openTooltipDialog(chartBodygraph.loveGates[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 5)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_love_on"
                v-if="chartBodygraph.loveGates[4]"
                @click="openTooltipDialog(chartBodygraph.loveGates[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 4)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_love_on"
                v-if="chartBodygraph.loveGates[3]"
                @click="openTooltipDialog(chartBodygraph.loveGates[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 3)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_love_on"
                v-if="chartBodygraph.loveGates[2]"
                @click="openTooltipDialog(chartBodygraph.loveGates[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 2)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_love_on"
                v-if="chartBodygraph.loveGates[1]"
                @click="openTooltipDialog(chartBodygraph.loveGates[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.loveGateIds, 1)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5-3"
              v-if="isFearsGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active isFearsGates Only"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_fears_on"
                v-if="chartBodygraph.fearGates[64]"
                @click="openTooltipDialog(chartBodygraph.fearGates[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 64)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_fears_on"
                v-if="chartBodygraph.fearGates[63]"
                @click="openTooltipDialog(chartBodygraph.fearGates[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 63)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_fears_on"
                v-if="chartBodygraph.fearGates[62]"
                @click="openTooltipDialog(chartBodygraph.fearGates[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 62)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_fears_on"
                v-if="chartBodygraph.fearGates[61]"
                @click="openTooltipDialog(chartBodygraph.fearGates[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 61)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_fears_on"
                v-if="chartBodygraph.fearGates[60]"
                @click="openTooltipDialog(chartBodygraph.fearGates[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 60)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_fears_on"
                v-if="chartBodygraph.fearGates[59]"
                @click="openTooltipDialog(chartBodygraph.fearGates[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 59)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_fears_on"
                v-if="chartBodygraph.fearGates[58]"
                @click="openTooltipDialog(chartBodygraph.fearGates[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 58)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_fears_on"
                v-if="chartBodygraph.fearGates[57]"
                @click="openTooltipDialog(chartBodygraph.fearGates[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 57)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_fears_on"
                v-if="chartBodygraph.fearGates[56]"
                @click="openTooltipDialog(chartBodygraph.fearGates[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 56)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_fears_on"
                v-if="chartBodygraph.fearGates[55]"
                @click="openTooltipDialog(chartBodygraph.fearGates[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 55)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_fears_on"
                v-if="chartBodygraph.fearGates[54]"
                @click="openTooltipDialog(chartBodygraph.fearGates[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 54)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_fears_on"
                v-if="chartBodygraph.fearGates[53]"
                @click="openTooltipDialog(chartBodygraph.fearGates[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 53)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_fears_on"
                v-if="chartBodygraph.fearGates[52]"
                @click="openTooltipDialog(chartBodygraph.fearGates[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 52)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_fears_on"
                v-if="chartBodygraph.fearGates[51]"
                @click="openTooltipDialog(chartBodygraph.fearGates[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 51)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_fears_on"
                v-if="chartBodygraph.fearGates[50]"
                @click="openTooltipDialog(chartBodygraph.fearGates[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 50)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_fears_on"
                v-if="chartBodygraph.fearGates[49]"
                @click="openTooltipDialog(chartBodygraph.fearGates[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 49)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_fears_on"
                v-if="chartBodygraph.fearGates[48]"
                @click="openTooltipDialog(chartBodygraph.fearGates[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 48)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_fears_on"
                v-if="chartBodygraph.fearGates[47]"
                @click="openTooltipDialog(chartBodygraph.fearGates[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 47)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_fears_on"
                v-if="chartBodygraph.fearGates[46]"
                @click="openTooltipDialog(chartBodygraph.fearGates[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 46)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_fears_on"
                v-if="chartBodygraph.fearGates[45]"
                @click="openTooltipDialog(chartBodygraph.fearGates[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 45)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_fears_on"
                v-if="chartBodygraph.fearGates[44]"
                @click="openTooltipDialog(chartBodygraph.fearGates[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 44)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_fears_on"
                v-if="chartBodygraph.fearGates[43]"
                @click="openTooltipDialog(chartBodygraph.fearGates[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 43)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_fears_on"
                v-if="chartBodygraph.fearGates[42]"
                @click="openTooltipDialog(chartBodygraph.fearGates[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 42)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_fears_on"
                v-if="chartBodygraph.fearGates[41]"
                @click="openTooltipDialog(chartBodygraph.fearGates[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 41)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_fears_on"
                v-if="chartBodygraph.fearGates[40]"
                @click="openTooltipDialog(chartBodygraph.fearGates[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 40)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_fears_on"
                v-if="chartBodygraph.fearGates[39]"
                @click="openTooltipDialog(chartBodygraph.fearGates[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 39)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_fears_on"
                v-if="chartBodygraph.fearGates[38]"
                @click="openTooltipDialog(chartBodygraph.fearGates[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 38)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_fears_on"
                v-if="chartBodygraph.fearGates[37]"
                @click="openTooltipDialog(chartBodygraph.fearGates[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 37)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_fears_on"
                v-if="chartBodygraph.fearGates[36]"
                @click="openTooltipDialog(chartBodygraph.fearGates[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 36)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_fears_on"
                v-if="chartBodygraph.fearGates[35]"
                @click="openTooltipDialog(chartBodygraph.fearGates[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 35)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_fears_on"
                v-if="chartBodygraph.fearGates[34]"
                @click="openTooltipDialog(chartBodygraph.fearGates[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 34)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_fears_on"
                v-if="chartBodygraph.fearGates[33]"
                @click="openTooltipDialog(chartBodygraph.fearGates[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 33)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_fears_on"
                v-if="chartBodygraph.fearGates[32]"
                @click="openTooltipDialog(chartBodygraph.fearGates[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 32)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_fears_on"
                v-if="chartBodygraph.fearGates[31]"
                @click="openTooltipDialog(chartBodygraph.fearGates[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 31)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_fears_on"
                v-if="chartBodygraph.fearGates[30]"
                @click="openTooltipDialog(chartBodygraph.fearGates[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 30)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_fears_on"
                v-if="chartBodygraph.fearGates[29]"
                @click="openTooltipDialog(chartBodygraph.fearGates[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 29)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_fears_on"
                v-if="chartBodygraph.fearGates[28]"
                @click="openTooltipDialog(chartBodygraph.fearGates[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 28)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_fears_on"
                v-if="chartBodygraph.fearGates[27]"
                @click="openTooltipDialog(chartBodygraph.fearGates[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 27)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_fears_on"
                v-if="chartBodygraph.fearGates[26]"
                @click="openTooltipDialog(chartBodygraph.fearGates[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 26)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_fears_on"
                v-if="chartBodygraph.fearGates[25]"
                @click="openTooltipDialog(chartBodygraph.fearGates[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 25)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_fears_on"
                v-if="chartBodygraph.fearGates[24]"
                @click="openTooltipDialog(chartBodygraph.fearGates[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 24)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_fears_on"
                v-if="chartBodygraph.fearGates[23]"
                @click="openTooltipDialog(chartBodygraph.fearGates[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 23)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_fears_on"
                v-if="chartBodygraph.fearGates[22]"
                @click="openTooltipDialog(chartBodygraph.fearGates[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 22)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_fears_on"
                v-if="chartBodygraph.fearGates[21]"
                @click="openTooltipDialog(chartBodygraph.fearGates[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 21)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_fears_on"
                v-if="chartBodygraph.fearGates[20]"
                @click="openTooltipDialog(chartBodygraph.fearGates[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 20)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_fears_on"
                v-if="chartBodygraph.fearGates[19]"
                @click="openTooltipDialog(chartBodygraph.fearGates[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 19)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_fears_on"
                v-if="chartBodygraph.fearGates[18]"
                @click="openTooltipDialog(chartBodygraph.fearGates[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 18)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_fears_on"
                v-if="chartBodygraph.fearGates[17]"
                @click="openTooltipDialog(chartBodygraph.fearGates[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 17)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_fears_on"
                v-if="chartBodygraph.fearGates[16]"
                @click="openTooltipDialog(chartBodygraph.fearGates[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 16)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_fears_on"
                v-if="chartBodygraph.fearGates[15]"
                @click="openTooltipDialog(chartBodygraph.fearGates[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 15)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_fears_on"
                v-if="chartBodygraph.fearGates[14]"
                @click="openTooltipDialog(chartBodygraph.fearGates[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 14)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_fears_on"
                v-if="chartBodygraph.fearGates[13]"
                @click="openTooltipDialog(chartBodygraph.fearGates[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 13)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_fears_on"
                v-if="chartBodygraph.fearGates[12]"
                @click="openTooltipDialog(chartBodygraph.fearGates[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 12)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_fears_on"
                v-if="chartBodygraph.fearGates[11]"
                @click="openTooltipDialog(chartBodygraph.fearGates[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 11)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_fears_on"
                v-if="chartBodygraph.fearGates[10]"
                @click="openTooltipDialog(chartBodygraph.fearGates[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 10)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_fears_on"
                v-if="chartBodygraph.fearGates[9]"
                @click="openTooltipDialog(chartBodygraph.fearGates[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 9)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_fears_on"
                v-if="chartBodygraph.fearGates[8]"
                @click="openTooltipDialog(chartBodygraph.fearGates[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 8)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_fears_on"
                v-if="chartBodygraph.fearGates[7]"
                @click="openTooltipDialog(chartBodygraph.fearGates[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 7)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_fears_on"
                v-if="chartBodygraph.fearGates[6]"
                @click="openTooltipDialog(chartBodygraph.fearGates[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 6)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_fears_on"
                v-if="chartBodygraph.fearGates[5]"
                @click="openTooltipDialog(chartBodygraph.fearGates[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 5)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_fears_on"
                v-if="chartBodygraph.fearGates[4]"
                @click="openTooltipDialog(chartBodygraph.fearGates[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 4)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_fears_on"
                v-if="chartBodygraph.fearGates[3]"
                @click="openTooltipDialog(chartBodygraph.fearGates[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 3)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_fears_on"
                v-if="chartBodygraph.fearGates[2]"
                @click="openTooltipDialog(chartBodygraph.fearGates[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 2)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_fears_on"
                v-if="chartBodygraph.fearGates[1]"
                @click="openTooltipDialog(chartBodygraph.fearGates[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.fearGateIds, 1)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5-4"
              v-if="isMelancholyGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active isMelancholyGates Only"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_melancholy_on"
                v-if="chartBodygraph.melancholyGates[64]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    64
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_melancholy_on"
                v-if="chartBodygraph.melancholyGates[63]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    63
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_melancholy_on"
                v-if="chartBodygraph.melancholyGates[62]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    62
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_melancholy_on"
                v-if="chartBodygraph.melancholyGates[61]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    61
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_melancholy_on"
                v-if="chartBodygraph.melancholyGates[60]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    60
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_melancholy_on"
                v-if="chartBodygraph.melancholyGates[59]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    59
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_melancholy_on"
                v-if="chartBodygraph.melancholyGates[58]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    58
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_melancholy_on"
                v-if="chartBodygraph.melancholyGates[57]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    57
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_melancholy_on"
                v-if="chartBodygraph.melancholyGates[56]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    56
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_melancholy_on"
                v-if="chartBodygraph.melancholyGates[55]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    55
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_melancholy_on"
                v-if="chartBodygraph.melancholyGates[54]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    54
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_melancholy_on"
                v-if="chartBodygraph.melancholyGates[53]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    53
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_melancholy_on"
                v-if="chartBodygraph.melancholyGates[52]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    52
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_melancholy_on"
                v-if="chartBodygraph.melancholyGates[51]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    51
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_melancholy_on"
                v-if="chartBodygraph.melancholyGates[50]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    50
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_melancholy_on"
                v-if="chartBodygraph.melancholyGates[49]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    49
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_melancholy_on"
                v-if="chartBodygraph.melancholyGates[48]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    48
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_melancholy_on"
                v-if="chartBodygraph.melancholyGates[47]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    47
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_melancholy_on"
                v-if="chartBodygraph.melancholyGates[46]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    46
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_melancholy_on"
                v-if="chartBodygraph.melancholyGates[45]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    45
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_melancholy_on"
                v-if="chartBodygraph.melancholyGates[44]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    44
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_melancholy_on"
                v-if="chartBodygraph.melancholyGates[43]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    43
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_melancholy_on"
                v-if="chartBodygraph.melancholyGates[42]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    42
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_melancholy_on"
                v-if="chartBodygraph.melancholyGates[41]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    41
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_melancholy_on"
                v-if="chartBodygraph.melancholyGates[40]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    40
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_melancholy_on"
                v-if="chartBodygraph.melancholyGates[39]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    39
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_melancholy_on"
                v-if="chartBodygraph.melancholyGates[38]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    38
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_melancholy_on"
                v-if="chartBodygraph.melancholyGates[37]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    37
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_melancholy_on"
                v-if="chartBodygraph.melancholyGates[36]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    36
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_melancholy_on"
                v-if="chartBodygraph.melancholyGates[35]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    35
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_melancholy_on"
                v-if="chartBodygraph.melancholyGates[34]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    34
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_melancholy_on"
                v-if="chartBodygraph.melancholyGates[33]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    33
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_melancholy_on"
                v-if="chartBodygraph.melancholyGates[32]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    32
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_melancholy_on"
                v-if="chartBodygraph.melancholyGates[31]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    31
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_melancholy_on"
                v-if="chartBodygraph.melancholyGates[30]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    30
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_melancholy_on"
                v-if="chartBodygraph.melancholyGates[29]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    29
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_melancholy_on"
                v-if="chartBodygraph.melancholyGates[28]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    28
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_melancholy_on"
                v-if="chartBodygraph.melancholyGates[27]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    27
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_melancholy_on"
                v-if="chartBodygraph.melancholyGates[26]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    26
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_melancholy_on"
                v-if="chartBodygraph.melancholyGates[25]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    25
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_melancholy_on"
                v-if="chartBodygraph.melancholyGates[24]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    24
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_melancholy_on"
                v-if="chartBodygraph.melancholyGates[23]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    23
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_melancholy_on"
                v-if="chartBodygraph.melancholyGates[22]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    22
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_melancholy_on"
                v-if="chartBodygraph.melancholyGates[21]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    21
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_melancholy_on"
                v-if="chartBodygraph.melancholyGates[20]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    20
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_melancholy_on"
                v-if="chartBodygraph.melancholyGates[19]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    19
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_melancholy_on"
                v-if="chartBodygraph.melancholyGates[18]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    18
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_melancholy_on"
                v-if="chartBodygraph.melancholyGates[17]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    17
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_melancholy_on"
                v-if="chartBodygraph.melancholyGates[16]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    16
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_melancholy_on"
                v-if="chartBodygraph.melancholyGates[15]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    15
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_melancholy_on"
                v-if="chartBodygraph.melancholyGates[14]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    14
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_melancholy_on"
                v-if="chartBodygraph.melancholyGates[13]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    13
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_melancholy_on"
                v-if="chartBodygraph.melancholyGates[12]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    12
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_melancholy_on"
                v-if="chartBodygraph.melancholyGates[11]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    11
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_melancholy_on"
                v-if="chartBodygraph.melancholyGates[10]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    10
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_melancholy_on"
                v-if="chartBodygraph.melancholyGates[9]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    9
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_melancholy_on"
                v-if="chartBodygraph.melancholyGates[8]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    8
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_melancholy_on"
                v-if="chartBodygraph.melancholyGates[7]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    7
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_melancholy_on"
                v-if="chartBodygraph.melancholyGates[6]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    6
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_melancholy_on"
                v-if="chartBodygraph.melancholyGates[5]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    5
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_melancholy_on"
                v-if="chartBodygraph.melancholyGates[4]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    4
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_melancholy_on"
                v-if="chartBodygraph.melancholyGates[3]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    3
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_melancholy_on"
                v-if="chartBodygraph.melancholyGates[2]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    2
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_melancholy_on"
                v-if="chartBodygraph.melancholyGates[1]"
                @click="openTooltipDialog(chartBodygraph.melancholyGates[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.melancholyGateIds,
                    1
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5-5"
              v-if="isDreamGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active isDreamGates Only"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_melancholy_on"
                v-if="chartBodygraph.dreamGates[64]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 64)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_melancholy_on"
                v-if="chartBodygraph.dreamGates[63]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 63)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_melancholy_on"
                v-if="chartBodygraph.dreamGates[62]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 62)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_melancholy_on"
                v-if="chartBodygraph.dreamGates[61]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 61)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_melancholy_on"
                v-if="chartBodygraph.dreamGates[60]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 60)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_melancholy_on"
                v-if="chartBodygraph.dreamGates[59]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 59)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_melancholy_on"
                v-if="chartBodygraph.dreamGates[58]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 58)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_dream_on"
                v-if="chartBodygraph.dreamGates[57]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 57)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[4]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <!-- <g
                id="gate_20_57_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['20-57']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['20-57'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '20-57'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g> -->
              <g
                id="gate_56_dream_on"
                v-if="chartBodygraph.dreamGates[56]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 56)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_dream_on"
                v-if="chartBodygraph.dreamGates[55]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 55)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[2]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_dream_on"
                v-if="chartBodygraph.dreamGates[54]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 54)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[1]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_dream_on"
                v-if="chartBodygraph.dreamGates[53]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 53)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[1]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <!-- <g
                id="gate_53_42_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['42-53']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['42-53'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '42-53'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g> -->
              <g
                id="gate_52_dream_on"
                v-if="chartBodygraph.dreamGates[52]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 52)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[1]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_dream_on"
                v-if="chartBodygraph.dreamGates[51]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 51)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[5]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_dream_on"
                v-if="chartBodygraph.dreamGates[50]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 50)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[4]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <!-- <g
                id="gate_50_27_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['27-50']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['27-50'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '27-50'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g> -->
              <g
                id="gate_49_dream_on"
                v-if="chartBodygraph.dreamGates[49]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 49)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[2]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_dream_on"
                v-if="chartBodygraph.dreamGates[48]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 48)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[4]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_dream_on"
                v-if="chartBodygraph.dreamGates[47]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 47)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[8]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_dream_on"
                v-if="chartBodygraph.dreamGates[46]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 46)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[6]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_dream_on"
                v-if="chartBodygraph.dreamGates[45]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 45)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_dream_on"
                v-if="chartBodygraph.dreamGates[44]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 44)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[4]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_dream_on"
                v-if="chartBodygraph.dreamGates[43]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 43)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[8]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_dream_on"
                v-if="chartBodygraph.dreamGates[42]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 42)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <!-- <g
                id="gate_42_53_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['42-53']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['42-53'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '42-53'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g> -->
              <g
                id="gate_41_dream_on"
                v-if="chartBodygraph.dreamGates[41]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 41)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[1]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_dream_on"
                v-if="chartBodygraph.dreamGates[40]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 40)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[5]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_dream_on"
                v-if="chartBodygraph.dreamGates[39]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 39)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[1]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_dream_on"
                v-if="chartBodygraph.dreamGates[38]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 38)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[1]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <!-- <g
                id="gate_38_28_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['28-38']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['28-38'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '28-38'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g> -->
              <g
                id="gate_37_dream_on"
                v-if="chartBodygraph.dreamGates[37]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 37)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[2]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_dream_on"
                v-if="chartBodygraph.dreamGates[36]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 36)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[2]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_dream_on"
                v-if="chartBodygraph.dreamGates[35]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 35)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_dream_on"
                v-if="chartBodygraph.dreamGates[34]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 34)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_dream_on"
                v-if="chartBodygraph.dreamGates[33]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 33)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_dream_on"
                v-if="chartBodygraph.dreamGates[32]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 32)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[4]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_dream_on"
                v-if="chartBodygraph.dreamGates[31]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 31)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_dream_on"
                v-if="chartBodygraph.dreamGates[30]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 30)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[2]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_dream_on"
                v-if="chartBodygraph.dreamGates[29]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 29)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_dream_on"
                v-if="chartBodygraph.dreamGates[28]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 28)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[4]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <!-- <g
                id="gate_28_38_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['28-38']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['28-38'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '28-38'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g> -->
              <g
                id="gate_27_dream_on"
                v-if="chartBodygraph.dreamGates[27]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 27)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <!-- <g
                id="gate_27_50_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['27-50']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['27-50'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '27-50'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g> -->
              <g
                id="gate_26_dream_on"
                v-if="chartBodygraph.dreamGates[26]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 26)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[5]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_dream_on"
                v-if="chartBodygraph.dreamGates[25]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 25)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[6]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_dream_on"
                v-if="chartBodygraph.dreamGates[24]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 24)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[8]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_dream_on"
                v-if="chartBodygraph.dreamGates[23]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 23)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_dream_on"
                v-if="chartBodygraph.dreamGates[22]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 22)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[2]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_dream_on"
                v-if="chartBodygraph.dreamGates[21]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 21)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[5]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_dream_on"
                v-if="chartBodygraph.dreamGates[20]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 20)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <!-- <g
                id="gate_20_57_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['20-57']"
                @click="
                  openTooltipDialog(chartBodygraph.dreamChannels['20-57'])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '20-57'
                  )
                }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g> -->
              <g
                id="gate_19_dream_on"
                v-if="chartBodygraph.dreamGates[19]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 19)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[1]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_dream_on"
                v-if="chartBodygraph.dreamGates[18]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 18)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[4]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_dream_on"
                v-if="chartBodygraph.dreamGates[17]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 17)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[8]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_dream_on"
                v-if="chartBodygraph.dreamGates[16]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 16)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_dream_on"
                v-if="chartBodygraph.dreamGates[15]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 15)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[6]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <!-- <g
                id="gate_15_05_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['5-15']"
                @click="openTooltipDialog(chartBodygraph.dreamChannels['5-15'])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '5-15'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g> -->
              <g
                id="gate_14_dream_on"
                v-if="chartBodygraph.dreamGates[14]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 14)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_dream_on"
                v-if="chartBodygraph.dreamGates[13]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 13)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[6]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_dream_on"
                v-if="chartBodygraph.dreamGates[12]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 12)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_dream_on"
                v-if="chartBodygraph.dreamGates[11]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 11)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[8]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_dream_on"
                v-if="chartBodygraph.dreamGates[10]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 10)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[6]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_dream_on"
                v-if="chartBodygraph.dreamGates[9]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 9)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_dream_on"
                v-if="chartBodygraph.dreamGates[8]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 8)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[7]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <!-- <g
                id="gate_08_01_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['1-8']"
                @click="openTooltipDialog(chartBodygraph.dreamChannels['1-8'])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '1-8'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g> -->
              <g
                id="gate_07_melancholy_on"
                v-if="chartBodygraph.dreamGates[7]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 7)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_melancholy_on"
                v-if="chartBodygraph.dreamGates[6]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 6)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_dream_on"
                v-if="chartBodygraph.dreamGates[5]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 5)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <!-- <g
                id="gate_05_15_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['5-15']"
                @click="openTooltipDialog(chartBodygraph.dreamChannels['5-15'])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '5-15'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g> -->
              <g
                id="gate_04_dream_on"
                v-if="chartBodygraph.dreamGates[4]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 4)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[8]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_dream_on"
                v-if="chartBodygraph.dreamGates[3]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 3)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[3]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_dream_on"
                v-if="chartBodygraph.dreamGates[2]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 2)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[6]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_dream_on"
                v-if="chartBodygraph.dreamGates[1]"
                @click="openTooltipDialog(chartBodygraph.dreamGates[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraph.dreamGateIds, 1)
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-if="!chartBodygraph.dreamCenters[6]"
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
              <!-- <g
                id="gate_01_08_dream_channel_on"
                v-if="chartBodygraph.dreamChannels['1-8']"
                @click="openTooltipDialog(chartBodygraph.dreamChannels['1-8'])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraph.dreamChannelIds,
                    '1-8'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g> -->
            </g>
            <g
              id="layer5-6"
              v-if="isLinesGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active isLinesGates Only"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 64)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    64
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 63)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    63
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 62)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    62
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 61)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    61
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 60)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    60
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 59)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    59
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 58)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    58
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 57)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    57
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 56)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    56
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 55)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    55
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 54)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    54
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 53)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    53
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 52)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    52
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 51)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    51
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 50)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    50
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 49)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    49
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 48)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    48
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 47)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[47])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    47
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 46)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    46
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 45)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    45
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 44)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    44
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 43)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    43
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 42)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    42
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 41)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    41
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 40)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    40
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 39)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    39
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 38)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    38
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 37)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    37
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 36)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    36
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 35)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    35
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 34)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    34
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 33)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    33
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 32)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    32
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 31)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    31
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 30)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    30
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 29)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    29
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 28)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    28
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 27)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[27])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    27
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 26)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    26
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 25)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    25
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 24)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    24
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 23)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    23
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 22)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    22
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 21)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    21
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 20)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    20
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 19)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    19
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 18)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    18
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 17)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    17
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 16)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    16
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 15)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    15
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 14)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    14
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 13)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    13
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 12)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    12
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 11)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    11
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 10)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    10
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 9)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    9
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 8)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    8
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 7)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    7
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 6)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    6
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 5)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    5
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 4)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    4
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 3)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    3
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 2)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    2
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_lines_on"
                v-if="isDefinedObj(chartBodygraph.lines[tabAddInfo].gates, 1)"
                @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.lines[tabAddInfo].gates,
                    1
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5-7"
              v-if="isCircuitGates"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active isCircuitGates Only"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    64
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[64])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    64
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_64_47_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '47-64'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['47-64'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '47-64'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    63
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[63])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    63
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_63_04_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '4-63'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['4-63'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '4-63'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    62
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[62])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    62
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_62_17_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '17-62'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['17-62'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '17-62'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    61
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[61])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    61
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_61_24_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '24-61'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['24-61'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '24-61'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    60
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[60])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    60
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_60_03_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '3-60'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['3-60'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '3-60'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    59
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[59])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    59
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_59_06_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '6-59'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['6-59'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '6-59'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    58
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[58])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    58
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_58_18_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '18-58'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['18-58'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '18-58'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    57
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[57])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    57
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_57_10_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-57'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-57'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-57'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_57_20_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '20-57'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['20-57'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '20-57'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_57_34_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '20-34'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['20-34'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '20-34'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    56
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[56])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    56
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_56_11_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '11-56'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['11-56'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '11-56'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    55
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[55])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    55
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_55_39_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '39-55'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['39-55'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '39-55'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    54
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[54])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    54
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_54_32_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '32-54'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['32-54'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '32-54'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    53
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[53])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    53
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_53_42_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '42-53'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['42-53'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '42-53'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    52
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[52])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    52
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_52_09_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '9-52'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['9-52'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '9-52'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    51
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[51])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    51
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_51_25_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '25-51'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['25-51'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '25-51'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    50
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[50])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    50
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_50_27_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '27-50'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['27-50'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '27-50'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    49
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[49])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    49
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_49_19_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '19-49'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['19-49'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '19-49'
                  )
                }"
                styl
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    48
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[48])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    48
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_48_16_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '16-48'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['16-48'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '16-48'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    47
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[47])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    47
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_47_64_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '47-64'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['47-64'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '47-64'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    46
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[46])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    46
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_46_29_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '29-46'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['29-46'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '29-46'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    45
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[45])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    45
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_45_21_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '21-45'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['21-45'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '21-45'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    44
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[44])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    44
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_44_26_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '26-44'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['26-44'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '26-44'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    43
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[43])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    43
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_43_23_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '23-43'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['23-43'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '23-43'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    42
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[42])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    42
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_42_53_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '42-53'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['42-53'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '42-53'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    41
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[41])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    41
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_41_30_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '30-41'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['30-41'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '30-41'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    40
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[40])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    40
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_40_37_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '37-40'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['37-40'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '37-40'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    39
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[39])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    39
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_39_55_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '39-55'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['39-55'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '39-55'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    38
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[38])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    38
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_38_28_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '28-38'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['28-38'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '28-38'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    37
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[37])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    37
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_37_40_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '37-40'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['37-40'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '37-40'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    36
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[36])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    36
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_36_35_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '35-36'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['35-36'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '35-36'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    35
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[35])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    35
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_35_36_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '35-36'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['35-36'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '35-36'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    34
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[34])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    34
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_34_57_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '34-57'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['34-57'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '34-57'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_34_10_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-34'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-34'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-34'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_34_20_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '20-34'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['20-34'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '20-34'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    33
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[33])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    33
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_33_13_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '13-33'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['13-33'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '13-33'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    32
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[32])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    32
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_32_54_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '32-54'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['32-54'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '32-54'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    31
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[31])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    31
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_31_07_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '7-31'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['7-31'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '7-31'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    30
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[30])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    30
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_30_41_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '30-41'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['30-41'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '30-41'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    29
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[29])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    29
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_29_46_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '29-46'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['29-46'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '29-46'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    28
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[28])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    28
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_28_38_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '28-38'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['28-38'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '28-38'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_50_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '27-50'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['27-50'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '27-50'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    26
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[26])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    26
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_26_44_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '26-44'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['26-44'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '26-44'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    25
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[25])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    25
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_25_51_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '25-51'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['25-51'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '25-51'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    24
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[24])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    24
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_24_61_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '24-61'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['24-61'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '24-61'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    23
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[23])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    23
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_23_43_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '23-43'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['23-43'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '23-43'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    22
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[22])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    22
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_22_12_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '12-22'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['12-22'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '12-22'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    21
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[21])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    21
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_21_45_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '21-45'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['21-45'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '21-45'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    20
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[20])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    20
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_20_10_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-20'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-20'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-20'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_20_34_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-34'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-34'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-34'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_20_57_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-57'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-57'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-57'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    19
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[19])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    19
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_19_49_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '19-49'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['19-49'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '19-49'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    18
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[18])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    18
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_18_58_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '18-58'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['18-58'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '18-58'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    17
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[17])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    17
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_17_62_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '17-62'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['17-62'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '17-62'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    16
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[16])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    16
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_16_48_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '16-48'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['16-48'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '16-48'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    15
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[15])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    15
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_15_05_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '5-15'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['5-15'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '5-15'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    14
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[14])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    14
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_14_02_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '2-14'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['2-14'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '2-14'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    13
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[13])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    13
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_13_33_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '13-33'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['13-33'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '13-33'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    12
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[12])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    12
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_12_22_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '12-22'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['12-22'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '12-22'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    11
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[11])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    11
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_11_56_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '11-56'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['11-56'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '11-56'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    10
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[10])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    10
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_20_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-20'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-20'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-20'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_34_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-34'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-34'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-34'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_57_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-57'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['10-57'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '10-57'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    9
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[9])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    9
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_09_52_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '9-52'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['9-52'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '9-52'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    8
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[8])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    8
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_08_01_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '1-8'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['1-8'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '1-8'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    7
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[7])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    7
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_07_31_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '7-31'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['7-31'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '7-31'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    6
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[6])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    6
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_06_59_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '6-59'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['6-59'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '6-59'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    5
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[5])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    5
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_05_15_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '5-15'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['5-15'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '5-15'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    4
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[4])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    4
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_04_63_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '4-63'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['4-63'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '4-63'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    3
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[3])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    3
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_03_50_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '3-60'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['3-60'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '3-60'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    2
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[2])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    2
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_02_14_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '2-14'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['2-14'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '2-14'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_circuit_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    1
                  )
                "
                @click="openTooltipDialog(chartBodygraph.gatesExt[1])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].gates,
                    1
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
              <g
                id="gate_01_08_circuit_channel_on"
                v-if="
                  isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '1-8'
                  )
                "
                @click="openTooltipDialog(chartBodygraph.channels2['1-8'])"
                v-bind:class="{
                  opacity1: isDefinedObj(
                    chartBodygraph.circuitGroups[tabAddInfo].channels,
                    '1-8'
                  )
                }"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  style="fill:#fff343;opacity:0.5"
                  r="15"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
          </svg>
        </v-col>
        <v-col
          v-if="
            !isHarmoniousGates &&
              !isLoveGates &&
              !isFearsGates &&
              !isMelancholyGates &&
              !isDreamGates &&
              !isLinesGates &&
              !isCircuitGates
          "
          cols="12"
          sm="12"
          md="3"
          class="col-xs bodygraph-desc add-info-block-wrap"
        >
          <div style="padding-left:9px">
            <BtnThemeSwitcher />
          </div>
          <v-card
            @click="
              openCustomTooltipDialog(
                {
                  title: getMessage('dialog_how_to_decipher_title'),
                  description_new: getMessage('dialog_how_to_decipher')
                },
                {
                  text: getMessage('btn_how_to_decipher_title'),
                  url: getMessage('btn_how_to_decipher_url_title'),
                  btn: getMessage('btn_how_to_decipher')
                }
              )
            "
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top: 10px;"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>{{ firstname }}</span>
                Как расшифровать
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(User.chartBodygraph['type'])"
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top: 10px;"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Ваш Тип</span>
                {{ chartBodygraph.type.title }}
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(User.chartBodygraph['profile'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span style="float:left">
                  Профиль
                </span>
                <span class="bodygraph-profiles">
                  <span class="bodygraph-profile1 bodygraph-profile-num">
                    &nbsp;&nbsp;&nbsp;&nbsp;{{
                      chartBodygraph.profile.details[0].id
                    }} </span
                  >/<span class="bodygraph-profile2 bodygraph-profile-num">
                    {{ chartBodygraph.profile.details[1].id }}
                  </span>
                </span>
                <span class="bodygraph-profile1">
                  {{ chartBodygraph.profile.details[0].title }}
                </span>
                —
                <span class="bodygraph-profile2">
                  {{ chartBodygraph.profile.details[1].title }}
                </span>
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(User.chartBodygraph['authority'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Внутренний Авторитет</span>
                {{ chartBodygraph.authority.title }}
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(User.chartBodygraph['strategy'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Внешняя Стратегия</span>
                {{ chartBodygraph.strategy.title }}
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(User.chartBodygraph['falseself'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Ложное Я</span>
                {{ chartBodygraph.falseself.title }}
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(User.chartBodygraph['sign'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Подпись</span>
                {{ chartBodygraph.sign.title }}
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(User.chartBodygraph['definition'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Определенность</span>
                {{ chartBodygraph.definition.title }}
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            v-if="extTips"
            @click="openTooltipDialog(User.chartBodygraph['quarter'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span class="bodygraph-inline">
                  Четверть
                </span>
                <span class="bodygraph-mainquarter">
                  {{ chartBodygraph.quarter.title }}
                </span>
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="
              openCustomTooltipDialog(User.chartBodygraph['cross'], {
                text: getMessage('tooltip_cross'),
                url: getMessage('url_tooltip_cross'),
                btn: getMessage('btn_tooltip_cross')
              })
            "
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Ваш крест</span>
                {{ chartBodygraph.angle.title }}
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openMoneyTooltipDialog(User.chartBodygraph['money'])"
            :style="{ marginTop: '10px' }"
            class="mx-auto no-desktop custom-expansion-panel"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Деньги</span>
                Как зарабатывать
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-expansion-panels class="nodisplay add-info-block">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>
                  <span>Деньги</span>
                  Как зарабатывать
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="add-info-block-head-desc">
                  <h3 v-if="!User.isLogged" class="tooltip-inner-header-top">
                    Как зарабатывать?
                  </h3>
                  <h3 v-else class="tooltip-inner-header-top">
                    Партнерская программа
                  </h3>
                  <div v-html="getTooltipsDescMsg('money', 1)"></div>
                  <div
                    v-if="isVisibleTooltipBtn"
                    class="reg-go"
                    style="min-height:40px"
                  >
                    <div
                      v-if="User.isLogged"
                      style="text-align:center;margin-top:25px"
                    >
                      <v-btn
                        v-if="User.user_products_id"
                        @click="
                          shareReport(
                            User.user_products_id,
                            User.is_user_product_hash
                          )
                        "
                        class="tooltip-btn tooltip-btn-bg"
                        style="background:linear-gradient(90deg,#00ba88,#196ad6);margin-right:4px;width:48%"
                        >Начать</v-btn
                      >
                      <v-btn
                        v-else
                        @click="
                          shareReport(User.reportid, User.is_user_product_hash)
                        "
                        class="tooltip-btn tooltip-btn-bg"
                        style="background:linear-gradient(90deg,#00ba88,#196ad6);margin-right:4px;width:48%"
                        >Начать</v-btn
                      >
                      <v-btn
                        href="/#/profile"
                        target="_new"
                        class="tooltip-btn tooltip-btn-bg"
                        style="width:48%"
                        >Кабинет</v-btn
                      >
                    </div>
                    <div v-else style="text-align: center">
                      <div v-html="RegGoTextBeforeBtn" class="reg-go-div"></div>
                      <v-btn
                        @click="clickTooltipBtnBG(extTips)"
                        v-html="getMessage(btnTooltipName)"
                        class="tooltip-btn tooltip-btn-bg min-width-170"
                      ></v-btn>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <div v-if="!User.isLogged" class="no-desktop" style="padding:10px">
            <div
              style="display:flex;justify-content:center;align-items:center;opacity:0.7"
            >
              <v-icon style="color:#378fb5;font-size:60px">
                mdi-alert-circle-outline
              </v-icon>
              <span style="padding-left:7px;font-size:15.6px">
                Расширенная информация ниже<br />будет доступна после
                регистрации.
              </span>
            </div>
          </div> -->
          <v-card
            @click="
              openMultiTooltipDialog(
                convertObjToArray(User.chartBodygraph['channels2']),
                getMessage('help_analitycs_channels')
              )
            "
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top:10px"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Каналы</span>
                Отличительные способности
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openPHSTooltipDialog()"
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top:10px"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>PHS</span>
                Система здоровья
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="
              openMultiTooltipDialog(
                convertObjToArray(User.chartBodygraph['returns']),
                getMessage('help_analitycs_returns')
              )
            "
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top:10px"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Важные даты</span>
                В жизни
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <div
            class="no-desktop"
            style="text-align:center;margin-bottom:5px;padding-bottom:10px"
          >
            <div class="reg-go-div" style="padding:7px 0;text-align:center">
              Закажите полную расшифровку своей карты в PDF формате
            </div>
            <v-btn
              :href="getBuyPDFLink()"
              target="_blank"
              class="tooltip-btn tooltip-btn-bg  min-width-170"
              dark
            >
              Смотреть
            </v-btn>
          </div>
          <span
            @click="
              openCustomTooltipDialog(
                {
                  title: getMessage('dialog_how_to_decipher_title'),
                  description_new: getMessage('dialog_how_to_decipher')
                },
                {
                  text: getMessage('btn_how_to_decipher_title'),
                  url: getMessage('btn_how_to_decipher_url_title'),
                  btn: getMessage('btn_how_to_decipher')
                }
              )
            "
            class="desc-tooltip no-mobile"
            style="margin-left:130px;margin-top:21px"
          >
            <v-icon>
              mdi-help-circle-outline
            </v-icon>
          </span>
          <h3 class="no-mobile">
            <span class="nowrap-text-overflow">
              {{ firstname }}
            </span>
            Нажмите сюда
          </h3>
          <span
            @click="openTooltipDialog(User.chartBodygraph['type'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 80px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span>
              Ваш Тип
            </span>
            {{ chartBodygraph.authority.short_title }}<br />
            {{ chartBodygraph.type.short_title }}
          </h3>
          <span
            @click="openTooltipDialog(User.chartBodygraph['profile'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 85px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span style="float:left">
              Профиль
            </span>
            <span class="bodygraph-profiles">
              <span class="bodygraph-profile1 bodygraph-profile-num">
                &nbsp;&nbsp;&nbsp;&nbsp;{{
                  chartBodygraph.profile.details[0].id
                }} </span
              >/<span class="bodygraph-profile2 bodygraph-profile-num">
                {{ chartBodygraph.profile.details[1].id }}
              </span>
            </span>
            <span class="bodygraph-profile1">
              {{ chartBodygraph.profile.details[0].title }}
            </span>
            —
            <span class="bodygraph-profile2">
              {{ chartBodygraph.profile.details[1].title }}
            </span>
          </h3>
          <span
            @click="openTooltipDialog(User.chartBodygraph['authority'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 205px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span>
              Внутренний Авторитет
            </span>
            {{ chartBodygraph.authority.title }}
          </h3>
          <span
            @click="openTooltipDialog(User.chartBodygraph['strategy'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 180px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span>
              Внешняя Стратегия
            </span>
            {{ chartBodygraph.strategy.title }}
          </h3>
          <span
            @click="openTooltipDialog(User.chartBodygraph['falseself'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 93px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span>
              Ложное Я
            </span>
            {{ chartBodygraph.falseself.title }}
          </h3>
          <span
            @click="openTooltipDialog(User.chartBodygraph['sign'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 87px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span class="bodygraph-inline">
              Подпись
            </span>
            {{ chartBodygraph.sign.title }}
          </h3>
          <span
            @click="openTooltipDialog(User.chartBodygraph['definition'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 150px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span>
              Определенность
            </span>
            {{ chartBodygraph.definition.title }}
          </h3>
          <span
            v-if="extTips"
            @click="openTooltipDialog(User.chartBodygraph['quarter'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 87px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 v-if="extTips" @click="clearTooltips" class="no-mobile">
            <span class="bodygraph-inline">
              Четверть
            </span>
            <span class="bodygraph-mainquarter">
              {{ chartBodygraph.quarter.title }}
            </span>
          </h3>
          <span
            @click="
              openCustomTooltipDialog(User.chartBodygraph['cross'], {
                text: getMessage('tooltip_cross'),
                url: getMessage('url_tooltip_cross'),
                btn: getMessage('btn_tooltip_cross')
              })
            "
            class="desc-tooltip no-mobile"
            style="margin-left: 97px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span>Ваш крест</span>
            {{ chartBodygraph.angle.title }}
          </h3>
          <span
            @click="openMoneyTooltipDialog(User.chartBodygraph['money'])"
            class="desc-tooltip no-mobile"
            style="margin-left: 70px"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
          <h3 @click="clearTooltips" class="no-mobile">
            <span>Деньги</span>
            Как зарабатывать
          </h3>
        </v-col>
        <v-col
          v-if="
            !isHarmoniousGates &&
              !isLoveGates &&
              !isFearsGates &&
              !isMelancholyGates &&
              !isDreamGates &&
              !isLinesGates &&
              !isCircuitGates
          "
          sm="12"
          md="12"
          class="mob-nopadding addition-info no-mobile"
        >
          <v-expansion-panels
            class="expansion-panels-user-stat v-expansion-custom-panels d-none d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <div>
                  <span
                    class="line-2"
                    style="display:block;font-size:1.25rem;color:#378fb5;text-align:center"
                  >
                    Расширенная информация
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-tabs
                  v-model="tabAddInfo"
                  class="additional-info-block-tabs"
                  background-color="transparent"
                  color="basil"
                  grow
                  show-arrows
                >
                  <v-tab v-if="chartBodygraph.channels2">
                    Каналы
                  </v-tab>
                  <!-- <v-tab
                      v-if="
                        chartBodygraph.melancholyGates ||
                          chartBodygraph.fearGates ||
                          chartBodygraph.loveGates
                      "
                      
                    >
                      Л.С.М.
                    </v-tab> -->
                  <!-- <v-tab
                    v-if="
                      chartBodygraph.food ||
                        chartBodygraph.motivation ||
                        chartBodygraph.vision ||
                        chartBodygraph.environment ||
                        chartBodygraph.mind
                    "
                  >
                    PHS
                  </v-tab -->
                  <v-tab v-if="chartBodygraph.food">
                    Питание
                  </v-tab>
                  <v-tab v-if="chartBodygraph.environment">
                    Окружение
                  </v-tab>
                  <v-tab v-if="chartBodygraph.vision">
                    Видение
                  </v-tab>
                  <v-tab v-if="chartBodygraph.motivation">
                    Мотивация
                  </v-tab>
                  <v-tab v-if="chartBodygraph.mind">
                    Ум
                  </v-tab>
                  <v-tab v-if="chartBodygraph.returns">Возвраты</v-tab>
                  <v-tabs-items v-model="tabAddInfo" touchless>
                    <v-tab-item>
                      <!-- <div
                        v-if="!User.isLogged && !showTooltipsForGuests"
                        class="separate-add-info-header2"
                      >
                        Эта информация будет доступна после
                        <a @click="dialogconfirm()">регистрации</a>.
                      </div>
                      <div
                        v-if="
                          User.isLogged ||
                            (!User.isLogged && showTooltipsForGuests)
                        "
                      > -->
                      <div>
                        <!-- <span
                          @click="
                            openTooltipDialog(
                              convertToTooltipDialogObj(
                                Messages['title_help_analitycs_channels'],
                                Messages['help_analitycs_channels']
                              )
                            )
                          "
                          class="desc-tooltip2"
                          style="margin-left: 90px;padding-top:15px"
                        >
                          <v-icon>mdi-help-circle-outline</v-icon>
                        </span>
                        <h2
                          class="expansion-panels-user-header"
                          style="padding-top:25px"
                        >
                          Каналы:
                        </h2> -->
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px; padding: 0 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="getMessage('help_analitycs_channels')"
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="User.isLogged">
                          <v-card
                            v-for="(item, i) in chartBodygraph.channels2"
                            :key="i"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ item.title }}
                                </v-list-item-title>
                                <div v-html="item.tooltip"></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon
                            style="color:#378fb5;font-size:60px!important"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                    <!-- <v-tab-item>
                        <div
                          v-if="!User.isLogged && !showTooltipsForGuests"
                          class="separate-add-info-header2"
                        >
                          Эта информация будет доступна после
                          <a @click="dialogconfirm()">регистрации</a>.
                        </div>
                        <div
                          v-if="
                            User.isLogged ||
                              (!User.isLogged && showTooltipsForGuests)
                          "
                        >
                          <span
                            v-tooltip="{
                              content: getHelpTooltip(
                                'help_analitycs_love_gates'
                              ),
                              show: showTooltips(
                                'BG_',
                                'help_analitycs_love_gates'
                              ),
                              trigger: 'manual'
                            }"
                            class="desc-tooltip2"
                            style="margin-left: 150px;"
                          >
                            <v-icon>mdi-help-circle-outline</v-icon>
                          </span>
                          <h2 class="expansion-panels-user-header">
                            Ворота любви:
                          </h2>
                          <v-card
                            v-for="(item, i) in chartBodygraph.loveGates"
                            :key="i"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ item.title }}
                                </v-list-item-title>
                                <div v-html="item.tooltip"></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <span
                            v-tooltip="{
                              content: getHelpTooltip('help_analitycs_fears'),
                              show: showTooltips('BG_', 'help_analitycs_fears'),
                              trigger: 'manual'
                            }"
                            class="desc-tooltip2"
                            style="margin-left:150px"
                          >
                            <v-icon>mdi-help-circle-outline</v-icon>
                          </span>
                          <h2 class="expansion-panels-user-header">
                            Ворота страха:
                          </h2>
                          <v-card
                            v-for="(item, i) in chartBodygraph.fearGates"
                            :key="'A' + i"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ item.title }}
                                </v-list-item-title>
                                <div v-html="item.tooltip"></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <span
                            v-tooltip="{
                              content: getHelpTooltip(
                                'help_analitycs_melancholy'
                              ),
                              show: showTooltips(
                                'BG_',
                                'help_analitycs_melancholy'
                              ),
                              trigger: 'manual'
                            }"
                            class="desc-tooltip2"
                            style="margin-left: 130px;"
                          >
                            <v-icon>mdi-help-circle-outline</v-icon>
                          </span>
                          <h2 class="expansion-panels-user-header">
                            Меланхолия:
                          </h2>
                          <v-card
                            v-for="(item, i) in chartBodygraph.melancholyGates"
                            :key="'B' + i"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ item.title }}
                                </v-list-item-title>
                                <div v-html="item.tooltip"></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                      </v-tab-item> -->
                    <v-tab-item>
                      <div>
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px; padding: 0 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="getMessage('help_analitycs_food')"
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="User.isLogged">
                          <v-card
                            v-if="chartBodygraph.environment.color"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.food.color.title }}
                                </v-list-item-title>
                                <div
                                  v-html="chartBodygraph.food.color.tooltip"
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.environment.arrow"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.food.arrow.title }}
                                </v-list-item-title>
                                <div
                                  v-html="chartBodygraph.food.arrow.tooltip"
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.environment.tone"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.food.tone.title }}
                                </v-list-item-title>
                                <div
                                  v-html="chartBodygraph.food.tone.tooltip"
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon
                            style="color:#378fb5;font-size:60px!important"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div>
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px; padding: 0 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="
                                  getMessage('help_analitycs_environment')
                                "
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="User.isLogged">
                          <v-card
                            v-if="chartBodygraph.environment.color"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.environment.color.title }}
                                </v-list-item-title>
                                <div
                                  v-html="
                                    chartBodygraph.environment.color.tooltip
                                  "
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.environment.tone"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.environment.tone.title }}
                                </v-list-item-title>
                                <div
                                  v-html="
                                    chartBodygraph.environment.tone.tooltip
                                  "
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.environment.arrow"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.environment.arrow.title }}
                                </v-list-item-title>
                                <div
                                  v-html="
                                    chartBodygraph.environment.arrow.tooltip
                                  "
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon
                            style="color:#378fb5;font-size:60px!important"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div>
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px; padding: 0 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="getMessage('help_analitycs_vision')"
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="User.isLogged">
                          <v-card
                            v-if="chartBodygraph.vision.color"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.vision.color.title }}
                                </v-list-item-title>
                                <div
                                  v-html="chartBodygraph.vision.color.tooltip"
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.vision.arrow"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.vision.arrow.title }}
                                </v-list-item-title>
                                <div
                                  v-html="chartBodygraph.vision.arrow.tooltip"
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.vision.tone"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.vision.tone.title }}
                                </v-list-item-title>
                                <div
                                  v-html="chartBodygraph.vision.tone.tooltip"
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon
                            style="color:#378fb5;font-size:60px!important"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div>
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px; padding: 0 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="getMessage('help_analitycs_motivation')"
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="User.isLogged">
                          <v-card
                            v-if="chartBodygraph.motivation.tone"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.motivation.tone.title }}
                                </v-list-item-title>
                                <div
                                  v-html="
                                    chartBodygraph.motivation.tone.tooltip
                                  "
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.motivation.color"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.motivation.color.title }}
                                </v-list-item-title>
                                <div
                                  v-html="
                                    chartBodygraph.motivation.color.tooltip
                                  "
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                          <v-card
                            v-if="chartBodygraph.motivation.arrow"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.motivation.arrow.title }}
                                </v-list-item-title>
                                <div
                                  v-html="
                                    chartBodygraph.motivation.arrow.tooltip
                                  "
                                ></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon
                            style="color:#378fb5;font-size:60px!important"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div>
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px; padding: 0 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="getMessage('help_analitycs_mind')"
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="User.isLogged">
                          <v-card
                            v-if="chartBodygraph.mind"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ chartBodygraph.mind.title }}
                                </v-list-item-title>
                                <div v-html="chartBodygraph.mind.tooltip"></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon
                            style="color:#378fb5;font-size:60px!important"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div>
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px; padding: 0 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="getMessage('help_analitycs_returns')"
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="User.isLogged">
                          <v-card
                            v-for="(item, i) in chartBodygraph.returns"
                            :key="i"
                            class="mx-auto margin-bottom-8 bg-dark-lighten"
                            outlined
                          >
                            <v-list-item three-line>
                              <v-list-item-content
                                class="expansion-panels-channels-cont"
                              >
                                <v-list-item-title
                                  class="headline mb-1 padding-bottom-15"
                                  style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                                >
                                  {{ item.title }}
                                </v-list-item-title>
                                <div class="add-info-bottom-block-h3">
                                  Ваш возраст:
                                  <span style="color: #0288D1">
                                    {{ item.age }}
                                  </span>
                                </div>
                                <div class="add-info-bottom-block-h3">
                                  Дата:
                                  <span style="color: #0288D1">
                                    {{ item.dateUTC }}
                                  </span>
                                </div>
                                <div v-html="item.tooltip"></div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </div>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon
                            style="color:#378fb5;font-size:60px!important"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <div
            v-if="isVisibleTooltipBtn && User.isLogged"
            class="reg-go3 no-mobile"
            style="background-color:transparent;position:initial"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div> -->
          <div
            class="reg-go3 no-mobile margin-bottom-0"
            style="background-color:transparent;position:initial"
          >
            <div class="reg-go-div2" style="text-align:center">
              Закажите полную расшифровку своей карты в PDF формате
            </div>
            <v-btn
              :href="getBuyPDFLink()"
              target="_blank"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            >
              Смотреть
            </v-btn>
          </div>
        </v-col>
        <v-col
          v-if="isHarmoniousGates"
          cols="12"
          class="text-center desc-padding-lt-rt-80 font-size-16"
        >
          <v-expansion-panels
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center;margin-left:24px"
                >
                  Гармоничные ворота
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-html="getMessage('help_analitycs_harmonious_gates')"
                  class="info-of-add-map-blocks"
                ></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-if="isVisibleTooltipBtn"
            class="reg-go3"
            style="background-color:transparent!important;position:relative"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div>
        </v-col>
        <v-col
          v-if="isLoveGates"
          cols="12"
          class="text-center desc-padding-lt-rt-80 font-size-16"
        >
          <v-expansion-panels
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center;margin-left:24px"
                >
                  Ворота любви
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-html="getMessage('help_analitycs_love_gates')"
                  class="info-of-add-map-blocks"
                ></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-if="isVisibleTooltipBtn"
            class="reg-go3"
            style="background-color:transparent!important;position:relative"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div>
        </v-col>
        <v-col
          v-if="isFearsGates"
          cols="12"
          class="text-center desc-padding-lt-rt-80 font-size-16"
        >
          <v-expansion-panels
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center;margin-left:24px"
                >
                  Ворота Страха
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-html="getMessage('help_analitycs_fears')"
                  class="info-of-add-map-blocks"
                ></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-if="isVisibleTooltipBtn"
            class="reg-go3"
            style="background-color:transparent!important;position:relative"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div>
        </v-col>
        <v-col
          v-if="isMelancholyGates"
          cols="12"
          class="text-center desc-padding-lt-rt-80 font-size-16"
        >
          <v-expansion-panels
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center;margin-left:24px"
                >
                  Меланхолия
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-html="getMessage('help_analitycs_melancholy')"
                  class="info-of-add-map-blocks"
                ></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-if="isVisibleTooltipBtn"
            class="reg-go3"
            style="background-color:transparent!important;position:relative"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div>
        </v-col>
        <v-col
          v-if="isDreamGates"
          cols="12"
          class="text-center desc-padding-lt-rt-80 font-size-16"
        >
          <v-expansion-panels
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center;margin-left:24px"
                >
                  Карта сна
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-html="getMessage('help_analitycs_dream')"
                  class="info-of-add-map-blocks"
                ></div>
                <!-- <v-col
                  cols="12"
                  class="text-center flex-space-evenly padding-top-0 padding-bottom-15 margin-top-25 margin-bottom-30"
                >
                  <v-btn
                    @click="goToPayAddTooltips()"
                    class="my-prof-controls-flex"
                    color="success"
                    fab
                    small
                    icon
                    dark
                  >
                    <v-icon style="font-size:26px">mdi-plus-box</v-icon>
                    <span class="d-flex" style="font-size:11px;margin-top:5px">
                      наложить
                    </span>
                  </v-btn>
                  <v-btn
                    @click="goToPayAddTooltips()"
                    class="my-prof-controls-flex"
                    color="primary"
                    fab
                    small
                    icon
                    dark
                  >
                    <v-icon style="font-size:26px">mdi-close-box</v-icon>
                    <span class="d-flex" style="font-size:11px;margin-top:5px">
                      сбросить
                    </span>
                  </v-btn>
                </v-col> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-if="isVisibleTooltipBtn"
            class="reg-go3"
            style="background-color:transparent!important;position:relative"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div>
        </v-col>
        <v-col
          v-if="isLinesGates"
          sm="12"
          md="12"
          class="mob-nopadding addition-info"
        >
          <v-expansion-panels
            v-model="panel"
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center;margin-left:24px"
                >
                  Линии
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-tabs
                  v-model="tabAddInfo"
                  class="custom-user-tabs"
                  centered
                  background-color="transparent"
                  color="basil"
                  grow
                  show-arrows
                  stacked
                >
                  <v-tab
                    v-for="(item, idx) in chartBodygraph.lines"
                    :key="idx"
                    exact
                  >
                    {{ item.id }}
                  </v-tab>
                </v-tabs>
                <v-window v-model="tabAddInfo">
                  <v-window-item
                    v-for="(item, idx) in chartBodygraph.lines"
                    :key="idx"
                  >
                    <v-card
                      class="mx-auto margin-bottom-8 bg-dark-lighten"
                      outlined
                    >
                      <v-card-text v-if="item.tooltip">
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px;padding: 0px 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="getMessage('help_analitycs_lines')"
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card
                          v-if="User.isLogged"
                          class="mx-auto margin-bottom-8 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                              <div class="gates-link-line">
                                Список Ворот с {{ item.title[0] }} линией:
                                <span v-for="(gate, f) in item.gates" :key="f">
                                  {{ gate
                                  }}<span v-if="item.gates.length - 1 !== f"
                                    >,</span
                                  >
                                </span>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                        <div v-else class="addition-info-guest-alert">
                          <v-icon style="color:#378fb5;font-size:60px">
                            mdi-alert-circle-outline
                          </v-icon>
                          <span>
                            Более подробная информация будет доступна после
                            регистрации.
                          </span>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-window-item>
                </v-window>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center"
                >
                  {{ chartBodygraph.gatelinesExalted.title }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels
                  class="add-info-block"
                  style="margin-bottom: 10px;padding: 0px 1px"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3>
                        <span>Что это?</span>
                        Подробнее...
                      </h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        v-html="getMessage('bg_what_is_exaltation')"
                        class="add-info-block-head-desc"
                      ></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-card
                  v-if="User.isLogged"
                  class="mx-auto margin-bottom-8 bg-dark-lighten"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content class="expansion-panels-channels-cont">
                      <v-list-item-title
                        class="headline mb-1 padding-bottom-15"
                        style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                      >
                        {{ chartBodygraph.gatelinesExalted.title }}
                      </v-list-item-title>
                      <div
                        v-html="chartBodygraph.gatelinesExalted.tooltip"
                      ></div>
                      <div class="gates-link-line">
                        Список Ворот в экзальтации:
                        <span
                          v-for="(gate, f) in chartBodygraph.gatelinesExalted
                            .gatelines"
                          :key="f"
                        >
                          {{ gate
                          }}<span
                            v-if="
                              chartBodygraph.gatelinesExalted.gatelines.length -
                                1 !==
                                f
                            "
                            >,</span
                          >
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <div v-else class="addition-info-guest-alert">
                  <v-icon style="color:#378fb5;font-size:60px">
                    mdi-alert-circle-outline
                  </v-icon>
                  <span style="opacity:0.7">
                    Более подробная информация будет доступна после регистрации.
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center"
                >
                  {{ chartBodygraph.gatelinesDetriment.title }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels
                  class="add-info-block"
                  style="margin-bottom: 10px;padding: 0px 1px"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3>
                        <span>Что это?</span>
                        Подробнее...
                      </h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        v-html="getMessage('bg_what_is_falling')"
                        class="add-info-block-head-desc"
                      ></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-card
                  v-if="User.isLogged"
                  class="mx-auto margin-bottom-8 bg-dark-lighten"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content class="expansion-panels-channels-cont">
                      <v-list-item-title
                        class="headline mb-1 padding-bottom-15"
                        style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                      >
                        {{ chartBodygraph.gatelinesDetriment.title }}
                      </v-list-item-title>
                      <div
                        v-html="chartBodygraph.gatelinesDetriment.tooltip"
                      ></div>
                      <div class="gates-link-line">
                        Список Ворот в падении:
                        <span
                          v-for="(gate, f) in chartBodygraph.gatelinesDetriment
                            .gatelines"
                          :key="f"
                        >
                          {{ gate
                          }}<span
                            v-if="
                              chartBodygraph.gatelinesDetriment.gatelines
                                .length -
                                1 !==
                                f
                            "
                            >,</span
                          >
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <div v-else class="addition-info-guest-alert">
                  <v-icon style="color:#378fb5;font-size:60px">
                    mdi-alert-circle-outline
                  </v-icon>
                  <span style="opacity:0.7">
                    Более подробная информация будет доступна после регистрации.
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-if="isVisibleTooltipBtn"
            class="reg-go3"
            style="background-color:transparent!important;position:relative"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div>
        </v-col>
        <v-col
          v-if="isCircuitGates"
          sm="12"
          md="12"
          class="mob-nopadding addition-info"
        >
          <v-expansion-panels
            v-model="panel"
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels d-sm-block"
            style="margin-top:20px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center;margin-left:24px"
                >
                  Группы контуров
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-tabs
                  v-model="tabAddInfo"
                  class="custom-user-tabs"
                  centered
                  background-color="transparent"
                  color="basil"
                  grow
                  show-arrows
                  stacked
                >
                  <v-tab
                    v-for="(item, idx) in chartBodygraph.circuitGroups"
                    :key="idx"
                    exact
                  >
                    {{ item.id }}
                  </v-tab>
                </v-tabs>
                <v-window v-model="tabAddInfo">
                  <v-window-item
                    v-for="(item, idx) in chartBodygraph.circuitGroups"
                    :key="idx"
                  >
                    <v-card
                      class="mx-auto margin-bottom-8 bg-dark-lighten"
                      outlined
                    >
                      <v-card-text v-if="item.tooltip">
                        <v-expansion-panels
                          class="add-info-block"
                          style="margin-bottom: 10px;padding: 0px 1px"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3>
                                <span>Что это?</span>
                                Подробнее...
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                v-html="
                                  getMessage('help_analitycs_countour_groups')
                                "
                                class="add-info-block-head-desc"
                              ></div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card
                          v-if="User.isLogged"
                          class="mx-auto margin-bottom-8 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                              <div>
                                <span style="opacity:0.7"
                                  >Количество Каналов:</span
                                >
                                <span style="color: #0288D1">
                                  {{ item.channelsCount }}
                                </span>
                                <span
                                  v-if="item.channels.length"
                                  style="color: #0288D1"
                                >
                                  (<span
                                    v-for="(channel, j) in item.channels"
                                    :key="j"
                                    >{{ channel
                                    }}<span
                                      v-if="j !== item.channels.length - 1"
                                      style="padding-right:5px"
                                      >,</span
                                    ></span
                                  >)
                                </span>
                              </div>
                              <div>
                                <span style="opacity:0.7"
                                  >Количество Ворот:</span
                                >
                                <span style="color: #0288D1">
                                  {{ item.gatesCount }}
                                </span>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-window-item>
                </v-window>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-if="isVisibleTooltipBtn"
            class="reg-go3"
            style="background-color:transparent!important;position:relative"
          >
            <div
              v-html="RegGoTextBeforeBtn"
              class="reg-go-div"
              style="text-align:center"
            ></div>
            <v-btn
              @click="clickTooltipBtnBG(extTips)"
              v-html="this.getMessage(btnTooltipName)"
              class="tooltip-btn tooltip-btn-bg min-width-135"
              style="margin-bottom:5px"
            ></v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row
        @click="clearTooltips"
        v-else
        class="d-flex align-center justify-center"
        style="height: 150px;"
      >
        <v-col
          sm="12"
          md="12"
          class="text-center display-0-24-no-line-h margin-top-20"
        >
          Чтобы создать Рейв-карту, введите свои данные во вкладке «СОЗДАТЬ
          КАРТУ»
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!chartBodygraph">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="text-center">
          <v-btn
            v-if="!chartBodygraph"
            @click="$router.push('/')"
            class="mx-2 hd-gradient margin-bottom-25"
            large
            color="primary accent-4"
            style="font-size: 0.85rem"
          >
            Ввести данные&nbsp;&nbsp;<v-icon small>
              mdi-calendar-account-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-dialog
      v-model="dialogProfile"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card style="padding: 0 10px !important">
        <v-card-title>
          <span class="headline" style="font-size: 1.4rem !important">
            {{ dialogProfileName }}
          </span>
          <v-btn @click="dialogProfile = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                v-if="$store.state.conf.ConfApp.tooltip_button_type === 6"
                cols="12"
                class="
                    no-top-bottom-padding display-0-24-no-line-h color-wthite-a60
                  "
              >
                <div class="tooltip-inner-divider">
                  <div class="tooltip-inner-offer-buy">
                    <div class="tooltip-inner-expansion-panel-header">
                      <div
                        @click="$offerTooltipsReverse()"
                        style="display: flex; justify-content: space-between"
                      >
                        <span>Открыть доступ</span>
                        <i
                          v-if="User.isTooltipsOfferbuy"
                          class="v-icon notranslate mdi mdi-chevron-up"
                        ></i>
                        <i
                          v-else
                          class="v-icon notranslate mdi mdi-chevron-down"
                        ></i>
                      </div>
                      <div v-if="User.isTooltipsOfferbuy">
                        <div v-html="getMessage('tooltip_buy_full')"></div>
                        <div style="display:flex;justify-content:center">
                          <v-btn
                            @click="clickTooltipBtnBG(`ext_tips`)"
                            class="tooltip-inner-btn-offer"
                            small
                            text
                            >Подробнее</v-btn
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                v-html="dialogMsg"
                cols="12"
                class="
                    no-top-bottom-padding display-0-24-no-line-h color-wthite-a60
                  "
                style="font-size:0.97rem !important"
              ></v-col>
              <div v-if="isVisibleTooltipBtn" class="reg-go2">
                <div
                  v-html="RegGoTextBeforeBtn"
                  class="reg-go-div"
                  style="padding:7px 0;text-align:center"
                ></div>
                <v-btn
                  @click="clickTooltipBtnBG(extTips)"
                  v-html="getMessage(btnTooltipName)"
                  color="btn-share accent-4 min-width-170"
                  class="hd-gradient"
                  dark
                ></v-btn>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="firstbgdialog"
      max-width="630px"
      scrollable
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="firstbgdialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row class="d-margin-0">
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <div class="no-mobile">
                  <img
                    src="https://static.human-design.space/widget/images/bgs/info.gif"
                    height="389"
                  />
                  <!-- <iframe
                      v-if="$store.state.ConfApp.theme === 'dark'"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/wkjARO2dGok?autoplay=1&controls=0&fs=0"
                      title="Human Design"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <iframe
                      v-else
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/xgYsk20d0m8?autoplay=1&controls=0&fs=0"
                      title="Human Design"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe> -->
                </div>
                <div class="no-desktop">
                  <img
                    src="https://static.human-design.space/widget/images/bgs/info.gif"
                    height="289"
                  />
                  <!-- <iframe
                      v-if="$store.state.ConfApp.theme === 'dark'"
                      width="auto"
                      height="240"
                      src="https://www.youtube.com/embed/wkjARO2dGok?autoplay=1&controls=0&fs=0"
                      title="Human Design"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <iframe
                      v-else
                      width="auto"
                      height="240"
                      src="https://www.youtube.com/embed/xgYsk20d0m8?autoplay=1&controls=0&fs=0"
                      title="Human Design"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe> -->
                </div>
                <br />
                <span class="mob-report-dailog-text">
                  Система расчетов интерактивна. Нажмите на любой элемент
                  Рейв-карты и вы увидите его значение. Расширенная информация
                  доступна после регистрации.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="firstbgdialog = false"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
              >
                Дальше&nbsp;<v-icon>mdi-button-pointer</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="zoomReportDialog"
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="zoomReportDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <v-icon style="font-size:6rem">
                  mdi-gesture-pinch
                </v-icon>
                <br />
                <span class="mob-report-dailog-text">
                  Чтобы увеличить или уменьшить изображение,<br />
                  используйте жест двумя пальцами.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="zoomReportDialog = false"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Понятно</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="notAccessDialog" max-width="900px" style="z-index: 9999">
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="notAccessDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <div v-html="this.getMessage('dialog_txt_notaccess')"></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52 accss-go"
            >
              <v-btn
                @click="clickTooltipBtnBG(`ext_tips`, true)"
                class="tooltip-btn tooltip-btn-bg min-width-135"
                style="margin-bottom:5px;height:36px"
                small
              >
                Подробнее
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoginDialog :showDialog.sync="showDialog" @close="closeDialog" />
    <TooltipDialog
      :showDialog.sync="showTooltipDialog"
      :obj.sync="objTooltipDialog"
      :isCustomBottom="isCustomBottom"
      :isMoneyBottom="isMoneyBottom"
      :objCustom="objCustom"
      @closeTooltipDialog="closeAllTooltipDialogs"
    />
    <MultiTooltipDialog
      :showDialog.sync="showMultiTooltipDialog"
      :arr.sync="arrMultiTooltipDialog"
      :explanatoryText.sync="explanatoryText"
      @closeMultiTooltipDialog="closeAllTooltipDialogs"
    />
    <PHSTooltipDialog
      :showDialog.sync="showPHSTooltipDialog"
      @closePHSDialog="closePHSTooltipDialog"
    />
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import TooltipDialog from "@/components/dialogs/TooltipDialog.vue";
import MultiTooltipDialog from "@/components/dialogs/MultiTooltipDialog.vue";
import PHSTooltipDialog from "@/components/dialogs/PHSTooltipDialog.vue";
import BtnThemeSwitcher from "@/components/parts/BtnThemeSwitcher";

export default {
  name: "BodyGraph",
  components: {
    LoginDialog,
    TooltipDialog,
    MultiTooltipDialog,
    PHSTooltipDialog,
    BtnThemeSwitcher
  },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  data() {
    return {
      tabAddInfo: 0,
      panel: [0],
      firstbgdialog: false,
      zoomReportDialog: false,
      notAccessDialog: false,
      dialogProfileName: "",
      dialogProfile: false,
      dialogMsg: "",
      dialogChannels: false,
      // RegGoTextBeforeBtn: '',
      // btnTooltipName: '',
      isVisibleTooltipBtn: true,
      isDesignToggle: false,
      isPersonToggle: false,
      helpInfoDialog: false,
      helpInfoText: null,
      // chartBodygraph: null,
      // toolTips: {},
      channelsActivated: [],
      gatesUndefinedCentres: [],
      showDialog: false,
      objTooltipDialog: {},
      showTooltipDialog: false,
      isCustomBottom: false,
      isMoneyBottom: false,
      objCustom: null,
      arrMultiTooltipDialog: [],
      explanatoryText: "",
      showMultiTooltipDialog: false,
      showPHSTooltipDialog: false
    };
  },
  computed: {
    ...mapState(["dateTime"]),
    ...mapState({
      User: (state) => state.account.User,
      Profile: (state) => state.account.Profile,
      ConfApp: (state) => state.conf.ConfApp,
      Messages: (state) => state.conf.Messages,
      DarkTheme: (state) => state.conf.DarkTheme
    }),
    heightVideo() {
      let height;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          height = 220;
          break;
        case "sm":
          height = 350;
          break;
        case "md":
          height = 350;
          break;
        case "lg":
          height = 350;
          break;
        case "xl":
          height = 350;
          break;
      }
      return height;
    },
    toolTips: {
      get() {
        return this.$store.state.account.User.toolTips;
      },
      set(value) {
        this.$store.commit("account/UPDATE_TOOLTIPS", value);
      }
    },
    chartBodygraph: {
      get() {
        return this.$store.state.account.User.chartBodygraph;
      },
      set(value) {
        this.$store.commit("get_body_graph", value);
      }
    },
    gexagrammColor() {
      return this.$store.state.account.User.gexagrammColor;
    },
    firstname() {
      return this.$store.state.account.User.bodyGraphName;
    },
    age() {
      return this.$store.state.account.User.bodyGraphAge;
    },
    RegGoTextBeforeBtn: {
      get() {
        return this.$store.state.account.User.RegGoTextBeforeBtn;
      },
      set(v) {
        this.$store.commit("account/update_reg_go_text_before_btn_1", v);
      }
    },
    btnTooltipName: {
      get() {
        return this.$store.state.account.User.btnTooltipName;
      },
      set(v) {
        this.$store.commit("account/update_btn_tooltip_name_1", v);
      }
    },
    extTips() {
      return this.$store.state.conf.ConfApp.isBuyedProTooltips;
    },
    isHarmoniousGates() {
      return this.$store.state.account.User.isHarmoniousGates;
    },
    isLoveGates() {
      return this.$store.state.account.User.isLoveGates;
    },
    isFearsGates() {
      return this.$store.state.account.User.isFearsGates;
    },
    isMelancholyGates() {
      return this.$store.state.account.User.isMelancholyGates;
    },
    isDreamGates() {
      return this.$store.state.account.User.isDreamGates;
    },
    isLinesGates() {
      return this.$store.state.account.User.isLinesGates;
    },
    isCircuitGates() {
      return this.$store.state.account.User.isCircuitGates;
    },
    showTooltipsForGuests() {
      return this.$store.getters["conf/show_tooltips_for_guests"];
    }
  },
  beforeMount() {
    window.clickTooltipBtn = this.clickTooltipBtnBG;
    window.clearTooltips = this.clearTooltips;
    window.goTooltips = this.goTooltips;
    window.offerTooltipsBuyBG = this.$offerTooltipsBuy;
    window.offerTooltipsReverse = this.$offerTooltipsReverse;
    window.callNotAccessDialog = this.callNotAccessDialog;
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "UPDATE_ZOOM_RP_DIALOG":
          this.zoomReportDialog = true;
          break;
        case "UPDATE_IS_STORED":
          this.clearTooltips();
          break;
        case "UPDATE_CLEAR_TOOLTIPS":
          this.clearTooltips();
          break;
        case "СALL_MAGICK_ZOOM":
          /* setTimeout(() => {
              this.$callPanZoom('#svg1582')
            }, 10) */
          break;
        case "account/call_rave_report_by_id":
          console.log("22222!!!!****");
          if (this.User.reportid) {
            console.log("333333!!!!****");
            this.$store.dispatch("account/getRaveReportById", {
              id: this.User.reportid
            });
          }
          break;
      }
    });
    // this.chartBodygraph = this.User.chartBodygraph
    // this.toolTips = this.User.toolTips
    this.initActiveGatesInUndefinedCentres();
    setTimeout(() => {
      // this.$callPanZoom('#svg1582')
      this.matchChannels();
    }, 10);
    // console.log("this.$device: ", this.$device);
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    // Events for init bodygraph
    initActiveGatesInUndefinedCentres() {
      if (this.chartBodygraph) {
        // Search activated gates in undefined centers
        this.gatesUndefinedCentres = [];
        this.chartBodygraph.centersUndefinedAndOpen.forEach((center) => {
          this.$store.state.account.centerToGates[center].forEach((gate) => {
            Object.values(this.chartBodygraph.gates).forEach((acgates) => {
              if (+acgates === +gate) this.gatesUndefinedCentres.push(gate);
            });
          });
        });
        // console.log(this.gatesUndefinedCentres)
        // For mobile devices scroll down
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
          this.scrollToTop();
        }, 500); /* 500ms throttle */
      }
    },
    /* scrollToSvg() {
        document.getElementsByClassName('svg_bg')[0].scrollTo({
          left: document.getElementById('svg1582').scrollWidth / 5,
          behavior: 'smooth'
        })
      }, */
    clickTooltipBtnBG(extTips, isInfoBlockExtTips) {
      const SHOP_URL =
        extTips === "ext_tips"
          ? this.$store.state.conf.ConfApp.tooltips_buy_full_url
          : extTips
          ? this.$store.state.conf.ConfApp.tooltips_shop_url
          : this.$store.state.conf.ConfApp.tooltips_sale_shop_url;
      switch (this.$store.state.conf.ConfApp.tooltip_button_type) {
        case 1:
          if (isInfoBlockExtTips) {
            const URL = SHOP_URL + "&tag=r" + this.User.reportid;
            window.open(URL, "_blank");
          } else {
            this.dialogconfirm();
          }
          break;
        case 2:
          this.$router.push("/profile");
          break;
        case 3: {
          // this.$router.push("/products");
          const URL3 = this.$store.state.conf.ConfApp.pdfs_shop_url;
          window.open(URL3, "_blank");
          break;
        }
        case 4: {
          const URL4 = SHOP_URL + "&tag=r" + this.User.reportid;
          window.open(URL4, "_blank");
          break;
        }
        case 6: {
          // Здесь сейчас показываются всегда FULL-подсказки
          const SHOP_URL6 = this.$store.state.conf.ConfApp
            .tooltips_buy_full_url;
          const URL6 = SHOP_URL6 + "&tag=r" + this.User.reportid;
          window.open(URL6, "_blank");
          break;
        }
        case 7:
          // FULL-подсказки выключены, предложение включить
          this.$router.push("/profile");
          break;
        default:
          this.$store.commit(
            "account/set_partner_info_text",
            this.getMessage("partners")
          );
          this.$store.commit("account/call_partner_dialog");
          break;
      }
    },
    // Defined Context Gates Points
    isDefinedTogglePointGate(num) {
      if (
        this.isDefinedObj(
          this.chartBodygraph.gatesActivatedAtPersonalityAndDesign,
          num
        )
      ) {
        return false;
      } else if (
        this.isDefinedObj(
          this.chartBodygraph.gatesActivatedAtDesignOnly,
          num
        ) &&
        this.isDesignToggle
      ) {
        return true;
      } else if (
        this.isDefinedObj(
          this.chartBodygraph.gatesActivatedAtPersonalityOnly,
          num
        ) &&
        this.isPersonToggle
      ) {
        return true;
      }
    },
    // Search activated gates in hovered center
    isGatesActiveForCenter(center) {
      if (this.chartBodygraph) {
        this.$store.state.account.centerToGates[center].forEach((gate) => {
          Object.values(this.chartBodygraph.gates).forEach((acgates) => {
            if (+acgates === +gate) {
              // this.$store.commit('PUSH_TO_GATE_LINES_CENTER_HOVER', gate)
              this.searchAcPlanets(gate); // Call to activated gates in planet context
            }
          });
        });
        // console.log(this.gateLinesCenterHover)
      }
    },
    // Search activated planets for gate
    // Key is gate number
    // versum = 1 is Person; versum = 2 is Design; versum = 3 is Both;
    searchAcPlanets(key, key2, key3) {
      if (key in this.chartBodygraph.gatesExtended) {
        if (this.chartBodygraph.gatesExtended[key].versum === 1) {
          this.chartBodygraph.gatesExtended[key].planets.forEach((planet) => {
            this.$store.commit("account/set_is_planet_per", { planet, num: 1 });
          });
        } else if (this.chartBodygraph.gatesExtended[key].versum === 2) {
          this.chartBodygraph.gatesExtended[key].planets.forEach((planet) => {
            this.$store.commit("account/set_is_planet_des", { planet, num: 1 });
          });
        } else {
          this.chartBodygraph.gatesExtended[key].planets.forEach((planet) => {
            if (
              +key === +this.chartBodygraph.mandalas[0][planet].gate &&
              +key === +this.chartBodygraph.mandalas[1][planet].gate
            ) {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            } else if (+key === +this.chartBodygraph.mandalas[0][planet].gate) {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
            } else if (+key === +this.chartBodygraph.mandalas[1][planet].gate) {
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            } else {
              // console.log('Data Error! Check chartBodygraph')
            }
          });
        }
      } else {
        // console.log('Property is undefined')
      }
      /* console.log(
        "this.chartBodygraph: ",
        this.chartBodygraph.gatesExtended,
        key,
        key2,
        key3
      ); */
      if (key2 in this.chartBodygraph.gatesExtended) {
        if (this.chartBodygraph.gatesExtended[key2].versum === 1) {
          this.chartBodygraph.gatesExtended[key2].planets.forEach((planet) => {
            this.$store.commit("account/set_is_planet_per", { planet, num: 1 });
          });
        } else if (this.chartBodygraph.gatesExtended[key2].versum === 2) {
          this.chartBodygraph.gatesExtended[key2].planets.forEach((planet) => {
            this.$store.commit("account/set_is_planet_des", { planet, num: 1 });
          });
        } else {
          this.chartBodygraph.gatesExtended[key2].planets.forEach((planet) => {
            if (
              +key2 === +this.chartBodygraph.mandalas[0][planet].gate &&
              +key2 === +this.chartBodygraph.mandalas[1][planet].gate
            ) {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            } else if (
              +key2 === +this.chartBodygraph.mandalas[0][planet].gate
            ) {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
            } else if (
              +key2 === +this.chartBodygraph.mandalas[1][planet].gate
            ) {
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            } else {
              // console.log('Data Error! Check chartBodygraph')
            }
          });
        }
      }
      if (key3 in this.chartBodygraph.gatesExtended) {
        if (this.chartBodygraph.gatesExtended[key3].versum === 1) {
          this.chartBodygraph.gatesExtended[key3].planets.forEach((planet) => {
            this.$store.commit("account/set_is_planet_per", { planet, num: 1 });
          });
        } else if (this.chartBodygraph.gatesExtended[key3].versum === 2) {
          this.chartBodygraph.gatesExtended[key3].planets.forEach((planet) => {
            this.$store.commit("account/set_is_planet_des", { planet, num: 1 });
          });
        } else {
          this.chartBodygraph.gatesExtended[key3].planets.forEach((planet) => {
            if (
              +key3 === +this.chartBodygraph.mandalas[0][planet].gate &&
              +key3 === +this.chartBodygraph.mandalas[1][planet].gate
            ) {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            } else if (
              +key3 === +this.chartBodygraph.mandalas[0][planet].gate
            ) {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
            } else if (
              +key3 === +this.chartBodygraph.mandalas[1][planet].gate
            ) {
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            } else {
              // console.log('Data Error! Check chartBodygraph')
            }
          });
        }
      }
    },
    // Search The All Same Gates
    searchAllAcPlanets(num) {
      this.searchAcPlanets(num);
      this.searchGateAcInCenter(num);
    },
    // Search & Return Activated Gate (planet hover) in Center
    searchGateAcInCenter(num) {
      if (this.chartBodygraph) {
        for (const center in this.$store.state.account.centerToGates) {
          this.$store.state.account.centerToGates[center].forEach((gate) => {
            if (+num === +gate) {
              this.$store.commit("account/push_to_gate_in_center_hover", gate);
              // console.log(gate, center, this.$store.state.account.gateInCenterHover)
            }
          });
        }
      }
    },
    hideDesign() {
      this.clearTooltips();
      this.isDesignToggle = !this.isDesignToggle;
      this.isPersonToggle =
        this.isDesignToggle && this.isPersonToggle
          ? !this.isPersonToggle
          : this.isPersonToggle;
      this.parsePairChannels();
      this.hideChannellessCenters(
        this.chartBodygraph.gatesActivatedAtPersonalityOnly,
        this.chartBodygraph.gatesActivatedAtPersonalityAndDesign,
        this.channelsActivated
      );
    },
    hidePerson() {
      this.clearTooltips();
      this.isPersonToggle = !this.isPersonToggle;
      this.isDesignToggle =
        this.isDesignToggle && this.isPersonToggle
          ? !this.isDesignToggle
          : this.isDesignToggle;
      this.parsePairChannels();
      this.hideChannellessCenters(
        this.chartBodygraph.gatesActivatedAtDesignOnly,
        this.chartBodygraph.gatesActivatedAtPersonalityAndDesign,
        this.channelsActivated
      );
    },
    parsePairChannels() {
      this.channelsActivated = [];
      let g1, g2;
      this.chartBodygraph.channels.forEach((ch) => {
        if (ch.length === 2) {
          g1 = +ch.slice(0, 1);
          g2 = +ch.slice(1);
          this.channelsActivated.push({ gate1: g1, gate2: g2 });
        } else if (ch.length === 3) {
          g1 = +ch.slice(0, 1);
          g2 = +ch.slice(1);
          this.channelsActivated.push({ gate1: g1, gate2: g2 });
        } else if (ch.length === 4) {
          g1 = +ch.slice(0, 2);
          g2 = +ch.slice(2);
          this.channelsActivated.push({ gate1: g1, gate2: g2 });
        }
      });
    },
    hideChannellessCenters(gates, gatesboth, channels) {
      if (!this.isDesignToggle && !this.isPersonToggle) {
        this.recalculateActivatedCenters();
      } else {
        this.switchOffAllCenters();
      }
      for (const center in this.$store.state.account.centerToGates) {
        this.$store.state.account.centerToGates[center].forEach((gate) => {
          channels.forEach((g) => {
            if (+g.gate1 === +gate) {
              let matchingGate1 = false;
              let matchingGate2 = false;
              gates.forEach((acGate) => {
                if (+acGate === +g.gate1) matchingGate1 = true;
                if (+acGate === +g.gate2) matchingGate2 = true;
              });
              gatesboth.forEach((acGateBoth) => {
                if (+acGateBoth === +g.gate1) matchingGate1 = true;
                if (+acGateBoth === +g.gate2) matchingGate2 = true;
              });
              if (matchingGate1 && matchingGate2) {
                this.$refs["center_" + center].classList.add("opacity1");
              }
            }
            if (+g.gate2 === +gate) {
              let matchingGate2 = false;
              let matchingGate1 = false;
              gates.forEach((acGate) => {
                if (+acGate === +g.gate2) matchingGate2 = true;
                if (+acGate === +g.gate1) matchingGate1 = true;
              });
              gatesboth.forEach((acGateBoth) => {
                if (+acGateBoth === +g.gate2) matchingGate2 = true;
                if (+acGateBoth === +g.gate1) matchingGate1 = true;
              });
              if (matchingGate2 && matchingGate1) {
                this.$refs["center_" + center].classList.add("opacity1");
              }
            }
          });
        });
      }
    },
    recalculateActivatedCenters() {
      for (let i = 1; i <= 9; i++) {
        if (this.isDefinedObj(this.chartBodygraph.centersDefined, i)) {
          this.$refs["center_" + i].classList.add("opacity1");
        }
      }
    },
    switchOffAllCenters() {
      for (let i = 1; i <= 9; i++) {
        this.$refs["center_" + i].classList.remove("opacity1");
      }
    },
    callNotAccessDialog() {
      this.notAccessDialog = true;
      this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
    },
    /* goToUniversalDialog() {
      this.$store.commit("conf/update_universal_modal", {
        title: `Привет, ${this.firstname}!`,
        message: this.getMessage("dialog_how_to_decipher"),
        background: "light",
        // button_name: "Понятно"
        button_name: this.getMessage("btn_how_to_decipher"),
        button_url: this.getMessage("btn_how_to_decipher_title"),
        button_target: "_blank"
      });
    }, */
    goToPayAddTooltips() {
      this.$store.commit("conf/update_universal_modal", {
        message: this.getMessage("full_tooltips_text"),
        background: "light",
        // button_name: "Понятно"
        button_name: this.getMessage("full_tooltips_url"),
        button_url: this.getMessage("full_tooltips_url_title"),
        button_target: "_blank"
      });
    },
    /* goModalFullScreen(name, val) {
        this.dialogProfileName = name + ' - ' + this.chartBodygraph[val].title
        this.dialogProfile = true
        switch (this.$store.state.conf.ConfApp.tooltip_button_type) {
          case 1:
            this.dialogMsg =
              '<div class="tooltip-inner-header"></div>' +
              this.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>' +
              '<div class="tooltip-inner-header-100"></div>'
            this.RegGoTextBeforeBtn = this.getMessage('tooltip_normal')
            this.btnTooltipName = 'btn_tooltip_normal'
            return this.dialogMsg
          case 2:
            this.isVisibleTooltipBtn = false
            this.dialogMsg =
              '<div class="tooltip-inner-header"></div>' +
              this.chartBodygraph[val].description +
              '<div class="tooltip-inner-last-child"></div>'
            this.RegGoTextBeforeBtn = this.getMessage('tooltip_notconfirmed')
            return this.dialogMsg
          case 3:
            this.dialogMsg =
              '<div class="tooltip-inner-header"></div>' +
              this.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>' +
              '<div class="tooltip-inner-header-100"></div>'
            this.RegGoTextBeforeBtn = this.getMessage('tooltip_full')
            this.btnTooltipName = 'btn_tooltip_full'
            return this.dialogMsg
          case 4:
            this.dialogMsg =
              '<div class="tooltip-inner-header"></div>' +
              this.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>' +
              '<div class="tooltip-inner-header-100"></div>'
            this.RegGoTextBeforeBtn = this.getMessage('tooltip_pro')
            this.btnTooltipName = 'btn_tooltip_pro'
            return this.dialogMsg
          case 6:
            this.dialogMsg =
              '<div class="tooltip-inner-header"></div>' +
              this.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>' +
              '<div class="tooltip-inner-header-100"></div>'
            this.RegGoTextBeforeBtn = this.getMessage('tooltip_buy_full')
            this.btnTooltipName = 'btn_tooltip_buy_full'
            return this.dialogMsg
        }
      }, */
    updateFrameData() {
      this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
    },
    getBuyPDFLink() {
      if (this.User.isLogged) {
        return `https://human-design.space/rasshifrovka-vashego-bodigrafa?utm_medium=organic&utm_source=podskazka_low_regdop&rave=${this.User.ext_reference_code}#service`;
      } else {
        return `https://human-design.space/rasshifrovka-vashego-bodigrafa?utm_medium=organic&utm_source=podskazka_low_unknowndop&rave=${this.User.ext_reference_code}#service`;
      }
    },
    delayedСlearAcPlanets() {
      // Фикс затупливания очистки активных планет на iOS при закрытии подсказки
      if (this.$device.ios) {
        setTimeout(() => {
          this.clearAcPlanets();
        }, 500);
      } else {
        this.clearAcPlanets();
      }
    },
    /* getMultiTooltipsMsg(...channels) {
      const listOfChannels = channels
        .map(
          ({ id, title }) =>
            `\n<div class="multi-channel-item" onclick="goTooltips('BG_','${id}')">${title}</div>`
        )
        .join("");
      const resultTooltip =
        '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
        '<div class="multi-channel">' +
        listOfChannels +
        "</div>";
      return resultTooltip;
    }, */
    openTooltipDialog(obj) {
      this.objTooltipDialog = obj;
      this.showTooltipDialog = true;
      // рега/логин для гостей
      /* if (this.User.isLogged) {
        this.objTooltipDialog = obj;
        this.showTooltipDialog = true;
      } else {
        this.update_login_dialog(true);
      } */
    },
    openCustomTooltipDialog(obj, obj2) {
      this.objTooltipDialog = obj;
      this.showTooltipDialog = true;
      this.isCustomBottom = true;
      this.objCustom = obj2;
    },
    openMoneyTooltipDialog(obj) {
      this.objTooltipDialog = obj;
      this.showTooltipDialog = true;
      this.isMoneyBottom = true;
    },
    convertToTooltipDialogObj(title, description) {
      return { title, description };
    },
    openColorTooltipDialog(key, prop) {
      if (prop in this.User.chartBodygraph[key]) {
        const obj = {
          title: this.User.chartBodygraph[key][prop].title,
          description: this.User.chartBodygraph[key][prop].tooltip_new
        };
        this.objTooltipDialog = obj;
        this.showTooltipDialog = true;
      }
      // рега/логин для гостей
      /* if (this.User.isLogged) {
        if (prop in this.User.chartBodygraph[key]) {
          const obj = {
            title: this.User.chartBodygraph[key][prop].title,
            description: this.User.chartBodygraph[key][prop].tooltip_new
          };
          this.objTooltipDialog = obj;
          this.showTooltipDialog = true;
        }
      } else {
        this.update_login_dialog(true);
      } */
    },
    openMultiTooltipDialog(arr, explanatoryText) {
      this.arrMultiTooltipDialog = arr;
      this.explanatoryText = explanatoryText;
      this.showMultiTooltipDialog = true;
      // рега/логин для гостей
      /* if (this.User.isLogged) {
        this.arrMultiTooltipDialog = arr;
        this.explanatoryText = explanatoryText;
        this.showMultiTooltipDialog = true;
      } else {
        this.update_login_dialog(true);
      } */
    },
    openPHSTooltipDialog() {
      this.showPHSTooltipDialog = true;
    },
    dialogconfirm() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeAllTooltipDialogs() {
      this.showTooltipDialog = false;
      this.showMultiTooltipDialog = false;
      this.isCustomBottom = false;
      this.isMoneyBottom = false;
      this.objCustom = {};
    },
    closePHSTooltipDialog() {
      this.showPHSTooltipDialog = false;
    },
    getSettings() {
      if (Object.keys(this.$store.state.conf.Messages).length === 0) {
        this.$store.dispatch("conf/getSettings");
      }
    },
    shareReport(id, userProduct) {
      const data = { id, userProduct };
      this.$store.dispatch("account/shareReport", data);
    },
    convertObjToArray(obj) {
      // console.log("In Obj: ", obj);
      const arr = [];
      for (let key in obj) {
        arr.push(obj[key]);
      }
      // console.log("Out Arr: ", arr);
      return arr;
    }
  }
};
</script>

<style scoped>
.svgbg-dark {
  fill: #303030;
}
.card-md {
  max-width: 1024px !important;
  /* min-height: 712.5px; */
  background-color: #f8f8f8 !important;
}
.headline {
  font-size: 1.3rem;
  font-weight: 300 !important;
}
.link {
  cursor: pointer;
}
.desc-tooltip,
.desc-tooltip2 {
  position: absolute;
  cursor: pointer;
  z-index: 3;
}
.desc-tooltip i {
  color: #85a3b1 !important;
}
.desc-tooltip2 i {
  font-size: 18px !important;
}
.inner-html-tooltip i {
  font-size: 16px !important;
  cursor: pointer;
}
.theme--dark .inner-html-tooltip i {
  color: #fff;
  opacity: 0.7;
}
.header-name {
  margin: 0 !important;
  padding-bottom: 0 !important;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
}
.header-name2 {
  font-size: 36px;
}
.theme--dark .header-name,
.theme--dark .header-desc {
  color: #378fb5 !important;
}
.multi-channel {
  padding: 17px 12px;
}
.multi-channel-item {
  line-height: 1.8rem;
  cursor: pointer;
}
.multi-channel-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.header-desc {
  padding-left: 7px;
  line-height: 13px;
  font-size: 1.03rem;
  margin-bottom: 14px !important;
}
.bodygraph-desc-sup {
  position: absolute;
  z-index: 1;
  width: 342px;
  min-height: 615px;
}
.bodygraph-desc-sub {
  position: absolute;
  z-index: 2;
}
.bodygraph-desc h2 {
  padding-bottom: 7px;
}
.bodygraph-desc h3 {
  font-family: "Roboto", sans-serif !important;
  padding: 4px 7px 4px 7px;
  font-size: 17px;
  font-weight: 400;
  color: #85a3b1;
}
.bodygraph-desc h3 span {
  color: #378fb5;
  display: block;
  font-size: 18px;
  line-height: 22px;
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .header-name,
  .header-desc {
    text-align: center;
  }
  .bodygraph-desc h3 {
    padding: 0 !important;
  }
}
.theme--dark .reg-go {
  background-color: #133a50;
}
/* Bottom toolbar bodygraph */
.none {
  display: none;
}
/* .theme--dark .bodygraph-desc h3 span {
    color: #00aeff;
  } */
.theme--dark .v-tabs-slider-wrapper {
  color: #177a9b;
}
.theme--dark .v-card__title {
  color: #177a99;
}
/* .theme--dark .v-card {
  background-color: #0b1f2c !important;
} */
.theme--dark .bodygraph-desc h3 span.bodygraph-profile2 {
  color: #f80574;
}
/* .bodygraph-desc h3 span.bodygraph-inline {
    display: inline-block;
  } */
.bodygraph-desc h4 {
  padding: 2px 7px;
}
.bodygraph-profiles {
  font-size: 24px;
  line-height: 24px;
}
.bodygraph-desc h3 span.bodygraph-profile1 {
  color: #00ba88;
  display: inline-block;
}
.bodygraph-desc h3 span.bodygraph-profile2 {
  color: #fe001e;
  display: inline-block;
}
.bodygraph-profile-num {
  font-size: 22px !important;
}
.bodygraph-desc h3 span.bodygraph-mainquarter {
  color: #00ba88;
  display: inline-block;
}
.bodygraph-desc i.v-icon {
  font-size: 16px;
}
/* Animated opacity */
.opacity1,
.opacity5 {
  display: block !important;
  opacity: 1 !important;
  animation: show 1.5s 1;
}
.opacity2 {
  opacity: 1 !important;
  animation: show 0.5s 1;
}
.opacity3 {
  display: block !important;
  /* opacity: 0.6 !important; */
  opacity: 0 !important;
  animation: show 1s 1;
}
.opacity4 {
  display: block !important;
  visibility: hidden !important;
  opacity: 0 !important;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}
.gates-center-hover {
  fill: #c443ae !important;
}
.gates {
  /* stroke: #986423; */
  stroke-width: 3 !important;
  stroke-opacity: 0;
  transition: stroke-opacity 0.7s;
}
.gates:hover {
  stroke-opacity: 1;
}
.des_toggle {
  stroke: #ff001d;
}
.des_toggle_cir {
  fill: #ff001d;
}
.theme--dark .des_toggle {
  stroke: #f80574;
}
.theme--dark .des_toggle_cir {
  fill: #f80574;
}
.des_toggle_quest {
  stroke: #85a3b1;
  fill: transparent;
}
.des_quest_quest {
  color: #85a3b1;
  fill: #85a3b1;
}
.per_toggle_quest {
  stroke: #85a3b1;
  fill: transparent;
}
.per_quest_quest {
  color: #85a3b1;
  fill: #85a3b1;
}
.per_toggle {
  stroke: #3e4448;
}
.per_toggle_cir {
  fill: #3e4448;
}
.theme--dark .per_toggle {
  stroke: #00aeff;
}
.theme--dark .per_toggle_cir {
  fill: #00aeff;
}
.not_active_des_toggle,
.not_active_per_toggle {
  stroke-opacity: 0.37;
  transition: stroke-opacity 0.25s;
}
.not_active_des {
  opacity: 0.37;
  cx: 75 !important;
  transition: opacity 0.25s;
}
.not_active_per {
  opacity: 0.37;
  cx: 796.5 !important;
  transition: opacity 0.25s;
}
.both-arrow {
  display: none;
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .both-arrow {
    display: block;
    position: absolute;
    width: 24px;
    margin-left: -12px;
    left: 50%;
  }
  /* .svg_bg {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .svg_bg svg {
      min-width: 600px;
    } */
  .bodygraph-desc-sup {
    min-height: 560px;
  }
  .card-md {
    /* min-height: 890px; */
  }
}
@media screen and (max-width: 420px) {
  .bodygraph-sm {
    max-width: 319px;
  }
  .theme--light .col-xs {
    flex-basis: auto;
    background-color: #f8f8f8;
  }
  .theme--dark .col-xs {
    flex-basis: auto;
    background-color: #102c3e;
  }
  .card-md {
    /* min-height: 890px; */
  }
  /* .bodygraph .v-card__text {
      line-height: 0.475rem !important;
    } */
}
@media screen and (min-width: 768px) {
  .card-md {
    min-width: 744px;
  }
  .theme--light .col-xs {
    background-color: #f8f8f8;
  }
  .theme--dark .col-xs {
    background-color: #102c3e;
  }
}
@media screen and (max-width: 768px) {
  .svg_bg {
    margin-top: 12px !important;
  }
  .bodygraph-desc {
    padding: 0 16px !important;
  }
  .svg_bg {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1024px) {
  .card-md {
    min-width: 876px;
  }
  .nopadding {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1100px) {
  .card-md {
    min-width: 965px;
  }
  .container {
    max-width: 995px;
  }
}
.col-bg-person {
  fill: rgb(62, 68, 72);
}
.col-strk-person {
  stroke: rgb(62, 68, 72) !important;
}
.col-bg-des {
  fill: rgb(255, 0, 29);
}
.col-strk-des {
  stroke: rgb(255, 0, 29);
}
.theme--dark .v-messages__message,
.theme--dark .v-counter,
.theme--dark .v-label,
.v-tab.v-tab {
  color: #177a99 !important;
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #85a3b1 !important;
}
.theme--dark .v-input:not(.v-input--is-disabled) input,
.theme--dark .v-select .v-select__selections {
  color: #85a3b1 !important;
}
.theme--dark.mdi-menu-down::before {
  color: #628b9e !important;
}
.theme--dark .col-bg-person {
  fill: #00aeff;
}
.theme--dark .col-bg-des {
  fill: #f80574;
}
.theme--dark .col-strk-person {
  stroke: #00aeff !important;
}
.theme--dark .col-strk-des {
  stroke: #f80574;
}
.theme--dark .svg_bg,
.theme--dark .card-md,
.theme--dark .card-md-small {
  background-color: #102c3e !important;
}
.theme--dark .planet-des {
  fill: #f80574;
  stroke: #f80574;
}
.svg_bg {
  background-color: #f8f8f8;
}
.theme--dark #rect1853 {
  fill: #102c3e;
  background: radial-gradient(#01506b, #102c3e);
}
#rect1853 {
  fill: rgb(248, 248, 248);
}
#g8332,
#g8416 {
  opacity: 0;
  transition: 1s;
  animation: show 1s 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
/* Planets & number rect */
.planet-per,
.planet-des {
  cursor: pointer;
  fill: rgb(62, 68, 72);
  stroke: rgb(62, 68, 72);
  stroke-width: 2;
  stroke-opacity: 0;
  transition: stroke-opacity 0.7s;
}
.planet-des {
  fill: rgb(255, 0, 29);
  stroke: rgb(255, 0, 29);
}
.planet-substrate-rect {
  width: 30px;
  height: 30px;
  fill: transparent;
  stroke: transparent;
}
.design-rect {
  cursor: pointer;
  width: 65px;
  height: 33px;
  fill: transparent;
  stroke: transparent;
}
.design-rect_quest {
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: transparent;
  stroke: transparent;
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .sun-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 60;
  }
  .sun-num-des {
    height: 40px !important;
    y: 65;
  }
  .earth-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 111;
  }
  .earth-num-des {
    height: 40px !important;
    y: 116;
  }
  .moon-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 161;
  }
  .moon-num-des {
    height: 40px !important;
    y: 166;
  }
  .north-node-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 214;
  }
  .north-node-num-des {
    height: 40px !important;
    y: 218;
  }
  .south-node-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 271;
  }
  .south-node-num-des {
    height: 40px !important;
    y: 268;
  }
  .mercury-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 316;
  }
  .mercury-num-des {
    height: 40px !important;
    y: 319;
  }
  .venus-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 368;
  }
  .venus-num-des {
    height: 40px !important;
    y: 370;
  }
  .mars-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 420;
  }
  .mars-num-des {
    height: 40px !important;
    y: 420;
  }
  .jupiter-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 470;
  }
  .jupiter-num-des {
    height: 40px !important;
    y: 472;
  }
  .saturn-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 521;
  }
  .saturn-num-des {
    height: 40px !important;
    y: 524;
  }
  .uranus-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 572;
  }
  .uranus-num-des {
    height: 40px !important;
    y: 576;
  }
  .neptune-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 621;
  }
  .neptune-num-des {
    height: 40px !important;
    y: 626;
  }
  .pluto-des {
    width: 50px !important;
    height: 50px !important;
    x: -15;
    y: 672;
  }
  .pluto-num-des {
    height: 40px !important;
    y: 675;
  }
  .sun-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 56;
  }
  .sun-num-per {
    height: 40px !important;
    y: 83;
  }
  .earth-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 106;
  }
  .earth-num-per {
    height: 40px !important;
    y: 134;
  }
  .moon-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 180;
  }
  .moon-num-per {
    height: 40px !important;
    y: 184;
  }
  .north-node-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 232;
  }
  .north-node-num-per {
    height: 40px !important;
    y: 234;
  }
  .south-node-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 281;
  }
  .south-node-num-per {
    height: 40px !important;
    y: 285;
  }
  .mercury-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 310;
  }
  .mercury-num-per {
    height: 40px !important;
    y: 337;
  }
  .venus-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 361;
  }
  .venus-num-per {
    height: 40px !important;
    y: 388;
  }
  .mars-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 415;
  }
  .mars-num-per {
    height: 40px !important;
    y: 440;
  }
  .jupiter-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 462;
  }
  .jupiter-num-per {
    height: 40px !important;
    y: 489;
  }
  .saturn-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 516;
  }
  .saturn-num-per {
    height: 40px !important;
    y: 542;
  }
  .uranus-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 566;
  }
  .uranus-num-per {
    height: 40px !important;
    y: 592;
  }
  .neptune-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 615;
  }
  .neptune-num-per {
    height: 40px !important;
    y: 643;
  }
  .pluto-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 690;
  }
  .pluto-num-per {
    height: 40px !important;
    y: 695;
  }
}
.theme--dark .planet-per {
  fill: #00aeff;
  stroke: #00aeff;
}
.planet-per:hover,
.planet-des:hover {
  stroke-opacity: 1;
}
.planet-number-per,
.planet-number-des {
  cursor: pointer;
  width: 48px;
  height: 29px;
  stroke: #3e4448;
  fill: transparent;
  stroke-width: 2;
  opacity: 0;
}
.theme--dark .planet-number-per {
  stroke: #00aeff;
}
.planet-number-des {
  stroke: rgb(255, 0, 29);
}
.theme--dark .planet-number-des {
  stroke: #f80574;
}
.line-arrow {
  fill: #3e4448;
}
.theme--dark .line-arrow {
  fill: #00aeff;
}
/* Centres & Gate lines */
#center_head,
#center_ajna,
#center_throat,
#center_ego,
#center_emo,
#center_self,
#center_sacral,
#center_splenic,
#center_root,
#path357,
#path359,
#circle373,
#path371,
#path361,
#path369,
#path365,
#path363,
#path375 {
  stroke: #49c4be;
  stroke-width: 4 !important;
  stroke-opacity: 0;
  transition: stroke-opacity 0.7s;
}
.theme--dark #center_head,
.theme--dark #center_ajna,
.theme--dark #center_throat,
.theme--dark #center_ego,
.theme--dark #center_emo,
.theme--dark #center_self,
.theme--dark #center_sacral,
.theme--dark #center_splenic,
.theme--dark #center_root,
.theme--dark #path357,
.theme--dark #path359,
.theme--dark #circle373,
.theme--dark #path371,
.theme--dark #path361,
.theme--dark #path369,
.theme--dark #path365,
.theme--dark #path363,
.theme--dark #path375 {
  stroke: #00adfe;
}
#center_throat {
  stroke: rgb(161, 117, 218);
}
#center_head:hover,
#center_ajna:hover,
#center_throat:hover,
#center_ego:hover,
#center_sacral:hover,
#center_emo:hover,
#center_self:hover,
#center_splenic:hover,
#center_root:hover,
#path357:hover,
#path359:hover,
#circle373:hover,
#path371:hover,
#path361:hover,
#path369:hover,
#path365:hover,
#path363:hover,
#path375:hover {
  stroke-opacity: 0.9;
}
.theme--dark #circle1820,
.theme--dark #path1818,
.theme--dark #path1814,
.theme--dark #path1814,
.theme--dark #path1822,
.theme--dark #path1806,
.theme--dark #path1808,
.theme--dark #path1810,
.theme--dark #path1812,
.theme--dark #path1816 {
  fill: #378fb5 !important;
  fill-opacity: 0.95 !important;
}
#gate_01_design,
#gate_01_person,
#gate_01_all_both,
#gate_01_08_channel,
#gate_01_harmonious_person,
#gate_01_dream_gates,
#gate_01_love_person,
#gate_01_fears_person,
#gate_01_melancholy_person,
#gate_02_design,
#gate_02_person,
#gate_02_all_both,
#gate_02_harmonious_person,
#gate_02_dream_gates,
#gate_02_love_person,
#gate_02_fears_person,
#gate_02_melancholy_person,
#gate_02_14_channel,
#gate_03_design,
#gate_03_person,
#gate_03_all_both,
#gate_03_harmonious_person,
#gate_03_dream_gates,
#gate_03_love_person,
#gate_03_fears_person,
#gate_03_melancholy_person,
#gate_03_60_channel,
#gate_04_design,
#gate_04_person,
#gate_04_all_both,
#gate_04_harmonious_person,
#gate_04_dream_gates,
#gate_04_love_person,
#gate_04_fears_person,
#gate_04_melancholy_person,
#gate_04_63_channel,
#gate_05_design,
#gate_05_person,
#gate_05_all_both,
#gate_05_harmonious_person,
#gate_05_dream_gates,
#gate_05_love_person,
#gate_05_fears_person,
#gate_05_melancholy_person,
#gate_06_design,
#gate_06_person,
#gate_06_all_both,
#gate_06_harmonious_person,
#gate_06_dream_gates,
#gate_06_love_person,
#gate_06_fears_person,
#gate_06_melancholy_person,
#gate_06_59_channel,
#gate_07_design,
#gate_07_person,
#gate_07_all_both,
#gate_07_harmonious_person,
#gate_07_dream_gates,
#gate_07_love_person,
#gate_07_fears_person,
#gate_07_melancholy_person,
#gate_07_31_channel,
#gate_08_design,
#gate_08_person,
#gate_08_all_both,
#gate_08_harmonious_person,
#gate_08_dream_gates,
#gate_20_57_dream_channel,
#gate_01_08_dream_channel,
#gate_05_15_dream_channel,
#gate_27_50_dream_channel,
#gate_42_53_dream_channel,
#gate_28_38_dream_channel,
#gate_08_love_person,
#gate_08_fears_person,
#gate_08_melancholy_person,
#gate_09_design,
#gate_09_person,
#gate_09_all_both,
#gate_09_harmonious_person,
#gate_09_dream_gates,
#gate_09_love_person,
#gate_09_fears_person,
#gate_09_melancholy_person,
#gate_09_52_channel,
#gate_10_design,
#gate_10_person,
#gate_10_all_both,
#gate_10_harmonious_person,
#gate_10_dream_gates,
#gate_10_love_person,
#gate_10_fears_person,
#gate_10_melancholy_person,
#gate_10_34_channel,
#gate_10_20_channel,
#gate_10_57_channel,
#gate_10-20_10-57_channel,
#gate_10-20_20-57_channel,
#gate_10-57_20-57_channel,
#gate_10-20_10-57_20-57_channel,
#gate_10-20_10-34_channel,
#gate_10-34_20-34_channel,
#gate_10-20_20-34_channel,
#gate_10-20_10-34_20-34_channel,
#gate_20-34_20-57_channel,
#gate_20-34_34-57_channel,
#gate_20-57_34-57_channel,
#gate_20-34_20-57_34-57_channel,
#gate_10-34_10-57_channel,
#gate_10-34_34-57_channel,
#gate_10-57_34-57_channel,
#gate_10-34_10-57_34-57_channel,
#gate_10-20_34-57_channel,
#gate_10-34_20-57_channel,
#gate_10-57_20-34_channel,
#gate_10-20_10-34_10-57_channel,
#gate_10-20_10-34_20-57_channel,
#gate_10-20_10-34_34-57_channel,
#gate_10-20_10-57_20-34_channel,
#gate_10-20_10-57_34-57_channel,
#gate_10-20_20-34_20-57_channel,
#gate_10-20_20-34_34-57_channel,
#gate_10-20_20-57_34-57_channel,
#gate_10-34_10-57_20-34_channel,
#gate_10-34_10-57_20-57_channel,
#gate_10-34_20-34_20-57_channel,
#gate_10-34_20-34_34-57_channel,
#gate_10-34_20-57_34-57_channel,
#gate_10-57_20-34_20-57_channel,
#gate_10-57_20-34_34-57_channel,
#gate_10-57_20-57_34-57_channel,
#gate_10-20_10-34_10-57_20-34_channel,
#gate_10-20_10-34_10-57_20-57_channel,
#gate_10-20_10-34_10-57_34-57_channel,
#gate_10-20_10-34_20-34_20-57_channel,
#gate_10-20_10-34_20-34_34-57_channel,
#gate_10-20_10-34_20-57_34-57_channel,
#gate_10-20_10-57_20-34_20-57_channel,
#gate_10-20_10-57_20-34_34-57_channel,
#gate_10-20_10-57_20-57_34-57_channel,
#gate_10-34_10-57_20-34_20-57_channel,
#gate_10-34_10-57_20-34_34-57_channel,
#gate_10-34_10-57_20-57_34-57_channel,
#gate_10-20_20-34_20-57_34-57_channel,
#gate_10-34_20-34_20-57_34-57_channel,
#gate_10-57_20-34_20-57_34-57_channel,
#gate_10-20_10-34_10-57_20-34_20-57_channel,
#gate_10-20_10-34_10-57_20-34_34-57_channel,
#gate_10-20_10-34_10-57_20-57_34-57_channel,
#gate_10-20_10-34_20-34_20-57_34-57_channel,
#gate_10-20_10-57_20-34_20-57_34-57_channel,
#gate_10-34_10-57_20-34_20-57_34-57_channel,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel,
#gate_11_design,
#gate_11_person,
#gate_11_all_both,
#gate_11_harmonious_person,
#gate_11_dream_gates,
#gate_11_love_person,
#gate_11_fears_person,
#gate_11_melancholy_person,
#gate_11_56_channel,
#gate_12_design,
#gate_12_person,
#gate_12_all_both,
#gate_12_harmonious_person,
#gate_12_dream_gates,
#gate_12_love_person,
#gate_12_fears_person,
#gate_12_melancholy_person,
#gate_12_22_channel,
#gate_13_design,
#gate_13_person,
#gate_13_all_both,
#gate_13_harmonious_person,
#gate_13_dream_gates,
#gate_13_love_person,
#gate_13_fears_person,
#gate_13_melancholy_person,
#gate_13_33_channel,
#gate_14_design,
#gate_14_person,
#gate_14_all_both,
#gate_14_harmonious_person,
#gate_14_dream_gates,
#gate_14_love_person,
#gate_14_fears_person,
#gate_14_melancholy_person,
#gate_15_design,
#gate_15_person,
#gate_15_all_both,
#gate_15_harmonious_person,
#gate_15_dream_gates,
#gate_15_love_person,
#gate_15_fears_person,
#gate_15_melancholy_person,
#gate_05_15_channel,
#gate_16_design,
#gate_16_person,
#gate_16_all_both,
#gate_16_harmonious_person,
#gate_16_dream_gates,
#gate_16_love_person,
#gate_16_fears_person,
#gate_16_melancholy_person,
#gate_16_48_channel,
#gate_17_design,
#gate_17_person,
#gate_17_all_both,
#gate_17_harmonious_person,
#gate_17_dream_gates,
#gate_17_love_person,
#gate_17_fears_person,
#gate_17_melancholy_person,
#gate_17_62_channel,
#gate_18_design,
#gate_18_person,
#gate_18_all_both,
#gate_18_harmonious_person,
#gate_18_dream_gates,
#gate_18_love_person,
#gate_18_fears_person,
#gate_18_melancholy_person,
#gate_18_58_channel,
#gate_19_design,
#gate_19_person,
#gate_19_all_both,
#gate_19_harmonious_person,
#gate_19_dream_gates,
#gate_19_love_person,
#gate_19_fears_person,
#gate_19_melancholy_person,
#gate_19_49_channel,
#gate_20_design,
#gate_20_person,
#gate_20_all_both,
#gate_20_harmonious_person,
#gate_20_dream_gates,
#gate_20_love_person,
#gate_20_fears_person,
#gate_20_melancholy_person,
#gate_20_34_channel,
#gate_20_57_channel,
#gate_21_design,
#gate_21_person,
#gate_21_all_both,
#gate_21_harmonious_person,
#gate_21_dream_gates,
#gate_21_love_person,
#gate_21_fears_person,
#gate_21_melancholy_person,
#gate_21_45_channel,
#gate_22_design,
#gate_22_person,
#gate_22_all_both,
#gate_22_harmonious_person,
#gate_22_dream_gates,
#gate_22_love_person,
#gate_22_fears_person,
#gate_22_melancholy_person,
#gate_23_design,
#gate_23_person,
#gate_23_all_both,
#gate_23_harmonious_person,
#gate_23_dream_gates,
#gate_23_love_person,
#gate_23_fears_person,
#gate_23_melancholy_person,
#gate_24_design,
#gate_24_person,
#gate_24_all_both,
#gate_24_harmonious_person,
#gate_24_dream_gates,
#gate_24_love_person,
#gate_24_fears_person,
#gate_24_melancholy_person,
#gate_24_61_channel,
#gate_25_design,
#gate_25_person,
#gate_25_all_both,
#gate_25_harmonious_person,
#gate_25_dream_gates,
#gate_25_love_person,
#gate_25_fears_person,
#gate_25_melancholy_person,
#gate_25_51_channel,
#gate_26_design,
#gate_26_person,
#gate_26_all_both,
#gate_26_harmonious_person,
#gate_26_dream_gates,
#gate_26_love_person,
#gate_26_fears_person,
#gate_26_melancholy_person,
#gate_26_44_channel,
#gate_27_design,
#gate_27_person,
#gate_27_all_both,
#gate_27_harmonious_person,
#gate_27_dream_gates,
#gate_27_love_person,
#gate_27_fears_person,
#gate_27_melancholy_person,
#gate_28_design,
#gate_28_person,
#gate_28_all_both,
#gate_28_harmonious_person,
#gate_28_dream_gates,
#gate_28_love_person,
#gate_28_fears_person,
#gate_28_melancholy_person,
#gate_28_38_channel,
#gate_29_design,
#gate_29_person,
#gate_29_all_both,
#gate_29_harmonious_person,
#gate_29_dream_gates,
#gate_29_love_person,
#gate_29_fears_person,
#gate_29_melancholy_person,
#gate_29_46_channel,
#gate_30_design,
#gate_30_person,
#gate_30_all_both,
#gate_30_harmonious_person,
#gate_30_dream_gates,
#gate_30_love_person,
#gate_30_fears_person,
#gate_30_melancholy_person,
#gate_30_41_channel,
#gate_31_design,
#gate_31_person,
#gate_31_all_both,
#gate_31_harmonious_person,
#gate_31_dream_gates,
#gate_31_love_person,
#gate_31_fears_person,
#gate_31_melancholy_person,
#gate_32_design,
#gate_32_person,
#gate_32_all_both,
#gate_32_harmonious_person,
#gate_32_dream_gates,
#gate_32_love_person,
#gate_32_fears_person,
#gate_32_melancholy_person,
#gate_32_54_channel,
#gate_33_design,
#gate_33_person,
#gate_33_all_both,
#gate_33_harmonious_person,
#gate_33_dream_gates,
#gate_33_love_person,
#gate_33_fears_person,
#gate_33_melancholy_person,
#gate_34_design,
#gate_34_person,
#gate_34_all_both,
#gate_34_harmonious_person,
#gate_34_dream_gates,
#gate_34_love_person,
#gate_34_fears_person,
#gate_34_melancholy_person,
#gate_34_57_channel,
#gate_35_design,
#gate_35_person,
#gate_35_all_both,
#gate_35_harmonious_person,
#gate_35_dream_gates,
#gate_35_love_person,
#gate_35_fears_person,
#gate_35_melancholy_person,
#gate_35_36_channel,
#gate_36_design,
#gate_36_person,
#gate_36_all_both,
#gate_36_harmonious_person,
#gate_36_dream_gates,
#gate_36_love_person,
#gate_36_fears_person,
#gate_36_melancholy_person,
#gate_37_design,
#gate_37_person,
#gate_37_all_both,
#gate_37_harmonious_person,
#gate_37_dream_gates,
#gate_37_love_person,
#gate_37_fears_person,
#gate_37_melancholy_person,
#gate_37_40_channel,
#gate_38_design,
#gate_38_person,
#gate_38_all_both,
#gate_38_harmonious_person,
#gate_38_dream_gates,
#gate_38_love_person,
#gate_38_fears_person,
#gate_38_melancholy_person,
#gate_39_design,
#gate_39_person,
#gate_39_harmonious_person,
#gate_39_dream_gates,
#gate_39_love_person,
#gate_39_fears_person,
#gate_39_melancholy_person,
#gate_39_all_both,
#gate_40_design,
#gate_40_person,
#gate_40_all_both,
#gate_40_harmonious_person,
#gate_40_dream_gates,
#gate_40_love_person,
#gate_40_fears_person,
#gate_40_melancholy_person,
#gate_41_design,
#gate_41_person,
#gate_41_all_both,
#gate_41_harmonious_person,
#gate_41_dream_gates,
#gate_41_love_person,
#gate_41_fears_person,
#gate_41_melancholy_person,
#gate_42_design,
#gate_42_person,
#gate_42_all_both,
#gate_42_harmonious_person,
#gate_42_dream_gates,
#gate_42_love_person,
#gate_42_fears_person,
#gate_42_melancholy_person,
#gate_42_53_channel,
#gate_43_design,
#gate_43_person,
#gate_43_all_both,
#gate_43_harmonious_person,
#gate_43_dream_gates,
#gate_43_love_person,
#gate_43_fears_person,
#gate_43_melancholy_person,
#gate_23_43_channel,
#gate_44_design,
#gate_44_person,
#gate_44_all_both,
#gate_44_harmonious_person,
#gate_44_dream_gates,
#gate_44_love_person,
#gate_44_fears_person,
#gate_44_melancholy_person,
#gate_45_design,
#gate_45_person,
#gate_45_all_both,
#gate_45_harmonious_person,
#gate_45_love_person,
#gate_45_fears_person,
#gate_45_melancholy_person,
#gate_45_dream_gates,
#gate_46_design,
#gate_46_person,
#gate_46_all_both,
#gate_46_harmonious_person,
#gate_46_love_person,
#gate_46_fears_person,
#gate_46_melancholy_person,
#gate_46_dream_gates,
#gate_47_design,
#gate_47_person,
#gate_47_all_both,
#gate_47_harmonious_person,
#gate_47_love_person,
#gate_47_fears_person,
#gate_47_melancholy_person,
#gate_47_dream_gates,
#gate_47_64_channel,
#gate_48_design,
#gate_48_person,
#gate_48_all_both,
#gate_48_harmonious_person,
#gate_48_love_person,
#gate_48_fears_person,
#gate_48_melancholy_person,
#gate_48_dream_gates,
#gate_49_design,
#gate_49_person,
#gate_49_all_both,
#gate_49_harmonious_person,
#gate_49_love_person,
#gate_49_fears_person,
#gate_49_melancholy_person,
#gate_49_dream_gates,
#gate_50_design,
#gate_50_person,
#gate_50_all_both,
#gate_50_harmonious_person,
#gate_50_love_person,
#gate_50_fears_person,
#gate_50_melancholy_person,
#gate_50_dream_gates,
#gate_27_50_channel,
#gate_51_design,
#gate_51_person,
#gate_51_all_both,
#gate_51_harmonious_person,
#gate_51_love_person,
#gate_51_fears_person,
#gate_51_melancholy_person,
#gate_51_dream_gates,
#gate_52_design,
#gate_52_person,
#gate_52_all_both,
#gate_52_harmonious_person,
#gate_52_love_person,
#gate_52_fears_person,
#gate_52_melancholy_person,
#gate_52_dream_gates,
#gate_53_design,
#gate_53_person,
#gate_53_all_both,
#gate_53_harmonious_person,
#gate_53_love_person,
#gate_53_fears_person,
#gate_53_melancholy_person,
#gate_53_dream_gates,
#gate_54_design,
#gate_54_person,
#gate_54_all_both,
#gate_54_harmonious_person,
#gate_54_love_person,
#gate_54_fears_person,
#gate_54_melancholy_person,
#gate_54_dream_gates,
#gate_55_design,
#gate_55_person,
#gate_55_all_both,
#gate_55_harmonious_person,
#gate_55_love_person,
#gate_55_fears_person,
#gate_55_melancholy_person,
#gate_55_dream_gates,
#gate_39_55_channel,
#gate_56_design,
#gate_56_person,
#gate_56_all_both,
#gate_56_harmonious_person,
#gate_56_love_person,
#gate_56_fears_person,
#gate_56_melancholy_person,
#gate_56_dream_gates,
#gate_57_design,
#gate_57_person,
#gate_57_all_both,
#gate_57_harmonious_person,
#gate_57_love_person,
#gate_57_fears_person,
#gate_57_melancholy_person,
#gate_57_dream_gates,
#gate_58_design,
#gate_58_person,
#gate_58_all_both,
#gate_58_harmonious_person,
#gate_58_love_person,
#gate_58_fears_person,
#gate_58_melancholy_person,
#gate_58_dream_gates,
#gate_59_design,
#gate_59_person,
#gate_59_all_both,
#gate_59_harmonious_person,
#gate_59_love_person,
#gate_59_fears_person,
#gate_59_melancholy_person,
#gate_59_dream_gates,
#gate_60_design,
#gate_60_person,
#gate_60_all_both,
#gate_60_harmonious_person,
#gate_60_love_person,
#gate_60_fears_person,
#gate_60_melancholy_person,
#gate_60_dream_gates,
#gate_61_design,
#gate_61_person,
#gate_61_all_both,
#gate_61_harmonious_person,
#gate_61_love_person,
#gate_61_fears_person,
#gate_61_melancholy_person,
#gate_61_dream_gates,
#gate_62_design,
#gate_62_person,
#gate_62_all_both,
#gate_62_harmonious_person,
#gate_62_love_person,
#gate_62_fears_person,
#gate_62_melancholy_person,
#gate_62_dream_gates,
#gate_63_design,
#gate_63_person,
#gate_63_all_both,
#gate_63_harmonious_person,
#gate_63_love_person,
#gate_63_fears_person,
#gate_63_melancholy_person,
#gate_63_dream_gates,
#gate_63_47_channel,
#gate_64_design,
#gate_64_person,
#gate_64_all_both,
#gate_64_harmonious_person,
#gate_64_love_person,
#gate_64_fears_person,
#gate_64_melancholy_person,
#gate_64_dream_gates,
#gate_01_lines_gates,
#gate_02_lines_gates,
#gate_03_lines_gates,
#gate_04_lines_gates,
#gate_05_lines_gates,
#gate_06_lines_gates,
#gate_07_lines_gates,
#gate_08_lines_gates,
#gate_09_lines_gates,
#gate_10_lines_gates,
#gate_11_lines_gates,
#gate_12_lines_gates,
#gate_13_lines_gates,
#gate_14_lines_gates,
#gate_15_lines_gates,
#gate_16_lines_gates,
#gate_17_lines_gates,
#gate_18_lines_gates,
#gate_19_lines_gates,
#gate_20_lines_gates,
#gate_21_lines_gates,
#gate_22_lines_gates,
#gate_23_lines_gates,
#gate_24_lines_gates,
#gate_25_lines_gates,
#gate_26_lines_gates,
#gate_27_lines_gates,
#gate_28_lines_gates,
#gate_29_lines_gates,
#gate_30_lines_gates,
#gate_31_lines_gates,
#gate_32_lines_gates,
#gate_33_lines_gates,
#gate_34_lines_gates,
#gate_35_lines_gates,
#gate_36_lines_gates,
#gate_37_lines_gates,
#gate_38_lines_gates,
#gate_39_lines_gates,
#gate_40_lines_gates,
#gate_41_lines_gates,
#gate_42_lines_gates,
#gate_43_lines_gates,
#gate_44_lines_gates,
#gate_45_lines_gates,
#gate_46_lines_gates,
#gate_47_lines_gates,
#gate_48_lines_gates,
#gate_49_lines_gates,
#gate_50_lines_gates,
#gate_51_lines_gates,
#gate_52_lines_gates,
#gate_53_lines_gates,
#gate_54_lines_gates,
#gate_55_lines_gates,
#gate_56_lines_gates,
#gate_57_lines_gates,
#gate_58_lines_gates,
#gate_59_lines_gates,
#gate_60_lines_gates,
#gate_61_lines_gates,
#gate_62_lines_gates,
#gate_63_lines_gates,
#gate_64_lines_gates,
#gate_01_circuit_gates,
#gate_02_circuit_gates,
#gate_03_circuit_gates,
#gate_04_circuit_gates,
#gate_05_circuit_gates,
#gate_06_circuit_gates,
#gate_07_circuit_gates,
#gate_08_circuit_gates,
#gate_09_circuit_gates,
#gate_10_circuit_gates,
#gate_11_circuit_gates,
#gate_12_circuit_gates,
#gate_13_circuit_gates,
#gate_14_circuit_gates,
#gate_15_circuit_gates,
#gate_16_circuit_gates,
#gate_17_circuit_gates,
#gate_18_circuit_gates,
#gate_19_circuit_gates,
#gate_20_circuit_gates,
#gate_21_circuit_gates,
#gate_22_circuit_gates,
#gate_23_circuit_gates,
#gate_24_circuit_gates,
#gate_25_circuit_gates,
#gate_26_circuit_gates,
#gate_27_circuit_gates,
#gate_28_circuit_gates,
#gate_29_circuit_gates,
#gate_30_circuit_gates,
#gate_31_circuit_gates,
#gate_32_circuit_gates,
#gate_33_circuit_gates,
#gate_34_circuit_gates,
#gate_35_circuit_gates,
#gate_36_circuit_gates,
#gate_37_circuit_gates,
#gate_38_circuit_gates,
#gate_39_circuit_gates,
#gate_40_circuit_gates,
#gate_41_circuit_gates,
#gate_42_circuit_gates,
#gate_43_circuit_gates,
#gate_44_circuit_gates,
#gate_45_circuit_gates,
#gate_46_circuit_gates,
#gate_47_circuit_gates,
#gate_48_circuit_gates,
#gate_49_circuit_gates,
#gate_50_circuit_gates,
#gate_51_circuit_gates,
#gate_52_circuit_gates,
#gate_53_circuit_gates,
#gate_54_circuit_gates,
#gate_55_circuit_gates,
#gate_56_circuit_gates,
#gate_57_circuit_gates,
#gate_58_circuit_gates,
#gate_59_circuit_gates,
#gate_60_circuit_gates,
#gate_61_circuit_gates,
#gate_62_circuit_gates,
#gate_63_circuit_gates,
#gate_64_circuit_gates,
#gate_01_08_circuit_channel,
#gate_02_14_circuit_channel,
#gate_03_60_circuit_channel,
#gate_04_63_circuit_channel,
#gate_05_15_circuit_channel,
#gate_06_59_circuit_channel,
#gate_07_31_circuit_channel,
#gate_09_52_circuit_channel,
#gate_10_20_circuit_channel,
#gate_10_34_circuit_channel,
#gate_10_57_circuit_channel,
#gate_11_56_circuit_channel,
#gate_12_22_circuit_channel,
#gate_13_33_circuit_channel,
#gate_16_48_circuit_channel,
#gate_17_62_circuit_channel,
#gate_18_58_circuit_channel,
#gate_19_49_circuit_channel,
#gate_20_34_circuit_channel,
#gate_20_57_circuit_channel,
#gate_21_45_circuit_channel,
#gate_23_43_circuit_channel,
#gate_24_61_circuit_channel,
#gate_25_51_circuit_channel,
#gate_26_44_circuit_channel,
#gate_27_50_circuit_channel,
#gate_28_38_circuit_channel,
#gate_29_46_circuit_channel,
#gate_30_41_circuit_channel,
#gate_32_54_circuit_channel,
#gate_34_57_circuit_channel,
#gate_35_36_circuit_channel,
#gate_37_40_circuit_channel,
#gate_39_55_circuit_channel,
#gate_42_53_circuit_channel,
#gate_47_64_circuit_channel {
  cursor: pointer;
  stroke: #49c4be;
  stroke-width: 3;
  stroke-opacity: 0;
  transition: stroke-opacity 0.3s;
}
#gate_01_design:hover,
#gate_01_person:hover,
#gate_01_all_both:hover,
#gate_01_harmonious_person:hover,
#gate_01_love_person:hover,
#gate_01_fears_person:hover,
#gate_01_melancholy_person:hover,
#gate_01_dream_gates:hover,
#gate_01_08_channel:hover,
#gate_02_design:hover,
#gate_02_person:hover,
#gate_02_all_both:hover,
#gate_02_harmonious_person:hover,
#gate_02_love_person:hover,
#gate_02_fears_person:hover,
#gate_02_melancholy_person:hover,
#gate_02_dream_gates:hover,
#gate_02_14_channel:hover,
#gate_03_design:hover,
#gate_03_person:hover,
#gate_03_all_both:hover,
#gate_03_harmonious_person:hover,
#gate_03_love_person:hover,
#gate_03_fears_person:hover,
#gate_03_melancholy_person:hover,
#gate_03_dream_gates:hover,
#gate_03_60_channel:hover,
#gate_04_design:hover,
#gate_04_person:hover,
#gate_04_all_both:hover,
#gate_04_harmonious_person:hover,
#gate_04_love_person:hover,
#gate_04_fears_person:hover,
#gate_04_melancholy_person:hover,
#gate_04_dream_gates:hover,
#gate_04_63_channel:hover,
#gate_05_design:hover,
#gate_05_person:hover,
#gate_05_all_both:hover,
#gate_05_harmonious_person:hover,
#gate_05_love_person:hover,
#gate_05_fears_person:hover,
#gate_05_melancholy_person:hover,
#gate_05_dream_gates:hover,
#gate_06_design:hover,
#gate_06_person:hover,
#gate_06_all_both:hover,
#gate_06_harmonious_person:hover,
#gate_06_love_person:hover,
#gate_06_fears_person:hover,
#gate_06_melancholy_person:hover,
#gate_06_dream_gates:hover,
#gate_06_59_channel:hover,
#gate_07_design:hover,
#gate_07_person:hover,
#gate_07_all_both:hover,
#gate_07_harmonious_person:hover,
#gate_07_love_person:hover,
#gate_07_fears_person:hover,
#gate_07_melancholy_person:hover,
#gate_07_dream_gates:hover,
#gate_07_31_channel:hover,
#gate_08_design:hover,
#gate_08_person:hover,
#gate_08_all_both:hover,
#gate_08_harmonious_person:hover,
#gate_08_love_person:hover,
#gate_08_fears_person:hover,
#gate_08_melancholy_person:hover,
#gate_08_dream_gates:hover,
#gate_20_57_dream_channel:hover,
#gate_01_08_dream_channel:hover,
#gate_05_15_dream_channel:hover,
#gate_27_50_dream_channel:hover,
#gate_42_53_dream_channel:hover,
#gate_28_38_dream_channel:hover,
#gate_09_design:hover,
#gate_09_person:hover,
#gate_09_all_both:hover,
#gate_09_harmonious_person:hover,
#gate_09_love_person:hover,
#gate_09_fears_person:hover,
#gate_09_melancholy_person:hover,
#gate_09_dream_gates:hover,
#gate_09_52_channel:hover,
#gate_10_design:hover,
#gate_10_person:hover,
#gate_10_all_both:hover,
#gate_10_harmonious_person:hover,
#gate_10_love_person:hover,
#gate_10_fears_person:hover,
#gate_10_melancholy_person:hover,
#gate_10_dream_gates:hover,
#gate_10_34_channel:hover,
#gate_10_20_channel:hover,
#gate_10_57_channel:hover,
#gate_10-20_10-57_channel:hover,
#gate_10-20_20-57_channel:hover,
#gate_10-57_20-57_channel:hover,
#gate_10-20_10-57_20-57_channel:hover,
#gate_10-20_10-34_channel:hover,
#gate_10-34_20-34_channel:hover,
#gate_10-20_20-34_channel:hover,
#gate_10-20_10-34_20-34_channel:hover,
#gate_20-34_20-57_channel:hover,
#gate_20-34_34-57_channel:hover,
#gate_20-57_34-57_channel:hover,
#gate_20-34_20-57_34-57_channel:hover,
#gate_10-34_10-57_channel:hover,
#gate_10-34_34-57_channel:hover,
#gate_10-57_34-57_channel:hover,
#gate_10-34_10-57_34-57_channel:hover,
#gate_10-20_34-57_channel:hover,
#gate_10-34_20-57_channel:hover,
#gate_10-57_20-34_channel:hover,
#gate_10-20_10-34_10-57_channel:hover,
#gate_10-20_10-34_20-57_channel:hover,
#gate_10-20_10-34_34-57_channel:hover,
#gate_10-20_10-57_20-34_channel:hover,
#gate_10-20_10-57_34-57_channel:hover,
#gate_10-20_20-34_20-57_channel:hover,
#gate_10-20_20-34_34-57_channel:hover,
#gate_10-20_20-57_34-57_channel:hover,
#gate_10-34_10-57_20-34_channel:hover,
#gate_10-34_10-57_20-57_channel:hover,
#gate_10-34_20-34_20-57_channel:hover,
#gate_10-34_20-34_34-57_channel:hover,
#gate_10-34_20-57_34-57_channel:hover,
#gate_10-57_20-34_20-57_channel:hover,
#gate_10-57_20-34_34-57_channel:hover,
#gate_10-57_20-57_34-57_channel:hover,
#gate_10-20_10-34_10-57_20-34_channel:hover,
#gate_10-20_10-34_10-57_20-57_channel:hover,
#gate_10-20_10-34_10-57_34-57_channel:hover,
#gate_10-20_10-34_20-34_20-57_channel:hover,
#gate_10-20_10-34_20-34_34-57_channel:hover,
#gate_10-20_10-34_20-57_34-57_channel:hover,
#gate_10-20_10-57_20-34_20-57_channel:hover,
#gate_10-20_10-57_20-34_34-57_channel:hover,
#gate_10-20_10-57_20-57_34-57_channel:hover,
#gate_10-34_10-57_20-34_20-57_channel:hover,
#gate_10-34_10-57_20-34_34-57_channel:hover,
#gate_10-34_10-57_20-57_34-57_channel:hover,
#gate_10-20_20-34_20-57_34-57_channel:hover,
#gate_10-34_20-34_20-57_34-57_channel:hover,
#gate_10-57_20-34_20-57_34-57_channel:hover,
#gate_10-20_10-34_10-57_20-34_20-57_channel:hover,
#gate_10-20_10-34_10-57_20-34_34-57_channel:hover,
#gate_10-20_10-34_10-57_20-57_34-57_channel:hover,
#gate_10-20_10-34_20-34_20-57_34-57_channel:hover,
#gate_10-20_10-57_20-34_20-57_34-57_channel:hover,
#gate_10-34_10-57_20-34_20-57_34-57_channel:hover,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel:hover,
#gate_11_design:hover,
#gate_11_person:hover,
#gate_11_all_both:hover,
#gate_11_harmonious_person:hover,
#gate_11_love_person:hover,
#gate_11_fears_person:hover,
#gate_11_melancholy_person:hover,
#gate_11_dream_gates:hover,
#gate_11_56_channel:hover,
#gate_12_design:hover,
#gate_12_person:hover,
#gate_12_all_both:hover,
#gate_12_harmonious_person:hover,
#gate_12_love_person:hover,
#gate_12_fears_person:hover,
#gate_12_melancholy_person:hover,
#gate_12_dream_gates:hover,
#gate_12_22_channel:hover,
#gate_13_design:hover,
#gate_13_person:hover,
#gate_13_all_both:hover,
#gate_13_harmonious_person:hover,
#gate_13_love_person:hover,
#gate_13_fears_person:hover,
#gate_13_melancholy_person:hover,
#gate_13_dream_gates:hover,
#gate_13_33_channel:hover,
#gate_14_design:hover,
#gate_14_person:hover,
#gate_14_all_both:hover,
#gate_14_harmonious_person:hover,
#gate_14_love_person:hover,
#gate_14_fears_person:hover,
#gate_14_melancholy_person:hover,
#gate_14_dream_gates:hover,
#gate_15_design:hover,
#gate_15_person:hover,
#gate_15_all_both:hover,
#gate_15_harmonious_person:hover,
#gate_15_love_person:hover,
#gate_15_fears_person:hover,
#gate_15_melancholy_person:hover,
#gate_15_dream_gates:hover,
#gate_05_15_channel:hover,
#gate_16_design:hover,
#gate_16_person:hover,
#gate_16_all_both:hover,
#gate_16_harmonious_person:hover,
#gate_16_love_person:hover,
#gate_16_fears_person:hover,
#gate_16_melancholy_person:hover,
#gate_16_dream_gates:hover,
#gate_16_48_channel:hover,
#gate_17_design:hover,
#gate_17_person:hover,
#gate_17_all_both:hover,
#gate_17_harmonious_person:hover,
#gate_17_love_person:hover,
#gate_17_fears_person:hover,
#gate_17_melancholy_person:hover,
#gate_17_dream_gates:hover,
#gate_17_62_channel:hover,
#gate_18_design:hover,
#gate_18_person:hover,
#gate_18_all_both:hover,
#gate_18_harmonious_person:hover,
#gate_18_love_person:hover,
#gate_18_fears_person:hover,
#gate_18_melancholy_person:hover,
#gate_18_dream_gates:hover,
#gate_18_58_channel:hover,
#gate_19_design:hover,
#gate_19_person:hover,
#gate_19_all_both:hover,
#gate_19_harmonious_person:hover,
#gate_19_love_person:hover,
#gate_19_fears_person:hover,
#gate_19_melancholy_person:hover,
#gate_19_dream_gates:hover,
#gate_19_49_channel:hover,
#gate_20_design:hover,
#gate_20_person:hover,
#gate_20_all_both:hover,
#gate_20_harmonious_person:hover,
#gate_20_love_person:hover,
#gate_20_fears_person:hover,
#gate_20_melancholy_person:hover,
#gate_20_dream_gates:hover,
#gate_20_34_channel:hover,
#gate_20_57_channel:hover,
#gate_21_design:hover,
#gate_21_person:hover,
#gate_21_all_both:hover,
#gate_21_harmonious_person:hover,
#gate_21_love_person:hover,
#gate_21_fears_person:hover,
#gate_21_melancholy_person:hover,
#gate_21_dream_gates:hover,
#gate_21_45_channel:hover,
#gate_22_design:hover,
#gate_22_person:hover,
#gate_22_all_both:hover,
#gate_22_harmonious_person:hover,
#gate_22_love_person:hover,
#gate_22_fears_person:hover,
#gate_22_melancholy_person:hover,
#gate_22_dream_gates:hover,
#gate_23_design:hover,
#gate_23_person:hover,
#gate_23_all_both:hover,
#gate_23_harmonious_person:hover,
#gate_23_love_person:hover,
#gate_23_fears_person:hover,
#gate_23_melancholy_person:hover,
#gate_23_dream_gates:hover,
#gate_24_design:hover,
#gate_24_person:hover,
#gate_24_all_both:hover,
#gate_24_harmonious_person:hover,
#gate_24_love_person:hover,
#gate_24_fears_person:hover,
#gate_24_melancholy_person:hover,
#gate_24_dream_gates:hover,
#gate_24_61_channel:hover,
#gate_25_design:hover,
#gate_25_person:hover,
#gate_25_harmonious_person:hover,
#gate_25_love_person:hover,
#gate_25_fears_person:hover,
#gate_25_melancholy_person:hover,
#gate_25_dream_gates:hover,
#gate_25_all_both:hover,
#gate_25_51_channel:hover,
#gate_26_design:hover,
#gate_26_person:hover,
#gate_26_all_both:hover,
#gate_26_harmonious_person:hover,
#gate_26_love_person:hover,
#gate_26_fears_person:hover,
#gate_26_melancholy_person:hover,
#gate_26_dream_gates:hover,
#gate_26_44_channel:hover,
#gate_27_design:hover,
#gate_27_person:hover,
#gate_27_all_both:hover,
#gate_27_harmonious_person:hover,
#gate_27_love_person:hover,
#gate_27_fears_person:hover,
#gate_27_melancholy_person:hover,
#gate_27_dream_gates:hover,
#gate_28_design:hover,
#gate_28_person:hover,
#gate_28_all_both:hover,
#gate_28_harmonious_person:hover,
#gate_28_love_person:hover,
#gate_28_fears_person:hover,
#gate_28_melancholy_person:hover,
#gate_28_dream_gates:hover,
#gate_28_38_channel:hover,
#gate_29_design:hover,
#gate_29_person:hover,
#gate_29_all_both:hover,
#gate_29_harmonious_person:hover,
#gate_29_love_person:hover,
#gate_29_fears_person:hover,
#gate_29_melancholy_person:hover,
#gate_29_dream_gates:hover,
#gate_29_46_channel:hover,
#gate_30_design:hover,
#gate_30_person:hover,
#gate_30_all_both:hover,
#gate_30_harmonious_person:hover,
#gate_30_love_person:hover,
#gate_30_fears_person:hover,
#gate_30_melancholy_person:hover,
#gate_30_dream_gates:hover,
#gate_30_41_channel:hover,
#gate_31_design:hover,
#gate_31_person:hover,
#gate_31_harmonious_person:hover,
#gate_31_love_person:hover,
#gate_31_fears_person:hover,
#gate_31_melancholy_person:hover,
#gate_31_dream_gates:hover,
#gate_31_all_both:hover,
#gate_32_design:hover,
#gate_32_person:hover,
#gate_32_all_both:hover,
#gate_32_harmonious_person:hover,
#gate_32_love_person:hover,
#gate_32_fears_person:hover,
#gate_32_melancholy_person:hover,
#gate_32_dream_gates:hover,
#gate_32_54_channel:hover,
#gate_33_design:hover,
#gate_33_person:hover,
#gate_33_all_both:hover,
#gate_33_harmonious_person:hover,
#gate_33_love_person:hover,
#gate_33_fears_person:hover,
#gate_33_melancholy_person:hover,
#gate_33_dream_gates:hover,
#gate_34_design:hover,
#gate_34_person:hover,
#gate_34_all_both:hover,
#gate_34_harmonious_person:hover,
#gate_34_love_person:hover,
#gate_34_fears_person:hover,
#gate_34_melancholy_person:hover,
#gate_34_dream_gates:hover,
#gate_34_57_channel:hover,
#gate_35_design:hover,
#gate_35_person:hover,
#gate_35_all_both:hover,
#gate_35_harmonious_person:hover,
#gate_35_dream_gates:hover,
#gate_35_love_person:hover,
#gate_35_fears_person:hover,
#gate_35_melancholy_person:hover,
#gate_35_36_channel:hover,
#gate_36_design:hover,
#gate_36_person:hover,
#gate_36_all_both:hover,
#gate_36_harmonious_person:hover,
#gate_36_love_person:hover,
#gate_36_fears_person:hover,
#gate_36_melancholy_person:hover,
#gate_36_dream_gates:hover,
#gate_37_design:hover,
#gate_37_person:hover,
#gate_37_all_both:hover,
#gate_37_harmonious_person:hover,
#gate_37_love_person:hover,
#gate_37_fears_person:hover,
#gate_37_melancholy_person:hover,
#gate_37_dream_gates:hover,
#gate_37_40_channel:hover,
#gate_38_design:hover,
#gate_38_person:hover,
#gate_38_all_both:hover,
#gate_38_harmonious_person:hover,
#gate_38_love_person:hover,
#gate_38_fears_person:hover,
#gate_38_melancholy_person:hover,
#gate_38_dream_gates:hover,
#gate_39_design:hover,
#gate_39_person:hover,
#gate_39_all_both:hover,
#gate_39_harmonious_person:hover,
#gate_39_love_person:hover,
#gate_39_fears_person:hover,
#gate_39_melancholy_person:hover,
#gate_39_dream_gates:hover,
#gate_40_design:hover,
#gate_40_person:hover,
#gate_40_all_both:hover,
#gate_40_harmonious_person:hover,
#gate_40_love_person:hover,
#gate_40_fears_person:hover,
#gate_40_melancholy_person:hover,
#gate_40_dream_gates:hover,
#gate_41_design:hover,
#gate_41_person:hover,
#gate_41_all_both:hover,
#gate_41_harmonious_person:hover,
#gate_41_love_person:hover,
#gate_41_fears_person:hover,
#gate_41_melancholy_person:hover,
#gate_41_dream_gates:hover,
#gate_42_design:hover,
#gate_42_person:hover,
#gate_42_all_both:hover,
#gate_42_harmonious_person:hover,
#gate_42_love_person:hover,
#gate_42_fears_person:hover,
#gate_42_melancholy_person:hover,
#gate_42_dream_gates:hover,
#gate_42_53_channel:hover,
#gate_43_design:hover,
#gate_43_person:hover,
#gate_43_all_both:hover,
#gate_43_harmonious_person:hover,
#gate_43_love_person:hover,
#gate_43_fears_person:hover,
#gate_43_melancholy_person:hover,
#gate_43_dream_gates:hover,
#gate_23_43_channel:hover,
#gate_44_design:hover,
#gate_44_person:hover,
#gate_44_all_both:hover,
#gate_44_harmonious_person:hover,
#gate_44_love_person:hover,
#gate_44_fears_person:hover,
#gate_44_melancholy_person:hover,
#gate_44_dream_gates:hover,
#gate_45_design:hover,
#gate_45_person:hover,
#gate_45_all_both:hover,
#gate_45_harmonious_person:hover,
#gate_45_love_person:hover,
#gate_45_fears_person:hover,
#gate_45_melancholy_person:hover,
#gate_45_dream_gates:hover,
#gate_46_design:hover,
#gate_46_person:hover,
#gate_46_all_both:hover,
#gate_46_harmonious_person:hover,
#gate_46_love_person:hover,
#gate_46_fears_person:hover,
#gate_46_melancholy_person:hover,
#gate_46_dream_gates:hover,
#gate_47_design:hover,
#gate_47_person:hover,
#gate_47_all_both:hover,
#gate_47_harmonious_person:hover,
#gate_47_love_person:hover,
#gate_47_fears_person:hover,
#gate_47_melancholy_person:hover,
#gate_47_dream_gates:hover,
#gate_47_64_channel:hover,
#gate_48_design:hover,
#gate_48_person:hover,
#gate_48_all_both:hover,
#gate_48_harmonious_person:hover,
#gate_48_love_person:hover,
#gate_48_fears_person:hover,
#gate_48_melancholy_person:hover,
#gate_48_dream_gates:hover,
#gate_49_design:hover,
#gate_49_person:hover,
#gate_49_all_both:hover,
#gate_49_harmonious_person:hover,
#gate_49_love_person:hover,
#gate_49_fears_person:hover,
#gate_49_melancholy_person:hover,
#gate_49_dream_gates:hover,
#gate_50_design:hover,
#gate_50_person:hover,
#gate_50_all_both:hover,
#gate_50_harmonious_person:hover,
#gate_50_love_person:hover,
#gate_50_fears_person:hover,
#gate_50_melancholy_person:hover,
#gate_50_dream_gates:hover,
#gate_27_50_channel:hover,
#gate_51_design:hover,
#gate_51_person:hover,
#gate_51_all_both:hover,
#gate_51_harmonious_person:hover,
#gate_51_love_person:hover,
#gate_51_fears_person:hover,
#gate_51_melancholy_person:hover,
#gate_51_dream_gates:hover,
#gate_52_design:hover,
#gate_52_person:hover,
#gate_52_all_both:hover,
#gate_52_harmonious_person:hover,
#gate_52_love_person:hover,
#gate_52_fears_person:hover,
#gate_52_melancholy_person:hover,
#gate_52_dream_gates:hover,
#gate_53_design:hover,
#gate_53_person:hover,
#gate_53_all_both:hover,
#gate_53_harmonious_person:hover,
#gate_53_love_person:hover,
#gate_53_fears_person:hover,
#gate_53_melancholy_person:hover,
#gate_53_dream_gates:hover,
#gate_54_design:hover,
#gate_54_person:hover,
#gate_54_all_both:hover,
#gate_54_harmonious_person:hover,
#gate_54_love_person:hover,
#gate_54_fears_person:hover,
#gate_54_melancholy_person:hover,
#gate_54_dream_gates:hover,
#gate_55_design:hover,
#gate_55_person:hover,
#gate_55_all_both:hover,
#gate_55_harmonious_person:hover,
#gate_55_love_person:hover,
#gate_55_fears_person:hover,
#gate_55_melancholy_person:hover,
#gate_55_dream_gates:hover,
#gate_39_55_channel:hover,
#gate_56_design:hover,
#gate_56_person:hover,
#gate_56_all_both:hover,
#gate_56_harmonious_person:hover,
#gate_56_love_person:hover,
#gate_56_fears_person:hover,
#gate_56_melancholy_person:hover,
#gate_56_dream_gates:hover,
#gate_57_design:hover,
#gate_57_person:hover,
#gate_57_all_both:hover,
#gate_57_harmonious_person:hover,
#gate_57_love_person:hover,
#gate_57_fears_person:hover,
#gate_57_melancholy_person:hover,
#gate_57_dream_gates:hover,
#gate_58_design:hover,
#gate_58_person:hover,
#gate_58_all_both:hover,
#gate_58_harmonious_person:hover,
#gate_58_love_person:hover,
#gate_58_fears_person:hover,
#gate_58_melancholy_person:hover,
#gate_58_dream_gates:hover,
#gate_59_design:hover,
#gate_59_person:hover,
#gate_59_all_both:hover,
#gate_59_harmonious_person:hover,
#gate_59_love_person:hover,
#gate_59_fears_person:hover,
#gate_59_melancholy_person:hover,
#gate_59_dream_gates:hover,
#gate_60_design:hover,
#gate_60_person:hover,
#gate_60_all_both:hover,
#gate_60_harmonious_person:hover,
#gate_60_love_person:hover,
#gate_60_fears_person:hover,
#gate_60_melancholy_person:hover,
#gate_60_dream_gates:hover,
#gate_61_design:hover,
#gate_61_person:hover,
#gate_61_all_both:hover,
#gate_61_harmonious_person:hover,
#gate_61_love_person:hover,
#gate_61_fears_person:hover,
#gate_61_melancholy_person:hover,
#gate_61_dream_gates:hover,
#gate_62_design:hover,
#gate_62_person:hover,
#gate_62_all_both:hover,
#gate_62_harmonious_person:hover,
#gate_62_love_person:hover,
#gate_62_fears_person:hover,
#gate_62_melancholy_person:hover,
#gate_62_dream_gates:hover,
#gate_63_design:hover,
#gate_63_person:hover,
#gate_63_all_both:hover,
#gate_63_harmonious_person:hover,
#gate_63_love_person:hover,
#gate_63_fears_person:hover,
#gate_63_melancholy_person:hover,
#gate_63_dream_gates:hover,
#gate_64_design:hover,
#gate_64_person:hover,
#gate_64_all_both:hover,
#gate_64_harmonious_person:hover,
#gate_64_love_person:hover,
#gate_64_fears_person:hover,
#gate_64_melancholy_person:hover,
#gate_64_dream_gates:hover,
#gate_01_lines_gates:hover,
#gate_02_lines_gates:hover,
#gate_03_lines_gates:hover,
#gate_04_lines_gates:hover,
#gate_05_lines_gates:hover,
#gate_06_lines_gates:hover,
#gate_07_lines_gates:hover,
#gate_08_lines_gates:hover,
#gate_09_lines_gates:hover,
#gate_10_lines_gates:hover,
#gate_11_lines_gates:hover,
#gate_12_lines_gates:hover,
#gate_13_lines_gates:hover,
#gate_14_lines_gates:hover,
#gate_15_lines_gates:hover,
#gate_16_lines_gates:hover,
#gate_17_lines_gates:hover,
#gate_18_lines_gates:hover,
#gate_19_lines_gates:hover,
#gate_20_lines_gates:hover,
#gate_20_lines_gates:hover,
#gate_20_lines_gates:hover,
#gate_21_lines_gates:hover,
#gate_22_lines_gates:hover,
#gate_23_lines_gates:hover,
#gate_24_lines_gates:hover,
#gate_25_lines_gates:hover,
#gate_26_lines_gates:hover,
#gate_27_lines_gates:hover,
#gate_28_lines_gates:hover,
#gate_29_lines_gates:hover,
#gate_30_lines_gates:hover,
#gate_31_lines_gates:hover,
#gate_32_lines_gates:hover,
#gate_33_lines_gates:hover,
#gate_34_lines_gates:hover,
#gate_35_lines_gates:hover,
#gate_36_lines_gates:hover,
#gate_37_lines_gates:hover,
#gate_38_lines_gates:hover,
#gate_39_lines_gates:hover,
#gate_40_lines_gates:hover,
#gate_41_lines_gates:hover,
#gate_42_lines_gates:hover,
#gate_43_lines_gates:hover,
#gate_44_lines_gates:hover,
#gate_45_lines_gates:hover,
#gate_46_lines_gates:hover,
#gate_47_lines_gates:hover,
#gate_48_lines_gates:hover,
#gate_49_lines_gates:hover,
#gate_50_lines_gates:hover,
#gate_51_lines_gates:hover,
#gate_52_lines_gates:hover,
#gate_53_lines_gates:hover,
#gate_54_lines_gates:hover,
#gate_55_lines_gates:hover,
#gate_56_lines_gates:hover,
#gate_57_lines_gates:hover,
#gate_58_lines_gates:hover,
#gate_59_lines_gates:hover,
#gate_60_lines_gates:hover,
#gate_61_lines_gates:hover,
#gate_62_lines_gates:hover,
#gate_63_lines_gates:hover,
#gate_64_lines_gates:hover,
#gate_01_circuit_gates:hover,
#gate_02_circuit_gates:hover,
#gate_03_circuit_gates:hover,
#gate_04_circuit_gates:hover,
#gate_05_circuit_gates:hover,
#gate_06_circuit_gates:hover,
#gate_07_circuit_gates:hover,
#gate_08_circuit_gates:hover,
#gate_09_circuit_gates:hover,
#gate_10_circuit_gates:hover,
#gate_11_circuit_gates:hover,
#gate_12_circuit_gates:hover,
#gate_13_circuit_gates:hover,
#gate_14_circuit_gates:hover,
#gate_15_circuit_gates:hover,
#gate_16_circuit_gates:hover,
#gate_17_circuit_gates:hover,
#gate_18_circuit_gates:hover,
#gate_19_circuit_gates:hover,
#gate_20_circuit_gates:hover,
#gate_21_circuit_gates:hover,
#gate_22_circuit_gates:hover,
#gate_23_circuit_gates:hover,
#gate_24_circuit_gates:hover,
#gate_25_circuit_gates:hover,
#gate_26_circuit_gates:hover,
#gate_27_circuit_gates:hover,
#gate_28_circuit_gates:hover,
#gate_29_circuit_gates:hover,
#gate_30_circuit_gates:hover,
#gate_31_circuit_gates:hover,
#gate_32_circuit_gates:hover,
#gate_33_circuit_gates:hover,
#gate_34_circuit_gates:hover,
#gate_35_circuit_gates:hover,
#gate_36_circuit_gates:hover,
#gate_37_circuit_gates:hover,
#gate_38_circuit_gates:hover,
#gate_39_circuit_gates:hover,
#gate_40_circuit_gates:hover,
#gate_41_circuit_gates:hover,
#gate_42_circuit_gates:hover,
#gate_43_circuit_gates:hover,
#gate_44_circuit_gates:hover,
#gate_45_circuit_gates:hover,
#gate_46_circuit_gates:hover,
#gate_47_circuit_gates:hover,
#gate_48_circuit_gates:hover,
#gate_49_circuit_gates:hover,
#gate_50_circuit_gates:hover,
#gate_51_circuit_gates:hover,
#gate_52_circuit_gates:hover,
#gate_53_circuit_gates:hover,
#gate_54_circuit_gates:hover,
#gate_55_circuit_gates:hover,
#gate_56_circuit_gates:hover,
#gate_57_circuit_gates:hover,
#gate_58_circuit_gates:hover,
#gate_59_circuit_gates:hover,
#gate_60_circuit_gates:hover,
#gate_61_circuit_gates:hover,
#gate_62_circuit_gates:hover,
#gate_63_circuit_gates:hover,
#gate_64_circuit_gates:hover,
#gate_01_08_circuit_channel:hover,
#gate_02_14_circuit_channel:hover,
#gate_03_60_circuit_channel:hover,
#gate_04_63_circuit_channel:hover,
#gate_05_15_circuit_channel:hover,
#gate_06_59_circuit_channel:hover,
#gate_07_31_circuit_channel:hover,
#gate_09_52_circuit_channel:hover,
#gate_10_20_circuit_channel:hover,
#gate_10_34_circuit_channel:hover,
#gate_10_57_circuit_channel:hover,
#gate_11_56_circuit_channel:hover,
#gate_12_22_circuit_channel:hover,
#gate_13_33_circuit_channel:hover,
#gate_16_48_circuit_channel:hover,
#gate_17_62_circuit_channel:hover,
#gate_18_58_circuit_channel:hover,
#gate_19_49_circuit_channel:hover,
#gate_20_34_circuit_channel:hover,
#gate_20_57_circuit_channel:hover,
#gate_21_45_circuit_channel:hover,
#gate_23_43_circuit_channel:hover,
#gate_24_61_circuit_channel:hover,
#gate_25_51_circuit_channel:hover,
#gate_26_44_circuit_channel:hover,
#gate_27_50_circuit_channel:hover,
#gate_28_38_circuit_channel:hover,
#gate_29_46_circuit_channel:hover,
#gate_30_41_circuit_channel:hover,
#gate_32_54_circuit_channel:hover,
#gate_34_57_circuit_channel:hover,
#gate_35_36_circuit_channel:hover,
#gate_37_40_circuit_channel:hover,
#gate_39_55_circuit_channel:hover,
#gate_42_53_circuit_channel:hover,
#gate_47_64_circuit_channel:hover,
.gate_hover {
  stroke-opacity: 1;
}
.blink_me {
  display: block !important;
  animation: blinker 3s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
