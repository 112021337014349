<template>
  <v-dialog
    v-model="showDialog"
    @click:outside="closeDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="950px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title style="justify-content: center">
        <span class="headline headline2" style="font-size: 1.3rem !important">
          Купить PDF-отчет
        </span>
        <v-btn @click="closeDialog" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="no-top-bottom-padding">
        <v-container class="no-top-bottom-padding mob-flex-center-height-100">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-bottom-padding mob-no-top-padding display-0-24-no-line-h"
            >
              <!-- <h3 v-html="getMessage('get_pdf_report_title')"></h3> -->
              <span v-html="getMessage('get_pdf_report')"></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center nomob-padding-bottom-52 nodesk-ac-btn-universal-dialog"
          >
            <v-btn
              @click="closeDialog"
              :href="shopUrl"
              target="_blank"
              class="tooltip-btn-bg mob-tooltip-btn-bg"
            >
              Купить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <hr />
      <v-card-text class="padding-top-20">
        <v-container class="no-top-bottom-padding">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-bottom-padding mob-no-top-padding display-0-24-no-line-h"
            >
              <!-- <h3 v-html="getMessage('get_pdf_base_report_title')"></h3> -->
              <span v-html="getMessage('get_pdf_base_report')"></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center nomob-padding-bottom-52 nodesk-ac-btn-universal-dialog"
          >
            <v-btn
              @click="closeDialog"
              :href="shopUrl2"
              target="_blank"
              class="tooltip-btn-bg mob-tooltip-btn-bg"
            >
              Купить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  name: "GetPDFsDialog",
  mixins: [commonInitAppMethods],
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    reportId: {
      type: Number,
      default: null
    },
    shopUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    shopUrl2() {
      return (
        "https://shop.human-design.space/order/best_rasshifrovka?tag=r" +
        this.reportId
      );
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeGetPDFsDialog");
    }
  }
};
</script>
