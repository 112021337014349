<template>
  <v-dialog
    :value="showDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="closeDialog"
    scrollable
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >Смена email</span
        >
        <v-btn @click="closeDialog()" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form1" lazy-validation>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="text-center">
                  <div class="log-sucsess">{{ changeSuccess }}</div>
                  <div class="log-errors">{{ changeErrors }}</div>
                </v-col>
                <v-col cols="12" class="flex-center">
                  Пин-код придет на ваш текущий email-адрес
                </v-col>
                <v-col cols="12" class="flex-center">
                  <v-btn @click="requestPin()" color="primary">
                    Запросить пин
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-form ref="form" lazy-validation>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <v-text-field
                    ref="formPin"
                    v-model="pin"
                    @keypress="isNumber($event)"
                    :rules="[rules.required, rules.pinCode]"
                    validate-on-blur
                    label="Пин-код"
                    hint="Введите пин-код из письма"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <v-text-field
                    v-model="newEmail"
                    :rules="[rules.required, rules.emailNewCheck]"
                    type="text"
                    validate-on-blur
                    label="Новый email"
                    hint="Введите новый email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <v-text-field
                    v-model="newEmailRepeat"
                    :rules="[
                      rules.required,
                      rules.emailRepeatCheck,
                      rules.emailConfirm
                    ]"
                    type="text"
                    validate-on-blur
                    label="Повторите"
                    hint="Повторите новый email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row class="d-flex justify-center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="text-center"
                >
                  <v-btn
                    @click="closeDialog()"
                    color="primary darken-1"
                    class="margin-right-3"
                    >Закрыть</v-btn
                  >
                  <v-btn @click="validateChangeEmail()" color="primary">
                    Изменить email
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from "@/api/api";
import { mapState } from "vuex";

export default {
  name: "ChangeEmailDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newEmail: "",
      newEmailRepeat: "",
      pin: "",
      changeSuccess: "",
      changeErrors: "",
      rules: {
        required: (value) => !!value || "Обязательный параметр.",
        emailNewCheck: (value) => {
          value = value.trim();
          this.newEmail = value;
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Неверный e-mail.";
        },
        emailRepeatCheck: (value) => {
          value = value.trim();
          this.newEmailRepeat = value;
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Неверный e-mail.";
        },
        emailConfirm: () =>
          this.newEmail === this.newEmailRepeat || "Emailы не совпадают",
        pinCode: (value) => {
          value = value.trim();
          if (value.length < 6) {
            return "Пин-код состоит из 6 цифр";
          } else {
            return true;
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      HttpOptions: (state) => state.conf.http_options
    })
  },
  methods: {
    validateChangeEmail() {
      if (this.$refs.form.validate()) {
        this.changeEmail();
      }
    },
    async changeEmail() {
      try {
        this.nameErrors = "";
        const changeEmailParams = {
          email: this.newEmail,
          pin: this.pin
        };
        const result = await Api.changeEmail(
          changeEmailParams,
          this.HttpOptions
        );
        if (result.success) {
          this.nameData = result;
          if (this.nameData.success) {
            this.changeSuccess = "Email успешно изменён!";
            localStorage.setItem("email", this.nameData.profile.email);
            this.$store.commit(
              "account/update_email",
              this.nameData.profile.email
            );
            setTimeout(() => this.closeDialog(), 500);
          } else {
            this.nameErrors = this.nameData.errors.message;
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
        this.nameErrors = e.response.data.message;
      }
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else if (charCode === 13) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async requestPin() {
      try {
        this.changeSuccess = "";
        this.changeErrors = "";
        const data = {};
        const result = await Api.requestEmailChangePin(data, this.HttpOptions);
        if (result.success) {
          this.changeSuccess = "Проверьте почту, пин-код успешно отправлен!";
        }
      } catch (e) {
        this.changeErrors = e.response.data.errors.reset_pin[0];
        // this.$errorHandler(e)
      }
    },
    closeDialog() {
      this.changeSuccess = "";
      this.changeErrors = "";
      this.$refs.form.reset();
      this.$emit("closeChangeEmailDialog");
    }
  }
};
</script>
<style scoped>
.margin-right-3 {
  margin-right: 3px;
}
</style>
